import { useAuth } from "contexts/AuthProvider";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash, FaFacebookF, FaGoogle } from "react-icons/fa6";
import { Link, Navigate } from "react-router-dom";
import AuthForm from "./AuthForm";

import Image1 from "assets/images/hero/mental-health-discussion.svg";

import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataAuthPage } from "data";
import "./AuthPage2.scss";

// NOTE : https://demo.bootstrapdash.com/login-templates-pro/login-8/#!

const msgSignIn = "Войти";
const msgSignUp = "Зарегистрироваться";
const msgAgreement =
  "Создавая аккаунт, вы соглашаетесь с условиями предоставления услуг и даёте согласие на обработку персональных данных.";
const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Отправить сообщение";

const SectionContent_ = (props) => {
  const { mode = "signin" } = props;
  let [authMode, setAuthMode] = useState(mode);

  const { auth, setAuthData, isAuthenticated, signIn, logout, register } =
    useAuth();

  const [username, setUsername] = useState("User1");
  const [email, setEmail] = useState("user4@mail.ru");
  const [password, setPassword] = useState("user123");
  const [confirmPassword, setConfirmPassword] = useState("user123");
  const [remember, setRemember] = useState(false);
  const [agreement, setAgreement] = useState(true);
  const [validate, setValidate] = useState({});

  const handleChangeAuthMode = (event) => {
    event.preventDefault();
    const newMode = authMode === "signin" ? "signup" : "signin";
    console.log(newMode);
    setAuthMode(newMode);
  };

  const validateLogin = () => {
    let isValid = true;

    let validator = null;
    //FormValidator.validator({
    //  email: {
    //    value: email,
    //    isRequired: true,
    //    isEmail: true,
    //  },
    //  password: {
    //    value: password,
    //    isRequired: true,
    //    minLength: 6,
    //  },
    //});

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleAuthenticate = (event) => {
    event.preventDefault();

    const validate = validateLogin();

    if (validate) {
      if (authMode === "signin") {
        //setAuthData({ email: email, password: password, remember: remember });
        signIn({ email: email, password: password, remember: remember });
      } else {
        //setAuthData({
        //  email: email,
        //  username: username,
        //  password: password,
        //  remember: remember,
        //});
        register({
          email: email,
          username: username,
          password: password,
          remember: remember,
        });
      }

      setValidate({});
      //setEmail("");
      //setPassword("");
      //alert("Successfully Login");
    }
  };

  const AuthForm = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = (e) => {
      if (showPassword) {
        setShowPassword(false);
      } else {
        setShowPassword(true);
      }
    };

    const FormInputEmail = () => {
      return (
        <Form.Group controlId="form.email" className="form-group">
          <Form.Label className="sr-only">Почта</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            className={`${
              validate.validate && validate.validate.email ? "is-invalid " : ""
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text
            id="emailHelpBlock"
            muted
            hidden={!(validate.validate && validate.validate.email)}
          >
            Ваш адрес электронной почты должен содержать буквы и цифры и не
            должен содержать пробелы, специальные символы или эмодзи.
          </Form.Text>
          <div
            className={`invalid-feedback text-start ${
              validate.validate && validate.validate.email
                ? "d-block"
                : "d-none"
            }`}
          >
            {validate.validate && validate.validate.email
              ? validate.validate.email[0]
              : ""}
          </div>
        </Form.Group>
      );
    };

    const FormInputPassword = () => {
      return (
        <Form.Group controlId="form.password" className="form-group mb-3">
          <Form.Label className="sr-only">Пароль</Form.Label>
          <InputGroup className="">
            <Form.Control
              type={showPassword ? "text" : "password"}
              className={`${
                validate.validate && validate.validate.password
                  ? "is-invalid "
                  : ""
              }`}
              placeholder="Ваш пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              aria-label="User's password"
              aria-describedby="basic-addon2"
            />
            <Button
              variant="outline-secondary"
              onClick={(e) => togglePassword(e)}
              id="button-addon3"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </Button>
          </InputGroup>
          <Form.Text
            id="passwordHelpBlock"
            muted
            hidden={!(validate.validate && validate.validate.email)}
          >
            Ваш пароль должен состоять из 8-20 символов, содержать буквы и числа
            и не должны содержать пробелов, специальных символов или смайлики.
          </Form.Text>
        </Form.Group>
      );
    };

    return (
      <Form
        className="auth-form"
        method="POST"
        onSubmit={props.onAuthenticate}
        autoComplete={"off"}
      >
        {props.mode === "signup" ? (
          <Fragment>
            <FormInputEmail />
            <FormInputPassword />
            <Form.Group
              controlId="form.confirm.password"
              className="form-group mb-3"
            >
              <Form.Label className="sr-only">Подтверждение пароля</Form.Label>
              <InputGroup className="">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  className={`${
                    validate.validate && validate.validate.password
                      ? "is-invalid "
                      : ""
                  }`}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  aria-label="User's confirm password"
                  aria-describedby="basic-addon2"
                />
                <Button
                  variant="outline-secondary"
                  onClick={(e) => togglePassword(e)}
                  id="button-addon2"
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </Button>
              </InputGroup>
              <Form.Text
                id="passwordHelpBlock"
                muted
                hidden={!(validate.validate && validate.validate.email)}
              >
                Ваш пароль должен состоять из 8-20 символов, содержать буквы и
                числа и не должны содержать пробелов, специальных символов или
                смайлики.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="form.agreement" className="login-check-box">
              <Form.Check
                type="checkbox"
                label={msgAgreement}
                id="agreement"
                checked={agreement}
                onChange={(e) => setAgreement(e.currentTarget.checked)}
              />
            </Form.Group>
          </Fragment>
        ) : (
          <Fragment>
            <FormInputEmail />
            <FormInputPassword />
            <Form.Group controlId="form.remember" className="login-check-box">
              <Form.Check
                type="checkbox"
                label="Запомнить"
                id="remember"
                checked={remember}
                onChange={(e) => setRemember(e.currentTarget.checked)}
              />
            </Form.Group>
          </Fragment>
        )}

        {props.mode === "signup" ? (
          <div className="text-end">
            <Link className="btn btn-link" onClick={props.onChangeMode}>
              Уже есть аккаунт2
            </Link>
          </div>
        ) : (
          <div className="text-end">
            Нет аккаунта?{" "}
            <Link className="btn btn-link" onClick={props.onChangeMode}>
              Зарегистрироваться
            </Link>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mb-5">
          <Button
            className="btn btn-primary theme-btn"
            variant="primary"
            type="submit"
          >
            {props.mode === "signin" ? msgSignIn : msgSignUp}
          </Button>
          <div className="forgot-password text-end">
            <Link to="/forgot-password">Забыли пароль?</Link>
          </div>
        </div>
      </Form>
    );
  };

  const SocialLoginLinks = () => {
    return (
      <nav className="social-login-links">
        <Link className="social-login-link">
          <img src={require("assets/icons/facebook.svg")} alt="facebook logo" />
          Facebook
        </Link>
        <Link href="#!" className="social-login-link">
          <img src={require("assets/icons/twitter.svg")} alt="twitter logo" />
          Twitter
        </Link>
        <Link href="#!" className="social-login-link">
          <img src={require("assets/icons/google.svg")} alt="google logo" />
          Google
        </Link>
      </nav>
    );
  };

  const SocicalLogin = (props) => {
    return (
      <div className="row social-login">
        <div className="col-6">
          <a href="#" className="btn btn-facebook w-100">
            <FaFacebookF className="me-1" /> Login
          </a>
        </div>
        <div className="col-6">
          <a href="#" className="btn btn-google w-100">
            <FaGoogle className="me-1" /> Login
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-7 col-lg-6 auth-left">
            <img
              src={Image1}
              className="img-fluid"
              alt={
                authMode === "signin" ? "Client registration" : "Client login"
              }
            />
          </div>
          <div className="col-md-12 col-lg-6 auth-right">
            <div className="auth-header">
              <h3>
                {authMode === "signin"
                  ? "Зарегистрируйся, чтобы начать"
                  : "Вход в личный кабинет"}
              </h3>
            </div>

            <AuthForm
              mode={authMode}
              onChangeMode={handleChangeAuthMode}
              onAuthenticate={handleAuthenticate}
            />
            <h6 className="auth-social">OR</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { mode, onSubmit, onChangeMode } = props;

  return (
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <div className="auth-wrapper">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7 col-lg-6 auth-left">
              <img
                src={Image1}
                className="img-fluid"
                alt={mode === "signin" ? "Client registration" : "Client login"}
              />
            </div>
            <div className="col-md-12 col-lg-6 auth-right">
              <div className="auth-header mb-5">
                <h3>
                  {mode === "signin"
                    ? "Вход в личный кабинет"
                    : "Зарегистрируйся, чтобы начать"}
                </h3>
              </div>

              <AuthForm
                mode={mode}
                onChangeMode={onChangeMode}
                onSubmit={onSubmit}
              />
              <h6 className="auth-social">OR</h6>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing Page "Customer Auth" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const CustomerAuthPage = (props) => {
  const { id = "auth", mode = "signin" } = props;
  let [authMode, setAuthMode] = useState(mode);
  const {
    auth,
    isAuthenticated,
    customerLogin: signIn,
    customerRegister: signUp,
    logout,
  } = useAuth();

  const handleChangeAuthMode = (event) => {
    event.preventDefault();
    const newMode = authMode === "signin" ? "signup" : "signin";
    // console.log(newMode);
    setAuthMode(newMode);
  };

  return (
    <Fragment>
      <SEO data={dataAuthPage.meta}>
        {/*Only canonicalize pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://psywealth.com/lk/auth" />
        {/* <script type="application/ld+json">{JSON.stringify(ldJson)}</script> */}
      </SEO>

      <PageMain id={id}>
        {isAuthenticated && <Navigate to="/customer/profile" replace={true} />}
        <SectionContent
          mode={authMode}
          onChangeMode={handleChangeAuthMode}
          onSubmit={authMode === "signin" ? signIn : signUp}
        />
      </PageMain>
    </Fragment>
  );
};

CustomerAuthPage.propTypes = {
  mode: PropTypes.oneOf(["signin", "signup"]),
  type: PropTypes.oneOf(["style1"]),
};

CustomerAuthPage.defaultProp = {};

export default CustomerAuthPage;

/* 


{authMode === "signin" ? (
                  <Fragment>
                    <div className="login-header">
                      <h3>
                        Клиенты Register{" "}
                        <a href="doctor-register.html">Are you a Doctor?</a>
                      </h3>
                    </div>
       ) : (      <div className="login-header">
                      <h3>
                        Login <span>Клиенты</span>
                      </h3>
                    </div>
       )}
                    <AuthForm
                      mode={authMode}
                      onChangeMode={handleChangeAuthMode}
                    />

               </Fragment>

            </div>

     <form action="patient-register-step1.html">
                      <div className="mb-3 form-focus">
                        <input type="text" className="form-control floating" />
                        <label className="focus-label">Name</label>
                      </div>
                      <div className="mb-3 form-focus">
                        <input type="text" className="form-control floating" />
                        <label className="focus-label">Mobile Number</label>
                      </div>
                      <div className="mb-3 form-focus">
                        <input
                          type="password"
                          className="form-control floating"
                        />
                        <label className="focus-label">Create Password</label>
                      </div>
                      <div className="text-end">
                        <Button
                          className="btn btn-link forgot-link"
                          onClick={handleChangeAuthMode}
                        >
                          Already have an account?
                        </Button>
                      </div>
                      <button
                        className="btn btn-primary w-100 btn-lg login-btn"
                        type="submit"
                      >
                        Signup
                      </button>
                      <div className="login-or">
                        <span className="or-line"></span>
                        <span className="span-or">or</span>
                      </div>

                      <SocicalLogin />
                    </form>
                  </Fragment>
         
                  <Fragment>
              
                    <form action="index.html">
                      <div className="mb-3 form-focus">
                        <input type="email" className="form-control floating" />
                        <label className="focus-label">Email</label>
                      </div>
                      <div className="mb-3 form-focus">
                        <input
                          type="password"
                          className="form-control floating"
                        />
                        <label className="focus-label">Password</label>
                      </div>
                      <div className="text-end">
                        <a className="forgot-link" href="forgot-password.html">
                          Forgot Password ?
                        </a>
                      </div>
                      <button
                        className="btn btn-primary w-100 btn-lg login-btn"
                        type="submit"
                      >
                        Login
                      </button>
                      <div className="login-or">
                        <span className="or-line"></span>
                        <span className="span-or">or</span>
                      </div>
                      <SocicalLogin />
                      <div className="text-center dont-have">
                        Don’t have an account?{" "}
                        <Button
                          className="btn btn-link"
                          onClick={handleChangeAuthMode}
                        >
                          Register
                        </Button>
                      </div>
                    </form>
                  </Fragment>
                )}
   <div className="container-fluid">
        <div className="row">
          <div className="col-sm-5 intro-section">
            <div className="brand-wrapper">
              <img
                src={require("assets/icons/logo-64x64.png")}
                alt=""
                className="logo"
              />
            </div>
            <div className="intro-content-wrapper">
              <h1 className="intro-title">{data.title}</h1>
              <p className="intro-text">{data.description}</p>
            </div>
            <div className="intro-section-footer">
              <div className="copyright">
                © Copyright {dataSite.main.copyrightYear}{" "}
                <strong>
                  <span className="copyright-text">
                    {dataSite.main.copyrightText}
                  </span>
                </strong>
              </div>
            </div>
          </div>
          <div className="col-sm-7 form-section">
            <div className="login-wrapper-signup-text text-center">
              {authMode === "signin"
                ? "Need an account?"
                : "Already registered?"}{" "}
              <span className="link-primary" onClick={handleChangeAuthMode}>
                {authMode === "signin" ? "Sign In" : "Sign Up"}
              </span>
            </div>

            <div className="login-wrapper">
              <h2 className="login-title">
                {authMode === "signin" ? "Sign In" : "Sign Up"}
              </h2>
              <LoginForm onChangeAuthMode={handleChangeAuthMode} />
              <h6 className="social-login-title">OR</h6>
              <SocialLoginLinks />
            </div>
          </div>
        </div>
      </div>
*/
