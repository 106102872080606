import { dataSite } from "data/dataSite";

export const dataMetaNotFoundPage = {
  title:
    "Страница не найдена. PsyWealth — подбор психологов и психотерапевтов.",
  description: `Бесплатно подберём психотерапевта под ваш запрос и бюджет. Проверенные психологи и психотерапевты от 2000 руб. Лично в Москве или онлайн из любой точки мира.`,
  name: "",
  type: "",
  author: "PsyWealth, Inc.",
  ExtraMetaTags: [{ name: "robots", content: "noindex, follow" }],
  OG: [
    {
      property: "og:title",
      content:
        "Страница не найдена. PsyWealth — подбор психолога и психотерапевта.",
    },
    {
      property: "og:description",
      content: `Бесплатно подберём психотерапевта под ваш запрос и бюджет. Проверенные психологи и психотерапевты от 2000 руб. Лично в Москве или онлайн из любой точки мира. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.`,
    },
    {
      property: "og:url",
      content: "https://psywealth.com/not-found",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "PsyWealth" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "PsyWealth",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
