import CustomHTML from "components/core/CustomHTML";
import { Fragment } from "react";
import { Accordion } from "react-bootstrap";

/**
 * Component for showing section "Feature" of the site.
 *
 * @component
 */
const FAQAccordion = (props) => {
  const { data, showMain, screenCol } = props;
  // console.log(props);

  let items = data.items;

  if (screenCol === 1) {
    items = items.filter((item, index) => index % 2);
  } else if (screenCol === 2) {
    items = items.filter((item, index) => (index + 1) % 2);
  } else {
    if (showMain) {
      items = items.filter((item) => item.main === true);
    }
  }

  const AccordionItem = ({ item, index }) => {
    return (
      <Accordion.Item
        eventKey={index}
        key={index}
        className="faq-item single-accordion"
      >
        <Accordion.Header as="div" className="faq-item-header accordion-btn">
          <h5>{item.title}</h5>
        </Accordion.Header>
        <Accordion.Body className="faq-item-content accordion-content">
          {/* {console.log(item.componentHTML)} */}
          {item.componentHTML && (
            <CustomHTML data={item.componentHTML.data || item.componentHTML} />
          )}
          {item.description && (
            <Fragment>
              {typeof item.description === "object" && item.description}
              {typeof item.description === "string" && (
                <p>{item.description}</p>
              )}
            </Fragment>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const AccordionItems = (props) => {
    return (
      <Fragment>
        {props.data &&
          props.data.map((item, index) => {
            // console.log(item, typeof item.description);
            return <AccordionItem item={item} index={index} key={index} />;
          })}
      </Fragment>
    );
  };

  return (
    <div className="faq-container accordion-style">
      <Accordion defaultActiveKey="0">
        <AccordionItems data={items} />
      </Accordion>
    </div>
  );
};

FAQAccordion.defaultProp = { showMain: true, screenCol: 0 };

export default FAQAccordion;
