import classNames from "classnames";
import { PropTypes } from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import HeroControl from "./HeaderControl";

import "./Hero4.scss";

const msgPrimary = "Найти специалиста";
const msgSecondary = "Бонус за регистрацию";

// NOTE :

const HeroImage = (props) => {
  const { data, ...otherProps } = props;

  return (
    <Link
      to="/matching"
      title=""
      target="_self"
      className={classNames(
        "pictures__item overflow-hidden",
        `pictures__item_${data.name}`,
        data.cn
      )}
      style={{ gridArea: data.name }}
    >
      <div className="pictures__item-inner">
        <img
          src={data.image.src}
          alt={data.image.alt}
          className="pictures__item-img"
        />
      </div>
    </Link>
  );
};

const VerticalLineItem = (props) => {
  const { data } = props;
  return (
    <div className="vertical-line__item">
      <div className="vertical-line__logo-wrap">
        <img
          className="vertical-line__logo image-loaded"
          alt={data.image.alt}
          src={data.image.src}
          data-lazy={data.image.lazy}
        />
      </div>
      <div className="vertical-line__content">
        <div className="vertical-line__title">{data.title}</div>
        <div className="vertical-line__subtitle">{data.subtitle}</div>
      </div>
    </div>
  );
};

const VerticalLineItem2 = (props) => {
  const { data } = props;
  return (
    <div className="vertical-line__item">
      {/* <div className="vertical-line__logo-wrap">
        <img
          className="vertical-line__logo image-loaded"
          alt={data.image.alt}
          src={data.image.src}
          data-lazy={data.image.lazy}
        />
      </div>
      <div className="vertical-line__content">
        <div className="vertical-line__title">{data.title}</div>
        <div className="vertical-line__subtitle">{data.subtitle}</div>
      </div>*/}

      <div
        className="bg-image-wrapper"
        style={{ maxWidth: "28rem", borderRadius: "24px" }}
      >
        <img
          src={
            data.image.src ||
            "https://mdbcdn.b-cdn.net/img/new/standard/city/053.webp"
          }
          className=""
          alt=""
          style={
            {
              //borderRadius: "24px", height: "100%"
              // overflow: "hidden",
              // borderRadius: "24px",
            }
          }
        />
        <div
          className="overlay text-light d-flex justify-content-center flex-column text-center"
          style={{
            // backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "24px",
          }}
        >
          {data.title && <h6 className="text-light">{data.title}</h6>}
          {data.subtitle && <p className="text-light m-0">{data.subtitle}</p>}
        </div>
      </div>
    </div>
  );
};

const VerticalLine = (props) => {
  const { data } = props;

  return (
    <div
      className="section__main-right anim-fade scrolled"
      data-scroll="0"
      data-delay="0.2"
      style={{ transitionDelay: "0.2s" }}
    >
      <div className="vertical-line">
        <div className="vertical-line__list">
          {data.itemsVL[0].items.map((item, index) => (
            <VerticalLineItem2 data={item} key={index} />
          ))}
        </div>

        <div className="vertical-line__list">
          {data.itemsVL[1].items.map((item, index) => (
            <VerticalLineItem2 data={item} key={index} />
          ))}
        </div>

        <div className="vertical-line__list">
          {data.itemsVL[2].items.map((item, index) => (
            <VerticalLineItem2 data={item} key={index} />
          ))}
        </div>

        <div className="vertical-line__top"></div>
        <div className="vertical-line__bottom"></div>
      </div>
    </div>
  );
};

/**
 * Component section content "Hero (style 4)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle4 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    innerRef,
    onSubmit,
    onRegister,
    ...otherProps
  } = props;
  const navigate = useNavigate();
  // console.log(props);

  const heroControl = {
    primary: { title: msgPrimary },
    secondary: { title: msgSecondary },
  };

  const handlerSubmit = () => {
    onSubmit && onSubmit();
  };

  const handlerRegister = () => {
    navigate("/lk/auth");
    onRegister && onRegister();
  };

  // <div className="py-lg-9 py-6 bg-cover bg-light">
  return (
    <div className="py-lg-9 py-6 bg-cover">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12">
            <div>
              <div className="text-center text-md-start ">
                <h1 className="display-5 fw-bold mb-3">{data.title}</h1>
                <h2 className="display-6 mb-3">{data.subtitle}</h2>
                <p className="lead p-large">{data.description}</p>

                <HeroControl
                  data={heroControl}
                  cn="mt-8"
                  onClickPrimary={handlerSubmit}
                  onClickSecondary={handlerRegister}
                />
              </div>

              <span className="fs-4"></span>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-5 col-12 text-center">
            <VerticalLine data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionContentStyle4.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle4;
