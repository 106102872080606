import { SectionHeader } from "components/sections/CommonSection";
import { FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

import "./FeaturePsyFormats2.scss";

const HoverButton = () => {
  return (
    <div className="card-hover-btn mui-flex mui-items-center main-intro-card__btn">
      <div className="card-hover-btn__text mui-text-caption-1">
        {" "}
        Подобрать психолога{" "}
      </div>
      <div className="card-hover-btn__arrow mui-flex mui-items-center mui-justify-center">
        <FaChevronRight
          className="mui-icon"
          style={{ width: "32px", height: "32px" }}
        >
          <Link to="/" />
        </FaChevronRight>
      </div>
    </div>
  );
};

const SectionItemStyle2 = (props) => {
  const { data, index, showTitle, showDescription } = props;

  //console.log(props);
  return (
    <div className="col-xl-3 col-lg-3 col-sm-6">
      <div className="feature-box feature-psy-formats">
        {data.image && (
          <div className="card">
            <img
              className="card-img img-fluid box-image"
              src={data.image.src}
              alt={data.image.alt}
              {...(data.image.props && {
                ...data.image.props,
              })}
            />
            <div className="card-img-overlay">
              <div className="feature-content box-content">
                {showTitle && (
                  <h3 className="feature-title box-title">{data.title}</h3>
                )}
                {showDescription && (
                  <p className="feature-desc box-desc">{data.description}</p>
                )}
                <HoverButton />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data, type, showTitle, showDescription } = props;
  const items = data.items.slice(0, type === "style1" ? 3 : 4);

  //console.log(props);

  // if (
  //   (type === "style1" && data.items.lenght !== 3) ||
  //   (type === "style2" && data.items.lenght !== 4)
  // ) {
  //   _debug("Wrong data items sets!");
  // }

  return (
    <div className="row equal">
      {items &&
        items.map((item, index) => {
          return (
            <SectionItemStyle2
              data={item}
              index={index}
              key={index}
              showTitle={showTitle}
              showDescription={showDescription}
            />
          );
        })}
    </div>
  );
};

/**
 * Component for showing section "Feature - Psychotherapy formats" of the site.
 *
 * @component
 */
const FeaturePsyFormats1 = (props) => {
  const {
    data,
    type,
    showHeaderSubtitle,
    showHeaderDescription,
    showTitle = true,
    showDescription = true,
  } = props;

  //console.log("11", props);

  return (
    <div className="container">
      <SectionHeader
        data={data}
        showSubtitle={showHeaderSubtitle}
        showDescription={showHeaderDescription}
      />
      <SectionContent
        data={data}
        type={type}
        showTitle={showTitle}
        showDescription={showDescription}
      />
    </div>
  );
};

FeaturePsyFormats1.propTypes = {};

export default FeaturePsyFormats1;
