import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogPosts = (params = {}, options = {}) => {
  // console.log("!", params);

  return useQuery({
    queryFn: () => BlogService.getBlogPosts(params),
    queryKey: ["blog_posts", params.pageNo ? params.pageNo : params],
    enabled: typeof params !== typeof undefined,
  });
};
