import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataSearchPage } from "data";
import { Fragment } from "react";
import { SearchSection } from "./components";

/**
 * Component for showing Page "Search" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageSearch = (props) => {
  const { id = "search" } = props;

  return (
    <Fragment>
      <SEO data={dataSearchPage.meta}>
        {/*Don’t canonicalise to a redirected page or a 404 page
         *<link rel="canonical" href="https://psywealth.com/not-found" />
         */}
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataSearchPage} />
        <SearchSection />
      </PageMain>
    </Fragment>
  );
};

export default PageSearch;
