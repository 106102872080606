import { PropTypes } from "prop-types";

import CustomSwiper from "components/core/Swiper";
import CardTherapist2 from "components/pages/Therapists/CardTherapist2";
import { SwiperSlide } from "swiper/react";
import "./Section.style.scss";

// NOTE :

const SectionContent = (props) => {
  return null;
};

/**
 * Component section content "Top Psychologist (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;
  // console.log("object");

  const items =
    data.items &&
    data.items.length > 0 &&
    data.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <CardTherapist2 data={item} index={index} key={index} type="style3" />
        </SwiperSlide>
      );
    });

  return (
    <div className="row">
      <div className="col-12">
        <div className="section-content slider">
          <CustomSwiper renderItems={items} />
        </div>
      </div>
    </div>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
