import { FAQAccordion } from "components/sections/FAQ/components";
import { Spinner } from "components/utils/Spinner";
import { PageSection, SectionHeader } from "core/components";
import { dataMessages } from "data";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetFAQByCourse } from "../utils";

const msgSubmit = "Написать";

const SectionContent = (props) => {
  const { data } = props;

  const newItems = data.items.map(
    ({ question: title, answer: description, content: componentHTML }) => ({
      title,
      description,
      componentHTML: componentHTML ? { data: componentHTML } : null,
      main: false,
      disable: false,
    })
  );

  return (
    <div className="row">
      <FAQAccordion data={{ ...data, items: newItems }} showMain={false} />
    </div>
  );
};

/**
 * Component for showing section "FAQ" of the site.
 *
 * @component
 */
const CourseFAQSection = (props) => {
  const { data, id = "course-faq", cn = "pt-140 pb-100" } = props;
  const { courseSlug: slug } = data;

  console.log("$$$$", data);

  const {
    data: faq,
    isLoading: isLoadingCourse,
    isError: isErrorCourse,
    error: errorCourse,
  } = useGetFAQByCourse(slug);

  const isLoading = isLoadingCourse;
  const isError = isErrorCourse;
  const errors = [errorCourse];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (faq) {
      _debug("COURSE FAQ:", faq);
    }
  }, [faq]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorCourse && errorCourse?.status === 204) {
    return <div className="error">Нет вопросов и ответов для курса</div>;
  }

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={{ ...data, items: faq }} />
      </div>
    </PageSection>
  );
};

CourseFAQSection.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default CourseFAQSection;
