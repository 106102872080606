import classNames from "classnames";
import { Spinner } from "components/utils/Spinner";
import { BLOG_ID, SITE } from "config/config";
import { dataMessages } from "data";
import { DOTS, usePagination } from "hooks";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { _debug } from "utils";
import BlogPostCard2 from "./components/BlogPostCard2";
import { useGetBlogPosts } from "./utils";

const PAGES_LIMIT = SITE.BLOG.PAGES_LIMIT;
const DEFAULT_PAGES_LIMIT = 6;
const initialState = {
  pageNo: 0,
  totalPages: 0,
  totalItems: 0,
  pageSize: PAGES_LIMIT || DEFAULT_PAGES_LIMIT,
  blog: BLOG_ID,
};

// NOTE : https://html.rrdevs.net/edcare/blog-details.html
// NOTE : https://themes.getbootstrap.com/preview/?theme_id=103332

const BlogListPagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex - 1);
  };

  const onPrevious = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex + 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  // Old function to delete: 16/12/2024
  const renderPaginationItems_old = () => {
    let content = [];
    for (let index = 0; index < totalCount; index++) {
      //console.log(index);
      //const item = animals[i];
      content.push(
        <Pagination.Item
          onClick={() => onPageChange(index)}
          key={index}
          active={index === currentPage - 1}
        >
          {index + 1}
        </Pagination.Item>
      );
    }
    return content;
  };

  const renderPaginationItems = () => {
    return (
      <Fragment>
        {paginationRange.map((pageNumber, index) => (
          <Fragment key={index}>
            {pageNumber === DOTS ? (
              <Pagination.Ellipsis key={index} className="dots">
                &#8230;
              </Pagination.Ellipsis>
            ) : (
              <Pagination.Item
                key={index}
                className={classNames(
                  "",
                  {
                    selected: pageNumber === currentPage,
                  },
                  {
                    active: pageNumber === currentPage,
                  }
                )}
                onClick={() => onPageChange(index)}
              >
                {pageNumber}
              </Pagination.Item>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <Pagination className="d-flex justify-content-center align-items-center">
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => onPageChange(0)}
      />
      <Pagination.Prev disabled={currentPage === 1} onClick={() => onNext()} />
      {renderPaginationItems()}
      <Pagination.Next
        disabled={currentPage === lastPage}
        onClick={() => onPrevious()}
      />
      <Pagination.Last
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(lastPage - 1)}
      />
    </Pagination>
  );
};

/**
 * Component for showing "Blog list of posts page" of the site.
 *
 * @component
 */
const PostsListPage = (props) => {
  const { isList } = props;
  const [pageState, setPageState] = useState(initialState);

  // const URL = `${BLOG_API_URI}/posts?`;
  // const fetchProjects = (page = 0) =>
  //   fetch(`${BLOG_API_URI}/posts?page=${page}`).then((res) => res.json());

  const {
    data: blogPagedPosts,
    isLoading: isLoadingBlogPagePosts,
    isError: isErrorBlogPagePosts,
    error: errorBlogPagePosts,
  } = useGetBlogPosts({
    blog: BLOG_ID,
    pageNo: pageState.pageNo || 0,
    pageSize: pageState.pageSize || DEFAULT_PAGES_LIMIT,
  });

  const isLoading = isLoadingBlogPagePosts;
  const isError = isErrorBlogPagePosts;
  const errors = [errorBlogPagePosts];

  // const {
  //   data: postPages,
  //   isLoading,
  //   hasError,
  //   errorMessage,
  //   updateUrl,
  //   updateParams,
  // } = useFetch(URL, pageState);

  // console.log(pageState);
  // console.log(postPages);

  //const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return blogPosts?.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  const handleChangeCurrentPage = (_pageNumber) => {
    setPageState((prev) => ({ ...prev, pageNo: _pageNumber }));
  };

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (blogPagedPosts) {
      _debug("BLOG PAGE POSTS:", blogPagedPosts);

      setPageState((prev) => ({
        ...prev,
        pageNo: blogPagedPosts.page,
        pageSize: blogPagedPosts.size,
        totalPages: blogPagedPosts.totalPages,
        totalItems: blogPagedPosts.totalElements,
      }));
    }
  }, [blogPagedPosts]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isError && errorBlogPagePosts?.status === 204) {
    return <div className="error">Нет данных о новых ростах</div>;
  }

  return (
    <div className="post-list-wrapper">
      {/* Posts */}
      {isList ? (
        blogPagedPosts &&
        blogPagedPosts.content &&
        blogPagedPosts.content.length &&
        blogPagedPosts.content.map((item, index) => (
          <BlogPostCard2 data={item} key={index} />
        ))
      ) : (
        <div className="row gx-md-5 gy-5 mb-5">
          {blogPagedPosts &&
            blogPagedPosts.content &&
            blogPagedPosts.content.length &&
            blogPagedPosts.content.map((item, index) => (
              <div className="col-md-6" key={index}>
                <BlogPostCard2 data={item} />
              </div>
            ))}
        </div>
      )}

      {/* Pagination */}
      <div className="pagination-wrapper">
        <BlogListPagination
          currentPage={pageState.pageNo + 1}
          totalCount={pageState.totalItems}
          pageSize={pageState.pageSize}
          siblingCount={1}
          onPageChange={handleChangeCurrentPage}
        />
      </div>
    </div>
  );
};

PostsListPage.propTypes = {
  isList: PropTypes.bool,
  type: PropTypes.oneOf(["style1"]),
};

PostsListPage.defaultProp = { isList: false, type: "style1" };

export default PostsListPage;
