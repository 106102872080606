import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";

// export type UseModalResp = {
//   modal: ReactNode
//   closeModal: () => void
//   openModal: () => void
//   modalBoxClassName?: string
// }

// export type UseModalProps = Omit<ModalProps, 'onBackdropClick'> & {
//   shouldAllowBackdropClick?: boolean //if it is true then modal can be closed
//   onModalOpen?: () => void //this function will be called on calling of openModal
//   onModalClose?: () => void //this function will be called on calling of closeModal
// }

/**
 * React hook for showing modal windows
 *
 * @hook
 * @version: 1.0.0
 **/
const useModal = ({ children, modalClassName, onModalClose, onModalOpen }) => {
  const ref = useRef(null);
  const [modalShow, setModalShow] = useState(false);

  const closeModal = () => {
    onModalClose && onModalClose();
    setModalShow(false);
  };

  const openModal = () => {
    // console.log("object;");
    onModalOpen && onModalOpen();
    setModalShow(true);
  };

  const modal = (
    <Modal
      show={modalShow}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      ref={ref}
      className={modalClassName}
    >
      {children}
    </Modal>
  );
  return {
    closeModal,
    openModal,
    modal,
  };
};

export default useModal;
