import classNames from "classnames";
import { formatDataShort } from "components/utils/Utils";
import { BLOG_API_URI } from "config/config";
import { FaRegCalendarAlt, FaRegComment, FaRegHeart } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";

const POST_URL = `${BLOG_API_URI}/posts`;

/**
 * Component for showing Component "Blog post Metadata" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostMetadata = (props) => {
  const { data, wrapperStyle = "" } = props;

  // console.log(data);

  let iconStyles = { color: "white", fontSize: "1.5em" };

  const MetaText = ({ to, icon, children }) => {
    return (
      <a href={to} className="small text-muted">
        <i>{icon}</i>
        {children}
      </a>
    );
  };

  return (
    <div className="post-meta-wrapper">
      <ul className={classNames("post-meta unordered-list", wrapperStyle)}>
        <li className="post-date">
          <MetaText to={"#!"} icon={<FaRegCalendarAlt className="icon" />}>
            {formatDataShort(
              data.updatedDate ? data.updatedDate : data.createdDate
            )}
          </MetaText>
        </li>
        <li className="post-views">
          <i>
            <FaRegEye />
          </i>
          {data.viewsCount ? `${data.viewCount}` : "120"}
          {/*  <MetaText to={"#!"} icon={<FaRegEye />}>
            {data.viewsCount ? `${data.viewCount}` : "120 views"}
          </MetaText> */}
        </li>
        <li className="post-comments">
          <i>
            <FaRegComment />
          </i>
          {data.commentsCount ? `${data.commentsCount}` : "нет"}
          {/*  <MetaText to={"#!"} icon={<FaRegComment />}>
            {data.commentsCount ? `${data.commentsCount}` : "No Comments"}
          </MetaText>*/}
        </li>
        <li className="post-likes ms-auto">
          <MetaText to={"#!"} icon={<FaRegHeart />}>
            {data.likesCount ? `${data.likesCount}` : null}
          </MetaText>
        </li>
      </ul>
    </div>
  );
};

export default BlogPostMetadata;
