import { Fragment } from "react";
import { Form } from "react-bootstrap";

/**
 * Component for showing modal form "Step - 3" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const Step3 = (props) => {
  const { data: formData, errors, register } = props;

  return (
    <Fragment>
      {/* Checks */}
      {["check1", "check2", "check3", "check4"].map((item, index) => (
        <Form.Group
          className="mb-3"
          controlId={`formCheckbox${index}`}
          key={index}
        >
          <Form.Check
            type="checkbox"
            label={formData[item].label}
            isInvalid={!!errors[item]}
            {...register(item, {
              required: formData[item].required,
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors[item]?.message}
          </Form.Control.Feedback>
        </Form.Group>
      ))}
    </Fragment>
  );
};

Step3.propTypes = {};

export default Step3;
