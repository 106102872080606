import React from "react";

import { SectionAboutUs } from "./AboutUs";
import { SectionAskQuestion } from "./AskQuestion";
import { SectionCities } from "./Cities";
import { SectionContacts } from "./Contacts";
import { SectionCallToAction } from "./CTA2";
import { SectionDemo } from "./demo/Demo";
import { SectionFAQ } from "./FAQ";
import { SectionFeatureOfFreeHelp } from "./FeatureOfFreeHelp";
import { SectionFeatureTabs } from "./FeatureTabs";
import { SectionHero } from "./Hero";
import { SectionHowItWorks } from "./HowItWorks";
import { SectionUsedFor } from "./NewSection";
import { SectionOurTeam } from "./OurTeam";
import { SectionOverview } from "./Overview";
import { SectionProblems } from "./Problems";
import { SectionServices } from "./Services";
import { SectionSubscribe } from "./Subscribe";
import { SectionTestimonial } from "./Testimonial";
import { SectionTopAdvice } from "./TopAdvice";
import { SectionTopCourses } from "./TopCourses";
import {
  SectionRelatedPosts,
  SectionTopPosts,
  SectionTopPostsByAuthor,
} from "./TopPosts";
import { SectionTopPsychologists } from "./TopPsychologists";
import { SectionWhatWeDo } from "./WhatWeDo";

import { Banner } from "./Banner";
import Feature from "./Feature";
import Feature1 from "./Feature1";
import Feature10 from "./Feature10";

const SectionAboutUsLazy = React.lazy(() =>
  import("./AboutUs/AboutUs.section")
);
const SectionAskQuestionLazy = React.lazy(() =>
  import("./AskQuestion/AskQuestion.section")
);
const SectionCitiesLazy = React.lazy(() => import("./Cities/Cities.section"));
const SectionSubscribeLazy = React.lazy(() =>
  import("./Subscribe/Subscribe.section")
);
const SectionFAQLazy = React.lazy(() => import("./FAQ/FAQ.section"));
const SectionFeatureTabsLazy = React.lazy(() =>
  import("./FeatureTabs/FeatureTabs.section")
);
const SectionContactsLazy = React.lazy(() =>
  import("./Contacts/Contacts.section")
);
const SectionServiceLazy = React.lazy(() =>
  import("./Services/Services.section")
);
const SectionHowItWorksLazy = React.lazy(() =>
  import("./HowItWorks/HowItWorks.section")
);
const SectionUsedForLazy = React.lazy(() =>
  import("./NewSection/UsedFor.section")
);
const SectionCallToActionLazy = React.lazy(() =>
  import("./CTA2/CallToAction.section")
);
const SectionTopAdviceLazy = React.lazy(() =>
  import("./TopAdvice/TopAdvice.section")
);
const SectionProblemsLazy = React.lazy(() =>
  import("./Problems/Problems.section")
);
const SectionOverviewLazy = React.lazy(() =>
  import("./Overview/Overview.section")
);
const SectionOurTeamLazy = React.lazy(() =>
  import("./OurTeam/OurTeam.section")
);
const SectionTopPostsLazy = React.lazy(() =>
  import("./TopPosts/TopPosts.section")
);
const SectionRelatedPostsLazy = React.lazy(() =>
  import("./TopPosts/RelatedPosts.sections")
);
const SectionTopPostsByAuthorLazy = React.lazy(() =>
  import("./TopPosts/TopPostsByAuthor.section")
);
const SectionTopCoursesLazy = React.lazy(() =>
  import("./TopCourses/TopCourses.section")
);
const SectionTopPsychologistsLazy = React.lazy(() =>
  import("./TopPsychologists/TopPsychologists.section")
);
const SectionTestimonialLazy = React.lazy(() =>
  import("./Testimonial/Testimonial.section")
);
const SectionWhatWeDoLazy = React.lazy(() =>
  import("./WhatWeDo/WhatWeDo.section")
);
const Process = React.lazy(() => import("./HowItWorks/components/Process"));
const FeatureRound = React.lazy(() => import("./FeatureRound"));
const DetailsOverview = React.lazy(() => import("./demo/DetailsOverview"));
const Service = React.lazy(() => import("./Service"));
const Pricing = React.lazy(() => import("./Pricing"));
const FeatureTabs = React.lazy(() => import("./FeatureTabs"));
// const CallToAction = React.lazy(() => import("./CTA/CallToAction"));
const SectionSlogan = React.lazy(() => import("./CTA/SectionSlogan"));
const OurProducts = React.lazy(() => import("./OurProducts/OurProducts"));

export {
  Banner,
  DetailsOverview,
  Feature,
  Feature1,
  Feature10,
  FeatureRound,
  FeatureTabs,
  OurProducts,
  DetailsOverview as Overview2,
  Pricing,
  Process,
  SectionAboutUs,
  SectionAboutUsLazy,
  SectionAskQuestion,
  SectionAskQuestionLazy,
  SectionCallToAction,
  SectionCallToActionLazy,
  SectionCities,
  SectionCitiesLazy,
  SectionContacts,
  SectionContactsLazy,
  SectionDemo,
  SectionFAQ,
  SectionFAQLazy,
  SectionFeatureOfFreeHelp,
  SectionFeatureTabs,
  SectionFeatureTabsLazy,
  SectionHero,
  SectionHowItWorks,
  SectionHowItWorksLazy,
  SectionOurTeam,
  SectionOurTeamLazy,
  SectionOverview,
  SectionOverviewLazy,
  SectionProblems,
  SectionProblemsLazy,
  SectionRelatedPosts,
  SectionRelatedPostsLazy,
  SectionServiceLazy,
  SectionServices,
  SectionSlogan,
  SectionSubscribe,
  SectionSubscribeLazy,
  SectionTestimonial,
  SectionTestimonialLazy,
  SectionTopAdvice,
  SectionTopAdviceLazy,
  SectionTopCourses,
  SectionTopCoursesLazy,
  SectionTopPosts,
  SectionTopPostsByAuthor,
  SectionTopPostsByAuthorLazy,
  SectionTopPostsLazy,
  SectionTopPsychologists,
  SectionTopPsychologistsLazy,
  SectionUsedFor,
  SectionUsedForLazy,
  SectionWhatWeDo,
  SectionWhatWeDoLazy,
  Service,
};
