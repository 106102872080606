import classNames from "classnames";
import { APP_API_URI } from "config/config";
import { useAuth } from "contexts/AuthProvider";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

/**
 * Component for showing Component "ProfileSidebar" of the site.
 *
 * @component
 **/
const ProfileSidebar = (props) => {
  const { data } = props;
  const { profile, menu } = data;
  const location = useLocation();
  const { auth, isAuthenticated } = useAuth();

  // console.log(location, menu, props);

  // if (!Array.isArray(menu) || menu?.length === 0) {
  //   return null;
  // }

  const getProfileImageURL = (id) => {
    const currentPermission = auth.permissions;

    if (currentPermission.includes("PSYCHOLOGIST")) {
      return `${APP_API_URI}/psychologists/psychologist/profile/${id}/image`;
    } else if (currentPermission.includes("CUSTOMER")) {
      return `${APP_API_URI}/customers/customer/profile/${id}/image`;
    }
  };

  return (
    <Fragment>
      {/*<!-- Responsive offcanvas body START -->*/}
      <div
        className="offcanvas-lg offcanvas-end"
        tabIndex="-1"
        id="offcanvasSidebar"
      >
        {/*<!-- Offcanvas header -->*/}
        <div className="offcanvas-header justify-content-end pb-2">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#offcanvasSidebar"
            aria-label="Close"
          ></button>
        </div>

        {/*<!-- Offcanvas body -->*/}
        <div className="offcanvas-body p-3 p-lg-0">
          <div className="card bg-light w-100">
            {/*<!-- Edit profile button -->*/}
            <div className="position-absolute top-0 end-0 p-3">
              <a
                href="#"
                className="text-primary-hover"
                data-bs-toggle="tooltip"
                data-bs-title="Edit profile"
              >
                <i className="bi bi-pencil-square"></i>
              </a>
            </div>

            {/*<!-- Card body START -->*/}
            <div className="card-body p-3">
              {/*<!-- Avatar and content -->*/}
              <div className="text-center mb-3">
                {/*<!-- Avatar -->*/}
                <div className="avatar avatar-xl mb-2">
                  <img
                    className="avatar-img rounded-circle border border-2 border-white"
                    src={profile ? getProfileImageURL(profile?.id) : undefined}
                    alt=""
                  />
                </div>
                <h6 className="mb-0">
                  {profile?.name || profile?.username || "User name"}
                </h6>
                <a href="#" className="text-reset text-primary-hover small">
                  {profile?.email || "hello@gmail.com"}
                </a>
                <hr />
              </div>

              {/*<!-- Sidebar menu item START -->*/}
              <ul className="nav nav-pills-primary-soft flex-column">
                {menu &&
                  menu.length > 0 &&
                  menu.map((item, index) => {
                    return (
                      <li className="nav-item" key={index}>
                        <Link
                          className={classNames("nav-link", {
                            active: item.path === location.pathname,
                          })}
                          to={item.path}
                        >
                          <i className="me-2">{item.icon}</i>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                {/*
                <li className="nav-item">
                  <a className="nav-link" href="account-bookings.html">
                    <i className="bi bi-ticket-perforated fa-fw me-2"></i>My
                    Bookings
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="account-travelers.html">
                    <i className="bi bi-people fa-fw me-2"></i>Travelers
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="account-payment-details.html">
                    <i className="bi bi-wallet fa-fw me-2"></i>Payment Details
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="account-wishlist.html">
                    <i className="bi bi-heart fa-fw me-2"></i>Wishlist
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="account-settings.html">
                    <i className="bi bi-gear fa-fw me-2"></i>Settings
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/customer/profile/account-delete"
                  >
                    <i className="bi bi-trash fa-fw me-2"></i>Delete Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-danger bg-danger-soft-hover"
                    href="#"
                  >
                    <i className="fas fa-sign-out-alt fa-fw me-2"></i>Sign Out
                  </a>
                </li> */}
              </ul>
              {/*<!-- Sidebar menu item END -->*/}
            </div>
            {/*<!-- Card body END -->*/}
          </div>
        </div>
      </div>
      {/*<!-- Responsive offcanvas body END -->*/}
    </Fragment>
  );
};

ProfileSidebar.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default ProfileSidebar;
