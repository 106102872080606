import { SEO } from "components/utils/Analytics";
import { dataTherapistsPage } from "data";
import { Fragment } from "react";

import "./Therapist.style.scss";

// NOTE : https://geeks-react.netlify.app/marketing/mentor/list/

const pageDataDemo = {
  title: "Наши психологи",
  items: null,
};

const TherapistFilteredListPage = () => {
  // const { slug } = useParams();
  // const URL = `${APP_API_URI}/therapists`;
  // //const {
  // //   data: postData,
  // //  isLoading,
  // //  updateUrl,
  // //  hasError,
  // //  errorMessage,
  // // } = useFetch(URL);

  // //const postData = therapists[0];
  // const postData = therapists.find((item) => item.slug === slug);

  // _debug(URL);
  // _debug(postData);
  // console.log(slug);

  // useEffect(() => {}, []);

  // useEffect(() => {
  //   //const fetchHtml = async () => {
  //   //  setHtmlFileString(await (await fetch(postData.file)).text());
  //   // };
  //   //if (postData) fetchHtml();
  // }, [postData]);

  // useEffect(() => {
  //   _debug("URL updated:", URL);
  //   //updateUrl(URL);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slug]);

  // useEffect(() => {
  //   if (postData) _debug("Data fetched: ", postData);
  //   if (hasError) _debug("Error: ", errorMessage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading, hasError, postData]);

  // //if (!postData) {
  // //  return <span>The blog post you've requested doesn't exist.</span>;
  // //}

  // if (isLoading || hasError) {
  //   return (
  //     <Fragment>
  //       <Spinner />
  //       {isLoading && <Spinner />}
  //       {hasError && <p>{errorMessage}</p>}
  //     </Fragment>
  //   );
  // }

  //if (!postData) return null;

  return (
    <Fragment>
      <SEO data={dataTherapistsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/therapists" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="therapist-list-page" id="main">
        <TherapistFilteredListPage data={pageDataDemo} />
      </main>
    </Fragment>
  );
};

export default TherapistFilteredListPage;
