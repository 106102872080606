import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataPoliciesPoliciesPage } from "data";
import { dataPoliciesPage } from "data/dataPages";
import { Fragment } from "react";
import { SectionPoliciesLazy } from "./components";

// NOTE :

/**
 * Component for showing Page "Policies" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PagePolicies = (props) => {
  const { id = "policies" } = props;

  return (
    <Fragment>
      <SEO data={dataPoliciesPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policies" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataPoliciesPage} />
        <SectionPoliciesLazy data={dataPoliciesPoliciesPage} type={"style1"} />
      </PageMain>
    </Fragment>
  );
};

export default PagePolicies;
