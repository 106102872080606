import { PageSection, SectionHeader } from "core/components";

// NOTE : https://epicbootstrap.com/snippets/team-cards

const items = [
  {
    title: "",
    description: "Посещали специалист личного психолога сам не менее года",
  },
  {
    title: "",
    description: "Есть ли у него не менее 50 часов супервизии",
  },
  {
    title: "",
    description:
      "Уровень эмпатии и этические принципы, которыми специалист руководствуется в работе",
  },
  {
    title: "",
    description:
      "Уровень эмпатии и этические принципы, которыми специалист руководствуется в работе",
  },
];

const SectionContent = (props) => {
  const { data } = props;

  const SectionItem = (props) => {
    const { data } = props;

    return (
      <div className="col-md-6">
        <div className="d-flex">
          <div className="text-primary me-4">
            <svg
              color="green-2"
              mr="3"
              mt="1"
              width="16"
              height="16"
              viewBox="0 0 25 25"
              fill="none"
              className="mt-1 mr-3 color-green-2 flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 11.4486V12.5066C23.9986 14.9865 23.1956 17.3995 21.7107 19.3857C20.2259 21.3719 18.1388 22.8249 15.7607 23.5281C13.3825 24.2312 10.8408 24.1468 8.51464 23.2873C6.18843 22.4279 4.20235 20.8396 2.8526 18.7592C1.50286 16.6788 0.86176 14.2179 1.02493 11.7433C1.1881 9.26883 2.14679 6.91336 3.75802 5.02821C5.36925 3.14307 7.5467 1.82927 9.96561 1.28275C12.3845 0.736229 14.9153 0.98627 17.1805 1.99558M24 3.3066L12.5 14.8181L9.05 11.3681"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
              ></path>
            </svg>
          </div>
          <div>
            <h5 className="mb-2 mb-lg-3 fw-bold">{data.title}</h5>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row g-4 g-md-5">
      {items.map((item, index) => (
        <SectionItem data={item} key={index} />
      ))}
    </div>
  );
};

const SectionTherapistRequirements = (props) => {
  const { data, id = "therapist-list", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data.items} />
      </div>
    </PageSection>
  );
};

export default SectionTherapistRequirements;
