import { PropTypes } from "prop-types";
import { PageSection, SectionHeader } from "../../sections/CommonSection";

import { Fragment } from "react";
import "./ServicesGrid.scss";

// NOTE : https://gridbyexample.com/

const msgShowMore = "Подробнее";

const SectionContent = (props) => {
  const { data } = props;

  const items = [
    {
      title: "Избавиться от страха или тревоги",
      subtitle: "",
      id: 1,
      image: require("assets/images/grid/fear.webp"),
      imageAlt: "",
    },
    {
      title: "Определиться с планами на жизнь или карьеру",
      subtitle: "",
      id: 2,
      image: require("assets/images/grid/relationship.webp"),
      imageAlt: "",
    },
    {
      title: "Наладить отношения с партнером, в семье, на работе",
      subtitle: "",
      id: 3,
      image: require("assets/images/grid/selfesteem.webp"),
      imageAlt: "",
    },
    {
      title: "Избавиться от страха или тревоги - 4",
      subtitle: "",
      id: 4,
      image: require("assets/images/grid/quality.webp"),
      imageAlt: "",
    },
    {
      title: "Избавиться от страха или тревоги - 5",
      subtitle: "",
      id: 5,
      image: require("assets/images/grid/burnout.webp"),
      imageAlt: "",
    },
    {
      title: "Улучшить качество жизни",
      subtitle: "",
      id: 6,
      image: require("assets/images/grid/body.webp"),
      imageAlt: "",
    },
    {
      title: "Научиться слушать свое тело",
      subtitle: "",
      id: 7,
      image: require("assets/images/grid/plans.webp"),
      imageAlt: "",
    },
    {
      title: "Побороть прокрастинацию",
      subtitle: "",
      id: 8,
      image: require("assets/images/grid/fear.webp"),
      imageAlt: "",
    },
    {
      title: "Повысить самооценку",
      subtitle: "",
      id: 9,
      image: require("assets/images/grid/fear.webp"),
      imageAlt: "",
    },
  ];

  const PanelBox = (props) => {
    const { data } = props;
    return (
      <Fragment>
        <div className="panel-item">
          <h5 className="panel-title mb-1">{data.title}</h5>
          {data.subtitle && <p className="panel-subtitle">{data.subtitle}</p>}
          <img
            className="img-fluid panel-image"
            src={data.image}
            alt={data.imageAlt}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div className="row">
      <div className="wrapper home-layout">
        <div className="panel-box panel-b">
          <PanelBox data={items[0]} />
        </div>

        <div className="panel-box row-2x">
          <PanelBox data={items[1]} />
        </div>

        <div className="panel-box panel-b">
          <PanelBox data={items[2]} />
        </div>

        <div className="panel-box row-2x">
          <PanelBox data={items[3]} />
        </div>

        <div className="panel-box panel-f offsite">
          <PanelBox data={items[4]} />
        </div>

        <div className="panel-box panel-f ">
          <PanelBox data={items[5]} />
        </div>

        <div className="panel-box panel-f ">
          <PanelBox data={items[6]} />
        </div>

        <div className="panel-box panel-b">
          <PanelBox data={items[7]} />
        </div>
        <div className="panel-box panel-b">
          <PanelBox data={items[8]} />
        </div>
      </div>
    </div>
  );
};

/**
 * Component for showing section "Services Grid" of the site.
 *
 * @component
 */
const ServicesGrid = (props) => {
  const {
    data,
    id = "services-grid",
    cn = "pt-130 pb-40",
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showTitle = true,
    showDescription = true,
    useAnimation = false,
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
        <SectionContent
          data={data}
          showTitle={showTitle}
          showDescription={showDescription}
          useAnimation={useAnimation}
        />
      </div>
    </PageSection>
  );
};

ServicesGrid.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showHeaderSubtitle: PropTypes.bool ?? false,
  showHeaderDescription: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

ServicesGrid.defaultProp = {};

export default ServicesGrid;
