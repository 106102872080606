import { SEO } from "components/utils/Analytics";
import { dataTherapistsPage } from "data";
import { Fragment } from "react";
import TherapistList from "./TherapistList.section";

import { Banner2 } from "components/sections/Banner";
import "./Therapist.style.scss";

// NOTE : https://epicbootstrap.com/snippets/team-cards
// NOTE : https://codesandbox.io/p/sandbox/load-more-functionality-using-react-hooks-kllwg?file=%2Fsrc%2FList.jsx%3A54%2C7-54%2C24

export const therapists = [
  {
    slug: 100,
    name: "Елизавета Черемохина",
    image: {
      src: require("assets/images/demo/large_1700768776-d.webp"),
      imageAlt: "",
    },

    ex: "Опыт 7 лет",
    price: "2 850 ₽",
    methods: "Гештальт-терапия, Системный семейный подход",
    specialization:
      "Эмоциональная зависимость, расстройство пищевого поведения, навязчивые мысли о здоровье и еще 20",
    about:
      "Я клинический психолог, арт-терапевт, травматерапевт и специалист по работе с психосоматикой. Ко мне можно обратиться с: - тревогой, страхами и фобиями; - депрессией и потерей смысла; - негативными мыслями; - неуверенностью в себе; - переживанием кризиса (развод, переезд, потеря и т.д.); - психологическими травмами; - проблемами во взаимоотношениях. Со мной безопасно и можно быть самим собой. Я приму, поддержу и на меня можно опереться в сложный период. После нашей работы вы научитесь опираться на себя, видеть свои сильные стороны и достижения, вернёте энергию и радость жизни. Как специалист я регулярно повышаю свою квалификацию, прохожу супервизию и личную терапию, что позволяет сделать работу безопасной и эффективной.",
  },
  {
    slug: 101,
    name: "Елизавета Черемохина",
    image: {
      src: require("assets/images/demo/large_1700768776-d.webp"),
      imageAlt: "",
    },
    ex: "Опыт 7 лет",
    price: "2 850 ₽",
    methods: "Гештальт-терапия, Системный семейный подход",
    specialization:
      "Эмоциональная зависимость, расстройство пищевого поведения, навязчивые мысли о здоровье и еще 20",
    about:
      "Я клинический психолог, арт-терапевт, травматерапевт и специалист по работе с психосоматикой. Ко мне можно обратиться с: - тревогой, страхами и фобиями; - депрессией и потерей смысла; - негативными мыслями; - неуверенностью в себе; - переживанием кризиса (развод, переезд, потеря и т.д.); - психологическими травмами; - проблемами во взаимоотношениях. Со мной безопасно и можно быть самим собой. Я приму, поддержу и на меня можно опереться в сложный период. После нашей работы вы научитесь опираться на себя, видеть свои сильные стороны и достижения, вернёте энергию и радость жизни. Как специалист я регулярно повышаю свою квалификацию, прохожу супервизию и личную терапию, что позволяет сделать работу безопасной и эффективной.",
  },
  {
    slug: 102,
    name: "Елизавета Черемохина",
    image: {
      src: require("assets/images/demo/large_1700768776-d.webp"),
      imageAlt: "",
    },
    ex: "Опыт 7 лет",
    price: "2 850 ₽",
    methods: "Гештальт-терапия, Системный семейный подход",
    specialization:
      "Эмоциональная зависимость, расстройство пищевого поведения, навязчивые мысли о здоровье и еще 20",
    about:
      "Я клинический психолог, арт-терапевт, травматерапевт и специалист по работе с психосоматикой. Ко мне можно обратиться с: - тревогой, страхами и фобиями; - депрессией и потерей смысла; - негативными мыслями; - неуверенностью в себе; - переживанием кризиса (развод, переезд, потеря и т.д.); - психологическими травмами; - проблемами во взаимоотношениях. Со мной безопасно и можно быть самим собой. Я приму, поддержу и на меня можно опереться в сложный период. После нашей работы вы научитесь опираться на себя, видеть свои сильные стороны и достижения, вернёте энергию и радость жизни. Как специалист я регулярно повышаю свою квалификацию, прохожу супервизию и личную терапию, что позволяет сделать работу безопасной и эффективной.",
  },

  {
    slug: 103,
    name: "Виктория Бортникова",
    image: {
      src: require("assets/images/demo/Test2.webp"),
      imageAlt: "",
    },
    ex: "Опыт 6 лет",
    price: "3 950 ₽",
    methods: "Экзистенциальная психотерапия",
    specialization:
      "Болезнь, своя или близких, приступы страха и тревоги, эмоциональная зависимость и еще 19",
    about:
      "Магистр психологии, работаю в экзистенциальном направлении. Имею успешный опыт работы с запросами: - личностное развитие; - проблема самоопределения, поиск смыслов и ценностей; - проблемы в отношениях; - работа с эмоциональными состояниями; - психосоматика; - отношение к себе, самооценка; - отношения с едой, нарушения пищевого поведения. Сеансы проходят в форме диалога. В ходе сеансов решение проблем и улучшение вашей жизни достигается через: - Осознание и конкретизацию целей, намерений, отношения к жизни; - Исследование возможностей и ограничений; - Нахождение способов изменения жизненного пути; - Развитие и укрепление индивидуального способа принятия решений.",
  },
];

const pageDataDemo = {
  title: "Наши психологи",
  items: therapists,
};

const TherapistsListPage = () => {
  // const { slug } = useParams();
  // const URL = `${APP_API_URI}/therapists`;
  // //const {
  // //   data: postData,
  // //  isLoading,
  // //  updateUrl,
  // //  hasError,
  // //  errorMessage,
  // // } = useFetch(URL);

  // //const postData = therapists[0];
  // const postData = therapists.find((item) => item.slug === slug);

  // _debug(URL);
  // _debug(postData);
  // console.log(slug);

  // useEffect(() => {}, []);

  // useEffect(() => {
  //   //const fetchHtml = async () => {
  //   //  setHtmlFileString(await (await fetch(postData.file)).text());
  //   // };
  //   //if (postData) fetchHtml();
  // }, [postData]);

  // useEffect(() => {
  //   _debug("URL updated:", URL);
  //   //updateUrl(URL);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slug]);

  // useEffect(() => {
  //   if (postData) _debug("Data fetched: ", postData);
  //   if (hasError) _debug("Error: ", errorMessage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading, hasError, postData]);

  // //if (!postData) {
  // //  return <span>The blog post you've requested doesn't exist.</span>;
  // //}

  // if (isLoading || hasError) {
  //   return (
  //     <Fragment>
  //       <Spinner />
  //       {isLoading && <Spinner />}
  //       {hasError && <p>{errorMessage}</p>}
  //     </Fragment>
  //   );
  // }

  //if (!postData) return null;

  return (
    <Fragment>
      <SEO data={dataTherapistsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/therapists" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="therapist-list-page" id="main">
        <Banner2 data={dataTherapistsPage} />
        <TherapistList data={pageDataDemo} />
      </main>
    </Fragment>
  );
};

export default TherapistsListPage;
