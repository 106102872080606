import { PropTypes } from "prop-types";
import { SectionTitles } from "./SectionTitles";

/**
 * Component for showing content section "Header" of the site.
 *
 * @component
 */
export const SectionContentHeader = (props) => {
  const { data, showDescription } = props;

  return (
    <div className="section-content-title">
      <SectionTitles data={data} showDescription={showDescription} {...props} />
    </div>
  );
};

SectionContentHeader.propTypes = {
  data: PropTypes.object,
  showDescription: PropTypes.bool ?? true,
};
