import React from "react";
import SectionPolicyPaymentTerms from "./PolicyPaymentTerms.section";
import SectionPolicyPrivacyTerms from "./PolicyPrivacyTerms.section";
import SectionPolicyTermsAndConditions from "./PolicyTermsAndConditions.section";
import { PaymentTerms } from "./Terms/PaymentTerms";
import { PolicyTerms } from "./Terms/PolicyTerms";

const SectionPoliciesLazy = React.lazy(() => import("./Policies.section"));
const SectionPolicyTermsAndConditionsLazy = React.lazy(() =>
  import("./PolicyPaymentTerms.section")
);
const SectionPolicyPaymentTermsLazy = React.lazy(() =>
  import("./PolicyPaymentTerms.section")
);
const SectionPolicyPrivacyTermsLazy = React.lazy(() =>
  import("./PolicyPrivacyTerms.section")
);

export {
  PaymentTerms,
  PolicyTerms,
  SectionPoliciesLazy,
  SectionPolicyPaymentTerms,
  SectionPolicyPaymentTermsLazy,
  SectionPolicyPrivacyTerms,
  SectionPolicyPrivacyTermsLazy,
  SectionPolicyTermsAndConditions,
  SectionPolicyTermsAndConditionsLazy,
};
