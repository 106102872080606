import { BLOG_ID } from "config/config";
import { Link } from "react-router-dom";
import ShareButtons from "./ShareButtons";

/**
 * Component for showing Component "Blog post Tags" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostTags = (props) => {
  const { data, wrapperStyle = "" } = props;

  // console.log(data);
  return (
    <div className="tag-wrap">
      <div className="tags">
        <h4 className="tag">Тэги:</h4>
        <ul>
          {data.tags.map((item, index) => (
            <li key={index}>
              <Link to={`/blog/tag/${item.id}?blog=${BLOG_ID}`}>{item}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="social-list-wrap">
        <h4 className="share">Поделиться:</h4>

        <ShareButtons />

        {/*  <ul className="social-list">
          <li>
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-pinterest-p"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-behance"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
        </ul>*/}
      </div>
    </div>
  );
};

export default BlogPostTags;
