import { useIsMobile } from "hooks";
import { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";

const MobileContent = (props) => {
  const { formData, register, errors } = props;

  return (
    <Fragment>
      {/* Files */}
      {["file1", "file2"].map((item, index) => (
        <Form.Group
          className="mb-3"
          controlId={`formFile${index}`}
          multiple
          key={index}
        >
          <Form.Label>{formData[item].label}</Form.Label>
          <Form.Control
            type="file"
            placeholder={formData[item].placeholder}
            isInvalid={!!errors[item]}
            multiple
            {...register(item, {
              required: formData[item].required,
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors[item]?.message}
          </Form.Control.Feedback>
          <Form.Text id={`formFileHelpBlock${index}`} muted>
            {formData[item].help}
          </Form.Text>
        </Form.Group>
      ))}
    </Fragment>
  );
};

/**
 * Component for showing modal form "Step - 2" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const Step2 = (props) => {
  const { data: formData, errors, register } = props;
  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <MobileContent formData={formData} register={register} errors={errors} />
    );

  return (
    <Fragment>
      {/* Files */}
      {["file1", "file2"].map((item, index) => (
        <Form.Group
          as={Row}
          className="mb-3"
          controlId={`formFile${index}`}
          multiple
          key={index}
        >
          <Form.Label column sm="2">
            {formData[item].label}
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="file"
              label="test"
              placeholder={formData[item].placeholder}
              isInvalid={!!errors[item]}
              {...register(item, {
                required: formData[item].required,
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors[item]?.message}
            </Form.Control.Feedback>
            <Form.Text id={`formFileHelpBlock${index}`} muted>
              {formData[item].help}
            </Form.Text>
          </Col>
        </Form.Group>
      ))}
    </Fragment>
  );
};

Step2.propTypes = {};

export default Step2;
