import { useMediaQuery } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { _debug } from "utils";
import FAQAccordion from "./FAQAccordion";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;
  const isWideScreen = useMediaQuery(1400);

  _debug({ isWideScreen });

  return (
    <div className="row justify-content-center">
      {isWideScreen ? (
        <div className="col-lg-8 col-md-offset-2 faq-right-collapse">
          <FAQAccordion data={data} showMain={false} />
        </div>
      ) : (
        <Fragment>
          <div className="col-md-6 faq-right-collapse">
            <FAQAccordion data={data} showMain={false} screenCol={1} />
          </div>
          <div className="col-md-6 faq-right-collapse">
            <FAQAccordion data={data} showMain={false} screenCol={2} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

/**
 * Component section content "FAQ (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
