import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { PageSection } from "../CommonSection";

import "./MobileAppSection.style.scss";

// NOTE : https://bootstrapmade.com/demo/Nova/

const SectionContent = (props) => {
  const { data, isMobile, useAnimation = false } = props;

  return (
    <Fragment>
      <section id="features" className="features section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7 aos-init aos-animate"
              {...(useAnimation && {
                dataAos: "fade-up",
                dataAosDelay: "100",
              })}
            >
              <h3 className="mb-0">{data.title}</h3>
              <h3>{data.title}</h3>

              <div className="row gy-4">
                {data.items.map((item, index) => {
                  return (
                    <div className="col-md-6">
                      <div className="icon-list d-flex">
                        <i>{item.icon}</i>
                        <span>{item.title}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="col-lg-5 position-relative aos-init aos-animate"
              {...(useAnimation && {
                dataAos: "zoom-out",
                dataAosDelay: "200",
              })}
            >
              <div className="phone-wrap">
                <img
                  src={data.image.src}
                  alt={data.image.alt}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="details">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 aos-init aos-animate"
                {...(useAnimation && {
                  dataAos: "fade-up",
                  dataAosDelay: "300",
                })}
              >
                <h4>{data.subtitle2}</h4>
                <p>{data.description}</p>
                <a href="#about" className="btn-get-started">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

/**
 * Component for showing section "Mobile App" of the site.
 *
 * @component
 */
const MobileAppSection = (props) => {
  const { data, id = "top-courses", cn = "pt-80" } = props;
  const isMobile = useIsMobile(768);

  return (
    <PageSection id={id} cn={cn}>
      {/* <div className="container">
        <SectionHeader data={data} />
        
      </div>*/}
      <SectionContent data={data} isMobile={false} />
    </PageSection>
  );
};

MobileAppSection.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default MobileAppSection;
