import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import FAQAccordion from "./FAQAccordion";

const msgSubmit = "Написать";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <div className="row gy-4">
      <div
        className="d-none d-md-block col-lg-4 aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="content px-xl-3 text-center">
          <img
            className="img-fluid"
            src={data.content.image}
            alt={data.content.imageAlt}
            width="280"
            height="600"
          />
          <div className="mt-3 ">
            <h3 className="mb-3">
              <span>{data.content.title}</span>
            </h3>
            <p className="lead p-large">{data.content.description}</p>
            <Link
              to="/faq/#ask-question"
              className="btn btn-outline-primary fs-base"
            >
              {msgSubmit}
            </Link>
          </div>
        </div>
      </div>
      <div
        className="col-lg-8 aos-animate"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <FAQAccordion data={data} showMain={true} />
      </div>
    </div>
  );
};

/**
 * Component section content "FAQ (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
