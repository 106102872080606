import { SITE } from "config/config";

const dataPageTitle = "Демо страница";
const dataPageDescription = "Демо страница ✅ Профессиональная помощь ✅";
const dataPageAuthor = SITE.AUTHOR;

const dataMetaOGTitle = dataPageTitle;
const dataMetaOGDescription = dataPageDescription;
const dataMetaOGImage = "https://psywealth.com/images/OG/main_1200x640.png";
const dataMetaOGImageAlt = "";
const dataMetaOGLocale = SITE.LOCALE;
const dataMetaOGSiteName = SITE.NAME;

const dataMetaBlogPage_CreateDate = "2023-10-01T07:00:00+00:00";
const dataMetaBlogPage_UpdateDate = "2024-12-10T07:59:00+00:00";

export const dataMetaDemoPage = {
  title: dataPageTitle,
  description: dataPageDescription,
  name: "",
  type: "",
  author: dataPageAuthor,
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: dataMetaOGTitle,
    },
    {
      property: "og:description",
      content: dataMetaOGDescription,
    },
    {
      property: "og:url",
      content: "",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaOGImage,
    },
    {
      property: "og:image:alt",
      content: dataMetaOGImageAlt,
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "640",
    },
    {
      property: "og:locale",
      content: dataMetaOGLocale,
    },
    {
      property: "og:site_name",
      content: dataMetaOGSiteName,
    },
  ],
  TW: [
    {
      name: "twitter:title",
      content:
        "Онлайн психолог | Подбор психолога и психотерапевта онлайн. Консультирование по видеочат, аудиочат и в мессенджерах.",
    },
    {
      name: "twitter:description",
      content:
        "psywealth.com - Психолог онлайн консультации. Психологическая помощь онлайн - чат, видео, аудио.",
    },
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },

    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
