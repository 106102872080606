import { isDevelopment } from "components/utils/Utils";
import {
  GA_TRACKING_ID,
  GA_TRACKING_ON_DEV,
  YA_TRACKING_ID,
  YA_TRACKING_ON_DEV,
} from "config/config";
import ReactGA from "react-ga4";
import ym, { YMInitializer } from "react-yandex-metrika";
import { _debug } from "utils";

const optionsGA4 = {
  testMode: isDevelopment,
  debug: isDevelopment,
  gaOptions: {
    cookieUpdate: false,
  },
  gtagOptions: {},
};

const optionsYA = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
  trackHash: true,
};

export const AnalyticsEvent = {
  subscribe: {
    category: "Subscription",
    action: "subscribe",
    label: "user subscribed",
    value: undefined,
  },
  askQuestion: {
    category: "Subscription",
    action: "askQuestion",
    label: "ask question",
    value: undefined,
  },
  contact: {
    category: "Subscription",
    action: "contact",
    label: "get contact",
    value: undefined,
  },
  partner: {
    category: "New partner",
    action: "register",
    label: "new partner registered",
    value: undefined,
  },
  order: {
    category: "Subscription",
    action: "order",
    label: "get new order",
    value: undefined,
  },
  error404: {
    category: "Error",
    action: "404_error",
    label: "Page not found",
    value: undefined,
  },
};

export const InitializeGA4 = () => {
  if (isDevelopment && !GA_TRACKING_ON_DEV) return;

  ReactGA.initialize(GA_TRACKING_ID, optionsGA4);
};

export const InitializeYA = (props) => {
  if (isDevelopment && !YA_TRACKING_ON_DEV) return;

  return (
    <YMInitializer
      accounts={[YA_TRACKING_ID]}
      options={optionsYA}
      version="2"
    />
  );
};

export const SendGA4 = (page) => {
  if (isDevelopment && !GA_TRACKING_ON_DEV) return;

  ReactGA.send({
    hitType: "pageview",
    page: page,
  });
};

export const SendYA = (page) => {
  if (isDevelopment && !YA_TRACKING_ON_DEV) return;

  ym("hit", page);
};

export const EventGA = async (props) => {
  const {
    category,
    action,
    label,
    value = undefined,
    nonInteraction = undefined,
  } = props;

  _debug("GA event:", category, ":", action, ":", label);

  if (isDevelopment && !GA_TRACKING_ON_DEV) return;

  // Send a custom event
  if (!value) {
    ReactGA.event({
      category: category,
      action: action,
      // optional
      label: label,
      // optional, must be a number
      //value: value,
      // optional, true/false
      nonInteraction: nonInteraction || true,
      // optional, beacon/xhr/image
      //transport: "xhr",
    });
  } else {
    ReactGA.event({
      category: category,
      action: action,
      // optional
      label: label,
      // optional, must be a number
      value: value,
      // optional, true/false
      nonInteraction: nonInteraction || true,
      // optional, beacon/xhr/image
      //transport: "xhr",
    });
  }
};

export const EventYA = (props) => {
  const { category, action, label, value } = props;

  if (isDevelopment && !YA_TRACKING_ON_DEV) return;

  // Send a custom event
  ym(
    "reachGoal",
    action,
    // optional, must be a number
    { value: value }
  );
};

export const sendToAnalytics = ({ id, name, value }) => {
  EventGA({
    category: "Web Vitals",
    action: name,
    label: id, // id unique to current page load
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    nonInteraction: true, // avoids affecting bounce rate
  });
};
