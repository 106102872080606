import { ModalFormHeroOrder } from "components/forms/HeroOrder";
import { ModalFormPartnership } from "components/forms/Partnership";
import {
  SectionCallToActionLazy,
  SectionFAQLazy,
  SectionFeatureTabsLazy,
  SectionHero,
  SectionHowItWorksLazy,
  SectionOverview,
  SectionServices,
  SectionTestimonialLazy,
  SectionUsedForLazy,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import {
  dataCTAPartnershipPage as dataCTA,
  dataCTAMainPage,
  dataFAQPartnershipPage as dataFAQ,
  dataFeatureTabsPartnershipPage,
  dataHeroPartnershipPage as dataHero,
  dataOverviewPartnershipPage as dataOverview,
  dataPartnershipPage,
  dataServicesPartnershipPage as dataServices,
  dataTestimonialPartnershipPage,
  dataUsedForPartnershipPage,
} from "data";
import { useModal } from "hooks";
import { Fragment, useRef } from "react";
import EventService from "services/events.service";
import { _debug } from "utils";

// NOTE :

/**
 * Component for showing Page "Partners, Psychologist" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PagePartnersPsychologist = (props) => {
  const { id = "partners-psychologist" } = props;

  const refCAT = useRef(null);

  const handlerHeroSubmit = async (data) => {
    _debug("Hero submit form: ", data);
    try {
      var numberRegex = /\D/g;
      const newData = {
        ...data,
        phone: data.phone.replace(numberRegex, ""),
      };
      console.log(newData);
      const result = await EventService.registerNewPartner(newData);
      console.log(result);
    } catch (err) {
      console.error(err);
    } finally {
      // closeModal1();
    }
  };

  const {
    modal: modal1,
    openModal: openModal1,
    closeModal: closeModal1,
  } = useModal({
    children: (
      <ModalFormPartnership
        onSubmit={(data) => handlerHeroSubmit(data)}
        onClose={() => closeModal1()}
      />
    ),
  });

  const handlerCTASubmit = async (data) => {
    _debug("CTA submit form: ", data);
    try {
      var numberRegex = /\D/g;
      const newData = { ...data, phone: data.phone.replace(numberRegex, "") };
      console.log(newData);
      const result = await EventService.registerOrder(newData);
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };

  const {
    modal: modal2,
    openModal: openModal2,
    closeModal: closeModal2,
  } = useModal({
    children: (
      <ModalFormHeroOrder
        onSubmit={(data) => handlerCTASubmit(data)}
        onClose={() => closeModal2()}
      />
    ),
  });

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataPartnershipPage.meta}>
        {/*Only canonicalize pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://psywealth.com/partnership" />
        {/* <script type="application/ld+json">{JSON.stringify(ldJson)}</script> */}
      </SEO>
      <PageMain id={id}>
        <SectionHero
          data={dataHero}
          type="style5"
          onScroll={executeScroll}
          onSubmit={openModal1}
        />
        <SectionServices data={dataServices} type={"style2"} />
        <SectionFeatureTabsLazy
          data={dataFeatureTabsPartnershipPage}
          type={"style3"}
        />
        <SectionCallToActionLazy
          data={dataCTA}
          type={"style3"}
          id={"call-to-action"}
          onSubmit={() => openModal2()}
          cn="mt-5 mb-5"
        />
        {/* <SectionCallToActionLazy
          data={dataCTAMainPage}
          type={"style2"}
          id={"call-to-action"}
          onSubmit={() => openModal2()}
        /> */}
        <SectionOverview data={dataOverview} type={"style2"} />
        {/*<SectionOverview data={dataOverview} type={"style1"} />*/}
        <SectionHowItWorksLazy data={dataCTAMainPage} type={"style1"} />
        {/* <Feature data={dataFeature} type="style1" /> */}
        {/* <Overview2 data={dataOverview} cn="pt-120 pb-120" /> */}
        {/* <OurAdvantages data={dataOurAdvantages} /> */}
        {/* <MobileAppSection data={dataMobileApp} /> */}
        <SectionUsedForLazy data={dataUsedForPartnershipPage} type={"style1"} />
        <SectionTestimonialLazy
          data={dataTestimonialPartnershipPage}
          type={"style2"}
        />
        <SectionFAQLazy data={dataFAQ} />
        {/*  <SectionAbout data={dataAbout} />*/}
        {modal1}
        {modal2}
      </PageMain>
    </Fragment>
  );
};

export default PagePartnersPsychologist;
