import { Loading } from "components/utils";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

/**
 * Component for showing section "Policies Layout" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const LayoutPoliciesPage = (props) => {
  const { id = "" } = props;

  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  );
};

export default LayoutPoliciesPage;
