import { PageSection, SectionHeader } from "core/components";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Accordion } from "react-bootstrap";

import { Image } from "components/utils/Image";
import "./TestimonialStyle2.style.scss";

// NOTE : https://preview.themeforest.net/item/healingy-therapy-counseling-psychologist-html-template/full_screen_preview/54989672

const SectionContent = (props) => {
  const { data, showSubtitle, showDescription, useAnimation } = props;

  const ContentIcon = (props) => {
    const { width, height } = props;

    return (
      <Fragment width={40}>
        <img src={props.icon} width={width} height={height} alt="" />
      </Fragment>
    );
  };

  const ContentItem = (props) => {
    const { data } = props;
    return (
      <div className="col-md-6">
        <div className="d-flex">
          <div className="text-muted">{data.icon}</div>
          <div className="ms-4">
            <h5 className="fw-bold">{data.title}</h5>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    );
  };

  const ContentItem2 = (props) => {
    const { data, index } = props;
    return (
      <div className="col-md-6">
        <Accordion.Item eventKey={index}>
          <Accordion.Header>{data.title}</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex">
              <div className="text-muted">{data.icon}</div>
              <div className="ms-4">
                <p>{data.description}</p>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>
    );
  };

  const ContentItem3 = (props) => {
    const { data } = props;
    return (
      <div className="col-md-6">
        <div className="d-flex">
          <div className="text-muted">
            <i>{data.icon}</i>
          </div>
          <div className="ms-4">
            <h5 className="fw-bold">{data.title}</h5>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="row gx-5 align-items-center">
        <div className="col-md-4 mb-5 mb-md-0">
          <figure className="d-block h-100 rounded-5 overflow-hidden">
            <Image image={data?.image} className="img-fluid" />
          </figure>
        </div>

        <div className="col-md-8">
          <Accordion>
            <div className="row gy-4">
              {data.items.map((item, index) => (
                <ContentItem3 data={item} index={index} key={index} />
              ))}
            </div>
          </Accordion>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing section "Testimonial (Style1)" of the site.
 *
 * @component
 */
const TestimonialStyle2 = (props) => {
  const {
    data,
    id = "testimonial2",
    cn = "",
    showDescription = false,
    showSubtitle = false,
    useAnimation = false,
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          classHeader=""
          showSubtitle={showSubtitle}
          showDescription={showDescription}
        />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

TestimonialStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? false,
  useAnimation: PropTypes.bool ?? false,
};

export default TestimonialStyle2;
