import { SectionHeader } from "components/sections/CommonSection";
import DOMPurify from "isomorphic-dompurify";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { DetailsSection } from "../Details.section";

// NOTE : https://alter.ru/psychologists/kajjani/ivan-gorbachev?position=1&from=search_page&from_link=%2Fpsychologists%3Falter_campaign%3Dlanding-menu

const SectionContent = (props) => {
  const { data, isMobile } = props;

  return (
    <Fragment>
      <div className="d-flex flex-column">
        {data.bio2 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.bio2),
            }}
          ></div>
        ) : (
          <p className="">{data.bio}</p>
        )}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing section "Therapist Bio" of the site.
 *
 * @component
 */
const TherapistBio = (props) => {
  const { data, id = "therapist-bio", cn = "pt-25 pb-25" } = props;

  return (
    <DetailsSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data.therapist} isMobile={false} />
      </div>
    </DetailsSection>
  );
};

TherapistBio.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistBio;
