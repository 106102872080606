const dataMetaBlogPage_Title =
  "Блог ментального здоровья и психологической помощи | Psywealth";
//  Сервис рассылок: email, sms, smtp, push, чат-боты
const dataMetaBlogPage_Description =
  "Блог ментального здоровья и психологической помощи | Psywealth";

const dataMetaBlogPage_Image =
  "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg";
const dataMetaBlogPage_CreateDate = "2023-10-01T07:00:00+00:00";
const dataMetaBlogPage_UpdateDate = "2024-12-10T07:59:00+00:00";

export const dataMetaHomePage = {
  title:
    "Психологи и психотерапевты онлайн на PsyWealth — онлайн-сервис психологической помощи",
  //  "Сервис онлайн-консультаций «PsyWealth.com», консультации с психологом или психотерапевтом - чат, видео, аудио",
  //  Cоздание, обработка и организация электронного архива документов предприятия - psywealth"
  // "Электронный архив – psywealth",
  //  Система электронного архива "psywealth": цифровое хранение документов
  description:
    //Бесплатно подберём психотерапевта под ваш запрос и бюджет. Проверенные психологи и психотерапевты от 2000 руб. Лично в Москве или онлайн из любой точки мира.

    "Сайт психологов — онлайн-сервис психотерапии | Подбор своего психотерапевта из проверенных | Цена психологической консультации от 2850 ₽ | Простой поиск ✅ Профессиональная помощь ✅",
  // "Cпециализированные решения по созданию, комплексному оснащению и наполнению электронных архивов, внедрению передовых информационных технологий и решений в области электронного документооборота. Попробуйте бесплатно и оцените удобство и простату наших решений.",
  // "psywealth автоматически архивирует все бухгалтерские документы с учетом типа документа, регистра и параметров и делает их хранение надежным и удобным. Загрузите 10 страниц бесплатно и оцените удобство",
  // Электронный архив: создание и внедрение системы для организации и хранения ваших документов в цифровом виде. &quot;psywealth&quot;, звоните: +7 (925) 995-87-90!
  // Электронный архив документов - создание единого электронного архива для всех типов документов - бумажных и электронных. Интеграция электронного архива в любую учётную систему - услуги Инфолоджистикс.
  // High Performance Object Storage, Multi-Cloud, Hybrid Cloud, Object Storage, Object Storage for AI, Cloud Storage, S3 Compatible, Golang, AWS S3, Amazon S3, Open Source Cloud Storage, Ceph, OpenStack Swift, Microsoft Azure Blobstore, Google Cloud Storage, Cloud Foundry Blobstore, Cloud Foundry Bosh, Artifact Storage, DC/OS, Kubernetes, Docker, Container Storage, Blobstore, NAS, Gluster, GlusterFS, PACS, VNA, Archive, Secondary Storage, Backup Storage, Snapshots Hybrid, OnPrem, Private Cloud
  // <meta name="description" content="MinIO's High Performance Object Storage is Open Source, Amazon S3 compatible, Kubernetes Native and is designed for cloud native workloads like AI.">
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content:
        "Психолог или психотерапевт онлайн. Доступная психологическая помощь и консультирование по видеочату, аудиочату и в мессенджерах.",
      //"Сервис онлайн-сессий с психологами - чат, видео, аудио - «PsyWealth.com»",
      // Облачный сервис Entera автоматически архивирует все отсканированные документы с учетом типа документа, регистра и параметров. Электронные копии документов будут доступны для экспорта на случай проверки или восстановления базы 1С. Копии автоматически “облегчаются” до размера, который полностью соответствует требованиям ФНС.
    },
    {
      property: "og:description",
      content:
        "Вам поможет специалист с дипломом психолога и опытом консультирования больше 5 лет. Обеспечиваем анонимность общения.",
      // Платформа автоматизации многоканального маркетинга c email, web push, SMS и чат-ботами для Facebook, Telegram, WhatsApp и Instagram — все объединено в бесплатную CRM.
      // Бесплатно подберём психотерапевта под ваш запрос и бюджет
      // "Электронный архив: создание и внедрение системы для организации и хранения ваших документов в цифровом виде. &quot;psywealth&quot;, звоните: +7 (925) 995-87-90!",
    },
    {
      property: "og:site_name",
      content:
        "psywealth.com - Психолог онлайн консультации. Психологическая помощь онлайн - чат, видео, аудио.",
    },
    {
      property: "og:url",
      content: "https://psywealth.com",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaBlogPage_Image,
    },
    {
      property: "og:image:alt",
      content:
        "Сервис онлайн-сессий с психологами - чат, видео, аудио - «PsyWealth.com»",
    },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "article:published_time",
      content: dataMetaBlogPage_CreateDate,
    },
    {
      property: "article:modified_time",
      content: dataMetaBlogPage_UpdateDate,
    },
  ],
  TW: [
    {
      name: "twitter:title",
      content:
        "Онлайн психолог | Подбор психолога и психотерапевта онлайн. Консультирование по видеочат, аудиочат и в мессенджерах.",
    },
    {
      name: "twitter:description",
      content:
        "psywealth.com - Психолог онлайн консультации. Психологическая помощь онлайн - чат, видео, аудио.",
    },
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },

    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
