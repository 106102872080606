import { ContentSiteEmail } from "components/utils/Utils";
import { PropTypes } from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

// NOTE :

const msgGoToHomePage = "Вернуться на главную";

const SectionContent = (props) => {
  const { data } = props;

  return null;
};

/**
 * Component section content "Not Found (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;
  const location = useLocation();

  return (
    <Container>
      <Row className="h-100">
        <Col lg="12">
          <div className="page-not-foud-content-wrapper text-center">
            <h3 className="mb-3">{data.subtitle}</h3>
            <h1 className="mb-5">{data.title}</h1>
            <h2 className="d-none">Page Not Found - 404!</h2>
            <h2 className="mb-3">
              Запрашиваемые путь <code>{location.pathname}</code>
            </h2>
            <p className="mb-2">{data.description}</p>
            <p className="mb-3">
              Вернитесь на <Link to="/">Главную</Link> или напишите нам на{" "}
              <ContentSiteEmail />
            </p>
            <Link to="/" className="btn theme-btn">
              {msgGoToHomePage}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
