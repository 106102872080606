import classNames from "classnames";
import { PageSection } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import {
  SectionContentStyle1,
  SectionContentStyle2,
  SectionContentStyle3,
  SectionContentStyle4,
  SectionContentStyle5,
} from "./components";

// NOTE :

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    ...otherProps
  } = props;

  return (
    <Fragment>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle2
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        } else if (type === "style3") {
          return (
            <SectionContentStyle3
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        } else if (type === "style4") {
          return (
            <SectionContentStyle4
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        } else if (type === "style5") {
          return (
            <SectionContentStyle5
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            showTitle={showTitle}
            showDescription={showDescription}
            {...otherProps}
          />
        );
      })()}
    </Fragment>
  );
};

/**
 * Component for showing section "Hero" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionHero = (props) => {
  const {
    data,
    id = "hero",
    cn = "",
    type,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)}>
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showContentTitle}
        showDescription={showContentDescription}
        {...otherProps}
      />
    </PageSection>
  );
};

SectionHero.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2", "style3", "style4", "style5"]),
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionHero;
