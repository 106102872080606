import classNames from "classnames";
import {
  PageSection,
  SectionHeader,
  useSectionPrefix,
} from "core/components/Section";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { SectionContentStyle1, SectionContentStyle2 } from "./components";

// NOTE : https://promo.youtalk.ru/rich/?_gl=1*13awslr*_gcl_au*MTE5ODIzNTM1MS4xNzIwNDAyNDI2*_ga*MzYwODY2NTUxLjE3MjA0MDI0MjY.*_ga_14P9R9JJ4H*MTcyMDQwMjQyNi4xLjEuMTcyMDQwNDMzMi41Ny4wLjA.

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    ...otherProps
  } = props;

  return (
    <Fragment>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle2
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showSubtitle={showSubtitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            showTitle={showTitle}
            showSubtitle={showSubtitle}
            showDescription={showDescription}
            {...otherProps}
          />
        );
      })()}
    </Fragment>
  );
};

/**
 * Component for showing section "Top Advice" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionTopAdvice = (props) => {
  const {
    data,
    id = "top-advice",
    cn = "",
    type,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      <SectionHeader
        data={data}
        className=""
        showSubtitle={showHeaderSubtitle}
        showDescription={showHeaderDescription}
      />
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showContentTitle}
        showDescription={showContentDescription}
        {...otherProps}
      />
    </PageSection>
  );
};

SectionTopAdvice.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionTopAdvice;
