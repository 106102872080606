//import Image1 from "assets/icons/cowork_mac.svg";
import Image1 from "assets/images/feature/features-2.webp";
import { BiData, BiLineChart, BiShield } from "react-icons/bi";
import { FaGift } from "react-icons/fa6";
import { RiBrush2Line, RiCommandLine, RiLineChartLine } from "react-icons/ri";

export const dataFeature = {
  title: "Единая система хранения",
  subtitle: "Единая система хранения",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  items: [
    {
      title: "Эффективность работы",
      subtitle: "",
      description:
        "Охватывает весь жизненный цикл документа от попадания в архив до уничтожения",
      icon: <BiData />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Следование стандартам",
      subtitle: "",
      description:
        "Разработан на основе нормативно-правовых актов по архивному делу, соответствует требованиям Росархива",
      icon: <BiLineChart />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Контроль и систематизация",
      subtitle: "",
      description:
        "Быстрый и лёгкий поиск по любым атрибутам, архивные описи, организация и упорядочивание дел",
      icon: <BiShield />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataFeature3 = {
  title: "Для чего использовать?",
  subtitle: "Для чего использовать?",
  description:
    "Электронный архив станет фундаментом для хранения ваших бизнес данных",
  image: Image1,
  imageAlt: "",
  items: [
    {
      title: "СЭД, Операторы ЭДО",
      subtitle: "",
      description: "",
      icon: <RiBrush2Line />,

      image: null,
      imageAlt: "",
    },
    {
      title: "Любые типы документов",
      subtitle: "",
      description:
        "Любые офисные документы: MS Office, Мой Офис, картинки и пр.",
      icon: <RiLineChartLine />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Сканеры, Сетевые папки",
      subtitle: "",
      description: "Любые оцифрованные электронные документы: pdf, jpg и пр.",
      icon: <RiCommandLine />,
      image: null,
      imageAlt: "",
    },
    {
      title: "ERP",
      subtitle: "",
      description:
        "Справочная информация, данные классификаторов и справочников",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Архивная служба",
      subtitle: "",
      description: "Учет бумажных документов, оцифровка бумажного архива",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "САПР",
      subtitle: "",
      description: "Хранение версий и данных по проектам",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};
