import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// NOTE :

const dataTopAdvice = {
  title: "Возможности",
  subtitle: "Возможности",
  description: "Возможности",
  items: [
    {
      title: "Что такое стресс?",
      subtitle: "",
      description: "",
      description1:
        "Обычно стрессом мы называем все, что заставляет нас находиться не в своей тарелке и нервничать.",
      description2:
        "На самом деле у стресса есть и позитивное предназначение - он помогает подготовиться нам к переменам, происходящим во внешнем мире. При чем телу все равно, позитивные или негативные это изменения с точки зрения сознания и ума. Для нашего организма одинаковыми поводами для стресса будут и развод и свадьба, повышение по работе и увольнение.",
      image: require("assets/images/demo/image1-1.webp"),
      imageAlt: "",
    },
    {
      title: "Почему стресс считается вредным?",
      subtitle: "",
      description: "",
      description1:
        "Если события, вызвавшие стресс, были чересчур яркими или их воздействие имело продолжительный период времени, мы переходим на стадию истощения или хронического стресса.",
      description2:
        "Если не позаботиться о себе вовремя, то он может привести к выгоранию, депрессии и другим негативным последствиям.",
      image: require("assets/images/demo/image1-2.webp"),
      imageAlt: "",
    },
    {
      title: "Как себя поддержать?",
      subtitle: "",
      description: "",
      description1:
        "Важная основа для прохождения периодов стресса - то, как мы общаемся с собой.",
      description2:
        "Если в моменты, когда нам и так плохо поддаваться тревоге, критиковать себя или полагаться только на внешнюю поддержку - справиться со стрессом будет непросто.",
      image: require("assets/images/demo/image1-3.webp"),
      imageAlt: "",
    },
  ],
};

const ContentItem = (props) => {
  const { data } = props;

  return (
    <div className="row gx-0">
      <div
        className="col-lg-6 d-flex align-items-center aos-init aos-animate"
        data-aos="zoom-out"
        data-aos-delay="200"
      >
        <div className="shadow-md hover-shadow-soft">
          <img
            className="img-fluid w-100 rounded-soft"
            src={data.image || require("assets/images/demo/one-page.webp")}
            alt={data.imageAlt || ""}
          />
        </div>
      </div>
      <div className="col-lg-6 d-flex flex-column justify-content-center">
        <div className="ms-md-2 ms-lg-5 mt-5 mt-md-0">
          <div className="content">
            <h3>{data.title}</h3>
            <p>{data.description1}</p>
            <p>{data.description2}</p>
            <div className="text-center text-lg-start"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Top Advice (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const items =
    dataTopAdvice.items.length > 0 &&
    dataTopAdvice.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <ContentItem data={item} index={index} key={index} />
        </SwiperSlide>
      );
    });

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="section-content slider">
            <Swiper
              className="swiper"
              slidesPerView={1}
              // spaceBetween={24}
              // autoHeight={false}
              pagination={{
                //clickable: true,
                dynamicBullets: true,
              }}
              modules={[Pagination, Navigation]}
              // pagination={true}
            >
              {items}
            </Swiper>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
