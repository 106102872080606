import {
  InputFloatingLabelEmail,
  InputFloatingLabelMessage,
  InputFloatingLabelName,
} from "components/utils/Forms/Input";
import { dataForm } from "data";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _debug } from "utils";

// import "./Section.style.scss";

// NOTE : https://sandbox.elemisthemes.com/docs/blocks/contact.html

const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Отправить сообщение";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const ContentForm = (props) => {
    const { data } = props;
    const {
      methods,
      register,
      control,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      mode: "onBlur",
      defaultValues: {
        name: "",
        email: "",
        message: "",
      },
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const onSubmit = async (data) => {
      //e.preventDefault();
      _debug("Form submit: ", data);

      setError(null);
      try {
        //gaEventTracker("submitFromCountDown");

        const response = await props.onSubmit({
          email: data.email,
        });

        if (response && response.error) {
          setError(response.error);
          setSuccess(false);
          return;
        }

        setSuccess(true);
      } catch (error) {
        setError(error.message);
        setSuccess(false);
      } finally {
        reset();
      }
    };

    return (
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          method="POST"
          className="row g-4 contact-form needs-validation"
          noValidate=""
        >
          <Form.Group controlId="formContactsName" as={Col} sm="12">
            <InputFloatingLabelName
              label={dataForm.name.msgLabel}
              placeholder={dataForm.name.msgPlaceholder}
              required={dataForm.name.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
          <Form.Group controlId="formContactEmail" as={Col} sm="12">
            <InputFloatingLabelEmail
              label={dataForm.email.msgLabel}
              placeholder={dataForm.email.msgPlaceholder}
              required={dataForm.email.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
          <Form.Group controlId="formContactMessage" as={Col} sm="12">
            <InputFloatingLabelMessage
              label={dataForm.message.msgLabel}
              placeholder={dataForm.message.msgPlaceholder}
              required={dataForm.message.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>

          {/* 
          <div className="messages"></div>
          <div className="form-floating mb-4">
            <input
              id="form_name2"
              type="text"
              name="name"
              className="form-control"
              placeholder="Jane"
              required="required"
              data-error="Name is required."
            />
            <label htmlFor="form_name2">Name *</label>
            <div className="valid-feedback"> Looks good! </div>
            <div className="invalid-feedback"> Please enter your name. </div>
          </div>
          <div className="form-floating mb-4">
            <input
              id="form_email2"
              type="email"
              name="email"
              className="form-control"
              placeholder="jane.doe@example.com"
              required="required"
              data-error="Valid email is required."
            />
            <label htmlFor="form_email2">Email *</label>
            <div className="valid-feedback"> Looks good! </div>
            <div className="invalid-feedback">
              {" "}
              Please provide a valid email address.{" "}
            </div>
          </div>
          <div className="form-floating mb-4">
            <textarea
              id="form_message2"
              name="message"
              className="form-control"
              placeholder="Your message"
              style={{ height: "150px" }}
              required=""
            ></textarea>
            <label htmlFor="form_message2">Message *</label>
            <div className="valid-feedback"> Looks good! </div>
            <div className="invalid-feedback">
              {" "}
              Please enter your messsage.{" "}
            </div>
          </div>
          */}

          <Form.Group controlId="formContacts" as={Col} sm="12">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="agree" />
              <label className="form-check-label" htmlFor="agree">
                Вы принимаете условия{" "}
                <Link
                  className="nav-link d-inline fs-normal text-decoration-underline p-0"
                  to="/terms"
                >
                  Политики &amp; Условий
                </Link>
              </label>
            </div>
          </Form.Group>
          <input
            type="submit"
            className="btn btn-primary rounded-pill btn-send mb-3"
            value="Send message"
          />
          <p className="text-muted">
            <strong>*</strong> Эти поля являются обязательными".
          </p>
        </Form>
        {error && (
          <p className="text-warning" style={{ textAlign: "center" }}>
            <small>{error}</small>
          </p>
        )}
        {success && (
          <p className="text-success" style={{ textAlign: "center" }}>
            <small>{msgSubmitted}</small>
          </p>
        )}
      </FormProvider>
    );
  };

  return (
    <div className="pt-15 pt-md-17 pb-13 pb-md-15 pb-lg-0">
      <div className="row gx-lg-8 gx-xl-0 gy-10 align-items-center">
        <div className="col-lg-6 col-xl-5 position-relative d-none d-lg-block">
          <div
            className="shape rounded-circle bg-soft-primary rellax w-21 h-21"
            data-rellax-speed="1"
            style={{
              top: "8rem",
              left: "2rem",
              transform: "translate3d(0px, -10px, 0px)",
            }}
          ></div>
          <figure className="ps-xxl-10">
            <img
              src={require("assets/images/new/woman_rm.webp")}
              srcSet="assets/images/new/woman.webp 2x"
              alt=""
            />
          </figure>
        </div>

        <div className="col-lg-6 col-xl-5 offset-xl-1">
          <h2 className="display-4 mb-3">{data.title}</h2>
          <p className="lead mb-8 pe-xl-10">{data.description}</p>
          <ContentForm />
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Contacts (style 4)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle4 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;
  console.log(props);

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle4.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle4;
