import { dataSite } from "data";
import { useInterval } from "hooks";
import { useState } from "react";
import { Form as BSForm } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { _debug } from "utils";
import "./Countdown.scss";

const countdownDateYear = process.env.REACT_APP_COUNTDOWN_YEAR;
const countdownDateMonth = process.env.REACT_APP_COUNTDOWN_MONTH - 1; // нумерация месяцев начинается с нуля
const countdownDateDay = process.env.REACT_APP_COUNTDOWN_DAY;

const countdownDate = new Date(
  countdownDateYear,
  countdownDateMonth,
  countdownDateDay,
  0,
  0,
  0,
  0
).getTime();

const msgTitle = dataSite.countdown.title;
const msgSubtitle = dataSite.countdown.subtitle;
const msgDescription = dataSite.countdown.description;
const msgSubmit = "Подписаться";
const msgSubmitted = "Сообщение успешно отправлено";

const subscriptionCreated = "";
const messageSubscribe = "";

const msgFormEmailPlaceholder = "Ваша почта";
const msgFormEmailRequired = "Требуется ввести адрес электронной почты";

/**
 * Component for showing section "Countdown" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionCountdown = (props) => {
  const { onSubmit } = props;

  const [delay] = useState(1000);
  const [isRunning] = useState(true);

  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  const FormSubscription = (props) => {
    const {
      methods,
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      mode: "onBlur",
      defaultValues: {
        email: "",
      },
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const onSubmit = async (data) => {
      _debug("Form submit: ", data);
      setError(null);
      try {
        if (props.onSubmit) {
          const response = props.onSubmit(data);
          if (response && response.error) {
            setError(response.error);
            setSuccess(false);
          }
        }
        setSuccess(true);
      } catch (error) {
        setError(error.message);
        setSuccess(false);
      } finally {
        reset();
      }
    };
    const onError = async (error) => {
      console.error(error);
    };

    return (
      <div className="form-wrapper">
        <FormProvider {...methods}>
          <BSForm
            onSubmit={handleSubmit(onSubmit, onError)}
            autoComplete={"off"}
            method="POST"
            className="input-group mx-auto"
            style={{ maxWidth: "480px" }}
          >
            <input
              type="email"
              className="form-control"
              placeholder="Введите вашу почту"
              required={true}
            />
            <button type="submit" className="btn btn-primary">
              {msgSubmit}
            </button>
          </BSForm>
          {error && (
            <p className="text-warning" style={{ textAlign: "center" }}>
              <small>{error}</small>
            </p>
          )}
          {success && (
            <p className="text-success" style={{ textAlign: "center" }}>
              <small>{msgSubmitted}</small>
            </p>
          )}
        </FormProvider>
      </div>
    );
  };

  useInterval(
    () => {
      // Set Time
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = String(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0");
      const minutes = String(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0");
      const seconds = String(
        Math.floor((distance % (1000 * 60)) / 1000)
      ).padStart(2, "0");

      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
    },
    isRunning ? delay : null
  );

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center h-100 text-center pb-2 py-sm-3 py-md-4 py-lg-5">
      {/* Logo */}
      <Link className="navbar-brand text-dark p-0 m-0 mb-5" to="/">
        <span className="text-primary flex-shrink-0 me-2">
          <svg
            width="35"
            height="32"
            viewBox="0 0 36 33"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M35.6,29c-1.1,3.4-5.4,4.4-7.9,1.9c-2.3-2.2-6.1-3.7-9.4-3.7c-3.1,0-7.5,1.8-10,4.1c-2.2,2-5.8,1.5-7.3-1.1c-1-1.8-1.2-4.1,0-6.2l0.6-1.1l0,0c0.6-0.7,4.4-5.2,12.5-5.7c0.5,1.8,2,3.1,3.9,3.1c2.2,0,4.1-1.9,4.1-4.2s-1.8-4.2-4.1-4.2c-2,0-3.6,1.4-4,3.3H7.7c-0.8,0-1.3-0.9-0.9-1.6l5.6-9.8c2.5-4.5,8.8-4.5,11.3,0L35.1,24C36,25.7,36.1,27.5,35.6,29z"
            ></path>
          </svg>
        </span>
        PsyWealth
      </Link>

      {/* Title */}
      {msgTitle && <h1 className="display-2 text-primary">{msgTitle}</h1>}
      {msgSubtitle && <h2 className="fs-xl text-primary">{msgSubtitle}</h2>}
      {msgDescription && (
        <p className="fs-xl mx-auto mb-5" style={{ maxWidth: "680px" }}>
          {msgDescription}
        </p>
      )}

      {/* Timer */}
      <div
        className="countdown d-flex flex-wrap justify-content-center mb-5"
        data-countdown-date={countdownDate}
      >
        <div className="text-center mb-2">
          <div className="position-relative p-2">
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-white rounded-1 d-dark-mode-none"></span>
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-secondary rounded-1 d-none d-dark-mode-block"></span>
            <div
              className="h3 fw-medium position-relative z-1 mb-0 mx-1"
              data-days={timerDays}
            >
              {timerDays}
            </div>
          </div>
          <span className="fs-sm">days</span>
        </div>
        <span className="blinking text-body-secondary fs-2 mx-2">:</span>
        <div className="text-center mb-2">
          <div className="position-relative p-2">
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-white rounded-1 d-dark-mode-none"></span>
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-secondary rounded-1 d-none d-dark-mode-block"></span>
            <div
              className="h3 fw-medium position-relative z-1 mb-0 mx-1"
              data-hours={timerHours}
            >
              {timerHours}
            </div>
          </div>
          <span className="fs-sm">hours</span>
        </div>
        <span className="blinking text-body-secondary fs-2 mx-2">:</span>
        <div className="text-center mb-2">
          <div className="position-relative p-2">
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-white rounded-1 d-dark-mode-none"></span>
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-secondary rounded-1 d-none d-dark-mode-block"></span>
            <div
              className="h3 fw-medium position-relative z-1 mb-0 mx-1"
              data-minutes={timerMinutes}
            >
              {timerMinutes}
            </div>
          </div>
          <span className="fs-sm">mins</span>
        </div>
        <span className="blinking text-body-secondary fs-2 mx-2">:</span>
        <div className="text-center mb-2">
          <div className="position-relative p-2">
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-white rounded-1 d-dark-mode-none"></span>
            <span className="position-absolute top-0 start-0 w-100 h-100 bg-secondary rounded-1 d-none d-dark-mode-block"></span>
            <div
              className="h3 fw-medium position-relative z-1 mb-0 mx-1"
              data-seconds={timerSeconds}
            >
              {timerSeconds}
            </div>
          </div>
          <span className="fs-sm">secs</span>
        </div>
      </div>

      {/* Form */}
      <FormSubscription onSubmit={onSubmit} />
    </div>
  );
};

export default SectionCountdown;
