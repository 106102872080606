import classNames from "classnames";
import { quiz } from "data";
import { useEffect, useState } from "react";

const msgFinish = "Завершить";
const msgNext = "Далее";
const msgReset = "С начала";

const Quiz = (props) => {
  const { onReset, onFinished } = props;
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
    scoreTotal: 0,
  });

  const { questions } = quiz;
  const { question, choices, correctAnswer } = questions[activeQuestion];

  const onClickNext = () => {
    // console.log(selectedAnswer);

    setResult((prev) =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + 5,
            correctAnswers: prev.correctAnswers + 1,
            scoreTotal: prev.scoreTotal + selectedAnswerIndex,
          }
        : {
            ...prev,
            wrongAnswers: prev.wrongAnswers + 1,
            scoreTotal: prev.scoreTotal + selectedAnswerIndex,
          }
    );
    setSelectedAnswerIndex(null);

    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const onAnswerSelected = (answer, index) => {
    // console.log(answer, index);

    setSelectedAnswerIndex(index);
    if (answer === correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  useEffect(() => {
    if (showResult) {
      onFinished && onFinished();
    }
  }, [showResult]);

  return (
    <div className="quiz-wrapper">
      {!showResult ? (
        <div>
          <div className="row g-0 mb-3">
            <div className="col-10">
              <h2>{question}</h2>
            </div>
            <div className="col-2">
              <span className="active-question-no">
                {addLeadingZero(activeQuestion + 1)}
              </span>
              <span className="total-question">
                /{addLeadingZero(questions.length)}
              </span>
            </div>
          </div>

          <div className="row g-0">
            <div className="col">
              <ul className="mb-5">
                {choices.map((answer, index) => (
                  <li
                    onClick={() => onAnswerSelected(answer, index)}
                    key={answer}
                    className={classNames(
                      "mt-3 p-3",
                      selectedAnswerIndex === index ? "selected-answer" : null
                    )}
                  >
                    {answer}
                  </li>
                ))}
              </ul>
              <div className="d-flex align-items-center justify-content-between">
                <button
                  onClick={onReset}
                  className="btn btn-outline-secondary fs-base"
                >
                  {msgReset}
                </button>

                <button
                  onClick={onClickNext}
                  disabled={selectedAnswerIndex === null}
                  className="btn btn-outline-primary fs-base"
                >
                  {activeQuestion === questions.length - 1
                    ? msgFinish
                    : msgNext}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="results">
          <h3>Результаты</h3>{" "}
          <p>
            Всего получено ответов: <span>{questions.length}</span>
          </p>
          <p>
            Ваш результат: <span>{result.scoreTotal}</span>
          </p>
          {/* {          <p>
            Total Question: <span>{questions.length}</span>
          </p>
          <p>
            Total Score:<span> {result.score}</span>
          </p>
          <p>
            Correct Answers:<span> {result.correctAnswers}</span>
          </p>
          <p>
            Wrong Answers:<span> {result.wrongAnswers}</span>
          </p> */}
        </div>
      )}
    </div>
  );
};

export default Quiz;
