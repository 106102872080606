import { PropTypes } from "prop-types";

import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTelegram,
  BiLogoTwitter,
} from "react-icons/bi";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

//import "./Section.style.scss";

// NOTE : https://sandbox.elemisthemes.com/docs/blocks/team.html

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const ContentItem = (props) => {
    const { data } = props;
    const OurTeamSocial = ({ items }) => {
      const getIconByType = (type) => {
        switch (type) {
          case "tg":
            return <BiLogoTelegram />;
          case "tweetwter":
            return <BiLogoTwitter />;
          case "fb":
            return <BiLogoFacebook />;
          case "instagram":
            return <BiLogoInstagram />;
          case "ln":
            return <BiLogoLinkedin />;
          default:
            return null;
        }
      };

      return (
        <div className="social">
          {items.map((item, index) => {
            return (
              <a
                href={item.link}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getIconByType(item.type)}
              </a>
            );
          })}
        </div>
      );
    };

    return (
      <div
        className="swiper-slide swiper-slide-active"
        role="group"
        aria-label="1 / 5"
        style={{ width: "259px", marginRight: "30px" }}
      >
        <img
          className="rounded-circle w-20 mx-auto mb-4"
          src={data.image}
          srcset="../../assets/img/avatars/t1@2x.jpg 2x"
          alt={data.imageAlt}
        />
        <h4 className="mb-1">{data.name}</h4>
        <div className="meta mb-2">{data.title}</div>
        {showDescription && <p className="mb-2">{data.description}</p>}

        {/* Social Links */}
        <nav className="nav social justify-content-center text-center mb-0">
          <OurTeamSocial items={data.socials} />
        </nav>

        {/* <nav className="nav social justify-content-center text-center mb-0">
          <a href="#">
            <i className="uil uil-twitter"></i>
          </a>
          <a href="#">
            <i className="uil uil-slack"></i>
          </a>
          <a href="#">
            <i className="uil uil-linkedin"></i>
          </a>
        </nav>*/}
      </div>
    );
  };

  const items =
    data.items.length > 0 &&
    data.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <ContentItem data={item} key={index} />
        </SwiperSlide>
      );
    });

  return (
    <div className="pt-15 pt-md-17 pb-13 pb-md-15">
      <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
        <div className="col-lg-4">
          <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">
            {data.title}
          </h2>
          <h3 className="display-5 mb-5">{data.subtitle}</h3>
          <p>{data.description}</p>
          {/*  <a href="#" className="btn btn-primary rounded-pill mt-3">
            See All Members
          </a>*/}
        </div>

        <div className="col-lg-8">
          <div
            className="swiper-container text-center mb-6 swiper-container-1"
            data-margin="30"
            data-dots="true"
            data-items-xl="3"
            data-items-md="2"
            data-items-xs="1"
          >
            <Swiper
              className="swiper"
              slidesPerView={3}
              spaceBetween={24}
              autoHeight={false}
              navigation={false}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              {items}
            </Swiper>

            {/*       <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 5" style="width: 259px; margin-right: 30px;">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="../../assets/img/avatars/t1.jpg" srcset="../../assets/img/avatars/t1@2x.jpg 2x" alt="">
                  <h4 className="mb-1">Cory Zamora</h4>
                  <div className="meta mb-2">Marketing Specialist</div>
                  <p className="mb-2">Etiam porta sem magna malesuada mollis.</p>
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="uil uil-twitter"></i></a>
                    <a href="#"><i className="uil uil-slack"></i></a>
                    <a href="#"><i className="uil uil-linkedin"></i></a>
                  </nav>
            
                </div>
              
                <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 5" style="width: 259px; margin-right: 30px;">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="../../assets/img/avatars/t2.jpg" srcset="../../assets/img/avatars/t2@2x.jpg 2x" alt="">
                  <h4 className="mb-1">Coriss Ambady</h4>
                  <div className="meta mb-2">Financial Analyst</div>
                  <p className="mb-2">Aenean eu leo quam. Pellentesque ornare lacinia.</p>
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="uil uil-youtube"></i></a>
                    <a href="#"><i className="uil uil-facebook-f"></i></a>
                    <a href="#"><i className="uil uil-dribbble"></i></a>
                  </nav>
                  
                </div>
 
                <div className="swiper-slide" role="group" aria-label="3 / 5" style="width: 259px; margin-right: 30px;">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="../../assets/img/avatars/t3.jpg" srcset="../../assets/img/avatars/t3@2x.jpg 2x" alt="">
                  <h4 className="mb-1">Nikolas Brooten</h4>
                  <div className="meta mb-2">Sales Manager</div>
                  <p className="mb-2">Donec ornare elit quam porta gravida at eget.</p>
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="uil uil-linkedin"></i></a>
                    <a href="#"><i className="uil uil-tumblr-square"></i></a>
                    <a href="#"><i className="uil uil-facebook-f"></i></a>
                  </nav>
                  
                </div>
               
                <div className="swiper-slide" role="group" aria-label="4 / 5" style="width: 259px; margin-right: 30px;">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="../../assets/img/avatars/t4.jpg" srcset="../../assets/img/avatars/t4@2x.jpg 2x" alt="">
                  <h4 className="mb-1">Jackie Sanders</h4>
                  <div className="meta mb-2">Investment Planner</div>
                  <p className="mb-2">Nullam risus eget urna mollis ornare vel eu leo.</p>
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="uil uil-twitter"></i></a>
                    <a href="#"><i className="uil uil-facebook-f"></i></a>
                    <a href="#"><i className="uil uil-dribbble"></i></a>
                  </nav>
             
                </div>
              
                <div className="swiper-slide" role="group" aria-label="5 / 5" style="width: 259px; margin-right: 30px;">
                  <img className="rounded-circle w-20 mx-auto mb-4" src="../../assets/img/avatars/t5.jpg" srcset="../../assets/img/avatars/t5@2x.jpg 2x" alt="">
                  <h4 className="mb-1">Tina Geller</h4>
                  <div className="meta mb-2">Assistant Buyer</div>
                  <p className="mb-2">Vivamus sagittis lacus vel augue laoreet rutrum.</p>
                  <nav className="nav social justify-content-center text-center mb-0">
                    <a href="#"><i className="uil uil-facebook-f"></i></a>
                    <a href="#"><i className="uil uil-slack"></i></a>
                    <a href="#"><i className="uil uil-dribbble"></i></a>
                  </nav>
                 
                </div>*/}

            {/*
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span> */}

            {/*
            <div className="swiper-controls">
              <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                <span
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                  tabindex="0"
                  role="button"
                  aria-label="Go to slide 1"
                  aria-current="true"
                ></span>
                <span
                  className="swiper-pagination-bullet"
                  tabindex="0"
                  role="button"
                  aria-label="Go to slide 2"
                ></span>
                <span
                  className="swiper-pagination-bullet"
                  tabindex="0"
                  role="button"
                  aria-label="Go to slide 3"
                ></span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Our Team (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
