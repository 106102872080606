import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import { isDevelopment } from "components/utils/Utils";
import { YA_MAP_API_KEY } from "config/config";
import { PageSection } from "core/components";
import { dataSite } from "data";
import { _debug } from "utils";

const center = [55.75, 37.57];
const MAP_API_KEY = YA_MAP_API_KEY;

const SectionContent = (props) => {
  const { data } = props;

  _debug(isDevelopment ? "debug" : "release");
  console.log(MAP_API_KEY);

  return (
    <div className="map-container">
      <YMaps
        query={{
          apikey: MAP_API_KEY,
          lang: "ru_RU",
          //load: "package.full",
          // controls: ["zoomControl", "fullscreenControl"],
          // mode: isDevelopment ? "debug" : "release",
        }}
        modules={["control.ZoomControl", "control.FullscreenControl"]}
      >
        <Map
          modules={["geoObject.addon.balloon"]}
          defaultState={{ center, zoom: 15 }}
          properties={{
            balloonContentBody: `${dataSite.main.title} ${dataSite.main.address}`,
          }}
          width="100%"
          height="680px"
        >
          <Placemark defaultGeometry={[55.75, 37.57]} />
        </Map>
      </YMaps>
    </div>
  );
};

/**
 * Component for showing section "Map" of the site.
 *
 * @component
 */
const SectionMap = (props) => {
  const { data, id = "map", cn = "" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <SectionContent data={data} id={id} cn={cn} />
    </PageSection>
  );
};

export default SectionMap;

/*

const ContactUs = () => {
  return (
    <section
      className="subscribe-section pt-70 pb-70 img-bg"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="section-title mb-30">
              <span
                className="text-white wow fadeInDown"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <font style="vertical-align: inherit;">Подписаться</font>
              </span>
              <h2
                className="text-white mb-40 wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <font style="vertical-align: inherit;">
                  Подпишитесь на нашу рассылку
                </font>
              </h2>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <form
              action="#"
              className="subscribe-form wow fadeInRight"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeInRight",
              }}
            >
              <input
                type="text"
                name="subs-email"
                id="subs-email"
                placeholder="Ваш адрес электронной почты"
              />
              <button type="submit">
                <i className="lni lni-telegram-original"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const ContactFormTitle = () => {
  return (
    <div className="section-title text-center mb-50">
      <span
        className="wow fadeInDown"
        data-wow-delay=".2s"
        style={{
          visibility: "visible",
          animationDelay: "0.2s",
          animationName: "fadeInDown",
        }}
      >
        Связаться
      </span>
      <h2
        className="wow fadeInUp"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
        }}
      >
        Готовы начать работу
      </h2>
      <p
        className="wow fadeInUp"
        data-wow-delay=".6s"
        style={{
          visibility: "visible",
          animationDelay: "0.6s",
          animationName: "fadeInUp",
        }}
      >
        At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis
        praesentium
      </p>
    </div>
  );
};

*/
