const dataOverviewImageAlt = "";

const dataUsedForImageCommon = { alt: "", width: "400", height: 390 };
export const dataUsedForMainPage = {
  title: "С чем поможет психолог или психотерапевт",
  subtitle: "",
  description: "",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
  // V1
  // Стрессом и тревожностью: Постоянная напряжённость и чувство неопределённости могут приводить к физическим и эмоциональным истощениям. Наши специалисты помогут вам выявить причины стресса и научат методам его управления.
  // Депрессией: Если вы чувствуете подавленность, отсутствие интереса к жизни или постоянную усталость, профессиональная поддержка может стать ключом к восстановлению вашего эмоционального благополучия.
  // Отношениями: Конфликты с близкими, друзьями или коллегами могут вызывать глубокую боль. Мы поможем вам разобраться в ваших чувствах и наладить здоровое общение.
  // Потерей и горем: Процесс утраты может быть тяжелым и запутанным. Наши психологи помогут вам пройти через этот непростой период, поддерживая и направляя вас к принятию и исцелению.
  // Личностным ростом: Вы хотите понять себя лучше, развить уверенность в себе и найти свое место в жизни? Мы предлагаем индивидуальные подходы, которые помогут вам раскрыть свой потенциал.

  // V2
  // Хронический стресс и тревожность
  // Постоянное ощущение напряжения, панические атаки и страхи могут мешать нормальному функционированию. Наши специалисты помогут вам научиться управлять тревожностью и находить спокойствие в повседневной жизни.
  // Депрессия и апатия
  // Если вы чувствуете себя подавленным, потеряли интерес к привычным вещам или испытываете постоянную усталость, психотерапия может помочь вам вернуть радость и смысл в жизнь.
  // Проблемы в отношениях
  // Конфликты с партнёрами, друзьями или коллегами могут вызывать глубокую боль и недопонимание. Мы поможем вам разобраться в ваших чувствах и научиться строить здоровые и гармоничные отношения.
  // Потеря и горе
  // Столкновение с утратой близкого человека или значимой ситуации может вызывать сильные эмоциональные переживания. Психологическая поддержка поможет вам пройти через этот трудный период и найти пути к исцелению.
  // Низкая самооценка и неуверенность в себе
  // Если вы постоянно сомневаетесь в своих силах или испытываете чувство неполноценности, наши специалисты помогут вам развить уверенность в себе и изменить негативные установки.
  // Трудности с адаптацией
  // Изменения в жизни, такие как переезд, смена работы или рождение ребёнка, могут вызывать стресс и беспокойство. Мы поможем вам адаптироваться к новым условиям и найти внутренние ресурсы для преодоления трудностей.
  // Психосоматические расстройства
  // Физические симптомы, вызванные эмоциональными переживаниями, могут значительно ухудшать качество жизни. Психотерапия может помочь вам разобраться в причинах этих симптомов и найти пути их устранения.
  // Проблемы с самоидентификацией
  // Если вы чувствуете, что не знаете, кто вы есть, или не можете определить свои цели и желания, обращение к психологу может помочь вам лучше понять себя и свои потребности.
  // Трудности в карьере
  // Неуверенность в профессиональных навыках, страх перед увольнением или проблемы с коллегами могут негативно влиять на вашу работу. Мы поможем вам разобраться в ваших карьерных целях и развить уверенность в своих силах.
  // Одиночество и изоляция
  // Чувство одиночества и отсутствие близких людей могут приводить к депрессии и тревоге. Мы предложим стратегии для улучшения социальных связей и формирования поддерживающей сети.
  // Проблемы с зависимостями
  // Алкоголь, наркотики, азартные игры или другие формы зависимости могут разрушать вашу жизнь. Психотерапия может помочь вам осознать корни ваших зависимостей и разработать стратегии для их преодоления.
  // Травматический опыт
  // Проживание травматических событий, таких как насилие, аварии или другие кризисы, может оставить глубокий след. Мы предлагаем безопасное пространство для работы с этими переживаниями и их последствиями.
  // Эмоциональное выгорание
  // Если вы чувствуете себя истощённым и не можете найти мотивацию, мы поможем вам восстановить силы и вернуть интерес к жизни.
  // Проблемы с родительством
  // Стресс и неуверенность в роли родителя могут вызывать беспокойство. Мы поможем вам развить навыки эффективного родительства и наладить гармоничные отношения с детьми.
  // Страхи и фобии
  // Специфические страхи, такие как боязнь высоты или общественных мест, могут ограничивать вашу жизнь. Психотерапия может помочь вам преодолеть эти страхи и вернуть контроль над своей жизнью.

  items: [
    {
      title: "Справиться со стрессом и тревожностью",
      description:
        "Постоянное ощущение напряжения, панические атаки и страхи могут мешать нормальному функционированию. Наши специалисты помогут вам научиться управлять тревожностью и находить спокойствие в повседневной жизни",
      index: 0,
      image: {
        src: require("assets/images/new/service-image-1.webp"),
        alt:
          "Справиться со стрессом и тревожностью" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Депрессия и апатия",
      description:
        "Если вы чувствуете себя подавленным, потеряли интерес к привычным вещам или испытываете постоянную усталость, психотерапия может помочь вам вернуть радость и смысл в жизнь.",
      index: 1,
      image: {
        src: require("assets/images/new/service-image-2.webp"),
        alt: "Депрессия и апатия" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Проблемы в отношениях",
      description:
        "Конфликты с партнёрами, друзьями или коллегами могут вызывать глубокую боль и недопонимание. Мы поможем вам разобраться в ваших чувствах и научиться строить здоровые и гармоничные отношения.",
      index: 2,
      image: {
        src: require("assets/images/new/service-image-3.webp"),
        alt: "Проблемы в отношениях" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Разобраться в семейных проблемах",
      description:
        "Часто проблемы в отношениях могут мешать нашему счастью. Наши психологи помогут вам разрешить конфликты, научат эффективному общению и помогут построить крепкие и доверительные отношения с близкими.",
      index: 3,
      image: {
        src: require("assets/images/new/service-image-4.webp"),
        alt: "Разобраться в семейных проблемах" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Личностный рост",
      description:
        "Вы хотите понять себя лучше, развить уверенность в себе и найти свое место в жизни? Мы предлагаем индивидуальные подходы, которые помогут вам раскрыть свой потенциал",
      index: 4,
      image: {
        src: require("assets/images/new/service-image-5.webp"),
        alt: "Личностный рост" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Низкая самооценка и неуверенность в себе",
      description:
        "Если вы постоянно сомневаетесь в своих силах или испытываете чувство неполноценности, наши специалисты помогут вам развить уверенность в себе и изменить негативные установки.",
      index: 5,
      image: {
        src: require("assets/images/new/service-image-6.webp"),
        alt:
          "Низкая самооценка и неуверенность в себе" ||
          dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Потеря и горе",
      description:
        "Столкновение с утратой близкого человека или значимой ситуации может вызывать сильные эмоциональные переживания. Психологическая поддержка поможет вам пройти через этот трудный период и найти пути к исцелению.",
      index: 6,
      image: {
        src: require("assets/images/new/service-image-1.webp"),
        alt: "Потеря и горе" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Психосоматические расстройства",
      description:
        "Физические симптомы, вызванные эмоциональными переживаниями, могут значительно ухудшать качество жизни. Психотерапия может помочь вам разобраться в причинах этих симптомов и найти пути их устранения.",
      index: 7,
      image: {
        src: require("assets/images/new/service-image-2.webp"),
        alt: "Психосоматические расстройства" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Травматический опыт",
      description:
        "Проживание травматических событий, таких как насилие, аварии или другие кризисы, может оставить глубокий след. Мы предлагаем безопасное пространство для работы с этими переживаниями и их последствиями.",
      index: 8,
      image: {
        src: require("assets/images/new/service-image-3.webp"),
        alt: "Травматический опыт" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Эмоциональное выгорание",
      description:
        "Если вы чувствуете себя истощённым и не можете найти мотивацию, мы поможем вам восстановить силы и вернуть интерес к жизни.",
      index: 9,
      image: {
        src: require("assets/images/new/service-image-4.webp"),
        alt: "Эмоциональное выгорание" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Страхи и фобии",
      description:
        "Специфические страхи, такие как боязнь высоты или общественных мест, могут ограничивать вашу жизнь. Психотерапия может помочь вам преодолеть эти страхи и вернуть контроль над своей жизнью.",
      index: 10,
      image: {
        src: require("assets/images/new/service-image-5.webp"),
        alt: "Страхи и фобии" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Трудности в карьере",
      description:
        "Неуверенность в профессиональных навыках, страх перед увольнением или проблемы с коллегами могут негативно влиять на вашу работу. Мы поможем вам разобраться в ваших карьерных целях и развить уверенность в своих силах.",
      index: 11,
      image: {
        src: require("assets/images/new/service-image-6.webp"),
        alt: "Трудности в карьере" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Одиночество и изоляция",
      description:
        "Чувство одиночества и отсутствие близких людей могут приводить к депрессии и тревоге. Мы предложим стратегии для улучшения социальных связей и формирования поддерживающей сети.",
      index: 12,
      image: {
        src: require("assets/images/new/service-image-1.webp"),
        alt: "Одиночество и изоляция" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
    {
      title: "Проблемы с зависимостями",
      description:
        "Алкоголь, наркотики, азартные игры или другие формы зависимости могут разрушать вашу жизнь. Психотерапия может помочь вам осознать корни ваших зависимостей и разработать стратегии для их преодоления.",
      index: 13,
      image: {
        src: require("assets/images/new/service-image-2.webp"),
        alt: "Проблемы с зависимостями" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
  ],

  images: [
    {
      image: {
        src: require("assets/images/feature/features_demo_1.webp"),
        alt:
          "Устроить личную жизнь и разобраться в семейных проблемах" ||
          dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_2.webp"),
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_3.webp"),
        alt:
          "Избавиться от страхов, панических атак и тревоги" ||
          dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_4.webp"),
        alt:
          "Преодолеть конфликты и наладить отношения с близкими" ||
          dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_5.webp"),
        alt: "Выйти из депрессии и обрести спокойствие" || dataOverviewImageAlt,
        title: null,
      },
    },
  ],
};
