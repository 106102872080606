import classNames from "classnames";
import { SectionFAQLazy, SectionRelatedPostsLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import {
  PageMain,
  PageSection,
  useSectionPrefix,
} from "core/components/Section";
import {
  dataBeckTestTestsPage,
  dataFAQTestsPage as dataFAQ,
  dataTestsPage,
} from "data";
import { dataQuizResultsTestsPage } from "data/dataQuizQuestions";
import { Fragment, useState } from "react";
import { SectionQuiz } from "./Quiz";

// NOTE :

const dataRelatedPosts = {
  title: "Похожее в блоге",
  subtitle: "Похожее в блоге",
  description: "Похожее в блоге",
};

const SectionTests = (props) => {
  const {
    data,
    id = "about-us",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentSubtitle = false,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card position-relative">
            <div className="card-body">
              <div className="product-item">
                <figure>
                  <a href="index.html" title="Product Title">
                    <img
                      src={require("assets/images/tests/test1jpg.webp")}
                      className="tab-image"
                      alt=""
                    />
                  </a>
                </figure>
                <h3>Шкала депрессии А.Бека</h3>
                <span className="qty">1 Unit</span>
                <span className="rating">4.5</span>
                <span className="price">$18.00</span>
                <div className="d-flex align-items-center justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card position-relative">
            <div className="card-body">
              <div className="product-item">
                <figure>
                  <a href="index.html" title="Product Title">
                    <img
                      src={require("assets/images/tests/test1jpg.webp")}
                      className="tab-image"
                      alt=""
                    />
                  </a>
                </figure>
                <h3>Шкала депрессии А.Бека</h3>
                <span className="qty">1 Unit</span>
                <span className="rating">4.5</span>
                <span className="price">$18.00</span>
                <div className="d-flex align-items-center justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

/**
 * Component for showing Page "Quiz" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageTests = (props) => {
  const { id = "quiz" } = props;
  const [isTestFinished, setIsTestFinished] = useState(false);
  console.log(isTestFinished);

  return (
    <Fragment>
      <SEO data={dataTestsPage.meta}>
        {/*Don’t canonicalise to a redirected page or a 404 page*/}
        <link rel="canonical" href="https://psywealth.com/tests" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataTestsPage} />

        {/* <SectionTests /> */}

        <SectionQuiz
          data={dataBeckTestTestsPage}
          onFinished={() => setIsTestFinished(true)}
        />
        {isTestFinished && (
          <SectionFAQLazy data={dataQuizResultsTestsPage} type={"style1"} />
        )}
        <SectionRelatedPostsLazy data={dataRelatedPosts} type={"style2"} />
        <SectionFAQLazy data={dataFAQ} type={"style1"} />
      </PageMain>
    </Fragment>
  );
};

export default PageTests;
