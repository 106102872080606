import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataCoursesPage } from "data";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { _debug } from "utils";
import { CoursesSection } from "./components";

// NOTE :

/**
 * Component for showing Page "Our team" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageCourses = (props) => {
  const { id = "courses" } = props;
  const { courseSlug } = useParams();

  useEffect(() => {}, []);

  useEffect(() => {
    _debug("URL updated:", URL);
    //updateUrl(URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  return (
    <Fragment>
      <SEO data={dataCoursesPage.meta}>
        <link rel="canonical" href="https://psywealth.com/courses" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner data={dataCoursesPage} />
        <CoursesSection data={dataCoursesPage} />
      </PageMain>
    </Fragment>
  );
};

export default PageCourses;
