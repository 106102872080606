import { PropTypes } from "prop-types";
import { Fragment } from "react";

import Background1 from "assets/images/feature/about-grid-img-1.webp";
import Background2 from "assets/images/feature/about-grid-img-2.webp";
import Background3 from "assets/images/feature/about-grid-img-3.webp";

// NOTE : https://block.codescandy.com/landing-jamstack-agancy.html

const ContentCounts = (props) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="pe-5 text-center">
        <h3 className="mb-0 fs-2 fw-bold text-primary">
          <span className="aos-init aos-animate">100</span>%
        </h3>
        <p className="text-gray-700 mb-0 fs-5">Satisfaction</p>
      </div>
      <div className="border-start border-gray-300"></div>
      <div className="px-5 text-center">
        <h3 className="mb-0 fs-2 fw-bold text-primary">
          <span className="aos-init aos-animate">24</span>/
          <span className="aos-init aos-animate">7</span>
        </h3>
        <p className="text-gray-700 mb-0 fs-5">Support</p>
      </div>
      <div className="border-start border-gray-300"></div>
      <div className="ps-5 text-center">
        <h3 className="mb-0 fs-2 fw-bold text-primary">
          <span className="aos-init aos-animate">100</span>
          k+
        </h3>
        <p className="text-gray-700 mb-0 fs-5">Sales</p>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  // Content : col-12 col-md-7 col-lg-6 order-md-1
  // Image : col-12 col-md-5 col-lg-6 order-md-2
  return (
    <Fragment>
      <div className="row align-items-center g-4">
        <div className="col-12 col-lg-6 col-xl-5 order-md-1">
          <div className="mb-5">
            {/* <small className="text-uppercase ls-md fw-semibold">
              who we are
            </small>*/}
            <h3 className="mt-4 mb-3">{data.subtitle}</h3>
            {data.descriptionHTML ? (
              data.descriptionHTML
            ) : (
              <Fragment>
                {data.description1 && (
                  <p className="mb-2">{data.description1}</p>
                )}
                {data.description2 && (
                  <p className="mb-0">{data.description2}</p>
                )}
              </Fragment>
            )}
          </div>
          <ContentCounts />
        </div>
        <div className="col-12 col-lg-6 col-xl-6 offset-xl-1 order-md-2">
          <div className="row g-4">
            <div className="col-lg-6 col-md-6 col-12">
              <div
                className="image-cards image-1"
                style={{
                  backgroundImage: `url(${Background1})`,
                  backgroundRepeat: "no-repeat",
                  height: "486px",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div
                className="image-cards image-2 mb-4"
                style={{
                  backgroundImage: `url(${Background2})`,
                  backgroundRepeat: "no-repeat",
                  height: "230px",
                  backgroundSize: "cover",
                }}
              ></div>

              <div
                className="image-cards image-3 mb-2"
                style={{
                  backgroundImage: `url(${Background3})`,
                  backgroundRepeat: "no-repeat",
                  height: "230px",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component section content "Feature Of Free Help (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  return (
    <SectionContent
      data={data}
      showTitle={showTitle}
      showDescription={showDescription}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
