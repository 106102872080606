import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";

import classNames from "classnames";
import FormAdditionalInfo from "components/sections/CTA/FormAdditionalInfo";
import { Modal } from "react-bootstrap";
import { BiCheckDouble } from "react-icons/bi";
import "./Section.style.scss";

// NOTE :

// NOTE : https://bootstrapmade.com/demo/Logis/

const msgSubmit = "Подробнее";

const SectionItem = (props) => {
  const { data, index } = props;
  const [show, setShow] = useState(false);

  const isStyle2 = index % 2 === 0;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const SectionItemModalForm = ({ data }) => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="additional-info-form-modal"
        size="lg"
        aria-labelledby="additional-info-form-modal-title"
      >
        <Modal.Dialog>
          <FormAdditionalInfo
            data={data}
            title={data.title}
            onSubmit={handleClose}
            handleClose={handleClose}
          />
        </Modal.Dialog>
      </Modal>
    );
  };

  const SectionListItem = (props) => {
    const { data: items } = props;
    // <ul className="list-unstyled ps-4 mb-n2">
    return (
      <ul className="list-unstyled ps-4">
        {items.map((itemList, indexList) => {
          if (!itemList.description) return null;
          return (
            <li
              className={classNames(
                indexList + 1 < items.length ? "mb-2" : "mb-5"
              )}
              key={indexList}
            >
              <i>{itemList.icon ? itemList.icon : <BiCheckDouble />}</i>
              {itemList.description}
            </li>
          );
        })}
      </ul>
    );
  };

  const SectionControlItem = ({ data }) => {
    return (
      <div className="control-wrapper mt-3 text-center">
        <button
          onClick={handleShow}
          className="btn btn-primary theme-btn"
          aria-label="Show more"
        >
          {data.msgSubmit || msgSubmit}
        </button>
      </div>
    );
  };

  const SectionItemBody = ({ data, isStyle2 }) => {
    return (
      <Fragment>
        <div
          className={
            isStyle2
              ? "ms-md-2 ms-lg-5 mt-5 mt-md-0"
              : "me-md-2 me-lg-5 mt-5 mt-md-0"
          }
        >
          <div className="card-body">
            <h3 className="h4 card-title">{data.title}</h3>
            {data.description1 || data.description2 ? (
              <Fragment>
                <p className="fst-italic">{data.description1}</p>
                <p>{data.description2}</p>
              </Fragment>
            ) : (
              <p>{data.description}</p>
            )}
            {data.list.items && data.list.items.length > 0 && (
              <SectionListItem data={data.list.items} />
            )}
            {data.control && data.control.show && (
              <Fragment>
                <SectionControlItem data={data.control} />
                <SectionItemModalForm data={data.control} />
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  const SectionItemImage = ({ data }) => {
    const { src, alt, title, ...otherProps } = data.image;

    return (
      <div className="text-center">
        <img
          className="img-fluid img-radius"
          src={src}
          alt={alt}
          title={title}
          loading="lazy"
          {...otherProps}
        />
      </div>
    );
  };

  // className="row gy-4 align-items-center features-item aos-animate"

  return (
    <Fragment>
      {isStyle2 ? (
        <div
          className="row gy-5 align-items-center features-item aos-animate"
          data-aos="fade-up"
        >
          {/* Image */}
          <div className="col-md-6 col-12">
            <SectionItemImage data={data} width="600" height="300" />
          </div>
          {/* Content */}
          <div className="col-md-6 col-12 text-center text-md-start">
            <SectionItemBody data={data} isStyle2 />
          </div>
        </div>
      ) : (
        <div
          className="row gy-4 align-items-center features-item aos-animate"
          data-aos="fade-up"
        >
          <div className="col-md-6 order-1 order-md-2 col-12">
            {/* col-md-7 col-lg-6 mb-4 mb-md-0 */}
            <SectionItemImage data={data} width="600" height="300" />
            {/* col-md-5 col-xl-4 offset-lg-1 aos-init aos-animate */}
          </div>
          <div className="col-md-6 order-2 order-md-1 col-12 text-center text-md-start">
            <SectionItemBody data={data} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      {data.items.map((item, index) => {
        return <SectionItem data={item} index={index} key={index} />;
      })}
    </Fragment>
  );
};

/**
 * Component section content "Overview (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  return (
    <SectionContent
      data={data}
      showTitle={showTitle}
      showDescription={showDescription}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
