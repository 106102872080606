import { Service } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import {
  dataHeroServicesPage as dataHero,
  dataServiceServicePage as dataService2,
} from "data";
import { dataServicesPage } from "data/dataPages";
import { Fragment } from "react";
import { Hero2 as Hero } from ".";

// NOTE :

/**
 * Component for showing Page "Services" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageServices = (props) => {
  const { id = "services" } = props;

  return (
    <Fragment>
      <SEO data={dataServicesPage.meta}>
        <link rel="canonical" href="https://psywealth.com/services" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Hero data={dataHero} />
        <Service data={dataService2} type="style1" />
        <Service data={dataService2} type="style2" />
      </PageMain>
    </Fragment>
  );
};

export default PageServices;
