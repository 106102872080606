import { Image } from "components/utils/Image";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import Icon1 from "assets/images/new/cta/bg.svg";

// NOTE :

const msgCTAAction = "Найти";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    innerRef,
    onSubmit,
    ...otherProps
  } = props;

  const handlerSubmit = () => {
    onSubmit && onSubmit();
  };

  //     <div className="bg-primary rounded-5 overflow-hidden mt-2 mt-sm-4 mt-lg-5">

  return (
    <div className="bg-primary rounded-5 overflow-hidden">
      <img
        className="position-absolute top-50 start-0 translate-middle-y d-none d-md-block"
        src={Icon1}
        width="186"
        alt="Background flower"
      />
      <div className="row position-relative align-items-center z-2">
        <div className="col-md-6 col-lg-5 offset-lg-1">
          <div className="text-center text-md-start py-5 px-4 px-sm-5 pe-md-0 ps-lg-4 ps-xl-5">
            {/* Subtitle */}
            <div className="d-flex align-items-center justify-content-center justify-content-md-start align-items-center mb-4">
              <span
                className="bg-white opacity-70 me-2 d-none d-md-block"
                style={{ width: "40px", height: "1px", marginTop: "-1px" }}
              ></span>
              <span className="fs-xs fw-semibold text-white opacity-70 text-uppercase">
                {data.subtitle}
              </span>
            </div>

            {/* Title */}
            {data.title && (
              <h2 className="h1 text-white pb-2 pb-sm-3">{data.title}</h2>
            )}

            {/* Description */}
            {data.description1 && data.description2 ? (
              <Fragment>
                <p className="lead text-white mb-1 fs-5">{data.description1}</p>
                <p className="lead text-white">{data.description2}</p>
              </Fragment>
            ) : (
              <p className="lead text-white pb-2 pb-sm-3">
                {data.description1}
              </p>
            )}

            {/* Link */}
            <Link
              className="btn btn-outline-light fs-base"
              onClick={handlerSubmit}
              aria-label={data?.msg?.msgCTA || msgCTAAction}
            >
              {msgCTAAction}
            </Link>

            {/* <BSButton
              className=""
              variant="outline-primary"
              onClick={handlerSubmit}
              aria-label={data?.msg?.msgCTA || msgCTAAction}
            >
              {data?.msg?.msgCTA || msgCTAAction}
            </BSButton> */}
          </div>
        </div>
        <div className="col-md-6 align-content-end">
          {/* <img
            className="d-block mx-auto mx-md-0 mt-n4 mt-md-0"
            src={require("assets/images/new/cta/image.webp")}
            width="534"
            alt="some text"
          /> */}
          <Image
            image={data?.image}
            className="d-block mx-auto mx-md-0 mt-n4 mt-md-0"
            width="534"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Call To Action (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;

  return (
    <div className="container">
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showTitle}
        showSubtitle={showSubtitle}
        showDescription={showDescription}
        innerRef={innerRef}
        {...otherProps}
      />
    </div>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showSubtitle: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
