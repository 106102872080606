import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// NOTE :

export const msgCTA = "Заказать";
export const msgSubmitted = "Заказ успешно направлен";
export const msgSubmit = "Заказать";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    innerRef,
    onSubmit,
    ...otherProps
  } = props;
  const isMobile = useIsMobile();

  const handlerSubmit = () => {
    onSubmit && onSubmit();
  };

  const ContentItem = (props) => {
    const { data } = props;

    return (
      <Fragment>
        {/* <span className="ratings five mb-3"></span> */}
        <div className="text-center">
          <blockquote className="d-block border-0 fs-4 fs-lg-3 mb-2">
            <p>{data.description1}</p>
            <div className="blockquote-details justify-content-center text-center">
              {data.subtitle &&
                data.description2(
                  <div className="info ps-0">
                    <h5 className="mb-1 text-white">{data.subtitle}</h5>
                    <p className="lead mb-0">{data.description2}</p>
                  </div>
                )}
            </div>
          </blockquote>
        </div>
      </Fragment>
    );
  };

  const items =
    data.items.length > 0 &&
    data.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <ContentItem data={item} key={index} />
        </SwiperSlide>
      );
    });

  return (
    <div className="bg-primary rounded-5 overflow-hidden mt-2 mt-sm-4 mt-lg-5">
      <div className="row justify-content-center g-0">
        <div className="col-10 col-lg-8 py-5">
          <div className="slider">
            <Swiper
              className="swiper pb-5"
              slidesPerView={1}
              // spaceBetween={24}
              // autoHeight={false}
              navigation={false}
              pagination={{
                // clickable: true,
                dynamicBullets: true,
              }}
              modules={[Pagination]}
            >
              {items}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Call To Action (style 4)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle4 = (props) => {
  const {
    data,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;

  return (
    <div className="container">
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        showDescription={showDescription}
        showSubtitle={showSubtitle}
        innerRef={innerRef}
        {...otherProps}
      />
    </div>
  );
};

SectionContentStyle4.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showSubtitle: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle4;
