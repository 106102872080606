import { PropTypes } from "prop-types";

import Icon1 from "assets/images/new/shape_leaf_left_top.svg";

import { useNavigate } from "react-router-dom";
import HeroControl from "./HeaderControl";
import "./Section.style.scss";

// NOTE :

const msgPrimary = "Заполнить анкету";
const msgSecondary = "Бонус за регистрацию";

/**
 * Component section content "Hero (style 5)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle5 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    innerRef,
    onSubmit,
    onRegister,
    ...otherProps
  } = props;
  const navigate = useNavigate();

  const heroControl = {
    primary: { title: msgPrimary },
    secondary: { title: msgSecondary },
  };

  const handlerSubmit = () => {
    onSubmit && onSubmit();
  };

  const handlerRegister = () => {
    navigate("/psychologist/lk/auth");
    onRegister && onRegister();
  };

  return (
    <div className="hero_section decoration_wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero_content_wrap">
              <h1 className="heading_text">{data.title}</h1>
              <p className="heading_description">{data.description}</p>

              <HeroControl
                data={heroControl}
                cn="mt-8"
                onClickPrimary={handlerSubmit}
                onClickSecondary={handlerRegister}
              />

              {/* <ul className="btns_group unordered_list">
                <li>
                  <a className="btn btn-primary" href="contact.html">
                    <span className="btn_text" data-text="Get A Consultation">
                      Get A Consultation{" "}
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </li>
                <li>
                  <a className="btn btn-outline-secondary" href="about.html">
                    <span className="btn_text" data-text="Learn More">
                      Learn More{" "}
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero_image_wrap">
              <img
                src={require("assets/images/new/main-slider-three-img-2.webp")}
                alt="Talking Minds – Psychotherapist Site Template"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="decoration_item shape_blur_shadow"></div>
      <div className="decoration_item shape_leaf">
        <img
          src={Icon1}
          alt="Shape Leaf - Talking Minds - Psychotherapist Site Template"
        />
      </div>
    </div>
  );
};

SectionContentStyle5.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle5;
