import background from "assets/images/common-bg.svg";
import { BLOCK_SPAM_MS } from "config/config";
//import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import { APP_API_URI } from "config/config";
import { PageSection, SectionContentHeader } from "core/components";
import DOMPurify from "isomorphic-dompurify";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import { registerSubscription } from "services/events.service";
import FormSubscription from "./Subscribe.form";

// NOTE : https://blog.logrocket.com/using-dangerouslysetinnerhtml-react-application/

const msgSubscribed = "Вы успешно подписаны";
const msgFormEmailPlaceholder = "Ваша почта";
const msgFormEmailRequired = "Требуется ввести адрес электронной почты"; // "Email is required",

const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const FormSubscription_ = (props) => {
  //const methods = useForm();
  const {
    methods,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //const gaEventTracker = useAnalyticsEventTracker("User subscribe");

  const handleRegisterSubscription_ = async (data) => {
    const response = await fetch(`${APP_API_URI}/subscription/subscribe`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      //mode: "no-cors",
      body: JSON.stringify(data),
    });

    return response.json();
  };

  const handleSubscriptionSubmit = async (data) => {
    //e.preventDefault();
    console.log("Submit subscription", data);

    setError(null);
    try {
      //gaEventTracker("Subscribe");

      const response = await registerSubscription({
        email: data.email,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  const onSubmit = (data) => {
    console.log("onSubmit", data);

    setIsSubmitted(true);

    // User subscribe
    handleSubscriptionSubmit(data);
    reset();
  };

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitted) setIsSubmitted(false);
      console.log(isSubmitted);
    }, BLOCK_SPAM_MS);
  }, [isSubmitted]);

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="subscribe-form wow fadeInRight"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDelay: "0.4s",
          animationName: "fadeInRight",
        }}
      >
        <Form.Group>
          <Form.Control
            type="text"
            name="email"
            id="email"
            placeholder={msgFormEmailPlaceholder}
            isInvalid={!!errors.email}
            {...register("email", {
              required: { msgFormEmailRequired },
              validate: {
                maxLength: (v) =>
                  v.length <= 50 ||
                  "The email should have at most 50 characters",
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  "Email address must be a valid address",
              },
            })}
            aria-invalid={errors?.email ? "true" : "false"}
          />
          <button type="submit" disabled={isSubmitted}>
            <FaTelegramPlane />
          </button>
          <Form.Control.Feedback type="invalid" tooltip>
            {errors.email && (
              <p>
                <small>{errors.email.message}</small>
              </p>
            )}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Text id="submitHelpBlock" className="help-block">
          Нажимая кнопку Вы соглашаетесь с{" "}
          <Link to="/policies">политикой конфиденциальности</Link>
        </Form.Text>
      </Form>

      {error && (
        <p style={{ color: "red", textAlign: "center" }}>
          <small>{error}</small>
        </p>
      )}
      {success && (
        <p style={{ color: "white", textAlign: "center" }}>
          <small>{msgSubscribed}</small>
        </p>
      )}
    </FormProvider>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="row align-items-center">
      <div className="col-xl-6 col-lg-6">
        <div className="section-content">
          <SectionContentHeader data={data} className="text-white mb-30" />
          {data.componentHTML ? (
            <div
              className="mt-3 text-white"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.componentHTML.data),
              }}
            ></div>
          ) : (
            <p>{data.description}</p>
          )}
        </div>
      </div>
      <div className="col-xl-6 col-lg-6">
        <div className="px-lg-5">
          <FormSubscription />
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Subscribe (style 1)" of the site.
 *
 * @component
 */
const SectionContentStyle1 = (props) => {
  const { data, id = "subscribe", cn = "pt-70 pb-70 img-bg" } = props;

  return (
    <PageSection
      id={id}
      cn={cn}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default SectionContentStyle1;
