import { SectionOurTeam } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataCoursesPage, dataOurTeamPage, dataTeam } from "data";
import { Fragment } from "react";
import SectionMap from "./components/Map.section";

// NOTE :

/**
 * Component for showing Page "Our team" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageOurTeam = (props) => {
  const { id = "our-team" } = props;

  return (
    <Fragment>
      <SEO data={dataOurTeamPage.meta}>
        <link rel="canonical" href="https://psywealth.com/ourteam" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataOurTeamPage} />
        <SectionOurTeam data={dataTeam} type={"style1"} />
        <SectionMap data={dataCoursesPage} />
      </PageMain>
    </Fragment>
  );
};

export default PageOurTeam;
