import classNames from "classnames";
import { PageSection, SectionHeader } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import { PolicyTerms } from "./PolicyTerms";

const SectionPolicyTerms = (props) => {
  const {
    data,
    id = "policy-terms",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentSubtitle = false,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      {showHeader && (
        <SectionHeader
          data={data}
          className=""
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
      )}
      <PolicyTerms />
    </PageSection>
  );
};

SectionPolicyTerms.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1"]),
  showHeader: PropTypes.bool ?? false,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentSubtitle: PropTypes.bool ?? false,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionPolicyTerms;
