export const dataPoliciesPoliciesPage = {
  title: "Список политик и правовых документов",
  subtitle: "Список политик и правовых документов",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};

export const dataPolicyTermsPolicyPage = {
  title: "Политика конфиденциальности",
  subtitle: "Политика конфиденциальности",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};

export const dataPolicyPaymentTermsPolicyPage = {
  title: "Политика проведения платежей и оплат",
  subtitle: "олитика проведения платежей и оплат",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};
