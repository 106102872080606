import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataMetaBlankPage, dataTypographyPage } from "data";
import { Fragment } from "react";
import SectionTypography from "./components/SectionTypography";

/**
 * Component for showing Page "Typography" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageTypography = (props) => {
  const { id = "typography" } = props;

  return (
    <Fragment>
      <SEO data={dataMetaBlankPage}>
        <link rel="canonical" href="https://psywealth.com/typography" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataTypographyPage} />
        <SectionTypography />
      </PageMain>
    </Fragment>
  );
};

export default PageTypography;
