import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import "./Section.style.scss";

// NOTE : https://demo.awaikenthemes.com/html-preview/hipno/index.html

const ContentItemImages = (props) => {
  const { data } = props;

  return (
    <figure className="service-image__anime">
      <img src={data.src} alt="" />
    </figure>
  );
};

const ContentItem = (props) => {
  const { data } = props;

  return (
    <div className="service-item">
      <div className="service-image">
        <Link to="/">
          <ContentItemImages data={data.image} />
        </Link>
      </div>
      <div className="service-content">
        <h3 className="service-content__title">{data.title}</h3>
      </div>
      <div className="service-control">
        <p className="service-control__text">{data.description}</p>
      </div>
    </div>
  );
};

/**
 * Component section content "Problems  (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  return (
    <div className="row">
      {data.items.slice(0, 6).map((item, index) => {
        return (
          <div key={index} className="col-lg-4 col-md-6">
            <ContentItem data={item} key={index} />
          </div>
        );
      })}
    </div>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
