import classNames from "classnames";
import { getThemeStyles } from "components/utils/Theme";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

/**
 * Component "Header titles" of the site.
 *
 * @component
 * @version 1.0.3
 **/
export const SectionTitles = (props) => {
  const { data, showSubtitle, showDescription, useAnimation } = props;
  const isMobile = useIsMobile();
  const sectionTitleStyle = getThemeStyles("sectionHeaderTitle");
  const sectionDescriptionStyle = getThemeStyles("sectionHeaderDescription");

  return (
    <Fragment>
      {showSubtitle && data.subtitle && !isMobile && (
        <span
          className={classNames("section-header__subtitle", {
            "aos-animate fadeUp": useAnimation,
          })}
          {...(useAnimation && {
            "data-aos": "fade-up",
            "data-aos-delay": 200,
          })}
        >
          {data.subtitle}
        </span>
      )}
      <h2
        className={classNames("section-header__title", sectionTitleStyle, {
          "aos-animate fadeUp": useAnimation,
          "mb-3": showDescription,
        })}
        {...(useAnimation && { "data-aos": "fade-up", "data-aos-delay": 400 })}
      >
        {data.title}
      </h2>
      {showDescription && (
        <p
          className={classNames(
            "section-header__description",
            sectionDescriptionStyle,
            {
              "aos-animate fadeUp": useAnimation,
            }
          )}
          {...(useAnimation && {
            "data-aos": "fade-up",
            "data-aos-delay": 600,
          })}
        >
          {data.description}
        </p>
      )}
    </Fragment>
  );
};

SectionTitles.propTypes = {
  //data: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
  }),
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? true,
};
