import classNames from "classnames";
import { Button as BSButton } from "react-bootstrap";

const msgPrimary = "Primary";
const msgSecondary = "Secondary";

/**
 * Component section content "Hero (style 5)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const HeroControl = (props) => {
  const {
    data,
    cn = "",
    onClickPrimary,
    onClickSecondary,
    ...otherProps
  } = props;

  return (
    <div
      className={classNames(
        "d-flex justify-content-center justify-content-md-start flex-shrink-0",
        cn
      )}
    >
      <BSButton
        onClick={onClickPrimary}
        aria-label={data.primary.title || msgPrimary}
        variant="primary"
        size="lg"
        className="py-3 px-4 px-md-5 me-2"
      >
        {data.primary.title || msgPrimary}
      </BSButton>
      <BSButton
        onClick={onClickSecondary}
        aria-label={data.secondary.title || msgSecondary}
        variant="outline-primary"
        size="lg"
        className="py-3 px-4 px-md-5 ms-2"
      >
        {data.secondary.title || msgSecondary}
      </BSButton>
    </div>
  );
};

export default HeroControl;
