import { APP_API_URI } from "config/config";
import { Link } from "react-router-dom";
import { createQueryString } from "utils/helpers";

import placeholderSrc from "assets/images/hello-world.webp";
import ProgressiveImg2 from "components/utils/Image/ProgressiveImg2";

// NOTE :

const msgSubmit = "Обратиться";

/**
 * Component for component "Card Psychologist" of the site.
 *
 * @component
 */
const CardPsychologist3 = (props) => {
  const { data, index, type } = props;
  // console.log("@@@@", data, index, type);

  const appointmentURL = `/test2${createQueryString({ therapist: data.slug })}`;
  const therapistURL = `/therapists/${data.slug}`;

  const CardImage = (props) => {
    const { data, type } = props;

    const getImageURL = (id) => {
      return `${APP_API_URI}/psychologists/psychologist/profile/${id}/image`;
    };

    return (
      <div className="team_memder_image">
        <Link
          to={therapistURL}
          aria-label={`therapist_${data.id}`}
          className="blog-image-wrap"
        >
          <ProgressiveImg2
            hightSrc={
              data.id
                ? getImageURL(data.id)
                : "https://www.bootdey.com/image/380x380/00FFFF/000000"
            }
            lowSrc={placeholderSrc}
            alt={data?.imageAlt || data.title}
            loading="lazy"
            className=""
          />
        </Link>
      </div>
    );
  };

  return (
    <div className="team_item">
      <CardImage data={data} />

      <div className="team_memder_info text-center">
        <h3 className="team_memder_name">
          <Link to={therapistURL} className="line-clamp-1">
            {data.name}
          </Link>
        </h3>
        <h5 className="team_memder_designation">
          Clinical psychologist, Psy. D
        </h5>
      </div>
    </div>
  );
};

CardPsychologist3.defaultProp = {};

export default CardPsychologist3;
