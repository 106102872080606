import { EventGA } from "components/utils/Analytics";
import { AnalyticsEvent } from "components/utils/Analytics/Analytics";
import SEO from "components/utils/Analytics/SEO";
import { PageMain } from "core/components/Section";
import { dataNotFoundPage } from "data";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NotFoundSection from "./components/NotFounds.section";

/**
 * Component for showing Page "Not Found - 404" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageNotFound = (props) => {
  const { id = "not-found" } = props;
  const location = useLocation();

  useEffect(() => {
    const sendEventGA = async () => {
      // GA
      await EventGA(
        AnalyticsEvent.error404.category,
        AnalyticsEvent.error404.action,
        `${AnalyticsEvent.error404.label}: ${location.pathname}`
      );
    };

    sendEventGA();
  }, [location]);

  return (
    <Fragment>
      <SEO data={dataNotFoundPage.meta}>
        {/*Don’t canonicalise to a redirected page or a 404 page
         *<link rel="canonical" href="https://psywealth.com/not-found" />
         */}
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <NotFoundSection data={dataNotFoundPage} type={"style2"} />
      </PageMain>
    </Fragment>
  );
};

export default PageNotFound;
