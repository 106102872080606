import { SITE } from "config/config";
import { dataSite } from "data/dataSite";

const dataPageTitle =
  "Страница не найдена. PsyWealth — подбор психологов и психотерапевтов.";
const dataPageDescription = `Бесплатно подберём психотерапевта под ваш запрос и бюджет. Проверенные психологи и психотерапевты от 2000 руб. Лично в Москве или онлайн из любой точки мира.`;
const dataPageAuthor = SITE.AUTHOR;

const dataMetaOGTitle = dataPageTitle;
const dataMetaOGDescription =
  `Бесплатно подберём психотерапевта под ваш запрос и бюджет. Проверенные психологи и психотерапевты от 2000 руб. Лично в Москве или онлайн из любой точки мира. Телефон: ${dataSite.phone}, почта: ${dataSite.email}.` ||
  dataPageDescription;
const dataMetaOGImage = "https://psywealth.com/images/OG/main_1200x640.png";
const dataMetaOGImageAlt = "";
const dataMetaOGLocale = SITE.LOCALE;
const dataMetaOGSiteName = SITE.NAME;

export const dataMetaNotFoundPage = {
  title: dataPageTitle,
  description: dataPageDescription,
  name: "",
  type: "",
  author: dataPageAuthor,
  ExtraMetaTags: [{ name: "robots", content: "noindex, follow" }],
  OG: [
    {
      property: "og:title",
      content: dataMetaOGTitle,
    },
    {
      property: "og:description",
      content: dataMetaOGDescription,
    },
    {
      property: "og:url",
      content: "",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaOGImage,
    },
    {
      property: "og:image:alt",
      content: dataMetaOGImageAlt,
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "640",
    },
    {
      property: "og:locale",
      content: dataMetaOGLocale,
    },
    {
      property: "og:site_name",
      content: dataMetaOGSiteName,
    },
  ],
  TW: [
    {
      name: "twitter:title",
      content: dataPageTitle,
    },
    {
      name: "twitter:description",
      content: dataPageDescription,
    },
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
