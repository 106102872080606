import classNames from "classnames";
import { SectionTitles } from "core/components/Section";
import { PropTypes } from "prop-types";

/**
 * Component for showing section "Profile Section" of the site.
 *
 * @component
 */
export const DetailsSection = (props) => {
  const { children, id, cn, ...otherProps } = props;
  let cnTmp = id ? `details-section ${id}-section` : `details-section`;

  return (
    <section className={classNames(cnTmp, cn)} id={id} {...otherProps}>
      {children}
    </section>
  );
};

DetailsSection.propTypes = {
  id: PropTypes.string,
  cn: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export const SectionDetailsHeader = (props) => {
  const { data, showDescription, showSubtitle } = props;

  //  <div className="col-xl-7 col-lg-8 col-md-9 mx-auto">
  return (
    <div className="row">
      <div className="mx-auto">
        <div className="section-title mb-lg-5 mb-3">
          <SectionTitles
            data={data}
            showSubtitle={showSubtitle}
            showDescription={showDescription}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};
