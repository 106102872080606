import { Fragment } from "react";

/**
 * Data of page "Tests" of the site.
 *
 *
 **/

export const dataHeroTestsPage = {
  title: "4 100+ психологов уже развивают практику с PsyWealth",
  subtitle: "4 100+ психологов уже развивают практику с PsyWealth",
  description:
    "Мы всегда рады специалистам, которые относятся к профессии так же трепетно и бережно, как и мы.",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: null,
  image: require("assets/images/hero/hero2-920x820.webp"),
  imageAlt: "Сервис психологической помощи - «PsyWealth»",
};

export const dataFAQTestsPage = {
  title: "Ответы на вопросы",
  subtitle: "Ответы на вопросы",
  description: "Ответы на вопросы",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt: "" || "",
  },
  items: [
    {
      title: "Как подготовиться и пройти психологическое тестирование?",
      description:
        "Отвечайте на вопросы откровенно, не пытайтесь придавать ответам дополнительный смысл. Чаще всего первый ответ, который приходит в голову, является наиболее правильным и точным. Тестирование проходит анонимно — никто не узнает, какие варианты вы выбрали и какой результат получили, если только вы сами не решите поделиться этой информацией в социальных сетях.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Какие виды психологических тестов существуют?",
      description: (
        <Fragment>
          <p>
            Психологические тесты могут показаться чем-то развлекательным, но на
            самом деле их разрабатывают и применяют для диагностики ученые и
            специалисты по всему миру.
          </p>
          <p>
            Существуют тесты, которые анализируют личностную структуру,
            интеллект и особенности мышления, а также отношения с партнерами и
            различные расстройства, такие как депрессия и проблемы с пищевым
            поведением. Эти тесты позволяют исследовать профессиональные
            склонности, уровень одиночества и тревожности, а также аспекты
            сексуальности и креативности.
          </p>
          <p>
            Мы будем знакомить вас с новыми тестами. Подписывайтесь на наши
            социальные сети и рассылку, чтобы ничего не упустить!
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Где используются психологические тесты?",
      description:
        "Собственно, везде, где нужно внимание к особенностям личности человека. В школе и институте, на работе и в госструктурах, в здравоохранении — и конечно, в психотерапии.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как работают психологические тесты?",
      description: (
        <Fragment>
          <p>
            Сначала специалисты проводят исследования, результаты которых
            одобряются или не одобряются в профессиональном сообществе. Если
            исследование получило поддержку, специалист может составить на его
            основе опросник, где каждый ответ респондента имеет значение и
            интерпретацию.
          </p>
          <p>
            Наши тесты не ставят диагноз и не дают полной картины — они нужны,
            чтобы вы могли обратиться к себе с правильными вопросами, посмотреть
            на ситуацию с нового ракурса, обнаружить и назвать проблему, с
            которой вы, возможно, столкнулись.
          </p>
          <p>
            Результаты тестов представляют собой обобщенную информацию, которая
            не может на 100% описывать каждый конкретный — всегда уникальный —
            случай. Корректно интерпретировать результаты тестирования поможет
            профессиональный психолог.,
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Какие преимущества и недостатки у психологических тестов?",
      description: (
        <Fragment>
          <p>
            Тут все просто. Среди преимуществ — научное обоснование методики, ее
            подтверждение и использование в профессиональном сообществе, а также
            — качественный анализ результатов от сервиса Ясно.
          </p>
          <p>
            Тем не менее, этот анализ является обобщенным и не может на 100%
            описывать каждый конкретный — всегда уникальный — случай. Корректно
            интерпретировать результаты тестирования поможет профессиональный
            психолог.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как составляют психологические тесты?",
      description: (
        <Fragment>
          <p>
            Первый этап — ученый или специалист определяет цель теста и что он
            должен измерять.
          </p>
          <p>
            Второй — разработка вопросов и задач, которые должны описывать
            нужную характеристику. Потом эти вопросы тестируются на группе
            людей, которая дает обратную связь.
          </p>
          <p>
            Третий этап — публикация теста как части научного или
            профессионального исследования, которое попадает под строгий суд
            сообщества. Если коллегам-профессионалам все нравится, тест
            стандартизируют.
          </p>
          <p>
            Четвертый и очень важный этап — проверка валидности и надежности
            теста. Его автору предстоит убедиться в том, что измерения верны с
            течением времени и расширением аудитории тестируемых, а также
            следить за стабильной репутацией теста в профессиональном
            сообществе.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};

export const dataBeckTestTestsPage = {
  title: "Шкала депрессии А.Бека",
  subtitle: "Психологические тесты",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};
