import { PropTypes } from "prop-types";

import "./Section.style.scss";

// NOTE :

const SectionContent = (props) => {
  const { data, showSubtitle, showDescription, useAnimation } = props;

  const ContentItem = (props) => {
    const { data } = props;

    return (
      <div
        className="col-lg-4 col-md-6 col-12 aos-init aos-animate"
        data-aos="flip-left"
        data-aos-duration="500"
      >
        <div className="card bg-transparent border-0 text-center">
          <div className="card-image pb-3">
            <img
              src={data.image.src}
              alt={data.image.alt}
              className="card-img-top w-50 d-block mx-auto"
            />
          </div>
          <div className="card-body py-0">
            <h4 className="card-title">{data.title}</h4>
            <p className="card-text text-muted">{data.description}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row g-4 g-md-5">
      {data.items &&
        data.items.length > 0 &&
        data.items.map((item, index) => (
          <ContentItem data={item} key={index} />
        ))}

      {/* Control */}
      {/*   <div className="col-12">
        <div
          className="text-center mt-4 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="600"
        >
          <a href="#" className="btn btn-outline-primary btn-lg rounded-pill">
            View more
          </a>
        </div>
      </div>*/}
    </div>
  );
};

/**
 * Component section content "Services (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  return (
    <SectionContent
      data={data}
      showTitle={showTitle}
      showDescription={showDescription}
    />
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
