import { Banner2 } from "components/sections/Banner";
import { Loading } from "components/utils";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataPolicyPage } from "data";
import { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";

/**
 * Component for showing section "Policy Layout" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PolicyPageLayout = (props) => {
  const { id = "policy-terms" } = props;

  return (
    <Fragment>
      <SEO data={dataPolicyPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policy" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataPolicyPage} />
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </PageMain>
    </Fragment>
  );
};

export default PolicyPageLayout;
