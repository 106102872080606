import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import "./Section.style.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// NOTE : https://demo.awaikenthemes.com/html-preview/hipno/index.html

const ContentItemImages = (props) => {
  const { data } = props;

  return (
    <figure className="service-image__anime">
      <img src={data.src} alt="" />
    </figure>
  );
};

const ContentItem = (props) => {
  const { data } = props;

  return (
    <div className="service-item">
      <div className="service-image">
        <Link to="/">
          <ContentItemImages data={data.image} />
        </Link>
      </div>
      <div className="service-content">
        <h3 className="service-content__title">{data.title}</h3>
      </div>
      <div className="service-control">
        <p className="service-control__text">{data.description}</p>
      </div>
    </div>
  );
};

/**
 * Component section "Problems (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;
  // console.log("!!!!", props);

  const items =
    data.items.length > 0 &&
    data.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <ContentItem data={item} key={index} />
          {/*    <CardTherapist2 data={item} index={index} key={index} type="top" /> */}
        </SwiperSlide>
      );
    });

  return (
    <div className="slider">
      <Swiper
        className="swiper"
        slidesPerView={3}
        spaceBetween={24}
        autoHeight={false}
        navigation={
          {
            // prevEl: ".swiper-button-prev",
            // nextEl: ".swiper-button-next",
          }
        }
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        // pagination={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {items}
      </Swiper>
    </div>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
