import classNames from "classnames";
import { PageSection, SectionHeader } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import {
  SectionContentStyle1,
  SectionContentStyle2,
  SectionContentStyle3,
  SectionContentStyle4,
} from "./components";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    ...otherProps
  } = props;

  return (
    <Fragment>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle2
              data={data}
              id={id}
              cn={cn}
              type={type}
              {...otherProps}
            />
          );
        } else if (type === "style3") {
          return (
            <SectionContentStyle3
              data={data}
              id={id}
              cn={cn}
              type={type}
              {...otherProps}
            />
          );
        } else if (type === "style4") {
          return (
            <SectionContentStyle4
              data={data}
              id={id}
              cn={cn}
              type={type}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            {...otherProps}
          />
        );
      })()}
    </Fragment>
  );
};

/**
 * Component for showing section "Contact" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContacts = (props) => {
  const {
    data,
    id = "contacts",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection
      id={id}
      cn={classNames(prefix, cn, {
        "bg-secondary py-5": type === "style3",
        "wrapper bg-light wrapper-border": type === "style4",
      })}
      container
    >
      {showHeader && (
        <SectionHeader
          data={data}
          className=""
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
      )}
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showContentTitle}
        showDescription={showContentDescription}
        {...otherProps}
      />
    </PageSection>
  );
};

SectionContacts.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2", "style3", "style4"]),
  showHeader: PropTypes.bool ?? false,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContacts;

/*

const ContactUs = () => {
  return (
    <section
      className="subscribe-section pt-70 pb-70 img-bg"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="section-title mb-30">
              <span
                className="text-white wow fadeInDown"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <font style="vertical-align: inherit;">Подписаться</font>
              </span>
              <h2
                className="text-white mb-40 wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <font style="vertical-align: inherit;">
                  Подпишитесь на нашу рассылку
                </font>
              </h2>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <form
              action="#"
              className="subscribe-form wow fadeInRight"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeInRight",
              }}
            >
              <input
                type="text"
                name="subs-email"
                id="subs-email"
                placeholder="Ваш адрес электронной почты"
              />
              <button type="submit">
                <i className="lni lni-telegram-original"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const ContactFormTitle = () => {
  return (
    <div className="section-title text-center mb-50">
      <span
        className="wow fadeInDown"
        data-wow-delay=".2s"
        style={{
          visibility: "visible",
          animationDelay: "0.2s",
          animationName: "fadeInDown",
        }}
      >
        Связаться
      </span>
      <h2
        className="wow fadeInUp"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
        }}
      >
        Готовы начать работу
      </h2>
      <p
        className="wow fadeInUp"
        data-wow-delay=".6s"
        style={{
          visibility: "visible",
          animationDelay: "0.6s",
          animationName: "fadeInUp",
        }}
      >
        At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis
        praesentium
      </p>
    </div>
  );
};

*/
