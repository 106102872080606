import { SectionSubscribeLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import {
  dataPolicyTermsPage,
  dataPolicyTermsPolicyPage,
  dataSubscribe,
} from "data";
import { Fragment } from "react";
import { SectionPolicyTermsAndConditionsLazy } from "./components";

/**
 * Component for showing Page "Terms and Conditional" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageTermsAndConditions = (props) => {
  const { id = "terms-and-conditions" } = props;

  return (
    <Fragment>
      <SEO data={dataPolicyTermsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policies/terms" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataPolicyTermsPage} />
        <SectionPolicyTermsAndConditionsLazy data={dataPolicyTermsPolicyPage} />
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
      </PageMain>
    </Fragment>
  );
};

export default PageTermsAndConditions;
