import classNames from "classnames";
import { PageSection } from "core/components";
import { SectionHeader, useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { SectionContentStyle1 } from "./components";

// NOTE :

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    ...otherProps
  } = props;

  return (
    <Fragment>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle1
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showSubtitle={showSubtitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            showTitle={showTitle}
            showSubtitle={showSubtitle}
            showDescription={showDescription}
            {...otherProps}
          />
        );
      })()}
    </Fragment>
  );
};

/**
 * Component for showing section "AboutUs" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionAboutUs = (props) => {
  const {
    data,
    id = "about-us",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentSubtitle = false,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      {showHeader && (
        <SectionHeader
          data={data}
          className=""
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
      )}
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showContentTitle}
        showSubtitle={showContentSubtitle}
        showDescription={showContentDescription}
        {...otherProps}
      />
    </PageSection>
  );
};

SectionAboutUs.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showHeader: PropTypes.bool ?? false,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentSubtitle: PropTypes.bool ?? false,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionAboutUs;
