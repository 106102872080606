import classNames from "classnames";
import { ProgressiveImg2 } from "components/utils/Image";
import { getThemeStyles } from "components/utils/Theme";
import { PropTypes } from "prop-types";
import { IoCheckmarkDoneOutline } from "react-icons/io5";

import "./Section.style.scss";

import placeholder_600x600 from "assets/images/placeholders/placeholder_600x600.webp";

// NOTE : https://preview.themeforest.net/item/balance-mental-health-clinic-elementor-template-kit/full_screen_preview/55287281?_ga=2.259732809.2135475957.1734227779-1341782319.1734226303

const ContentItem = (props) => {
  const { data, isOverlay } = props;
  const contentTitleStyle = getThemeStyles("contentTitle");
  const contentDescriptionStyle = getThemeStyles("contentDescription");

  return (
    <div className="section-content">
      <h3
        className={classNames("section-content__title", contentTitleStyle, {
          "text-white": isOverlay,
        })}
      >
        {data.title}
      </h3>
      <p
        className={classNames(
          "section-content__description",
          contentDescriptionStyle
        )}
      >
        {data.description}
      </p>
      {data?.items && (
        <ul className="list-unstyled">
          {data.items.map((item, index) => (
            <li key={index} className="d-flex align-items-center mb-3">
              <IoCheckmarkDoneOutline className="me-3" width={24} height={24} />
              {item?.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const ContentImage_old = () => {
  return <img src={placeholder_600x600} className="img-fluid" alt="" />;
};

const ContentImage = (props) => {
  const { data: image } = props;
  return (
    <ProgressiveImg2
      hightSrc={image.src}
      lowSrc={placeholder_600x600}
      alt={image.alt || "PsyWealth.com"} // {data?.imageAlt || data.title}
      loading="lazy"
      className="img-fluid"
      style={{ objectFit: "cover" }}
    />
  );
};

/**
 * Component section content "What We Do (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  // console.log(data);
  // background-color: transparent;
  // --background-overlay: '';
  // background-image: linear-gradient(90deg, var(--e-global-color-cc605cb) 0%, var(--e-global-color-primary) 100%);

  return (
    <div className="row">
      {/* Block 1, 2 */}
      <div className="col-md-8">
        <div className="row g-4">
          {/* 1 */}
          <div className="col-lg-12">
            <div className="demo-four-service-card bg-color1">
              <div className="row g-0">
                <div className="col-12 col-md-6">
                  <ContentItem data={data.items[0]} />
                </div>
                <div className="col-md-6 d-sm-none d-md-block">
                  <ContentImage data={data.items[0].image} />
                </div>
              </div>
            </div>
          </div>

          {/* 2 */}
          {/* <div className="col-lg-12">
          <div className="justify-content-between demo-four-service-card bg-color2">
            <div className="row g-0">
              <div className="col-6 order-2">
                <Content data={items[0]} />
              </div>
              <div className="col-6 order-1">
                <Image />
              </div>
            </div>
          </div>
        </div>*/}

          {/* 2 */}
          <div className="col-lg-12">
            <div className="row g-0 justify-content-between demo-four-service-card align-items-center">
              <div
                className="image-wrapper bg-overlay"
                style={{
                  backgroundImage: `url(${data.items[1].image.src})`,

                  borderRadius: "25px",
                  minHeight: "427px",
                  overflow: "hidden",

                  // backgroundPosition: "center center",
                  // backgroundRepeat: "no-repeat",
                  // backgroundSize: "cover",

                  // margin: "auto",
                  // width: "100%",
                }}
              >
                <div className="col-md-6 offset-md-6 text-white">
                  <ContentItem data={data.items[1]} isOverlay />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Block 3 */}
      <div className="col-md-4 mt-4 mt-md-0">
        <div className="row g-0 h-100">
          <div className="d-flex justify-content-between flex-column demo-four-service-card bg-color3">
            <div className="col-12">
              <ContentItem data={data.items[2]} />
            </div>
            <div className="col-md-12 d-sm-none d-md-block align-self-bottom">
              <ContentImage data={data.items[2].image} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
