import {
  dataFAQComponent1,
  dataFAQComponent2,
  dataFAQComponent3,
  dataFAQComponent4,
  dataFAQComponent5,
  dataFAQComponent6,
  dataFAQComponent7,
} from "./dataFAQComponents";

export const dataFAQ = {
  title: "Ответы на частые вопросы",
  subtitle: "Ответы на частые вопросы",
  description: "Свяжитесь с нами, если у вас есть вопрос",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt:
      "" ||
      "Психолог онлайн, сервис онлайн консультация с психологами - PsyWealth",
  },
  items: [
    {
      title: "Кто такой психолог?",
      description:
        "В широком смысле психолог — это профессионал, который помогает людям справиться со своими мыслями, чувствами и поведением. В психотерапии можно улучшить качество своей жизни: наладить отношения с людьми, найти источник проблем и тревоги, изучить самого себя.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Что делает психолог?",
      description: "",
      componentHTML: dataFAQComponent1,
      main: true,
      disable: false,
    },
    {
      title: "В каких случаях может потребовать консультация психолога?",
      description: "",
      componentHTML: dataFAQComponent2,
      main: true,
      disable: false,
    },
    {
      title: "А что, если у меня нет чёткого запроса?",
      description:
        "Это абсолютно нормально. Важная часть работы психолога — задать вам уточняющие вопросы и помочь сформулировать запрос. Скажем по секрету, во многих случаях запрос, который клиент озвучил в начале, в процессе консультирования изменился или был переформулирован. Поэтому можете смело начинать, даже если смутно представляете, о чём именно хотите поговорить.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "С какими вопросами вы можете мне помочь?",
      description: "",
      componentHTML: dataFAQComponent7,
      main: true,
      disable: false,
    },
    {
      title: "В каком формате я получу информацию о психологе?",
      description: "",
      componentHTML: dataFAQComponent3,
      main: true,
      disable: false,
    },
    {
      title: "Что нужно, чтобы начать?",
      description: "",
      componentHTML: dataFAQComponent4,
      main: true,
      disable: false,
    },
    {
      title: "Могу ли я заменить психолога?",
      description: "",
      componentHTML: dataFAQComponent5,
      main: true,
      disable: false,
    },
    {
      title: "Могу ли я оставаться анонимным?",
      description: "",
      componentHTML: dataFAQComponent6,
      main: true,
      disable: false,
    },
    {
      title: "Я буду работать с одним психологом или с разными?",
      description:
        "Вы работаете с тем психологом, которого выбрали. Никто кроме вас и вашего психолога не будет иметь доступа к тому, о чём вы говорите на сессиях, потому что они проходят не на нашей платформе, а в привычных вам мессенджерах или сервисах видеосвязи.",
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};
