import { Fragment } from "react";
import { BiCoffee, BiMap, BiPhoneCall } from "react-icons/bi";
import { dataSite } from "./dataSite";

const msgContact = "Контакты";
const msgAddress = "Адрес";
const msgWorkHours = "Время работы";

const WH = (
  <Fragment>
    <span className="d-block fs-sm text-body-secondary mb-1">
      Понедельник - Пятница
    </span>
    <div className="d-flex align-items-center">
      <span className="text-nav fs-lg fw-medium">{dataSite.main.whStart}</span>
      <span
        className="border-top mx-4 text-white"
        style={{ width: "36px", height: "1px" }}
      ></span>
      <span className="text-nav fs-lg fw-medium">{dataSite.main.whClose}</span>
    </div>
  </Fragment>
);

export const dataContacts = {
  title: "Связаться с нами",
  subtitle: "Готовы начать работу",
  description:
    "Есть вопросы? Свяжитесь с нами через контактную форму, и мы свяжемся с вами в ближайшее время.",
  items: [
    {
      title: msgContact,
      subtitle: "",
      items: [
        {
          title: dataSite.main.email,
          type: "link",
          link: dataSite.main.emailLink,
          component: null,
        },
        {
          title: dataSite.main.phone,
          type: "link",
          link: dataSite.main.phoneLink,
          component: null,
        },
      ],
      icon: <BiPhoneCall />,
    },
    {
      title: msgWorkHours,
      subtitle: "",
      items: [
        {
          title: dataSite.main.wh,
          type: "component",
          link: null,
          component: WH,
        },
        // {
        //   title: dataSite.main.wh1,
        //   type: "paragraph",
        //   link: null,
        //   component: null,
        // },
      ],
      icon: <BiCoffee />,
    },
    {
      title: msgAddress,
      subtitle: "",
      items: [
        {
          title: dataSite.main.address,
          type: "paragraph",
          link: null,
          component: null,
        },
      ],
      icon: <BiMap />,
    },
  ],
  extraData: {
    title: "Напишите нам",
    subtitle: "Напишите нам",
    description: "",
  },
};

export const dataContactMap = {
  title: "Как нас найти",
  subtitle: "Как нас найти",
  description: "Интерактивная карта",
  items: [
    {
      title: "",
      subtitle: "",
      items: null,
      icon: null,
    },
  ],
  extraData: {},
};
