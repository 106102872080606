import { SectionSubscribe } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataPayPage, dataPolicyTermsPolicyPage, dataSubscribe } from "data";
import { Fragment } from "react";
import { SectionPolicyPaymentTerms } from "./components";

/**
 * Component for showing Page "Payment Policy and Terms" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PagePaymentTerms = () => {
  return (
    <Fragment>
      <SEO data={dataPayPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policy/payment" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>

      <SectionPolicyPaymentTerms data={dataPolicyTermsPolicyPage} />
      <SectionSubscribe data={dataSubscribe} type="style3" />
    </Fragment>
  );
};

export default PagePaymentTerms;
