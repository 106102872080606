import { SectionSubscribeLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataPolicyPaymentPage, dataSubscribe } from "data";
import { Fragment } from "react";
import { SectionPolicyPaymentTermsLazy } from "./components";

/**
 * Component for showing Page "Payment Policy Terms" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PagePaymentTerms = (props) => {
  const { id = "payment-terms" } = props;

  return (
    <Fragment>
      <SEO data={dataPolicyPaymentPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policies/payment" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataPolicyPaymentPage} />
        <SectionPolicyPaymentTermsLazy data={dataPolicyPaymentPage} />
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
      </PageMain>
    </Fragment>
  );
};

export default PagePaymentTerms;
