import { SEO } from "components/utils/Analytics";
import { dataCoursesPage } from "data";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { _debug } from "utils";
import { CourseFAQSection, CourseSection } from "./components";

// NOTE :

const CoursePage = () => {
  const { courseSlug } = useParams();

  useEffect(() => {}, []);

  useEffect(() => {
    _debug("URL updated:", URL);
    //updateUrl(URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  return (
    <Fragment>
      <SEO data={dataCoursesPage.meta}>
        <link rel="canonical" href="https://psywealth.com/courses" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="courses-page" id="main">
        <CourseSection data={{ ...dataCoursesPage, courseSlug }} />
        <CourseFAQSection data={{ ...dataCoursesPage, courseSlug }} />
      </main>
    </Fragment>
  );
};

export default CoursePage;
