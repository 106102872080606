import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetRelatedBlogPosts = (params = {}, options = {}) => {
  // console.log("!", params);

  return useQuery({
    queryFn: () => BlogService.getRelatedBlogPosts(params),
    queryKey: ["blog_related_posts", params],
    enabled: typeof params !== typeof undefined,
  });
};
