import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { Spinner } from "components/utils/Spinner";
import { dataMessages, dataPsychologistProfilePage as dataPage } from "data";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { SectionPsychologistProfile } from "./components";
import { useGetProfile } from "./utils/useGetProfile";

// NOTE :

/**
 * Component for showing Page "Psychologist Profile" of the site.
 *
 * @page
 **/
const LayoutPsychologistProfile = () => {
  const {
    data: psychologistProfile,
    isLoading: isLoadingPsychologistProfile,
    isError: isErrorPsychologistProfile,
    error: errorPsychologistProfile,
  } = useGetProfile();

  const isLoading = isLoadingPsychologistProfile;
  const isError = isErrorPsychologistProfile;
  const errors = [errorPsychologistProfile];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (psychologistProfile) {
      _debug("PSYCHOLOGIST PROFILE:", psychologistProfile);
    }
  }, [psychologistProfile]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Fragment>
      <SEO data={dataPage.meta}>
        <link
          rel="canonical"
          href="https://psywealth.com/psychologist/profile"
        />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-psychologist-profile">
        <Banner2 data={dataPage} />
        <SectionPsychologistProfile
          data={{
            ...dataPage,
            profile: psychologistProfile,
          }}
        />
      </main>
    </Fragment>
  );
};

export default LayoutPsychologistProfile;
