import { PropTypes } from "prop-types";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { PageSection, SectionHeader } from "../../sections/CommonSection";

import "./SectionAbout.scss";

// NOTE : https://bootstrapmade.com/demo/FlexStart/

const msgShowMore = "Подробнее";

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="row gx-0">
      <div className="col-lg-6 d-flex flex-column justify-content-center">
        <div className="content">
          <h3>Who We Are</h3>
          <h2>{data.title}</h2>
          <p>{data.description}</p>
          <div className="text-center text-lg-start">
            <Link to={`/blog`} className="btn theme-btn">
              <span>{msgShowMore}</span>
              <AiOutlineArrowRight />
            </Link>
          </div>
        </div>
      </div>

      <div
        className="col-lg-6 d-flex align-items-center aos-init aos-animate"
        data-aos="zoom-out"
        data-aos-delay="200"
      >
        <img
          className="img-fluid"
          src={data.image.src}
          alt={data.image.alt || ""}
        />
      </div>
    </div>
  );
};

/**
 * Component for showing section "About (for psychologist)" of the site.
 *
 * @component
 */
const SectionAbout = (props) => {
  const {
    data,
    id = "psy-about",
    cn = "pt-80 pb-80",
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showTitle = true,
    showDescription = true,
    useAnimation = false,
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
        <SectionContent
          data={data}
          showTitle={showTitle}
          showDescription={showDescription}
          useAnimation={useAnimation}
        />
      </div>
    </PageSection>
  );
};

SectionAbout.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showHeaderSubtitle: PropTypes.bool ?? false,
  showHeaderDescription: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

SectionAbout.defaultProp = {};

export default SectionAbout;
