import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// NOTE :

const msgCTA = "Больше городов";

const CardItem = (props) => {
  const { data } = props;
  return (
    <div
      className="d-flex align-items-center justify-content-center border rounded-5"
      style={{ minHeight: "80px", padding: "1rem 0.5rem" }}
    >
      <Link to="/">
        <h3 className="h5 fw-bold mb-0">{data.title}</h3>
        {/*   <img
        className="d-block d-dark-mode-none mx-auto"
        src="assets/img/landing/business-consulting/clients/02-dark.svg"
        width="196"
        alt="Logo"
      />*/}
      </Link>
    </div>
  );
};

const CardItem2 = () => {
  return (
    <div className="col">
      <a href="#">
        <img
          src="assets/images/client/01.svg"
          className="grayscale"
          alt="client-img"
        />
      </a>
    </div>
  );
};

const ContentItem3 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const items =
    data.items.length > 0 &&
    data.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <CardItem data={item} key={index} />
        </SwiperSlide>
      );
    });
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={16}
      centeredSlides={true}
      breakpoints={{
        768: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
      }}
      loop
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay]}
    >
      {items}
    </Swiper>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <section className="pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-xl-4 mb-5 mb-lg-0">
              <h2 className="mb-4">{data.title}</h2>
              <a
                className="icon-link icon-link-hover heading-color text-primary-hover"
                href="#"
              >
                {msgCTA}
                <i className="bi bi-arrow-right"></i>{" "}
              </a>
            </div>
            <div className="col-lg-7 text-center ms-auto">
              <ContentItem3 data={data} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

/**
 * Component section content "Cities (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showSubtitle={showSubtitle}
      showDescription={showDescription}
      innerRef={innerRef}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
