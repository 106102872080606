/**
 * Data of page "Gifts" of the site.
 *
 *
 */

import Image1 from "assets/images/services/gifts-howitworks-1.svg";
import Image2 from "assets/images/services/gifts-howitworks-2.svg";
import Image3 from "assets/images/services/gifts-howitworks-3.svg";
import Image4 from "assets/images/services/gifts-howitworks-4.svg";

export const dataHeroGiftsPage = {
  title: "Подарочный сертификат",
  subtitle: "Самый важный подарок",
  description:
    "Нам всем иногда нужна помощь, но иногда её бывает сложно попросить. Позаботьтесь о близком человеке — подарите ему сессию с психологом.",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: null,
  image: require("assets/images/hero/hero2-920x820.webp"),
  imageAlt: "Сервис психологической помощи - «PsyWealth»",
};

export const dataFeatureGiftsPage = {
  title: "Как это работает",
  subtitle: "Как это работает",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  items: [
    {
      title: "Выберите и оплатите сертификат",
      subtitle: "",
      description: "Выберите и оплатите сертификат",
      icon: null,
      image: Image1,
      imageAlt: "",
    },
    {
      title: "Получите сертификат с промокодом",
      subtitle: "",
      description: "Получите сертификат с промокодом",
      icon: null,
      image: Image2,
      imageAlt: "",
    },
    {
      title: "Подарите сертификат",
      subtitle: "",
      description: "Подарите сертификат близкому человеку",
      icon: null,
      image: Image3,
      imageAlt: "",
    },
    {
      title: "Введите промокод при оплате услуг",
      subtitle: "",
      description: "Введите промокод при оплате услуг",
      icon: null,
      image: Image4,
      imageAlt: "",
    },
  ],
};

export const dataOverviewGiftsPage = {
  title: "Вы получите",
  subtitle: "Вы получите",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Это не наши слова — так считают наши пользователи",
  items: [
    {
      title: "Клиентов",
      subtitle: "",
      description1: "В среднем каждый наш специалист работает с 10 клиентами.",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features12-1_600x600.webp"),
      imageAlt: "Фото электронный архив финансовых и бухгалтерских документов",
      imageTitle: null,
      list: {
        items: [],
      },
      control: {
        show: false,
        msgSubmit: "Подробнее",
        title: "Архив финансовых и бухгалтерских документов",
        description: "",
        descriptionHTML: null,
      },
    },
    {
      title: "Супервизии (разумеется, бесплатные)",
      subtitle: "",
      description1:
        "В неделю у нас проходит 7 супервизионных групп – их можно посещать, как в качестве докладчика, так и в качестве слушателя.",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features12-4_600x600.webp"),
      imageAlt: "",
      imageTitle: null,
      list: {
        items: [],
      },
      control: {
        show: false,
        msgSubmit: "Подробнее",
        title: "Архив кадровых документов и документов воинского учёта",
        description: "",
        descriptionHTML: null,
      },
    },
    {
      title: "Семинары (тоже бесплатные)",
      subtitle: "",
      description1:
        "Каждую неделю мы организовываем семинары от самых выдающихся членов профессионального сообщества.",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features12-2_600x600.webp"),
      imageAlt: "",
      imageTitle: null,
      list: {
        items: [],
      },
      control: {
        show: false,
        msgSubmit: "Подробнее",
        title: "Архив кадровых документов и документов воинского учёта",
        description: "",
        descriptionHTML: null,
      },
    },
    {
      title: "Доступ к сообществу профессионалов",
      subtitle: "",
      description1:
        "Мы регулярно проводим общие онлайн-встречи, чтобы обменяться знаниями и опытом.",
      description2: "",
      icon: null,
      image: require("assets/images/feature/features12-4_600x600.webp"),
      imageAlt: "",
      imageTitle: null,
      list: {
        items: [],
      },
      control: {
        show: false,
        msgSubmit: "Подробнее",
        title: "Архив кадровых документов и документов воинского учёта",
        description: "",
        descriptionHTML: null,
      },
    },
  ],
};

export const dataFAQComponent1 = {
  data:
    `<p>Вашему близкому человеку нужно:</p>` +
    `<ol className="list-styled">` +
    `<li>Зайти на сайт <a>psywealth.com</a></li>` +
    `</ol>`,
};

export const dataFAQGiftsPage = {
  title: "Вопросы и ответы",
  subtitle: "Вопросы и ответы",
  description: "Свяжитесь с нами, если у вас есть вопрос",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt: "" || "",
  },
  items: [
    {
      title: "Каков срок действия сертификата?",
      description:
        "Сертификат действителен на протяжении одного года после покупки. При этом, активировать его необходимо в течение 6 месяцев после покупки.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как активировать сертификат?",
      description: "",
      componentHTML: dataFAQComponent1,
      main: true,
      disable: false,
    },
    {
      title:
        "Может ли воспользоваться подарком тот, кто уже клиент «PsyWealth.com»?",
      description:
        "Да, конечно. Клиенты «PsyWealth.com» в любой момент могут привязать промокод в личном кабинете в разделе «Платежи».",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Можно ли передаривать сертификат?",
      description:
        "Только до регистрации. После активации промокода передавать подаренные сессии нельзя.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Суммируется ли сертификат с другими бонусами и промокодами?",
      description:
        "Другие бонусы, акции и промокоды на подаренные сессии не распространяются.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title:
        "Можно ли по подарочному сертификату на консультацию прийти с партнёром?",
      description:
        "Сертификаты действуют только для индивидуальных консультаций с психологом. То есть возможно присутствовать только одному клиенту. Парные консультации, с партнёром, можно приобрести отдельно, зарегистрировавшись в «PsyWealth.com».",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Может ли получатель подарка выбрать сессию дороже 2 850 ₽?",
      description:
        "Да, может. В таком случае система предложит привязать карту и доплатить разницу между стоимостью выбранной сессии и 2 850 ₽.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Можно ли оплатить сертификат картой иностранного банка?",
      description:
        "Да, можно оплатить картой зарубежного банка. Стоимость в евро рассчитывается по курсу ЦБ РФ, включает в себя дополнительные налоги, банковские комиссии и другие транзакционные издержки.",
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};

export const dataCTAGiftsPage = {
  title: "Развивайте свою практику с нами",
  title1: "Заявка на консультацию",
  title2: "",
  subtitle: "Оставить заявку",
  description:
    "Оставить заявку на консультацию, наш менеджер свяжется с вами и поможет подобрать решение для вашей задачи",
  msg: {
    msgCTA: "Заполнить анкету",
  },
  backgroundImage: require("assets/images/bg/cta-bg2.webp"),
};

export const dataPricingGiftsPage = {
  title: "Выберите сертификат",
  subtitle: "Выберите сертификат",
  description: "Выберете подходящий подарочный сертификат для подарка",
  items: [
    {
      title: "Базовый",
      subtitle: "",
      price: 4900.0,
      description: "* - зависит от объема хранилища",

      items: [
        { title: "до 5 пользователей" },
        { title: "Минимальная отчетность" },
        { title: "10Гб архивное хранилище" },
        { title: "Размещение в облаке" },
        { title: "Однопоточный агент" },
      ],
    },
    {
      title: "Стандарт",
      subtitle: "",
      price: 9900.0,
      description: "* - зависит от объема хранилища",

      items: [
        { title: "до 20 пользователей" },
        { title: "Расширенная отчетность" },
        { title: "100Гб архивное хранилище" },
        { title: "Размещение в облаке или собственной инфраструктуре" },
        { title: "Веб-клиент и мобильный клиент" },
        { title: "7/24 поддержка" },
        { title: "Многопользовательская работа" },
      ],
    },
    {
      title: "Премиальный",
      subtitle: "",
      price: 19900.0,
      description: "* - зависит от объема хранилища",

      items: [
        { title: "Неограниченное количество пользователей" },
        { title: "Расширенная отчетность" },
        { title: "до 10Тб архивное хранилище" },
        { title: "Веб-клиент и мобильный клиент" },
        { title: "7/24 расширенная поддержка" },
        { title: "Многопользовательская работа" },
      ],
    },
  ],
};
