import { Spinner } from "components/utils/Spinner";
import { BLOG_ID } from "config/config";
import { dataMessages } from "data";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetBlogCategories } from "../utils/useGetBlogCategories";
import SidebarBox from "./SidebarBox";

const msgTitle = "Категории";
const msgNoData = "Нет данных о категориях в блоге";

/**
 * Component for showing Component "Sidebar box post categories" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogBoxCategories = () => {
  const {
    data: blogCategories,
    isLoading: isLoadingBlogCategories,
    isError: isErrorBlogCategories,
    error: errorBlogCategories,
  } = useGetBlogCategories();

  const isLoading = isLoadingBlogCategories;
  const isError = isErrorBlogCategories;
  const errors = [errorBlogCategories];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (blogCategories) {
      _debug("BLOG CATEGORIES:", blogCategories);
    }
  }, [blogCategories]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isError && errorBlogCategories?.status === 204) {
    return <div className="error">{msgNoData}</div>;
  }

  return (
    <SidebarBox title={msgTitle} cn="categories-box mb-3">
      <ul className="post-category-list unordered-list-block">
        {blogCategories.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/blog/category/${item.id}?blog=${BLOG_ID}`}>
                <span>{item.name}</span>
                <span className="ml-2">(20)</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </SidebarBox>
  );
};

export default BlogBoxCategories;

// const URL = `${BLOG_API_URI}/categories/all?blog=${BLOG_ID}`;
// const { data: catsData, isLoading, hasError, errorMessage } = useFetch(URL);

// _debug(URL);

// useEffect(() => {
//   if (catsData) _debug("Data fetched: ", catsData);
//   if (hasError) _debug("Error: ", errorMessage);
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [isLoading, hasError, catsData]);

// if (isLoading || hasError) {
//   return (
//     <Fragment>
//       {isLoading && <Spinner />}
//       {hasError && <p>{errorMessage}</p>}
//     </Fragment>
//   );
// }

// if (!catsData) {
//   return <h5>No categories founds</h5>;
// }
