import { Spinner } from "components/utils/Spinner";
import { PageSection, SectionHeader } from "core/components";
import { dataMessages } from "data";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetCourses } from "../utils";
import CardCourse from "./CardCourse";

// import "./Therapist.scss";

// NOTE :

const SectionContent = (props) => {
  const { data } = props;

  const {
    data: courses,
    isLoading: isLoadingCourses,
    isError: isErrorCourses,
    error: errorCourses,
  } = useGetCourses();

  const isLoading = isLoadingCourses;
  const isError = isErrorCourses;
  const errors = [errorCourses];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (courses) {
      _debug("COURSES:", courses);
    }
  }, [courses]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorCourses && errorCourses?.status === 204) {
    return <div className="error">Нет курсов</div>;
  }

  return (
    <Fragment>
      <div className="row gy-3">
        {courses.map((item, index) => {
          return (
            <CardCourse data={item} index={index} key={index} type="course" />
          );
        })}
      </div>
    </Fragment>
  );
};

/**
 * Component for showing section "Courses" of the site.
 *
 * @component
 */
const CoursesSection = (props) => {
  const { data, id = "courses", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />
        <SectionContent data={data} />
      </div>
    </PageSection>
  );
};

export default CoursesSection;
