import classNames from "classnames";
import { PageSection } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import { SectionContentStyle1 } from "./components";

/**
 * Component for showing section "Cities" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionCities = (props) => {
  const {
    data,
    id = "cities",
    cn = "",
    type,
    showTitle = true,
    showSubtitle = false,
    showDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container={false}>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle1
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showSubtitle={showSubtitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            showTitle={showTitle}
            showSubtitle={showSubtitle}
            showDescription={showDescription}
            {...otherProps}
          />
        );
      })()}
    </PageSection>
  );
};

SectionCities.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showTitle: PropTypes.bool ?? true,
  showSubtitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? false,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionCities;
