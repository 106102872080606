import { PropTypes } from "prop-types";
import { Tab, Tabs } from "react-bootstrap";

import classNames from "classnames";
import { Image } from "components/utils/Image";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Section.style.scss";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=50603

const TabPanelItem = (props) => {
  const { data } = props;
  return (
    <div className="col-md-6">
      <div className="d-flex">
        {data.icon && (
          <div className="text-muted">
            <i>{data.icon}</i>
          </div>
        )}
        <div className={classNames({ "ms-4": data.icon })}>
          <h5 className="fw-bold">{data.title}</h5>
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const TabPanel = (props) => {
  const { data, showTitle, showDescription } = props;

  return (
    <Fragment>
      <div className="row gx-5 align-items-center">
        <div className="col-md-4 mb-5 mb-md-0">
          <figure className="d-block h-100 rounded-5 overflow-hidden">
            <Image image={data?.image} className="img-fluid" />
          </figure>
        </div>

        <div className="col-md-8">
          <div className="ps-md-4 ps-lg-0">
            {/* Headers */}
            {showTitle && data.title && (
              <h2 className="mb-xl-4">{data.title}</h2>
            )}
            {showDescription && data.description && (
              <p className="pb-3 mb-3 mb-xl-4">{data.description}</p>
            )}

            {/* Items */}
            <div className="row gy-4">
              {data.items &&
                data.items.length > 0 &&
                data.items.map((item, index) => (
                  <TabPanelItem data={item} index={index} key={index} />
                ))}
            </div>

            {/* Controls */}
            {data.control && (
              <div className="row justify-content-center mt-5">
                <Link
                  className="btn btn-primary w-100 w-sm-auto"
                  to={data.control.link}
                >
                  {data.control.msgSubmit}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component section content "Feature Tabs (style 3)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle3 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  const defaultActiveKey = data.items[0].tab.key || "main";

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      className="flex-nowrap overflow-auto text-nowrap w-100 mx-auto pb-3 mb-3 mb-lg-4"
      justify
    >
      {data.items.map((item, index) => (
        <Tab
          eventKey={item.tab.key}
          title={item.tab.title}
          key={index}
          disabled={!item.tab.available}
        >
          <TabPanel
            data={item}
            showTitle={showTitle}
            showDescription={showDescription}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

SectionContentStyle3.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle3;
