import { VideoModal } from "components/sections/TypicalSections";
import { Spinner } from "components/utils/Spinner";
import { APP_API_URI } from "config/config";
import { PageSection } from "core/components";
import { dataMessages } from "data";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoPlay } from "react-icons/io5";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { SectionHeader } from "../CommonSection";
import { useGetTopVideo } from "./useGetTopVideos.hook";

import "./VideoOfTheDay.style.scss";

// NOTE :

const videoURL = "https://www.youtube.com/embed/C0DPdy98e4c";
const msgShowMore = "Подробнее";

const SectionGoToDailyVideoCourses = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-6 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/courses"} className="btn btn-primary theme-btn">
              Смотреть все курсы
            </Link>
          </h2>
          <p className="display-30">Смотреть все курсы</p>
        </div>
      </div>
    </div>
  );
};

const VideoSection_ = (props) => {
  const { videoId } = props;
  const URL = `${APP_API_URI}/v1/event/videos/video/${videoId}`;
  // const URL = "https://www.youtube.com/watch?v=jlWMTNZNOc0";
  // console.log(URL);
  return (
    <Fragment>
      <div className="row justify-content-center">
        <div className="col pt-7">
          <div className="row justify-content-center">
            <div className="col-md-8 col-10">
              <video width="100%" height="100%" controls>
                <source src={URL} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-7 justify-content-center">
        <div className="col-lg-4 col-md-8 mb-4 mb-lg-0">
          <div className="media p-xxl-4">
            <img src="assets/img/icons/icon-1.png" alt="" width="60" />
            <div className="media-body ml-3">
              <h5 className="font-weight-bold">Create your account in Shape</h5>
              <p className="text-900">
                its easy, simple and just a few clicks job to create an account
                with shape
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-8 mb-4 mb-lg-0">
          <div className="media p-xxl-4">
            <img src="assets/img/icons/icon.png" alt="" width="60" />
            <div className="media-body ml-3">
              <h5 className="font-weight-bold">
                Start building beautiful Apps With shape
              </h5>
              <p className="text-900">
                with our design system, you will find it no brainers job to
                build your application
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-8 mb-4 mb-lg-0">
          <div className="media p-xxl-4">
            <img src="assets/img/icons/icon-3.png" alt="" width="60" />
            <div className="media-body ml-3">
              <h5 className="font-weight-bold">
                Deploy your site and make money
              </h5>
              <p className="text-900">
                make it, bake it, and next thing you know your cake is ready.
                enjoy it!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const VideoSection2_ = (props) => {
  const { videoId } = props;
  const URL = `${APP_API_URI}/v1/event/videos/video/${videoId}`;
  return (
    <Fragment>
      <div className="row align-items-center justify-content-center text-center">
        <div className="col-11 col-md-12">
          <div className="pos-relative mt-4 py-5 py-md-11">
            <div className="background-holder radius-secondary"></div>
            <a
              className="video-modal"
              href="https://www.youtube.com/watch?v=jlWMTNZNOc0"
              data-start="6"
              data-end="168"
            >
              <div
                className="btn-elixir-play"
                style={{ transform: "matrix(1, 0, 0, 1, 0, 0)", opacity: 1 }}
              >
                <span className="ion-play fs-2"></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const VideoSection1 = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };
  const closeModal = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  return (
    <div className="row gx-0">
      <div className="col-lg-6 d-flex flex-column justify-content-center">
        <div className="content">
          <h3>Актуальная тема</h3>
          <h2>Ждём вас для обсуждения</h2>
          <p>
            Всегда актуальные темы для обсуждения, ответы на ваши вопросы и
            разбор примеров
          </p>
          <div className="text-center text-lg-start">
            <Link className="btn theme-btn" to={`/top-themes`}>
              <span>{msgShowMore}</span>
              <AiOutlineArrowRight />
            </Link>
          </div>
        </div>
      </div>
      <div
        className="col-lg-6 d-flex align-items-center aos-init aos-animate"
        data-aos="zoom-out"
        data-aos-delay="200"
      >
        <div className="shadow-md hover-shadow-soft position-relative video-card">
          <img
            className="img-fluid w-100 rounded-soft"
            src={require("assets/images/demo/one-page.webp")}
            alt=""
          />

          <div className="btn-play-wrap">
            <Link
              className="btn btn-play"
              to="/"
              onClick={openModal}
              aria-label="Show video"
            >
              <span>
                <IoPlay />
              </span>
            </Link>
          </div>
        </div>
      </div>

      <VideoModal
        isOpen={isOpen}
        handleCloseModal={closeModal}
        videoURL={videoURL}
      />
    </div>
  );
};

const SectionContent = (props) => {
  const { data, isMobile } = props;

  const {
    data: topVideo,
    isLoading: isLoadingTopVideo,
    isError: isErrorTopVideo,
    error: errorTopVideo,
  } = useGetTopVideo();

  const isLoading = isLoadingTopVideo;
  const isError = isErrorTopVideo;
  const errors = [errorTopVideo];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (topVideo) {
      _debug("TOP VIDEOS:", topVideo);
    }
  }, [topVideo]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isLoadingTopVideo && errorTopVideo?.status === 204) {
    return <div className="error">Нет видео</div>;
  }

  return (
    <Fragment>
      {topVideo && topVideo.length > 0 && (
        <VideoSection1 videoId={topVideo[0].id} />
      )}
    </Fragment>
  );
};

/**
 * Component for showing "VideoOfTheDay" of the site.
 *
 * @component
 */
const VideoOfTheDay = (props) => {
  const { data, id = "daily-video", cn = "py-80" } = props;
  const isMobile = useIsMobile(768);

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader data={data} />
        <SectionContent data={data} isMobile={false} />
      </div>
    </PageSection>
  );
};

VideoOfTheDay.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default VideoOfTheDay;
