import { Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const CustomSwiper = (props) => {
  const { renderItems: items } = props;
  return (
    <Swiper
      className="swiper pb-5"
      wrapperClass="pt-1"
      slidesPerView={3}
      spaceBetween={24}
      autoHeight={false}
      pagination={{
        // clickable: true,
        dynamicBullets: true,
      }}
      modules={[Pagination, Navigation]}
      // pagination={true}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
    >
      {items}
    </Swiper>
  );
};

export default CustomSwiper;
