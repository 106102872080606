import { ModalFormHeroOrder } from "components/forms/HeroOrder";
import {
  SectionCallToActionLazy,
  SectionFAQLazy,
  SectionFeatureOfFreeHelp,
  SectionFeatureTabsLazy,
  SectionHero,
  SectionProblemsLazy,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import {
  dataConsultationsFreeHelpPage as dataConsultations,
  dataContactsFreeHelpPage as dataContacts,
  dataCTAFreeHelpPage as dataCTA,
  dataFAQFreeHelpPage as dataFAQ,
  dataFeature1FreeHelpPage as dataFeature1,
  dataHeroFreeHelpPage as dataHero,
  dataUsedForMainPage,
} from "data";
import { dataFreeHelpPage } from "data/dataPages";
import { useModal } from "hooks";
import { Fragment, useRef } from "react";
import EventService from "services/events.service";
import { _debug } from "utils";

/**
 * Component for showing Page "Free Psychological Help" of the site.
 *
 * @component
 */
const PageFreePsychologicalHelp = () => {
  const refCAT = useRef(null);

  const handlerCTASubmit = async (data) => {
    _debug("CTA submit form: ", data);
    try {
      var numberRegex = /\D/g;
      const newData = { ...data, phone: data.phone.replace(numberRegex, "") };
      console.log(newData);
      const result = await EventService.registerOrder(newData);
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };

  const {
    modal: modal2,
    openModal: openModal2,
    closeModal: closeModal2,
  } = useModal({
    children: (
      <ModalFormHeroOrder
        onSubmit={(data) => handlerCTASubmit(data)}
        onClose={() => closeModal2()}
      />
    ),
  });

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataFreeHelpPage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link
          rel="canonical"
          href="https://psywealth.com/free/psychological-help"
        />
        {dataFreeHelpPage.metaLdJson && (
          <script type="application/ld+json">
            {JSON.stringify(dataFreeHelpPage.metaLdJson)}
          </script>
        )}
      </SEO>
      <main className="page-main" id="top">
        <SectionHero data={dataHero} type="style2" onScroll={executeScroll} />

        {/*<SectionCountUp data={dataCountUpMainPage} />*/}

        <SectionFeatureOfFreeHelp data={dataFeature1} type="style1" />
        {/*<SectionFeatureOfFreeHelp data={dataFeature1} type="style2" />*/}
        {/*  <FeatureAdditionalSection data={dataConsultations} type="style2" /> */}

        {/*<CasesOfUsedSection data={dataCasesOfUse} /> */}

        <SectionProblemsLazy data={dataUsedForMainPage} type={"style2"} />

        {/*<ContactsSection data={dataConsultations} id="free-consultations" />*/}

        <SectionFeatureTabsLazy
          data={dataConsultations}
          type={"style2"}
          id="free-consultations"
        />

        {/* <OurAdvantages data={dataDisadvantages} />*/}

        <SectionCallToActionLazy
          data={dataCTA}
          type={"style3"}
          onSubmit={() => openModal2()}
        />

        {/* <SectionCTA data={dataCTA} innerRef={refCAT} />*/}

        {/*<ContactsSection data={dataContacts} />*/}

        <SectionFeatureTabsLazy
          data={dataContacts}
          type={"style2"}
          id="free-contacts"
        />

        <SectionFAQLazy data={dataFAQ} />
        {modal2}
      </main>
    </Fragment>
  );
};

export default PageFreePsychologicalHelp;
