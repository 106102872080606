import { APP_API_URI } from "config/config";
import { Fragment } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// NOTE :

const getImageURL = (id) => {
  return `${APP_API_URI}/psychologists/psychologist/profile/${id}/image`;
};

const ContentSlideImage = (props) => {
  const { data } = props;

  return (
    <div className="therapist-info-card-image">
      <div className="psychologist-avatar avatar-in-profile">
        <img
          src={
            data.id
              ? getImageURL(data.id)
              : "https://www.bootdey.com/image/180x180/00FFFF/000000"
          }
          alt={
            data.id
              ? `${data.name}, Семейный психолог, Врач-психотерапевт, ${data.qualification}`
              : ""
          }
          srcSet={`${getImageURL(data.id)} 2x`}
          className="rounded-circle"
          style={{ width: "185px", minWidth: "185px", height: "185px" }}
        />
      </div>
    </div>
  );
};

const TherapistInfoCard = (props) => {
  const { data } = props;

  // const items =
  // posts &&
  // posts.length > 0 &&
  // posts.map((item, index) => {
  //   return (
  //     <SwiperSlide key={index}>
  //       <BlogPostCard data={item} key={index} />
  //     </SwiperSlide>
  //   );
  // });
  const items = (
    <Fragment>
      <SwiperSlide key={1}>
        <ContentSlideImage data={data} />
      </SwiperSlide>
      <SwiperSlide key={2}>
        <ContentSlideImage data={data} />
      </SwiperSlide>
      <SwiperSlide key={3}>
        <ContentSlideImage data={data} />
      </SwiperSlide>
    </Fragment>
  );

  return (
    <div className="therapist-info-card">
      <Swiper
        className="swiper pb-3"
        slidesPerView={1}
        loop
        pagination={{
          // clickable: true,
          dynamicBullets: true,
        }}
        modules={[Pagination, Navigation]}
      >
        {items}
      </Swiper>

      <ul className="info">
        <li>
          <div className="d-flex justify-content-between align-items-center">
            Семейный психолог, Врач-психотерапевт, Психолог
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between align-items-center">
            <span>На платформе</span>
            {data.registeredTime ? data?.registeredTime : "6 мес"}
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between align-items-center">
            <span>Опыт</span>
            {data.experience}
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between align-items-center">
            <span>Сессий</span>
            {data.sessionCount ? data?.sessionCount : "100+"}
          </div>
        </li>
      </ul>

      <div className="courses-btn-box">
        <button className="btn btn-primary">Выбрать время</button>
      </div>
    </div>
  );
};

export default TherapistInfoCard;
