import { SectionContactsLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataContacts, dataContactsPage } from "data";
import { Fragment } from "react";
import { SectionMap } from "./components";

/**
 * Component for showing Page "Contacts" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageContacts = (props) => {
  const { id = "contacts" } = props;

  return (
    <Fragment>
      <SEO data={dataContactsPage.meta}>
        <link rel="canonical" href="https://psywealth.com/contacts" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataContactsPage} />

        <SectionContactsLazy
          data={dataContacts}
          cn=""
          type={"style4"}
          showHeader={false}
        />
        <SectionContactsLazy
          data={dataContacts}
          cn=""
          type={"style3"}
          showHeader={false}
        />
        <SectionMap data={dataContacts} />
        {/* <SectionSubscribe data={dataSubscribe} type="style3" /> */}
      </PageMain>
    </Fragment>
  );
};

export default PageContacts;
