import classNames from "classnames";

/**
 * Component for showing Component "Sidebar box" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SidebarBox = (props) => {
  const { title, cn, children } = props;

  return (
    <div className={classNames("sidebar-box", cn)}>
      <div className="sidebar-box-title">
        <h4 className="title-text">{title}</h4>
        {children}
      </div>
    </div>
  );
};

export default SidebarBox;
