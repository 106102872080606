import { BiAward } from "react-icons/bi";

// import Image1 from "assets/icons/feature1-1.svg";
import Image1 from "assets/images/demo/feature1-1.webp";
import Image2 from "assets/images/demo/feature1-2.webp";
import Image3 from "assets/images/demo/feature1-3.webp";
import { IconContext } from "react-icons";
import {
  BsActivity,
  BsAspectRatio,
  BsBrightnessHigh,
  BsCameraVideo,
  BsClipboard2,
  BsEmojiWink,
  BsLayoutTextWindowReverse,
  BsMortarboard,
  BsPencilSquare,
  BsSpeedometer2,
  BsStar,
  BsXDiamond,
} from "react-icons/bs";

import Icon10_4 from "assets/images/new/payment.svg";
import Icon10_3 from "assets/images/new/security.svg";
import Icon10_2 from "assets/images/new/statistics.svg";
import Icon10_1 from "assets/images/new/support.svg";

export const dataHeroMainPage = {
  title: "Онлайн-сервис подбора психологов и психотерапевтов",
  // subtitle: "Психотерапевты для занятий лично или онлайн",
  subtitle: "Психологическая помощь лично или онлайн",
  description: (
    <>
      Работа с лучшими психологами и психотерапевтами.
      <br />
      Платформа PsyWealth™ надежный способ решения психологических проблем.
    </>
  ),
  // "Онлайн-сервис подбора психологов и психотерапевтов подходящих именно вам",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: [
    {
      description: "повысить качество жизни",
    },
    { description: "справиться со стрессом" },
    { description: "наладить отношения с близкими" },
    { description: "найти своё призвание" },
  ],
  image: {
    src: require("assets/images/hero/hero-iphones.webp"),
    alt: "Сервис психологической помощи - «PsyWealth»",
    title: "",
  },

  itemsVL: [
    {
      items: [
        {
          title: "Тревога",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-1.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-1.svg",
          },
        },
        {
          title: "Стресс",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-2.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Понять себя",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-3.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Депрессия",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-4.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-1.svg",
          },
        },
        {
          title: "Отношения",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-5.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Проблемы с едой",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-6.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
      ],
    },
    {
      items: [
        {
          title: "Гештальт-терапевт",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-3.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-1.svg",
          },
        },
        {
          title: "Арт-терапевт",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-4.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Психоаналитик",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-5.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Юнгианский анализ",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-6.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-1.svg",
          },
        },
        {
          title: "Клинический психолог",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-1.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Психотерапевт",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-2.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
      ],
    },

    {
      items: [
        {
          title: "Психологи очно",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-6.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-1.svg",
          },
        },
        {
          title: "Психотерапевты онлайн",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-5.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Семейные психотерапевты",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-4.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Психологи для пар",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-3.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-1.svg",
          },
        },
        {
          title: "Семейные психологи",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-2.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
        {
          title: "Коучи",
          subtitle: "",
          description: "",
          image: {
            src: require("assets/images/hero/persons/person2-1.webp"),
            alt: "",
            lazy: "https://gptchat.ru/wp-content/uploads/2024/06/brand-5.svg",
          },
        },
      ],
    },
  ],
};

export const dataAboutUsMainPage = {
  title: "Вы и ваш запрос — уникальны",
  subtitle: "Вы и ваш запрос — уникальны",
  // description: "Только 13% психологов проходят отбор в команду",
  description1:
    "Подобрать специалиста, который подойдет именно вам, можно с помощью нашей анкеты — заполнение займет не больше 5 минут.",
  description2:
    "Ответы помогут нам узнать вас лучше — алгоритм проанализирует базу психологов и выберет тех, чьи опыт и подход соответствуют вашему запросу.",
  image: {
    // src: "https://freefrontend.dev/assets/rectangle-tall2.png",
    src: null,
    alt: "",
    title: "",
  },
  items: [],
};

export const dataCitiesMainPage = {
  title: "Психолог в твоем городе",
  subtitle: "Найди психолога в своем городе",
  description: "Найди психолога в своем городе",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Москва",
    },
    {
      title: "Санкт-Петербург",
    },
    {
      title: "Тверь",
    },
    {
      title: "Калуга",
    },
    {
      title: "Москва",
    },
    {
      title: "Санкт-Петербург",
    },
    {
      title: "Тверь",
    },
    {
      title: "Калуга",
    },
  ],
};

const dataOverviewImageAlt = "";
export const dataOverviewMainPage_old = {
  title: "Сервис устроен легко и удобно",
  subtitle: "Это не наши слова — так считают наши пользователи",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Это не наши слова — так считают наши пользователи",
  items: [
    {
      title: "Консультации по безопасному видеочату",
      subtitle: "",
      description1:
        "На нашей платформе видеоконсультации проходят в защищенном личном кабинете",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features10-1_600x300.webp"),
        alt: "Консультации по безопасному видеочату" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Простое управление расписанием",
      subtitle: "",
      description1:
        "Назначайте и переносите, мы напомним и не дадим вам забыть",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features10-2_600x300.webp"),
        alt: "Простое управление расписанием" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Сопровождение на всех этапах",
      subtitle: "",
      description1:
        "Ответим на вопросы о психологии, поможем, поддержим, объясним, направим",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features10-2_600x300.webp"),
        alt: "Сопровождение на всех этапах" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        items: [],
      },
    },
  ],
};

export const dataOverviewMainPage = {
  title: "Как работает сервис",
  subtitle: "Как работает сервис",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "",
  items: [
    {
      title: "Заполните анкету",
      subtitle: "Несколько вопросов, которые займут 6 минут",
      description1: "",
      description2:
        "Предложим психотерапевтов, которые знают, как работать с вашим запросом. Учтём пожелания по цене и расположению. Укажите ключевые моменты:",
      icon: null,
      image: {
        src: require("assets/images/new/steps_2-1.webp"),
        alt: "Заполните анкету" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        icon: null,
        items: [
          {
            description:
              "С чем нужна помощь — например, с карьерой, отношениями или переживанием потери?",
          },
          {
            description:
              "Важен ли вам пол и возраст психолога, а также подход, в котором работает специалист?",
          },
        ],
      },
      control: null,
    },
    {
      title: "Выберите психотерапевта",
      subtitle: "",
      description1: "",
      description2:
        "Посмотрите, что психотерапевты рассказывают о себе и своём опыте в анкете. Выберите того, кто интуитивно вызывает симпатию и доверие.",
      icon: null,
      image: {
        src: require("assets/images/new/steps_2-2.webp"),
        alt: "Выберите психотерапевта" || dataOverviewImageAlt,
        title: null,
      },
      list: { icon: null, items: [] },
      control: null,
    },
    {
      title: "Договоритесь о встрече",
      subtitle: "",
      description1: "",
      description2:
        "Мы создадим чат для вас с психотерапевтом в удобном для вас мессенджере , где вы сможете задать интересующие вас вопросы и договориться о встрече.",
      icon: null,
      image: {
        src: require("assets/images/new/steps_2-3.webp"),
        alt: "Договоритесь о встрече" || dataOverviewImageAlt,
        title: null,
      },
      list: { icon: null, items: [] },
    },
    {
      title: "Начните психотерапию",
      subtitle: "",
      description1: "",
      description2:
        "Мы не стандартизируем формат взаимодействия с психотерапевтом. Мы соединяем вас с терапевтом, а дальше вы сами договариваетесь о способе связи, оплаты и регулярности встреч.",
      icon: null,
      image: {
        src: require("assets/images/new/steps_2-4.webp"),
        alt: "Начните психотерапию" || dataOverviewImageAlt,
        title: null,
      },
      list: {
        icon: null,
        items: [
          { description: "Управляйте расписанием в личном кабинете" },
          {
            description:
              "Выбирайте дату и время сессии, оплачивайте и переносите встречи",
          },
        ],
      },
    },
    {
      title: "Мы всегда на связи",
      subtitle: "",
      description1: "",
      description2:
        "На любом этапе терапии вы можете задать нам вопрос, поделиться впечатлениями о работе психотерапевта или оставить отзыв о сервисе. Напишите на почту info@psywealth.com или в телеграм @PsyWealth_bot",
      icon: null,
      image: {
        src: require("assets/images/new/steps_2-5.webp"),
        alt: "Мы всегда на связи" || dataOverviewImageAlt,
        title: null,
      },
      list: { icon: null, items: [] },
    },
  ],
};

export const dataFeatureTabsMainPage = {
  title: "Психологические консультации",
  subtitle: "Психологические консультации",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      tab: { id: 1, title: "Запрос", key: "home1", available: true },
      title: "Запрос клиента",
      description: (
        <>
          <p>
            Процесс терапии начинается с запроса клиента, который сам
            определяет, каких изменений он стремится достичь в сотрудничестве с
            психологом.
          </p>
          <p>
            Четко сформулированная цель является отличной основой для начала
            работы, и остается лишь выстроить план действий и следовать ему.
          </p>
          <p>
            Алгоритмы PsyWealth учитывают как запрос клиента, так и
            специализацию психолога, что позволяет сделать процесс более быстрым
            и эффективным. Если клиенту трудно сформулировать свой запрос,
            задача терапевта заключается в помощи в этом процессе.
          </p>
        </>
      ),
      image: {
        src: require("assets/images/new/feature_1-1_1000x1000.webp"),
        alt: "Отношения с клиентом строятся на запросе клиента",
        title: "",
      },
      items: [],
    },
    {
      tab: {
        id: 2,
        title: "Отношения",
        // title: "Отношения между клиентом и психологом",
        key: "home2",
        available: true,
      },
      title: "Отношения между клиентом и психологом",
      description: (
        <>
          <p>
            Со временем между специалистом и клиентом формируется
            терапевтический альянс. Это сотрудничество, в рамках которого обе
            стороны объединяют усилия для решения возникшей проблемы.
          </p>
          <p>
            Для некоторых клиентов это могут быть первые в жизни отношения, в
            которых:
          </p>
          <ul>
            <li>они ощущают безопасность;</li>
            <li>
              могут проявлять себя без страха быть осуждаемыми и не стремиться к
              идеалу;
            </li>
            <li>
              нет тем, которые бы считались запретными, и неправильных эмоций.
            </li>
          </ul>
          <p>
            Именно такие отношения придают человеку уверенность и способствуют
            положительным изменениям в его жизни. Терапевтический альянс
            основывается на доверии.
          </p>
          <p>
            Платформа PsyWealth работает только с профессиональными психологами,
            которому можно доверять.
          </p>
        </>
      ),
      image: {
        src: require("assets/images/new/feature_1-2_1000x1000.webp"),
        alt: "Отношения с клиентом строятся на сотрудничестве между клиентом и психологом",
        title: "",
      },
      items: [],
    },
    {
      tab: {
        id: 3,
        title: "Профессионализм",
        key: "home3",
        available: true,
      },
      title: "Профессионализм и специализация",
      description: (
        <>
          <p>
            Наличие психологического образования и компетенции в методах
            психотерапии является основополагающим требованием для работы в
            качестве психолога.
          </p>
          <p>
            Однако на практике это не всегда соблюдается. В России
            законодательство практически не регулирует область психотерапии, и
            любой желающий, прошедший краткосрочные курсы, может назвать себя
            психологом.
          </p>
          <p>
            Платформа PsyWealth проверяет дипломы о высшем образовании,
            сертификаты повышения квалификации и опыт работы с конкретными
            запросами клиентов.
          </p>
        </>
      ),
      image: {
        src: require("assets/images/new/feature_1-3_1000x1000.webp"),
        alt: "Отношения с клиентом строятся на профессионализме и специализации",
        title: "",
      },
      items: [],
    },
  ],
};

export const dataSloganMainPage = {
  title:
    "Большинство наших клиентов чувствуют результат уже после третьей консультации",
  subtitle: "",
  description1:
    "Подберем специалиста и метод терапии, который подойдет именно вам. Специалист поможет поставить цель терапии и приведет к результату",
  description2: "",
};

export const dataCTAMainPage = {
  title: "",
  subtitle: "Специальное предложение",
  description1:
    "Подберем специалиста и метод терапии, который подойдет именно вам. Специалист поможет поставить цель терапии и приведет к результату",
  description2: "",
  image: {
    src: require("assets/images/new/cta/image.webp"),
    alt: "Заполните анкету" || dataOverviewImageAlt,
    title: null,
  },
};

export const dataCTAMainPageExtra = {
  title: "",
  subtitle: "",
  description1: "",
  description2: "",
  image: {
    src: null,
    alt: "" || dataOverviewImageAlt,
    title: "",
  },
  items: [
    {
      title: "",
      subtitle: "",
      description1:
        "Создаем заботливую и поддерживающую среду, в которой каждый чувствует себя важным и целостным.",
      description2: "",
      image: {
        src: null,
        alt: "" || dataOverviewImageAlt,
        title: "",
      },
    },
    {
      title: "",
      subtitle: "",
      description1:
        "Бывают приятные моменты и моменты испытаний. Иногда мечты сбываются, а порой рассыпаются на части. Жизнь не идеальна, но каждый из нас способен быть счастливым.",
      description2: "",
      image: {
        src: null,
        alt: "" || dataOverviewImageAlt,
        title: "",
      },
    },
    {
      title: "",
      subtitle: "",
      description1: "Быть счастливым можно научиться",
      description2: "",
      image: {
        src: null,
        alt: "" || dataOverviewImageAlt,
        title: "",
      },
    },
  ],
};

export const dataDemoMainPage = {
  title: "Экстренная помощь",
  subtitle: "",
  description1: "В ситуации, угрожающей жизни, обращайтесь",
  description2: "",
  image: {
    src: require("assets/images/new/lighthouse-1.webp"),
    alt: "Экстренная помощь" || dataOverviewImageAlt,
    title: null,
  },
};

// export const dataOurProductsMainPage = {
//   title: "Наши решения и продукты",
//   subtitle: "Наши решения и продукты",
//   description:
//     "Программное обеспечение собственной разработки, внесенное в реестр отечественного ПО",
//   image: null,
//   imageAlt: "",
//   items: [],
// };

export const dataServiceMainPage = {
  title: "С чем поможет психолог?",
  subtitle: "С чем поможет психолог?",
  description: "С чем поможет психолог?",
  items: [
    {
      title: null,
      subtitle: null,
      description: "Наладить гармоничные отношения с близкими людьми",
      image: require("assets/images/feature/helps-item-1.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Справиться со стрессом, тревогой и страхом",
      image: require("assets/images/feature/helps-item-2.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Принять себя и повысить самооценку",
      image: require("assets/images/feature/helps-item-3.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Научиться говорить «нет» и отстаивать личные границы",
      image: require("assets/images/feature/helps-item-4.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Пережить перемены: переезд, расставание, потеря работы",
      image: require("assets/images/feature/helps-item-5.webp"),
      imageAlt: "",
      icon: null,
    },

    {
      title: null,
      subtitle: null,
      description: "Понять, почему на работе проблемы и нет мотивации",
      image: require("assets/images/feature/helps-item-6.webp"),
      imageAlt: "",
      icon: null,
    },
  ],
};

export const dataTopCoursesMainPage = {
  title: "Обучение и развитие",
  subtitle: "Обучение и развитие",
  description: "Проходите дополнительные курсы, чтобы закрепить результат",
  items: [],
};

export const dataTopPsychologistsMainPage = {
  title: "Наши психологи",
  subtitle: "Наши психологи",
  description: "Выберите психолога с которым комфортно вам",
  items: [],
};

export const dataTopAdviceMainPage = {
  title: "Актуальная тема",
  subtitle: "Актуальная тема",
  description: "Каждый месяц мы говорим о самом актуальном для вас",
  items: [],
};

export const dataPsySelection = {
  title: "Как подобрать психолога на сайте?",
  subtitle: "Как подобрать психолога на сайте?",
  description: "Как подобрать психолога на сайте?",
  items: [
    {
      title: "Что бы вы хотели обсудить?",
      subtitle: "",
      description:
        "Подберите интересующую вас тему, сформулируйте запрос или укажите проблемную область",
      image: require("assets/images/app/app-1.webp"),
    },
    {
      title: "Заполните анкету",
      subtitle: "",
      description:
        "Ответите на вопросы о вашем состоянии в анкете и укажете предпочтения к психологу",
      image: require("assets/images/app/app-2.webp"),
    },
    {
      title: "Мы подберем вам психолога",
      subtitle: "",
      description:
        "Сервис подберет психолога из сотни профессионалов, после чего ваш будущий специалист ознакомится с анкетой",
      image: require("assets/images/app/app-2.webp"),
    },
    {
      title: "Когда вам удобно?",
      subtitle: "",
      description: "Выбирайте время, для удобной встречи с психологом",
      image: require("assets/images/app/app-3.webp"),
    },
    {
      title: "Оформите подписку и начните терапию",
      subtitle: "",
      description:
        "Специалист изучит вашу анкету и сам свяжется с вами, чтобы договориться о времени первой сессии",
      image: require("assets/images/app/app-3.webp"),
    },
  ],
};

export const dataWhatWeDoMainPage = {
  title: "Форматы психотерапии",
  subtitle: "Форматы психотерапии",
  description:
    "Формата психотерапии помочь различным группам клиентов справиться с их уникальными проблемами и вызовами." +
    "Выбор формата зависит от конкретных потребностей и ситуации, и наши специалисты готовы поддержать вас в этом процессе.",
  items: [
    {
      title: "Для себя",
      subtitle: "Один на один",
      description:
        "Индивидуальная психотерапия, где участвуют двое: клиент и психолог",
      icon: null,

      image: {
        src: require("assets/images/new/what-we-do-1.webp"),
        alt:
          "Индивидуальные консультации, личная работа с психологом" ||
          dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },

      items: [
        { title: "Личное пространство" },
        { title: "Персональный подход подход" },
        { title: "Гибкий формат работы" },
        { title: "Быстрые результаты" },
      ],
    },
    {
      title: "Для пары",
      subtitle: "Парный",
      description:
        "К психологу обращаются партнеры, чтобы разобраться в отношениях друг с другом",
      icon: null,

      image: {
        src: require("assets/images/new/what-we-do-2.webp"),
        alt: "Психолог для пар, семейные консультации" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },

      items: [
        { title: "Игровая терапия" },
        { title: "Работа с родителями" },
        { title: "Фокус на развитии" },
        { title: "Безопасная среда" },
      ],
    },
    {
      title: "Для ребенка",
      subtitle: "Вместе с ребенком",
      description:
        "Психолог работает с ребенком и взрослым родителем или опекуном",
      icon: null,

      image: {
        src: require("assets/images/new/what-we-do-3.webp"),
        alt: "Психолог для ребенка, детский психолог" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },

      items: [
        { title: "Разрешение конфликтов" },
        { title: "Поддержка" },
        { title: "Улучшение коммуникации" },
        { title: "Совместное решение проблем" },
      ],
    },
  ],
};

export const dataFeature2MainPage = {
  title: "Психотерапевты для занятий лично или онлайн",
  subtitle: "Психотерапевты для занятий лично или онлайн",
  description: "Психотерапевты для занятий лично или онлайн",
  items: [
    {
      title: "Для себя",
      subtitle: "Один на один",
      description:
        "Индивидуальная психотерапия, где участвуют двое: клиент и психолог",
      icon: null,

      image: {
        src: Image1,
        alt:
          "Индивидуальные консультации, личная работа с психологом" ||
          dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
    {
      title: "Для пары",
      subtitle: "Парный",
      description:
        "К психологу обращаются партнеры, чтобы разобраться в отношениях друг с другом",
      icon: null,
      image: {
        src: Image2,
        alt: "Психолог для пар, семейные консультации" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
    {
      title: "Для ребенка",
      subtitle: "Вместе с ребенком",
      description: "Психолог работает с ребенком и взрослым родственником",
      icon: null,
      image: {
        src: Image3,
        alt: "Психолог для ребенка, детский психолог" || dataOverviewImageAlt,
        title: null,
        props: { width: "300", height: "300" },
      },
    },
  ],
};

const Icon1 = ({ size = "2rem" }) => (
  <IconContext.Provider value={{ size, className: "" }}>
    <i>
      <BiAward />
    </i>
  </IconContext.Provider>
);

const Icon2 = ({ size = "2rem" }) => (
  <i>
    <BiAward style={{ color: "white", fontSize: size }} />
  </i>
);

const Icon3 = (props) => (
  <IconContext.Provider value={{ size: props.size, className: "" }}>
    <props.icon />
  </IconContext.Provider>
);

const Icon4 = (props) => (
  <props.icon style={{ fontSize: props.size, ...props }} />
);

export const dataAltFeatureMainPage = {
  title: "Как мы отбираем психологов?",
  subtitle: "Как мы отбираем психологов?",
  // description: "Только 13% психологов проходят отбор в команду",
  description:
    "Мы уже проверили их образование, опыт, часы супервизии и этичность. Вам осталось только выбрать понравившегося!",
  image: {
    // src: "https://freefrontend.dev/assets/rectangle-tall2.png",
    src: require("assets/images/new/why-choose-img-1.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Образование",
      subtitle: "",
      description:
        "Проверяем дипломы: высшее психологическое, психиатрическое или переподготовка",
      icon: <Icon3 icon={BiAward} size="3.5rem" />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Опыт работы",
      subtitle: "",
      description:
        "Рассматриваем специалистов с подтверждённой практикой от трёх лет",
      icon: <Icon3 icon={BiAward} size="3.5rem" />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Рекомендации",
      subtitle: "",
      description:
        "Просим предоставить рекомендацию от супервизора, старших коллег или психологической ассоциации",
      icon: <Icon3 icon={BiAward} size="3.5rem" />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Собеседование",
      subtitle: "",
      description:
        "Разбираем успешные кейсы и проверяем навыки прямо на вступительном интервью",
      icon: <Icon3 icon={BiAward} size="3.5rem" />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Этический кодекс",
      subtitle: "",
      description:
        "Смотрим, разделяет ли кандидат наши ценности. Профессиональный психолог не оценивает и не осуждает",
      icon: <Icon3 icon={BiAward} size="3.5rem" />,
      image: { src: null, alt: "", title: "" },
    },
    {
      title: "Обучение",
      subtitle: "",
      description:
        "Развиваем психологов через семинары и супервизии. Даже профессионалам важно учиться",
      icon: <Icon3 icon={BiAward} size="3.5rem" />,
      image: { src: null, alt: "", title: "" },
    },
  ],
};

export const dataCountUpMainPage = {
  title: "Какой вид психотерапии вы ищете?",
  subtitle: "Какой вид психотерапии вы ищете?",
  description: "Какой вид психотерапии вы ищете?",
  items: [
    {
      title: "100000",
      subtitle: "",
      //description: "человек нашли своего психолога онлайн",
      description: "Человек получили поддержку",
      suffix: "",
      prefix: "",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "400",
      subtitle: "",
      // description: "проверенных специалистов",
      description: "Квалифицированных специалистов",
      suffix: " +",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "7",
      subtitle: "",
      //description: "средний опыт практики",
      description: "Средний опыт работы и практики",
      suffix: " лет",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "80",
      subtitle: "",
      //description: "клиентов чувствуют результат после 5-й сессии",
      description: "Клиентов чувствуют результат после 3-й сессии",
      suffix: " %",
      icon: null,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

export const dataAboutMainPage = {
  title: "Ждём от вас",
  subtitle: "Ждём от вас",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
};

export const dataOurAdvantagesMainPage = {
  title: "Что делает психотерапию с нами удобной?",
  subtitle: "Что делает психотерапию с нами удобной?",
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Рекомендуем профессионалов в которых уверены",
      subtitle: "",
      description:
        "Вам не нужно беспокоиться о квалификации специалиста. Мы всё проверили.",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: Icon10_1,
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      title: "Помогаем найти именно «вашего» специалиста",
      subtitle: "",
      description:
        "Если психолог не подойдёт по любым причинам, мы предложим другого.",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: Icon10_2,
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      title: "Заботимся о результате",
      subtitle: "",
      description:
        "Напоминаем о сессиях, регулярно собираем отзывы и приходим на помощь, если что-то идёт не так.",
      suffix: "",
      prefix: "",
      icon: <BsLayoutTextWindowReverse />,
      image: {
        src: Icon10_2,
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      title: "Гарантируем понятную фиксированную цену",
      subtitle: "",
      description:
        "Даже у психологов с похожим опытом цены за сессию могут различаться в несколько раз. У нас такого не бывает: в сервисе единая и справедливая цена.",
      suffix: "",
      prefix: "",
      icon: <BsPencilSquare />,
      image: {
        src: Icon10_3,
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      title: " Делаем оплату сессий удобной",
      subtitle: "",
      description:
        "Деньги за сессию списываются автоматически, но специалист получает их только после встречи. Если психолог отменит консультацию, деньги вернутся на вашу карту.",
      suffix: "",
      prefix: "",
      icon: <BsSpeedometer2 />,
      image: {
        src: Icon10_4,
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsClipboard2 />,
      image: {
        src: Icon10_4,
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
  ],
};

export const dataOurAdvantagesVideoMainPage = {
  title: "Что делает психотерапию с нами удобной?",
  subtitle: "Что делает психотерапию с нами удобной?",
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  items: [],
};

export const dataBlogMainPage = {
  title: "Актуальные новости и события",
  subtitle: "Актуальные новости и события",
  description:
    "Каждый год мы проводим десятки вебинаров и семинаров, участвуем в отраслевых конференциях с нашими партнёрами и проводим собственные. Присоединяйтесь!",
};

export const dataStartTabsMainPage = {
  title: "Как начать психотерапию",
  subtitle: "Как начать психотерапию",
  description: "",
  items: [
    {
      id: 1,
      title: "1. Заполните анкету",
      subtitle: "",
      description:
        "Расскажите, что вас беспокоит и с каким специалистом хотите работать. Например, можно указать определённый возраст специалиста и выбрать удобное время для сессии.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-1.webp"),
        imageAlt: "",
      },
    },
    {
      id: 2,
      title: "2. Выберите специалиста",
      subtitle: "",
      description:
        "Умный алгоритм предложит специалистов, которые работают с вашим запросом и подходят по пожеланиям.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-2.webp"),
        imageAlt: "",
      },
    },
    {
      id: 3,
      title: "3. Начните психотерапию",
      subtitle: "",
      description:
        "Выберите дату и время первой сессии. Мы пришлём напоминание и расскажем в письме, как подготовиться к первой встрече.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-3.webp"),
        imageAlt: "",
      },
    },
    {
      id: 4,
      title: "4. Следите за процессом",
      subtitle: "",
      description:
        "Выберите дату и время первой сессии. Мы пришлём напоминание и расскажем в письме, как подготовиться к первой встрече.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-3.webp"),
        imageAlt: "",
      },
    },
    {
      id: 5,
      title: "6. Общайтесь через приложение",
      subtitle: "",
      description:
        "Выберите дату и время первой сессии. Мы пришлём напоминание и расскажем в письме, как подготовиться к первой встрече.",
      icon: null,
      image: {
        src: require("assets/images/tabs/tabs-3.webp"),
        imageAlt: "",
      },
    },
  ],
};

export const dataHelpDesk = {
  title: "Нужна помощь?",
  subtitle: "Подберем для вас психолога вручную бесплатно",
  description:
    "Если вам сложно выбрать психолога, разобраться в подходах и сформулировать первичный запрос самостоятельно — мы поможем!",
  descriptionHTML:
    "<p>Если вам сложно выбрать психолога, разобраться в подходах и сформулировать первичный запрос самостоятельно — мы поможем!</p>",
  items: [],
};

export const dataMobileAppMainPage = {
  title: "А вот что PsyWealth сделает за вас",
  subtitle: "А вот что PsyWealth сделает за вас",
  subtitle2: (
    <>
      This is a text in the first line;
      <br />
      this is a text in a second line
    </>
  ),
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/iphone14-2.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Запомнит все о клиентах",
      subtitle: "",
      description: "Запомнит все о клиентах",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Запомнит расписание",
      subtitle: "",
      description: "Запомнит расписание",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsMortarboard />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsStar />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsXDiamond />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsCameraVideo />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: " Делаем оплату сессий удобной",
      subtitle: "",
      description:
        "Деньги за сессию списываются автоматически, но специалист получает их только после встречи. Если психолог отменит консультацию, деньги вернутся на вашу карту.",
      suffix: "",
      prefix: "",
      icon: <BsBrightnessHigh />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsActivity />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

export const dataTestimonialMainPage = {
  title: "Что говорят наши клиенты?",
  subtitle: "Отзывы",
  description: "",
  items: [
    {
      title: "Лукьянов Андрей",
      subtitle: "Коммерческий директор ООО «АСТРА ТЕХНОЛОГИИ»",
      description:
        "Для нашей задачи создания электронной библиотеки организации ELARCH подошел почти идеально. Хорошо, что продукт постоянно прогрессирует и радует новыми возможностями.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
    {
      title: "Ломакин Александр",
      subtitle: "Директор ООО “Скан Солюшн”",
      description:
        "ELARCH оказался для наших проектов по организации удалённой обработки большого количества документов единственным подходящим решением. Подобного решения раньше на рынке просто не было.",
      image: require("assets/images/testimonial/testimonial-2-150x150.webp"),
    },
  ],
};
