import { Banner } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataCustomerEntryPage } from "data/dataPages";
import { Fragment } from "react";
import CustomerEntry from "./CustomerEntry";

/**
 * Component for showing Page "Customer Entry" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageCustomerEntry = (props) => {
  const { id = "customer-entry" } = props;
  return (
    <Fragment>
      <SEO data={dataCustomerEntryPage.meta}>
        {/*Don’t canonicalise to a redirected page or a 404 page*/}
        <link rel="canonical" href="https://psywealth.com/launcher" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner data={dataCustomerEntryPage} />
        <CustomerEntry data={dataCustomerEntryPage} />
      </PageMain>
    </Fragment>
  );
};

export default PageCustomerEntry;
