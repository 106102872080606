import classNames from "classnames";
import { PageSection } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import SectionContentStyle1 from "./SectionStyle1";
import SectionContentStyle2 from "./SectionStyle2";

/**
 * Component for showing section "Not Found" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionNotFound = (props) => {
  const {
    data,
    id = "not-found",
    cn = "",
    type,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container={false}>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle2
              data={data}
              id={id}
              cn={cn}
              type={type}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            {...otherProps}
          />
        );
      })()}
    </PageSection>
  );
};

SectionNotFound.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionNotFound;
