import { APP_API_URI } from "config/config";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

// import "./Therapist.scss";

// NOTE :

const CardImage = (props) => {
  const { data, type } = props;

  const getURLByType = (type, imageId) => {
    // console.log(type, imageId);
    if (type === "lesson")
      return `${APP_API_URI}/course/lessons/image/${imageId}`;
    return `${APP_API_URI}/course/topic/image/${imageId}`;
  };

  return (
    <img
      className="img"
      src={
        data.imageId
          ? getURLByType(type, data.imageId)
          : "https://www.bootdey.com/image/480x280/00FFFF/000000"
      }
      alt={data.tittle}
      width="480"
      height="280"
      loading="lazy"
    />
  );
};

/**
 * Component for showing section "Card Course" of the site.
 *
 * @component
 */
const CardCourse = (props) => {
  const { data, index, type } = props;
  // console.log("@@@@", data, index);

  return (
    <div className="card blog-grid">
      <div className="card-image blog-grid-img position-relative">
        <CardImage data={data} type={type} />
      </div>
      <div className="card-content blog-grid-text p-4">
        <h2 className="card-title h5 mb-3">
          {type === "lesson" ? (
            <Link to={`/courses/${data.courseSlug}/${data.slug}`}>
              {data.name}
            </Link>
          ) : (
            <Link to={`/courses/${data.slug}`}>{data.name}</Link>
          )}
        </h2>
        <p className="display-30">{data.description}</p>
      </div>
    </div>
  );
};

CardCourse.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.oneOf(["lesson", "course"]),
};

CardCourse.defaultProp = {};

export default CardCourse;
