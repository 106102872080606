import { PropTypes } from "prop-types";
import FormContact2 from "./FormContact2";

// import "./Section.style.scss";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=50603

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const handleSubmit = () => {};

  return (
    <div className="row pb-1 pb-sm-3 pb-lg-4">
      <div className="col-lg-4 pe-xxl-4">
        {/* Section title */}
        <h2 className="display-2">{data.title}</h2>
        <p className="fs-lg pb-4 mb-0 mb-sm-2">{data.description}</p>
      </div>
      <div className="col-lg-8 col-xl-7 offset-xl-1">
        {/* Contact form */}
        <FormContact2 data={data} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

/**
 * Component section content "Contacts (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
