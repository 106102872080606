import { SectionFAQLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { dataFAQ, dataTherapistsPage } from "data";
import { SectionTherapistRequirements } from "./components";
import TherapistSection from "./Therapist.section";
import { therapists } from "./TherapistList.page";

// import "./qq.scss";

// NOTE : https://epicbootstrap.com/snippets/team-cards

const pageDataDemo = {
  title: "Наши психологи",
  items: therapists,
};

/**
 * Component for showing Page "Single Psychologist (Therapist)" of the site.
 *
 * @version: 1.0.0
 **/
const TherapistsSinglePage = (props) => {
  return (
    <main className="therapist-page" id="main">
      <Banner2 data={dataTherapistsPage} />
      <TherapistSection data={pageDataDemo} />
      <SectionTherapistRequirements data={pageDataDemo} />
      <SectionFAQLazy data={dataFAQ} />
    </main>
  );
};

export default TherapistsSinglePage;
