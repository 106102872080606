import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { Spinner } from "components/utils/Spinner";
import { dataMessages, dataCustomerProfilePage as dataPage } from "data";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { SectionCustomerProfile } from "./components";
import { useGetProfile } from "./utils/useGetProfile";

// NOTE :

/**
 * Component for showing section "Customer Profile" of the site.
 *
 * @page
 **/
const LayoutCustomerProfile = (props) => {
  const {
    data: customerProfile,
    isLoading: isLoadingCustomerProfile,
    isError: isErrorCustomerProfile,
    error: errorCustomerProfile,
  } = useGetProfile();

  const isLoading = isLoadingCustomerProfile;
  const isError = isErrorCustomerProfile;
  const errors = [errorCustomerProfile];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (customerProfile && customerProfile.content) {
      _debug("CUSTOMER PROFILE:", customerProfile.content);
    }
  }, [customerProfile]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <Fragment>
      <SEO data={dataPage.meta}>
        <link rel="canonical" href="https://psywealth.com/customer/profile" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <main className="page-customer-profile">
        <Banner2 data={dataPage} />
        <SectionCustomerProfile
          data={{ ...dataPage, profile: customerProfile }}
        />
      </main>
    </Fragment>
  );
};

export default LayoutCustomerProfile;
