import { useLocation } from "react-router-dom";

import Image1 from "assets/images/new/shape_leaf_left.svg";
import Image2 from "assets/images/new/shape_leaf_right.svg";
import { Breadcrumb } from "react-bootstrap";

const pageNames = [
  { path: "blog", title: "Блог" },
  { path: "home", title: "Home" },
];

/**
 * Component for showing section "Page Banner" of the site.
 *
 * @component
 * @version: 2.0
 **/
const Banner2 = (props) => {
  const { data } = props;
  const location = useLocation();

  let currentLink = "";
  let pathname = ["home"];
  pathname = pathname.concat(
    location.pathname.split("/").filter((crumb) => crumb !== "")
  );

  // console.log(pathname, location.pathname, location);

  const getNameByPath = (name) => {
    if (name.includes("blog")) {
      return "Блог";
    } else if (name.includes("home")) {
      return "Home";
    } else if (name.includes("contacts")) {
      return "Контакты";
    }
    return name;
  };

  const renderBreadcrumb = () => {
    //   pathname
    // .map((crumb) => {
    //   currentLink += `/${crumb}`;

    return (
      <Breadcrumb
        className=""
        listProps={{
          className:
            "breadcrumb unordered_list justify-content-lg-end justify-content-center",
        }}
      >
        {pathname.map((item, index) => (
          <Breadcrumb.Item
            key={index}
            href={`/${item}`}
            active={location.pathname.includes(item)}
          >
            {getNameByPath(item)}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  /* <li className="breadcrumb-item active" key={crumb} aria-current="page">
          <Link to={currentLink}>{crumb}</Link> */

  return (
    <section className="page-banner-section-style2 pt-120 pb-75 decoration-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h1 className="page-title mb-0">{data.title}</h1>
          </div>
          <div className="col-lg-6">{renderBreadcrumb()}</div>
        </div>
      </div>
      <div className="decoration-item shape_leaf_1">
        <img src={Image1} alt="Shape Leaf - Talking Minds - Psychotherapist" />
      </div>
      <div className="decoration-item shape_leaf_2">
        <img src={Image2} alt="Shape Leaf - Talking Minds - Psychotherapist" />
      </div>
    </section>
  );
};

export default Banner2;

/* <div className="row">
<div className="col-xl-12">
  <div className="banner-content">
    <h1 className="text-white">{data.title}</h1>

    <div className="page-breadcrumb">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/">Home</Link>
          </li>
          {crumbs}
        </ol>
      </nav>
    </div>
  </div>
</div>
</div> */
