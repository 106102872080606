import classNames from "classnames";
import { useComponentPrefix } from "components/core/utils/utils";
import { PropTypes } from "prop-types";
import React from "react";

/**
 * Component "PageSection" of the site.
 *
 * @component
 * @version 1.0.3
 **/
export const PageSection = React.forwardRef(
  (
    {
      id,
      className,
      // Retained for backward compatibility with previous component specification
      cn,
      componentPrefix,
      container = false,
      children,
      // Need to define the default "as" during prop destructuring to be compatible with styled-components
      as: Component = "section",
      ...otherProps
    },
    ref
  ) => {
    //let prefix = id ? `page-section ${id}-section` : `page-section`;
    const prefix = useComponentPrefix(
      componentPrefix,
      id ? `page-section section-${id}` : `page-section`
    );

    return (
      <Component
        ref={ref}
        className={classNames(prefix, className, cn)}
        id={id}
        {...otherProps}
      >
        {container ? <div className="container">{children}</div> : children}
      </Component>
    );
  }
);

PageSection.displayName = "PageSection";
PageSection.propTypes = {
  /** @default 'page-section' */
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,

  /** Set a custom element for this component */
  as: PropTypes.elementType,
};
