import { SITE } from "config/config";

const dataPageTitle =
  "Блог о ментальном здоровье и психологической помощи | Psywealth";
const dataPageDescription =
  "Знание о том, как устроена психология — первый шаг к улучшению своего ментального здоровья. Статьи психологов о психологии, истории людей и новости из мира психологии | Psywealth";
// Блог о психологии. Статьи психологов о психологии, истории людей и новости из мира психологии.
const dataPageAuthor = SITE.AUTHOR;

const dataMetaOGTitle = dataPageTitle;
const dataMetaOGDescription =
  "Команда сервиса Psywealth отвечает на самые популярные вопросы про психологию, психотерапию и как это все работает. Статьи психологов о психологии, истории людей и новости из мира психологии." ||
  dataPageDescription;
const dataMetaOGImage = "https://psywealth.com/images/OG/main_1200x640.png";
const dataMetaOGImageAlt = "";
const dataMetaOGLocale = SITE.LOCALE;
const dataMetaOGSiteName = SITE.NAME;

const dataMetaBlogPage_CreateDate = "2023-10-01T07:00:00+00:00";
const dataMetaBlogPage_UpdateDate = "2024-12-10T07:59:00+00:00";

export const dataMetaBlogPage = {
  title: dataPageTitle,
  description: dataPageDescription,
  name: "",
  type: "",
  author: dataPageAuthor,
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: dataMetaOGTitle,
    },
    {
      property: "og:description",
      content: dataMetaOGDescription,
    },
    {
      property: "og:url",
      content: "",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaOGImage,
    },
    {
      property: "og:image:alt",
      content: dataMetaOGImageAlt,
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "640",
    },
    {
      property: "og:locale",
      content: dataMetaOGLocale,
    },
    {
      property: "og:site_name",
      content: dataMetaOGSiteName,
    },
    // {
    //   property: "article:published_time",
    //   content: dataMetaBlogPage_CreateDate,
    // },
    // {
    //   property: "article:modified_time",
    //   content: dataMetaBlogPage_UpdateDate,
    // },
  ],
  TW: [
    {
      name: "twitter:title",
      content: dataPageTitle,
    },
    {
      name: "twitter:description",
      content: dataPageDescription,
    },
    {
      property: "twitter:image",
      content: dataMetaOGImage,
    },
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:label1", content: "" },
    { property: "twitter:data1", content: "" },
  ],
};
