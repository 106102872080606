import { Image } from "components/utils/Image";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Button } from "react-bootstrap";

import background from "assets/images/bg/cta-bg.webp";

// NOTE : https://codescandy.com/geeks-bootstrap-5/docs/cta-snippet.html

export const msgCTA = "Заказать";
export const msgSubmitted = "Заказ успешно направлен";
export const msgSubmit = "Заказать";
const msgCTAAction = "Найти";

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <img src={background} alt="" />

      <div className="container">
        <div
          className="row justify-content-center aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="col-xl-10">
            <div className="text-center">
              <h3 className="mb-2">Call To Action</h3>
              <p className="mb-1">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
              <a className="cta-btn mt-1" href="/">
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const SectionContent1 = (props) => {
  const { data } = props;
  // console.log(data);

  return (
    <div className="row">
      <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
        <div className="bg-primary py-6 px-6 px-xl-0 rounded-5">
          <div className="row align-items-center">
            <div className="offset-xl-1 col-xl-5 col-md-6 col-12">
              {/* Title */}
              <h2 className="h1 text-white mb-3">{data.title}</h2>

              {/* Description */}
              {data.description1 && data.description2 ? (
                <Fragment>
                  <p className="text-white fs-4 mb-1">{data.description1}</p>
                  <p className="text-white fs-4 ">{data.description2}</p>
                </Fragment>
              ) : (
                <p className="text-white fs-4">{data.description1}</p>
              )}

              {/* Link */}
              <button className="btn btn-dark">{msgCTAAction}</button>
            </div>
            <div className="col-xl-6 col-md-6 col-12">
              <div className="text-center">
                <Image image={data?.image} className="img-fluid" />
                {/* <img
                  src={require("assets/images/demo/course.webp")}
                  alt="learning"
                  className="img-fluid"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionContent2 = (props) => {
  const {
    data,
    id,
    cn,
    showSubtitle,
    showDescription,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;
  const isMobile = useIsMobile();
  const [show, setShow] = useState(false);

  return (
    <section
      id={id || "call-to-action"}
      ref={innerRef}
      style={{
        backgroundImage: data.backgroundImage
          ? `url(${data.backgroundImage})`
          : `url(${background})`,
      }}
      className="call-to-action-section cta-bg img-bg pt-110 pb-100"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-5">
            <div className="section-content-title mb-60">
              {showSubtitle && data.subtitle && !isMobile && (
                <span
                  className="aos-animate fadeUp"
                  {...(useAnimation && {
                    "data-aos": "fade-up",
                    "data-aos-delay": 200,
                  })}
                >
                  {data.subtitle}
                </span>
              )}
              <h2
                className="mb-3 text-white aos-animate fadeUp"
                {...(useAnimation && {
                  "data-aos": "fade-up",
                  "data-aos-delay": 400,
                })}
              >
                {data.title}
              </h2>
              {showDescription && (
                <p
                  className="text-white aos-animate fadeUp"
                  {...(useAnimation && {
                    "data-aos": "fade-up",
                    "data-aos-delay": 600,
                  })}
                >
                  {data.description}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-5 col-lg-7">
            <div className="contact-btn text-center text-lg-center">
              <Button
                className="btn theme-btn"
                variant="primary"
                aria-label={data.msg.msgCTA || msgCTA}
              >
                {data.msg.msgCTA || msgCTA}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * Component section content "Call To Action (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;

  return (
    <div className="container">
      <SectionContent1
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showTitle}
        showSubtitle={showSubtitle}
        showDescription={showDescription}
        innerRef={innerRef}
        {...otherProps}
      />
    </div>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showSubtitle: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
