import React from "react";
import LayoutCustomerProfile from "./CustomerProfile.layout";

const CustomerProfileTabAppointmentsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabBioLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabDeleteLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabOrdersLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabPaymentsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabProfileLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabSettingsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const CustomerProfileTabWishlistLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);

export {
  CustomerProfileTabAppointmentsLazy,
  CustomerProfileTabBioLazy,
  CustomerProfileTabDeleteLazy,
  CustomerProfileTabOrdersLazy,
  CustomerProfileTabPaymentsLazy,
  CustomerProfileTabProfileLazy,
  CustomerProfileTabSettingsLazy,
  CustomerProfileTabWishlistLazy,
  LayoutCustomerProfile,
};
