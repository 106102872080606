import { PropTypes } from "prop-types";
import { Link, useLocation } from "react-router-dom";

// NOTE : https://around.createx.studio/404-v2.html

const msgGoToHomePage = "Вернуться на главную";

const SectionContent = (props) => {
  const { data } = props;

  return null;
};

/**
 * Component section content "Not Found (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;
  const location = useLocation();

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-between min-vh-100 position-relative">
      <div className="position-absolute top-0 start-0 w-100 h-100 d-lg-none">
        <div
          className="d-dark-mode-none position-absolute top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "#f2f3f7" }}
        ></div>
      </div>
      <div className="d-flex flex-column justify-content-center w-lg-50 position-relative z-2 mt-auto mt-lg-0">
        <div
          className="text-center text-lg-start pt-5 pb-3 py-lg-5 px-3 px-sm-4 px-lg-5 mx-auto"
          style={{ maxWidth: "630px" }}
        >
          <h1 className="display-1">{data.title}</h1>
          <p className="lead pb-2 mb-4 mb-lg-5">{data.description}</p>
          <p className="lead pb-2 mb-4 mb-lg-5">
            Запрашиваемые путь <code>{location.pathname}</code>
          </p>
          <Link to="/" className="btn btn-lg btn-primary">
            {msgGoToHomePage}
          </Link>
        </div>
      </div>
      <div className="d-flex flex-column position-relative justify-content-end align-items-center w-lg-50 overflow-hidden">
        <div className="position-absolute top-0 start-0 w-100 h-100 d-none d-lg-block">
          <div
            className="d-dark-mode-none position-absolute top-0 start-0 w-100 h-100"
            style={{ backgroundColor: "#f2f3f7" }}
          ></div>
          <div
            className="d-none d-dark-mode-block position-absolute top-0 start-0 w-100 h-100"
            style={{ backgroundColor: "rgba(255,255,255, .04)" }}
          ></div>
        </div>
        <div className="position-relative z-2" style={{ maxWidth: "948px" }}>
          <img
            src={require("assets/images/new/404/layer01.webp")}
            alt="Lady"
            id="lady"
            className="show"
          />
          <img
            className="position-absolute top-0 start-0 w-100 h-100 show"
            src={require("assets/images/new/404/layer02-light.webp")}
            alt="Bubble"
            id="bubble"
          />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 show"
            id="question"
          >
            <img
              className="d-dark-mode-none"
              src={require("assets/images/new/404/layer03-light.webp")}
              alt="Question mark"
            />
            <img
              className="d-none d-dark-mode-block"
              src={require("assets/images/new/404/layer03-dark.webp")}
              alt="Question mark"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
