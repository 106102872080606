import { SITE } from "config/config";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const SITE_NAME = SITE.NAME;
const SITE_LOCALE = SITE.LOCALE;

const SEO = (props) => {
  const { data, children } = props;
  const { title, description, name, type, author, OG, TW, ExtraMetaTags } =
    data;
  const fullURL = window.location.href;
  const location = useLocation();

  console.log(location, fullURL);

  return (
    <Helmet prioritizeSeoTags>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta charset="UTF-8" />
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      {/* End standard metadata tags */}
      {/* Extra meta tags */}
      {ExtraMetaTags &&
        ExtraMetaTags.map((item, index) => {
          if (!item.content) return null;
          return (
            <meta
              property={item.property}
              content={item.property}
              key={index}
            />
          );
        })}
      {/* End Twitter tags */}
      {/* OG, Facebook tags */}
      {OG &&
        OG.map((item, index) => {
          if (item.property === "og:title" && !item.content) {
            return (
              <meta property={item.property} content={title} key={index} />
            );
          } else if (item.property === "og:description" && !item.content) {
            return (
              <meta
                property={item.property}
                content={description}
                key={index}
              />
            );
          } else if (item.property === "og:url" && !item.content) {
            console.log("object");
            return (
              <meta
                property={item.property}
                content={description}
                key={index}
              />
            );
          } else if (item.property === "og:site_name" && !item.content) {
            console.log("object");
            return (
              <meta property={item.property} content={SITE_NAME} key={index} />
            );
          } else if (item.property === "og:locale" && !item.content) {
            console.log("object");
            return (
              <meta
                property={item.property}
                content={SITE_LOCALE}
                key={index}
              />
            );
          }

          if (!item.content) return null;
          return (
            <meta property={item.property} content={fullURL} key={index} />
          );
        })}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      {TW &&
        TW.map((item, index) => {
          if (item.property === "twitter:title" && !item.content) {
            return (
              <meta property={item.property} content={title} key={index} />
            );
          }
          if (item.property === "twitter:description" && !item.content) {
            return (
              <meta
                property={item.property}
                content={description}
                key={index}
              />
            );
          }
          if (!item.content) return null;
          return (
            <meta
              property={item.property}
              content={item.property}
              key={index}
            />
          );
        })}
      {/* End Twitter tags */}
      {children}
    </Helmet>
  );
};

export default SEO;

// <script type="application/ld+json">{LD}</script>

// NOTE : https://www.danielkcheung.com/how-to-describe-saas-product-with-schema/

//"copyrightHolder":{
//  "@type":"corporation",
//  "name":"Adobe",
//  "legalName":"Adobe Inc.",
//  "alternateName":"Adobe Systems Incorporated",
//  "@id":"https://www.adobe.com#organization",
//  "tickerSymbol":"ADBE",
//  "sameAs":[
//      "https://www.linkedin.com/company/adobe/",
//      "https://www.instagram.com/adobe/",
//      "https://twitter.com/Adobe",
//      "https://en.wikipedia.org/wiki/Adobe_Inc."]
//},
//"creator":{"@id":"https://www.adobe.com#organization"},
//"publisher":{"@id":"https://www.adobe.com#organization"}
//
