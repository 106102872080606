import { PropTypes } from "prop-types";
import { Tab, Tabs } from "react-bootstrap";

import classNames from "classnames";
import { CallTo } from "components/utils/Utils";
import "./Section.style.scss";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=50603

const TabPanelItem = (props) => {
  const { data } = props;

  return (
    <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
      <div className="custom-card bg-white shadow-lg">
        <div className="d-flex">
          <div>
            <h5 className="custom-card__title mb-2">{data.title}</h5>
            <p className="custom-card__description mb-2">{data.description}</p>
            {data.contacts && (
              <p className={classNames(data.contacts2 ? "mb-2" : "mb-0")}>
                <CallTo phone={data.contacts}>{data.contacts}</CallTo>
              </p>
            )}
            {data.contacts2 && (
              <p className="mb-0">
                <a href={"tel:" + data.contacts2}>{data.contacts2}</a>
              </p>
            )}
          </div>
        </div>
        {data.image && data.image.src && (
          <img
            src={data.image.src}
            alt={data.image.alt}
            className="custom-card__image img-fluid"
          />
        )}
      </div>
    </div>
  );
};

const TabPanel = (props) => {
  const { data } = props;

  return (
    <div className="row">
      {data.items.map((item, index) => {
        return <TabPanelItem data={item} key={index} />;
      })}
    </div>
  );
};

/**
 * Component section content "Feature Tabs (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  const defaultActiveKey = data.items[0].tab.key || "main";

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      id="uncontrolled-tab-example"
      className="flex-nowrap overflow-auto text-nowrap w-100 mx-auto pb-3 mb-3 mb-lg-4"
      justify
    >
      {data.items.map((item, index) => {
        return (
          <Tab
            eventKey={item.tab.key}
            title={item.tab.title}
            key={index}
            disabled={!item.tab.available}
          >
            <TabPanel data={item} />
          </Tab>
        );
      })}
    </Tabs>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
