// const dataPageTitle = "Psywealth | Авторизация";
const dataPageTitle = "Личный кабинет клиента | Psywealth";
const dataPageDescription =
  "Личный кабинет клиента «Psywealth». Управление консультациями и заказами";

export const dataMetaAuthPage = {
  title: dataPageTitle,
  description: dataPageDescription,
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: dataPageTitle,
    },
    {
      property: "og:description",
      content: dataPageDescription,
    },
    {
      property: "og:site_name",
      content:
        "psywealth.com - Психолог онлайн консультации. Психологическая помощь онлайн - чат, видео, аудио.",
    },
    {
      property: "og:url",
      content: "https://psywealth.com",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: "",
    },
    {
      property: "og:image:alt",
      content:
        "Сервис онлайн-сессий с психологами - чат, видео, аудио - «PsyWealth.com»",
    },
    {
      property: "og:locale",
      content: "ru_RU",
    },
  ],
  TW: [
    {
      name: "twitter:title",
      content: dataPageTitle,
    },
    {
      name: "twitter:description",
      content: dataPageDescription,
    },
  ],
};
