import { Fragment, useEffect } from "react";
//import { profileImage } from "services/actions";
import { Spinner } from "components/utils/Spinner";
import { PageSection } from "core/components";
import { dataMessages } from "data";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetCustomerOrderById } from "../Customer/utils/useGetCustomerOrderById";

// NOTE :

const SectionContent = (props) => {
  const { data } = props;

  console.log("eeee", props);

  return (
    <Fragment>
      <h2>Успех в оплате встречи</h2>
      <h4>{data.order.totalAmount}</h4>
      <p>{data.order.id}</p>
    </Fragment>
  );
};

/**
 * Component for showing section "Psychologist Profile" of the site.
 *
 * @component
 **/
const PaymentSuccessful = (props) => {
  const { data, id = "payment-successful", cn = "pt-80 pb-80" } = props;

  console.log(data);

  const {
    data: customerOrder,
    isLoading: isLoadingCustomerOrder,
    isError: isErrorCustomerOrder,
    error: errorCustomerOrder,
  } = useGetCustomerOrderById(data.order);

  const isLoading = isLoadingCustomerOrder;
  const isError = isErrorCustomerOrder;
  const errors = [errorCustomerOrder];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (customerOrder) {
      _debug("PAYMENT ORDER:", customerOrder);
    }
  }, [customerOrder]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={{ ...data, order: customerOrder }} />
      </div>
    </PageSection>
  );
};

export default PaymentSuccessful;
