import classNames from "classnames";
import { getThemeStyles } from "components/utils/Theme";
import { PropTypes } from "prop-types";

import { Fragment } from "react";
import { FaStar } from "react-icons/fa6";
import "./Section.style.scss";

// NOTE :

const msgLearnMore = "Подробнее";

const customers = [
  {
    image: {
      src: require("assets/images/customers/customer-img-1.webp"),
      alt: "",
    },
  },
  {
    image: {
      src: require("assets/images/customers/customer-img-2.webp"),
      alt: "",
    },
  },
  {
    image: {
      src: require("assets/images/customers/customer-img-3.webp"),
      alt: "",
    },
  },
  {
    image: {
      src: require("assets/images/customers/customer-img-4.webp"),
      alt: "",
    },
  },
];

const ContentCustomerBox = (props) => {
  const CustomerImage = (props) => {
    const { data } = props;

    return (
      <div className="customer-img">
        <figure
          className="image-anime reveal"
          style={{
            transform: "translate(0px, 0px)",
            opacity: 1,
            visibility: "inherit",
          }}
        >
          <img
            src={data.image.src}
            alt={data.image.alt}
            style={{ transform: "translate(0px, 0px)" }}
          />
        </figure>
      </div>
    );
  };

  const renderCustomerImages =
    customers.length > 0 &&
    customers.map((item, index) => {
      return <CustomerImage data={item} key={index} />;
    });

  return (
    <div className="about-customer-box">
      <div className="about-customer-rating">
        <i>
          <FaStar />
        </i>
        <i>
          <FaStar />
        </i>
        <i>
          <FaStar />
        </i>
        <i>
          <FaStar />
        </i>
      </div>
      <div className="about-customer-content">
        <p>
          Наши клиенты <span className="counter">8,000</span>
        </p>
      </div>
      <div className="customer-images">
        {renderCustomerImages}
        <div className="customer-img add-more">
          <p>
            <span className="counter">30</span>k
          </p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;
  const contentTitleStyle = getThemeStyles("contentTitle");
  const contentDescriptionStyle = getThemeStyles("contentDescription");

  // console.log(props);

  return (
    <div className="row gx-4 align-items-center">
      <div className="col-md-6">
        {/* <div className="me-md-2 me-lg-5">
          <img
            className="img-fluid rounded-3"
            src="https://freefrontend.dev/assets/square.png"
          />
        </div>*/}

        <div className="about-us-images">
          <div className="about-img-1">
            <figure className="image-anime">
              <img src={require("assets/images/new/about-img-1.webp")} alt="" />
            </figure>
          </div>

          <div className="about-img-2">
            <figure className="image-anime">
              <img src={require("assets/images/new/about-img-2.webp")} alt="" />
            </figure>
          </div>

          <ContentCustomerBox />
        </div>
      </div>
      <div className="col-md-6">
        <div className="ms-md-2 ms-lg-5 mt-5 mt-md-0">
          <div className="section-content">
            {showSubtitle && (
              <span
                className={classNames(
                  "section-content__subtitle",
                  "text-muted"
                )}
              >
                {data.subtitle}
              </span>
            )}
            <h3
              className={classNames(
                "section-content__title",
                contentTitleStyle
              )}
            >
              {data.title}
            </h3>
            {showDescription && (
              <Fragment>
                <p
                  className={classNames(
                    "section-content__description",
                    contentDescriptionStyle
                  )}
                >
                  {data.description1}
                </p>
                <p
                  className={classNames(
                    "section-content__description",
                    contentDescriptionStyle
                  )}
                >
                  {data.description2}
                </p>
              </Fragment>
            )}
            <a className="btn btn-primary" href="/">
              {msgLearnMore}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "About Us (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showSubtitle={showSubtitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
