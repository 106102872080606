import { PropTypes } from "prop-types";
import { Fragment } from "react";

// NOTE :

const ContentCounts = (props) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="pe-5 text-center">
        <h3 className="mb-0 fs-2 fw-bold text-primary">
          <span className="aos-init aos-animate">100</span>%
        </h3>
        <p className="text-gray-700 mb-0" fs-5>
          Satisfaction
        </p>
      </div>
      <div className="border-start border-gray-300"></div>
      <div className="px-5 text-center">
        <h3 className="mb-0 fs-2 fw-bold text-primary">
          <span className="aos-init aos-animate">24</span>/
          <span className="aos-init aos-animate">7</span>
        </h3>
        <p className="text-gray-700 mb-0 fs-5">Support</p>
      </div>
      <div className="border-start border-gray-300"></div>
      <div className="ps-5 text-center">
        <h3 className="mb-0 fs-2 fw-bold text-primary">
          <span className="aos-init aos-animate">100</span>
          k+
        </h3>
        <p className="text-gray-700 mb-0 fs-5">Sales</p>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <section className="pt-12 pt-md-13 bg-gray-200">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <img
              src={require("assets/images/feature/illustration-8.webp")}
              alt="..."
              className="img-fluid mb-6 mb-md-0"
            />
          </div>
          <div className="col-12 col-md-7 col-lg-6 order-md-1">
            <h2>
              {data.title}
              <br />
              <span className="text-primary">{data.subtitle}</span>.
            </h2>

            <p className="fs-lg text-gray-700 mb-6">{data.description}</p>

            <ContentCounts />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

/**
 * Component section content "Feature Of Free Help (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  return <SectionContent data={{ ...data }} type={type} />;
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
