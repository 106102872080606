import useMultiStepForm from "components/sections/Form/useMultiStepForm";
import { SITE } from "config/config";
import { dataForm, dataMessages } from "data";
import { Fragment, useEffect, useState } from "react";
import { Button as ButtonBS, Form, Modal, ProgressBar } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Step1, Step2, Step3 } from "./steps";

const msgFinish = "Отправить";
const msgNext = "Далее";
const msgBack = "Назад";
const msgClose = "Закрыть";
const msgOk = "ОК";
const msgSubmit = "Отправить";
const msgSuccess =
  "Благодарим за регистрацию, в ближайшее время менеджер свяжется с вами и согласует время встречи";

const AUTO_CLOSE_TIME = SITE.FORM.AUTO_CLOSE_TIME;

const initState = {
  name: "",
  email: "",
  phone: "",
  description: "",
  file1: [],
  file2: [],
  check1: false,
  check2: false,
  check3: false,
  check4: false,
};

// const INITIAL_DATA = {
//   therapist: undefined,
//   productId: undefined,
//   time: undefined,
//   type: undefined,
// };

/**
 * Component for showing modal form "New Partners" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const ModalFormPartnership = (props) => {
  const {
    onSubmit,
    onError,
    onClose,
    showErrorMessage = true,
    autoClose = true,
  } = props;
  const [initialValues, setInitialValues] = useState(initState);
  const [isAutoClose, setIsAutoClose] = useState(false);
  // const [data, setData] = useState(INITIAL_DATA);

  let autoCloseForm = isAutoClose && autoClose;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    // mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues,
  });

  const STEPS = [
    <Step1 data={dataForm.partners} errors={errors} register={register} />,
    <Step2 data={dataForm.partners} errors={errors} register={register} />,
    <Step3 data={dataForm.partners} errors={errors} register={register} />,
  ];

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    prevStep,
    nextStep,
  } = useMultiStepForm(STEPS);

  const handleFormSubmit = async (data) => {
    // e.preventDefault();
    if (!isLastStep) return nextStep();

    // _debug(data);

    try {
      onSubmit && (await onSubmit(data));
    } catch (err) {
      onError && (await onError(err));

      if (showErrorMessage) toast.error(`${dataMessages.msgError}: ${err}`);

      console.error(err);
    } finally {
      setIsAutoClose(true);
    }
  };

  const handleFormError = async (error) => {
    console.error("Error form submit invalid", error);
    onError && (await onError(error));
  };

  const handleFormClose = async () => {
    onClose && (await onClose());
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const timeoutId =
      autoCloseForm &&
      setTimeout(() => {
        handleFormClose();
      }, AUTO_CLOSE_TIME);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [autoCloseForm]);

  return (
    <Form
      onSubmit={handleSubmit(handleFormSubmit, handleFormError)}
      onReset={reset}
    >
      <div
        style={{
          position: "absolute",
          top: "1.5rem",
          right: "5rem",
        }}
      >
        {currentStepIndex + 1} / {steps.length}
      </div>

      <Fragment>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {dataForm.partners.title}
          </Modal.Title>
        </Modal.Header>
        {isAutoClose ? (
          <Fragment>
            <Modal.Body>
              <p className="lead my-4">{msgSuccess}</p>
            </Modal.Body>
            <Modal.Footer>
              <ButtonBS
                variant="secondary"
                onClick={handleFormClose}
                className=""
              >
                {msgOk}
              </ButtonBS>
            </Modal.Footer>
          </Fragment>
        ) : (
          <Fragment>
            <Modal.Body>
              <div className="mb-3">
                <ProgressBar
                  now={currentStepIndex + 1}
                  max={steps.length}
                  label={`${currentStepIndex + 1}/${steps.length}`}
                />
              </div>
              {step}
            </Modal.Body>
            <Modal.Footer>
              <ButtonBS
                variant="secondary"
                onClick={handleFormClose}
                className=""
                style={{ marginRight: "auto" }}
              >
                {msgClose}
              </ButtonBS>
              <ButtonBS
                type="button"
                variant="secondary"
                disabled={isFirstStep}
                onClick={prevStep}
              >
                {msgBack}
              </ButtonBS>
              <ButtonBS type="submit" variant="primary">
                {isLastStep ? msgFinish : msgNext}
              </ButtonBS>
            </Modal.Footer>
          </Fragment>
        )}
      </Fragment>
    </Form>
  );
};

ModalFormPartnership.propTypes = {};

export default ModalFormPartnership;
