import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Button as BSButton } from "react-bootstrap";

// NOTE :

export const msgCTA = "Заказать";
export const msgSubmitted = "Заказ успешно направлен";
export const msgSubmit = "Заказать";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    innerRef,
    onSubmit,
    ...otherProps
  } = props;
  const isMobile = useIsMobile();

  const handlerSubmit = () => {
    onSubmit && onSubmit();
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-7 col-lg-5 ps-xl-12 ps-10">
          <div className="section-content-title mb-60">
            {showSubtitle && data.subtitle && !isMobile && (
              <span
                className="aos-animate fadeUp"
                {...(useAnimation && {
                  "data-aos": "fade-up",
                  "data-aos-delay": 200,
                })}
              >
                {data.subtitle}
              </span>
            )}
            <h2
              className="mb-3 text-white aos-animate fadeUp"
              {...(useAnimation && {
                "data-aos": "fade-up",
                "data-aos-delay": 400,
              })}
            >
              {data.title}
            </h2>
            {showDescription && (
              <p
                className="text-white aos-animate fadeUp"
                {...(useAnimation && {
                  "data-aos": "fade-up",
                  "data-aos-delay": 600,
                })}
              >
                {data.description}
              </p>
            )}
          </div>
        </div>
        <div className="col-xl-5 col-lg-7">
          <div className="contact-btn text-center text-lg-center">
            <BSButton
              variant="outline-light"
              onClick={handlerSubmit}
              className="btn-outline-light-bg"
              aria-label={data?.msg?.msgCTA || msgCTA}
            >
              {data?.msg?.msgCTA || msgCTA}
            </BSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Call To Action (style 3)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle3 = (props) => {
  const {
    data,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;

  return (
    <div className="container">
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        showDescription={showDescription}
        showSubtitle={showSubtitle}
        innerRef={innerRef}
        {...otherProps}
      />
    </div>
  );
};

SectionContentStyle3.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showSubtitle: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle3;
