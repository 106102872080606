import classNames from "classnames";
import { PageSection } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import {
  SectionContentStyle1,
  SectionContentStyle2,
  SectionContentStyle3,
  SectionContentStyle4,
} from "./components";

import background from "assets/images/bg/cta-bg.webp";

/**
 * Component for showing section "Call To Action" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionCallToAction = (props) => {
  const {
    data,
    id = "call-to-action",
    cn = "",
    type,
    showTitle = true,
    showSubtitle = false,
    showDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);
  const styleAdditional = {
    backgroundImage: data.backgroundImage
      ? `url(${data.backgroundImage})`
      : `url(${background})`,
  };

  return (
    <PageSection
      id={id}
      cn={classNames(prefix, cn, {
        "cta-bg_ img-bg py-8": type === "style3",
      })}
      {...(type === "style3" && { style: styleAdditional })}
      container={false}
    >
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle2
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showSubtitle={showSubtitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        } else if (type === "style3") {
          return (
            <SectionContentStyle3
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showSubtitle={showSubtitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        } else if (type === "style4") {
          return (
            <SectionContentStyle4
              data={data}
              id={id}
              cn={cn}
              type={type}
              showTitle={showTitle}
              showSubtitle={showSubtitle}
              showDescription={showDescription}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            showTitle={showTitle}
            showSubtitle={showSubtitle}
            showDescription={showDescription}
            {...otherProps}
          />
        );
      })()}
    </PageSection>
  );
};

SectionCallToAction.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2", "style3", "style4"]),
  showTitle: PropTypes.bool ?? true,
  showSubtitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? false,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionCallToAction;
