export const APP_URI = process.env.REACT_APP_SRV_BASE_PATH;
export const APP_API_URI = process.env.REACT_APP_SRV_API_PATH;

export const SITE = {
  CAROUSEL_INTERVAL: 100000,
  BLOG: {
    PREVIEW: { MAX_DESCRIPTION: 150 },
    PAGES_LIMIT: 6,
  },
  FORM: {
    AUTO_CLOSE_TIME: 5000,
  },
  URL: "https://psywealth.com",
  NAME: "psywealth.com",
  LOCALE: "ru_RU",
  AUTHOR: "Psywealth, Inc.",
};

export const BLOCK_SPAM_MS = 5000;

export const USE_AOS = true;

export const GA_TRACKING_ON_DEV =
  process.env.REACT_APP_GA_TRACKING_ON_DEV || false;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_CODE;

export const YA_TRACKING_ON_DEV =
  process.env.REACT_APP_YA_TRACKING_ON_DEV || false;
export const YA_TRACKING_ID =
  Number(process.env.REACT_APP_YA_TRACKING_CODE) || -1;

export const BLOG_API_URI = process.env.REACT_APP_BLOG_API_PATH;
export const BLOG_ID = process.env.REACT_APP_BLOG_ID || "none";

export const APP_COOKIE_NAME =
  process.env.REACT_APP_COOKIE_NAME || "_app_cookie.dev";

export const YA_MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY || "";
