import {
  Overview2,
  SectionSlogan,
  SectionSubscribe,
  SectionTopAdvice,
  SectionTopCourses,
  SectionTopPostsLazy,
  SectionTopPsychologists,
  Service,
} from "components/sections";
import AltFeatures from "components/sections/demo/AltFeatures";
import PsySelection from "components/sections/demo/PsySelection";
import ServicesGrid from "components/sections/demo/ServicesGrid";
import SectionFAQ from "components/sections/FAQ/FAQ.section";
import { SectionHero } from "components/sections/Hero";
import { SEO } from "components/utils/Analytics";
import {
  dataAboutMainPage as dataAbout,
  dataAltFeatureMainPage,
  dataBlog,
  dataFAQ,
  dataFeature2MainPage,
  dataHeroMainPage as dataHero,
  dataHomePage,
  dataMobileAppMainPage as dataMobileApp,
  dataOverviewMainPage as dataOverview,
  dataPsySelection,
  dataServiceMainPage as dataService,
  dataSloganMainPage as dataSlogan,
  dataSubscribe,
  dataTopAdviceMainPage as dataTopAdvice,
  dataTopCoursesMainPage as dataTopCourses,
  dataTopPsychologistsMainPage as dataTopPsychologists,
  dataWhatWeDoMainPage,
  ldJson,
} from "data";
import { Fragment, useRef } from "react";
import { Approaches } from "../../sections/Approaches";
import { SectionCallToAction } from "../../sections/CTA2";
import { VideoOfTheDay } from "../../sections/DailyVideos";
import { MobileAppSection } from "../../sections/MobileApp";
import { FeaturePsyFormats } from "../../sections/PsyFormats";
import Tabs from "../../sections/PsyStart/PsyStartTabs";
import SectionAbout from "../_old/SectionAbout";

// NOTE :

/**
 * Component for showing Page "Demo" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageDemo = () => {
  const refCAT = useRef(null);

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataHomePage.meta}>
        {/*Only canonicalise pages which are a duplicate or are near-identical.*/}
        <link rel="canonical" href="https://psywealth.com/demo" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </SEO>
      <main className="page-main" id="top">
        <SectionHero data={dataHero} type="style1" onScroll={executeScroll} />
        {/*<SectionCountUp data={dataCountUpMainPage} />*/}
        <SectionCallToAction data={dataTopPsychologists} type="style1" />
        <SectionCallToAction data={dataTopPsychologists} type="style2" />
        {/* <OurAdvantages data={dataOurAdvantagesMainPage} />*/}
        <SectionTopPsychologists
          data={dataTopPsychologists}
          cn="pt-120 pb-120"
        />
        <Overview2 data={dataOverview} cn="pt-120 pb-120" />
        <FeaturePsyFormats data={dataWhatWeDoMainPage} type="style1" />
        <FeaturePsyFormats data={dataFeature2MainPage} type="style2" />
        <ServicesGrid data={dataService} />
        <PsySelection data={dataPsySelection} />
        <Tabs data={dataTopCourses} />

        <SectionTopCourses data={dataTopCourses} />
        <SectionFAQ data={dataFAQ} />
        <SectionTopPostsLazy data={dataBlog} />
        <SectionSubscribe data={dataSubscribe} type="style3" />
        <h2 className="text-center">ДЕМО СЕКЦИЙ - DEMO</h2>
        <Service data={dataService} type="style2" />
        {/* <CTA data={dataCTALetsContact} innerRef={refCAT} />*/}
        {/*<Testimonial data={dataTestimonial} />*/}
        <Approaches data={dataTopAdvice} />
        <SectionTopAdvice data={dataTopAdvice} />
        <VideoOfTheDay data={dataTopAdvice} />
        <SectionSlogan data={dataSlogan} />
        <MobileAppSection data={dataMobileApp} />
        <AltFeatures data={dataAltFeatureMainPage} />
        <SectionAbout data={dataAbout} />

        {/* <Feature10 data={dataFeatureMainPage} type="style2" />*/}
        {/*<FeatureRound data={dataFeatureMainPage} />*/}
        {/*<Feature99 data={dataFeatureMainPage} type="style2" />*/}
        {/* <About data={dataAbout} type="style2" />*/}
        {/* <FeatureTabs data={dataAbout} />*/}
        {/*  <ServicesInfo data={dataServicesInfoMainPage} />*/}
        {/* <Pricing data={dataPricing} /> */}
        {/* <OurProducts data={dataOurProducts} />*/}

        {/* <OurAdvantages data={dataOurAdvantagesMainPage} />*/}
        {/*  <OurAdvantagesVideo data={dataOurAdvantagesVideoMainPage} />*/}
        {/* <HelpDesk data={dataHelpDesk} type="style1" />*/}
        {/* <CallToAction data={dataCTALetsContact} innerRef={refCAT} />*/}
        {/*<Subscribe data={dataSubscribe} />*/}
        {/*<Subscribe2 data={dataSubscribe} />*/}
        {/*<SectionCountUp data={dataCountUpMainPage} />*/}
        {/*<CallToAction data={dataTopPsychologists} type="style1" />*/}
        {/*<CallToAction data={dataTopPsychologists} type="style2" />*/}
        {/* <OurAdvantages data={dataOurAdvantagesMainPage} />*/}
        {/* <UsedFor data={dataUsedForMainPage} />*/}
        {/*<TestimonialStyle1 data={dataAltFeatureMainPage} />*/}
        {/*<AltFeatures data={dataAltFeatureMainPage} />*/}
        {/*<FeaturePsyFormats data={dataFeatureMainPage} type="style3" />*/}
        {/*<TopPsychologists
          data={dataTopPsychologists}
          cn="pt-120 pb-120"
          type="style2"
        />*/}
        {/*  <ServicesGrid data={dataService} />*/}
        {/* <PsySelection data={dataPsySelection} />*/}
        {/* <MobileAppSection data={dataMobileAppMainPage} />*/}
        {/* <Tabs data={dataTopCourses} />*/}
        {/* <Feature10 data={dataFeatureMainPage} type="style2" />*/}
        {/*<FeatureRound data={dataFeatureMainPage} />*/}
        {/*<Feature99 data={dataFeatureMainPage} type="style2" />*/}
        {/* <About data={dataAbout} type="style2" />*/}
        {/* <FeatureTabs data={dataAbout} />*/}
        {/*  <ServicesInfo data={dataServicesInfoMainPage} />*/}
        {/* <Pricing data={dataPricing} /> */}
        {/* <OurProducts data={dataOurProducts} />*/}
        {/* <TopCourses data={dataTopCourses} />*/}
        {/*  <h2 className="text-center">ДЕМО СЕКЦИЙ - DEMO</h2>*/}
        {/*  <Service data={dataService} type="style2" />*/}
        {/*  <Testimonial data={dataTestimonial} />*/}
        {/*   <Approaches data={dataTopAdvice} />*/}
        {/*  <VideoOfTheDay data={dataTopAdvice} />*/}
        {/*  <SectionSlogan data={dataSlogan} />*/}
        {/*  <MobileAppSection data={dataTopAdvice} />*/}
        {/*  <AltFeatures data={dataAltFeatureMainPage} />*/}
        {/*  <SectionAbout data={dataAbout} />*/}
      </main>
    </Fragment>
  );
};

export default PageDemo;
