import { PropTypes } from "prop-types";
import { Tab, Tabs } from "react-bootstrap";

import { Link } from "react-router-dom";
import "./Section.style.scss";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=50603

const TabPanelItem = (props) => {
  const { data } = props;
  // console.log(typeof data.description);
  // console.log(typeof data.description === "string");
  // console.log("111");

  return (
    <div className="col">
      <i className="d-block h2 text-primary fw-normal pb-2 mb-1"></i>
      <h3 className="h6 mb-2">{data.title}</h3>
      {typeof data.description === "string" ? (
        <p className="fs-sm mb-0">{data.description}</p>
      ) : (
        data.description
      )}
    </div>
  );
};

const TabPanel = (props) => {
  const { data, showTitle, showDescription } = props;

  return (
    <div className="row align-items-lg-center">
      <div className="col-md-6 pb-4 pb-md-0 mb-2 mb-md-0">
        <figure className="d-block h-100 rounded-5 overflow-hidden">
          <img
            className="img-fluid rounded-5"
            src={data.image.src}
            alt={data.image.alt}
          />
        </figure>
      </div>
      <div className="col-md-6 col-lg-5 col-xl-5 offset-lg-1">
        <div className="ps-md-4 ps-lg-0">
          {/* Headers */}
          {data.title && <h2 className="mb-xl-4">{data.title}</h2>}
          {/* {data.description && (
            <p className="pb-3 mb-3 mb-xl-4">{data.description}</p>
          )} */}
          {typeof data.description === "string" ? (
            <p className="pb-3 mb-3 mb-xl-4">{data.description}</p>
          ) : (
            data.description
          )}

          {/* Items */}
          <div className="row row-cols-2 g-4 pb-2 pb-xl-0 mb-4 mb-xl-5">
            {data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => (
                <TabPanelItem data={item} key={index} />
              ))}
          </div>

          {data.control && (
            <Link
              className="btn btn-primary w-100 w-sm-auto"
              to={data.control.link}
            >
              {data.control.msgSubmit}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Feature Tabs (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  const defaultActiveKey = data.items[0].tab.key || "main";

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      className="flex-nowrap overflow-auto text-nowrap w-100 mx-auto pb-3 mb-3 mb-lg-4"
      justify
    >
      {data.items.map((item, index) => (
        <Tab
          eventKey={item.tab.key}
          title={item.tab.title}
          key={index}
          disabled={!item.tab.available}
        >
          <TabPanel
            data={item}
            showTitle={showTitle}
            showDescription={showDescription}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
