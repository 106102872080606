import { CardCourse } from "components/pages/Courses";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import CustomSwiper from "components/core/Swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./TopCourses.scss";

// NOTE :

const msgTitle = "Категории";
const msgNoData = "Нет данных о категориях в блоге";

const SectionGoToCourses = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-6 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/courses"} className="btn btn-primary theme-btn">
              Смотреть все курсы
            </Link>
          </h2>
          <p className="display-30">Смотреть все курсы</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return <Fragment></Fragment>;
};

/**
 * Component section content "Top Courses (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data: courses,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle = true,
    showDescription,
  } = props;

  const items =
    courses &&
    courses.length > 0 &&
    courses.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <CardCourse data={item} index={index} key={index} />
        </SwiperSlide>
      );
    });

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="section-content slider">
            <CustomSwiper renderItems={items} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.array,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
