import { useQuery } from "@tanstack/react-query";
import CourseService from "services/course.service";

export const useGetTopCourses = (params = {}, options = {}) => {
  // console.log("!", params);

  return useQuery({
    queryFn: () => CourseService.getTop(params),
    queryKey: ["course_top", params.pageNo ? params.pageNo : params],
    enabled: typeof params !== typeof undefined,
  });
};
