import classNames from "classnames";
import DOMPurify from "isomorphic-dompurify";

export const CustomHTML = (props) => {
  const { data, className = "" } = props;

  return (
    <div
      className={classNames("", className)}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(data),
      }}
    ></div>
  );
};

export default CustomHTML;
