import { useIsMobile } from "hooks";
import { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";

const MobileContent = (props) => {
  const { formData, register, errors } = props;

  return (
    <Fragment>
      {/* Name*/}
      <Form.Group className="mb-3" controlId="form.ControlInput1">
        <Form.Label>{formData.name.label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={formData.name.placeholder}
          isInvalid={!!errors.name}
          autoFocus
          {...register("name", {
            required: formData.name.required,
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name?.message}
        </Form.Control.Feedback>
      </Form.Group>
      {/* Email */}
      <Form.Group className="mb-3" controlId="form.ControlInput2">
        <Form.Label>{formData.email.label}</Form.Label>

        <Form.Control
          type="email"
          placeholder={formData.email.placeholder}
          isInvalid={!!errors.email}
          {...register("email", {
            required: formData.email.required,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      {/* Phone */}
      <Form.Group className="mb-3" controlId="form.ControlInput3">
        <Form.Label>{formData.phone.label}</Form.Label>

        <Form.Control
          type="phone"
          placeholder={formData.phone.placeholder}
          isInvalid={!!errors.phone}
          {...register("phone", {
            required: formData.phone.required,
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone?.message}
        </Form.Control.Feedback>
      </Form.Group>
      {/* Description */}
      <Form.Group className="mb-3" controlId="form.ControlTextarea">
        <Form.Label>{formData.description.label}</Form.Label>

        <Form.Control
          as="textarea"
          rows={3}
          placeholder={formData.description.placeholder}
          {...register("description", { required: false })}
        />
      </Form.Group>
    </Fragment>
  );
};

/**
 * Component for showing modal form "Step - 1" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const Step1 = (props) => {
  const { data: formData, errors, register } = props;
  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <MobileContent formData={formData} register={register} errors={errors} />
    );

  return (
    <Fragment>
      {/* Name*/}
      <Form.Group as={Row} className="mb-3" controlId="form.ControlInput1">
        <Form.Label column sm="2">
          {formData.name.label}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            placeholder={formData.name.placeholder}
            isInvalid={!!errors.name}
            autoFocus
            {...register("name", {
              required: formData.name.required,
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name?.message}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      {/* Email */}
      <Form.Group as={Row} className="mb-3" controlId="form.ControlInput2">
        <Form.Label column sm="2">
          {formData.email.label}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="email"
            placeholder={formData.email.placeholder}
            isInvalid={!!errors.email}
            {...register("email", {
              required: formData.email.required,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.message}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      {/* Phone */}
      <Form.Group as={Row} className="mb-3" controlId="form.ControlInput3">
        <Form.Label column sm="2">
          {formData.phone.label}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="phone"
            placeholder={formData.phone.placeholder}
            isInvalid={!!errors.phone}
            {...register("phone", {
              required: formData.phone.required,
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone?.message}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      {/* Description */}
      <Form.Group as={Row} className="mb-3" controlId="form.ControlTextarea1">
        <Form.Label column sm="2">
          {formData.description.label}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={formData.description.placeholder}
            {...register("description1", { required: false })}
          />
        </Col>
      </Form.Group>
    </Fragment>
  );
};

Step1.propTypes = {};

export default Step1;
