import { getSocialIconByType } from "components/utils/Social";
import { ExternalLink2 } from "../Utils";

/**
 * Component for showing "Social link block" of the site.
 *
 * @component
 */
const SocialLinks = (props) => {
  const { data } = props;
  //_debug(data);

  const renderSocialLinksItems = (dataLinks) => {
    return (
      <ul className="d-flex justify-content-start">
        {dataLinks
          .filter((item) => item.status === true)
          .map((item, index) => {
            return (
              <li key={index}>
                <ExternalLink2
                  to={item.link}
                  aria-label={`${item.name}`}
                  className="btn-sm.rounded-circle"
                >
                  {getSocialIconByType(item.type)}
                </ExternalLink2>
              </li>
            );
          })}
      </ul>
    );
  };

  if (!data) return null;

  return <div className="social-links">{renderSocialLinksItems(data)}</div>;
};

SocialLinks.defaultProp = {};

export default SocialLinks;
