import React from "react";
import LayoutPsychologistProfile from "./PsychologistProfile.layout";

const PsychologistAvailableTimingsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAvailableTimings")
);
const PsychologistProfileEducationEditLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabEducation/EducationEdit")
);
const PsychologistProfileTabAppointmentsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabAppointments")
);
const PsychologistProfileTabBioLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabBio")
);
const PsychologistProfileTabDeleteLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabDelete")
);
const PsychologistProfileTabEducationLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabEducation/ProfileTabEducation")
);
const PsychologistProfileTabOrdersLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabOrders")
);
const PsychologistProfileTabPaymentsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabPayments")
);
const PsychologistProfileTabProductLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabProduct")
);
const PsychologistProfileTabProfileLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabProfile")
);
const PsychologistProfileTabSettingsLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabSettings")
);
const PsychologistProfileTabWishlistLazy = React.lazy(() =>
  import("./components/ProfileTabs/ProfileTabWishlist")
);

export {
  LayoutPsychologistProfile,
  PsychologistAvailableTimingsLazy,
  PsychologistProfileEducationEditLazy,
  PsychologistProfileTabAppointmentsLazy,
  PsychologistProfileTabBioLazy,
  PsychologistProfileTabDeleteLazy,
  PsychologistProfileTabEducationLazy,
  PsychologistProfileTabOrdersLazy,
  PsychologistProfileTabPaymentsLazy,
  PsychologistProfileTabProductLazy,
  PsychologistProfileTabProfileLazy,
  PsychologistProfileTabSettingsLazy,
  PsychologistProfileTabWishlistLazy,
};
