import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiLoaderAlt } from "react-icons/bi";
import { FaPlay } from "react-icons/fa6";
import ModalVideo from "react-modal-video";

// import "./Section.style.scss";

// NOTE : https://demo.awaikenthemes.com/html-preview/hipno/index.html

const items = [
  { title: "200", subtitle: "k", description: "satisfied clients" },
  { title: "500", subtitle: "+", description: "certified therapists" },
  { title: "899", subtitle: "+", description: "treatment programs" },
];

/**
 * Component section content "What We Do (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);

  const openModal = () => {
    setModal(!modal);
  };

  const ContentItem = (props) => {
    const { data } = props;
    return (
      <div className="video-counter-item">
        <h2>
          <span className="counter">{data.title}</span>
          {data.subtitle}
        </h2>
        <p>{data.description}</p>
      </div>
    );
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="intro-video-box">
            <div className="intro-bg-video">
              {/*<!-- <video autoplay muted loop id="myVideo"><source src="images/hero-bg-video.mp4" type="video/mp4"></video> -->*/}
              <video autoPlay="" muted="" loop="" id="myVideo">
                <source
                  src="https://demo.awaikenthemes.com/assets/videos/intro-bg-video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>

            <div className="video-play-button">
              <a
                href="https://www.youtube.com/watch?v=Y-x0efG1seA"
                className="popup-video"
                data-cursor-text="Play"
                onClick={handleShow}
              >
                <i>
                  <FaPlay />
                </i>
              </a>
            </div>

            <div className="intro-video-counter">
              {items.map((item, index) => (
                <ContentItem data={item} key={index} />
              ))}
            </div>
          </div>

          {/* Modal */}
          <ModalVideo
            custom
            className="react-player"
            autoplay
            isOpen={modal}
            url={"https://www.youtube.com/embed/4UZrsTqkcW4"}
            onClose={() => setModal(false)}
          />

          {false ? (
            <Modal
              show={modal}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                {videoLoading ? (
                  <div className="modal__spinner">
                    <BiLoaderAlt
                      className="modal__spinner-style"
                      fadeIn="none"
                    />
                  </div>
                ) : null}
                <div className="ratio ratio-16x9">
                  <iframe
                    className="modal__video-style"
                    onLoad={spinner}
                    loading="lazy"
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/4UZrsTqkcW4"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </Modal.Body>
            </Modal>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
