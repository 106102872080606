import classNames from "classnames";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";

/**
 * Component "Header" of the site.
 *
 * @component
 * @version 1.0.0
 **/
export const CustomSectionHeader = (props) => {
  const {
    data,
    showDescription,
    showSubtitle,
    className,
    to = "/",
    message = "Все",
    useAnimation,
  } = props;
  const isMobile = useIsMobile();

  return (
    <div className="row section-row align-items-center">
      <div className="col-lg-6 col-md-9">
        <div className={classNames("section-header", className)}>
          {showSubtitle && data.subtitle && !isMobile && (
            <span
              className={classNames("section-header__subtitle", {
                "aos-animate fadeUp": useAnimation,
              })}
              {...(useAnimation && {
                "data-aos": "fade-up",
                "data-aos-delay": 200,
              })}
            >
              {data.subtitle}
            </span>
          )}

          <h2
            className={classNames(
              "section-header__title",
              "h1 pb-2 mb-3 mb-lg-4 mt-md-3 mt-lg-0",
              {
                "aos-animate fadeUp": useAnimation,
                "mb-3": showDescription,
              }
            )}
            {...(useAnimation && {
              "data-aos": "fade-up",
              "data-aos-delay": 400,
            })}
          >
            {data.title}
          </h2>
        </div>
      </div>

      <div className="col-lg-6 col-md-3">
        <div className="text-end">
          <a href={to} className="btn btn-outline-primary">
            {message}
          </a>
        </div>
      </div>
    </div>
  );
};

CustomSectionHeader.propTypes = {
  data: PropTypes.object,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  to: PropTypes.string,
  message: PropTypes.string,
  useAnimation: PropTypes.bool ?? true,
};
