import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorSection from "components/pages/NotFound/components/ErrorSection";
import { Loading } from "components/utils";
import { InitializeGA4, InitializeYA } from "components/utils/Analytics";
import { AuthProvider } from "contexts/AuthProvider";
import { ProductsProvider } from "contexts/ProductProvider";
import { StrictMode, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./AppRoutes";

// // Importing site Fonts
// import "../styles/fonts/Glory/Glory-VariableFont_wght.ttf";
// import "../styles/fonts/Roboto/Roboto-Black.ttf";

// Bootstrap import move to SASS and main.css
// Importing the Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Importing main styl

import { ProfileProvider } from "contexts/ProfileProvider";
import "../styles/css/main.css";
//import "../styles/scss/App.scss";

// Disable i18n
//import "./i18n";

InitializeGA4();
//InitializeYA();

const queryClient = new QueryClient();

const helmetContext = {};

const App = () => {
  //const [loading, setLoading] = useState(false);
  //const deviceType = useDeviceType();

  //_debug(deviceType);

  useEffect(() => {
    //setLoading(true);
    //setTimeout(() => {
    //  setLoading(false);
    //}, 1000);
  }, []);

  //<Provider store={store}></Provider>

  return (
    <StrictMode>
      <InitializeYA />
      <ErrorBoundary fallback={ErrorSection}>
        <HelmetProvider context={helmetContext}>
          <AuthProvider>
            <ProductsProvider>
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <Suspense fallback={<Loading />}>
                    <ProfileProvider>
                      <ToastContainer position="bottom-right" />
                      <AppRoutes />
                    </ProfileProvider>
                  </Suspense>
                </QueryClientProvider>
              </BrowserRouter>
            </ProductsProvider>
          </AuthProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
