import { EventGA } from "components/utils/Analytics";
import { AnalyticsEvent } from "components/utils/Analytics/Analytics";
import { APP_API_URI, BLOG_ID } from "config/config";
import { _debug } from "utils";
import { requestGlobalHeaders, requestGlobalOptions } from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${APP_API_URI}/site/event`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

export const registerOrder = async (data) => {
  const dataExtended = JSON.stringify({ ...data, blogName: BLOG_ID });

  // GA
  EventGA(
    AnalyticsEvent.order.category,
    AnalyticsEvent.order.action,
    AnalyticsEvent.order.label
  );

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(headers),
    body: dataExtended,
  };

  const response = await fetch(
    `${APP_API_URI}/site/event/v1/order`,
    requestOptions
  );

  console.log(`${APP_API_URI}/site/event/v1/order`, response, dataExtended);
  // if (response.ok && (response.status === 200 || response.status === 204)) {
  //   return await response.json();
  // }

  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const registerNewPartner = async (data) => {
  const { file1: dataFile1, file2: dataFile2, ...dataNarrowed } = data;
  const dataJSON = JSON.stringify({ ...dataNarrowed, blogName: BLOG_ID });
  // const { file1: dataFile1, file2: dataFile2, ...dataNarrowed } = dataExtended;
  // console.log("123", dataNarrowed);

  // GA
  EventGA(
    AnalyticsEvent.partner.category,
    AnalyticsEvent.partner.action,
    AnalyticsEvent.partner.label
  );

  const formData = new FormData();

  const appendFiles = (form, files) => {
    if (files instanceof FileList) {
      for (const file of files) {
        form.append("files", file, file?.name);
      }
    } else {
      // console.log(1, files);
      form.append("files", files);
    }
  };

  appendFiles(formData, dataFile1);
  appendFiles(formData, dataFile2);

  // delete data.file1 & data.file2
  formData.append("data", dataJSON);

  // console.log(formData);

  const headers = {
    // ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(headers),
    body: formData,
  };

  const response = await fetch(
    `${APP_API_URI}/site/event/v1/partner`,
    requestOptions
  );

  console.log(`${APP_API_URI}/site/event/v1/partner`, response, dataJSON);
  // if (response.ok && (response.status === 200 || response.status === 204)) {
  //   return await response.json();
  // }

  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const registerSubscription = async (data) => {
  const dataExtended = JSON.stringify({ ...data, blogName: BLOG_ID });

  // GA
  await EventGA(
    AnalyticsEvent.subscribe.category,
    AnalyticsEvent.subscribe.action,
    AnalyticsEvent.subscribe.label
  );

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(headers),
    body: dataExtended,
  };

  const response = await fetch(
    `${APP_API_URI}/site/event/v1/subscribe`,
    requestOptions
  );

  if (response.ok && response.status === 200) {
    return await response.json();
  }
};

export const registerUserContact = async (data) => {
  const bodyExtended = JSON.stringify({ ...data, blogName: BLOG_ID });

  // GA
  EventGA(
    AnalyticsEvent.contact.category,
    AnalyticsEvent.contact.action,
    AnalyticsEvent.contact.label
  );

  const response = await fetch(`${APP_API_URI}/site/event/v1/contact`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    },
    //mode: "no-cors",
    // body: JSON.stringify(data),
    body: bodyExtended,
  });

  return response.json();
};

export const registerFAQQuestion = async (data) => {
  const bodyExtended = JSON.stringify({ ...data, blogName: BLOG_ID });

  // GA
  EventGA(
    AnalyticsEvent.askQuestion.category,
    AnalyticsEvent.askQuestion.action,
    AnalyticsEvent.askQuestion.label
  );

  const response = await fetch(`${APP_API_URI}/site/event/v1/question`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    },
    //mode: "no-cors",
    // body: JSON.stringify(data),
    body: bodyExtended,
  });

  return response.json();
};

const EventService = {
  registerOrder,
  registerNewPartner,
  registerSubscription,
  registerFAQQuestion,
  registerUserContact,
};

export default EventService;
