import classNames from "classnames";
import { CustomSectionHeader } from "components/core/CustomSectionHeader";
import { Spinner } from "components/utils/Spinner";
import { PageSection, SectionHeader } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { dataMessages } from "data";
import { PropTypes } from "prop-types";
import { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { SectionContentStyle1, SectionContentStyle2 } from "./components";
import { useGetTopCourses } from "./utils";

// NOTE :

const msgHeader = "Подробнее";
const msgNoData = "Нет данных о курсах";

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    ...otherProps
  } = props;

  return (
    <Fragment>
      {(() => {
        if (type === "style2") {
          return (
            <SectionContentStyle2
              data={data}
              id={id}
              cn={cn}
              type={type}
              {...otherProps}
            />
          );
        }
        return (
          <SectionContentStyle1
            data={data}
            id={id}
            cn={cn}
            type={type}
            {...otherProps}
          />
        );
      })()}
    </Fragment>
  );
};

/**
 * Component for showing section "Top Courses" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionTopCourses = (props) => {
  const {
    data,
    id = "top-courses",
    cn = "",
    type,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  const {
    data: topCourses,
    isLoading: isLoadingTopCourses,
    isError: isErrorTopCourses,
    error: errorTopCourses,
  } = useGetTopCourses();

  const isLoading = isLoadingTopCourses;
  const isError = isErrorTopCourses;
  const errors = [errorTopCourses];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (topCourses) {
      _debug("TOP COURSES:", topCourses);
    }
  }, [topCourses]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorTopCourses && errorTopCourses?.status === 204) {
    return <div className="error">{msgNoData}</div>;
  }

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      <SectionHeader
        render={() => (
          <CustomSectionHeader
            data={data}
            showSubtitle={showHeaderSubtitle}
            showDescription={showHeaderDescription}
            message={msgHeader}
          />
        )}
      />
      <SectionContent
        data={topCourses}
        id={id}
        cn={cn}
        type={type}
        showTitle={showContentTitle}
        showDescription={showContentDescription}
        {...otherProps}
      />
    </PageSection>
  );
};

SectionTopCourses.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionTopCourses;
