import { Nav } from "react-bootstrap";

/**
 * Component for showing "Footer" of the site.
 *
 * @component
 */

const FooterWidget = (props) => {
  const { title, menu, icon } = props;

  const FooterWidgetMenu = (props) => {
    const { data } = props;

    const renderMenuItems = (menuItems) => {
      return menuItems.map((menuItem, index) => {
        if (!menuItem.path) {
          return null;
        }

        return (
          <li className="links-list-item" key={index}>
            <Nav.Link href={menuItem.path} bsPrefix="links-list-item-link">
              {icon}
              {menuItem.title}
            </Nav.Link>
          </li>
        );
      });
    };

    return (
      <div className="footer-widget-links-list footer-links footer-menu">
        <Nav className="footer-links flex-column" as={"ul"}>
          {renderMenuItems(data)}
        </Nav>
      </div>
    );
  };

  return (
    <div className="footer-widget style2">
      <h4 className="footer-widget-title mb-3">{title}</h4>
      <FooterWidgetMenu data={menu} />
    </div>
  );
};

export default FooterWidget;
