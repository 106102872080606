export const dataMetaBlankPage = {
  title: "Электронный архив «psywealth»",
  description: "",
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [{ name: "robots", content: "noindex, follow" }],
  OG: [
    {
      property: "og:title",
      content: "Электронный архив «psywealth-Архив»",
    },
    {
      property: "og:description",
      content: "",
    },
    {
      property: "og:url",
      content: "https://psywealth.com/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content: "",
    },
    { property: "og:image:alt", content: "«psywealth-Архив»" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "psywealth.com",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
