import { dataContacts } from "data";
import { PropTypes } from "prop-types";
import { registerUserContact } from "services/events.service";
import FormContact from "./FormContact";

// import "./Section.style.scss";

// NOTE :

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const ContactItem = (props) => {
    const { item, index } = props;
    return (
      <div className="col-12 col-md-6 col-xl-12">
        <div className="contact-item">
          <div className="contact-icon">{item.icon}</div>
          <div className="contact-content">
            <h4>{item.title}</h4>
            {item.items.map((item1, index1) => {
              return <p key={index1}>{item1.title}</p>;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-4">
          <div className="contact-item-wrapper">
            <div className="row">
              {dataContacts.items.map((item, index) => (
                <ContactItem item={item} index={index} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          {/*   <div className="contact-form-wrapper">*/}
          {/*  <SectionContentHeader data={data.extraData} id={id} cn={cn} />*/}
          <FormContact onSubmit={registerUserContact} />
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Contacts (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
