import { PropTypes } from "prop-types";

// import "./Section.style.scss";

// NOTE : https://demo.awaikenthemes.com/html-preview/hipno/index.html

const items = [
  {
    title: "Поступление заявки",
    subtitle: "01",
    description:
      "Вам приходит уже оплаченная заявка с собранным анамнезом из ответов на 30 вопросов",
  },
  {
    title: "Знакомство с клиентом и согласование времени сессии",
    subtitle: "02",
    description:
      "Мы рекомендуем психологам и клиентам проводить видеосессию как можно раньше, чтобы нивелировать эффект новизны формата.",
  },
  {
    title: "Проведение сессии и поддержка в чате",
    subtitle: "03",
    description:
      "После проведения сессии клиент будет иметь возможность писать вам о своих мыслях, трудностях и переживаниях на протяжении 5 рабочих дней.",
  },
  {
    title: "Повтор на следующей неделе",
    subtitle: "04",
    description:
      "Клиент автоматически продлевается на следующую неделю до тех пор, пока не отменит подписку. Вы будете сразу же уведомлены об этом.",
  },
];

const ContentItem = (props) => {
  const { data } = props;

  return (
    <div
      className="how-work-step-item wow fadeInUp"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      <div className="how-work-step-no">
        <h4 className="text-white fs-3 mb-0">{data.subtitle}</h4>
      </div>
      <div className="how-work-step-content">
        <h3 className="fs-3">{data.title}</h3>
        <p>{data.description}</p>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="how-work-step-box">
          {items.map((item, index) => (
            <ContentItem data={item} key={index} />
          ))}

          {/* <div
            className="how-work-step-item wow fadeInUp"
            style="visibility: visible; animation-name: fadeInUp;"
          >
            <div className="how-work-step-no">
              <h3>01</h3>
            </div>
            <div className="how-work-step-content">
              <h3>01. book appointment</h3>
              <p>Scheduling appointment with us is simple and</p>
            </div>
          </div>

          <div
            className="how-work-step-item wow fadeInUp"
            data-wow-delay="0.2s"
            style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"
          >
            <div className="how-work-step-no">
              <h3>02</h3>
            </div>
            <div className="how-work-step-content">
              <h3>02. initial consultation</h3>
              <p>Scheduling appointment with us is simple and</p>
            </div>
          </div>

          <div
            className="how-work-step-item wow fadeInUp"
            data-wow-delay="0.4s"
            style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"
          >
            <div className="how-work-step-no">
              <h3>03</h3>
            </div>
            <div className="how-work-step-content">
              <h3>03. progress Check-Ins</h3>
              <p>Scheduling appointment with us is simple and</p>
            </div>
          </div>

          <div
            className="how-work-step-item wow fadeInUp"
            data-wow-delay="0.6s"
            style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;"
          >
            <div className="how-work-step-no">
              <h3>04</h3>
            </div>
            <div className="how-work-step-content">
              <h3>04. ongoing support</h3>
              <p>Scheduling appointment with us is simple and</p>
            </div>
          
        </div>*/}
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "How It Works (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
