import { APP_API_URI } from "config/config";
import { useIsMobile } from "hooks";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { DetailsSection } from "../Details.section";

// NOTE : https://alter.ru/psychologists/kajjani/ivan-gorbachev?position=1&from=search_page&from_link=%2Fpsychologists%3Falter_campaign%3Dlanding-menu

const SectionContent = (props) => {
  const { data, isMobile } = props;

  const getImageURL = (id) => {
    return `${APP_API_URI}/psychologists/psychologist/profile/${id}/image`;
  };

  return (
    <Fragment>
      <div className="d-flex is-align-items-flex-start">
        <div className="header">
          <h1>{data.name}</h1>
          <p className="sub text-3">{data.qualification}</p>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing section "Therapist Profile" of the site.
 *
 * @component
 */
const TherapistInfo = (props) => {
  const { data, id = "therapist-profile", cn = "" } = props;
  const isMobile = useIsMobile(768);

  return (
    <DetailsSection id={id} cn={cn}>
      <div className="container">
        <SectionContent data={data.therapist} isMobile={isMobile} />
      </div>
    </DetailsSection>
  );
};

TherapistInfo.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistInfo;
