import { Fragment } from "react";

export const quiz = {
  topic: "Javascript",
  level: "Beginner",
  totalQuestions: 4,
  perQuestionScore: 5,
  questions: [
    {
      question: "Вопрос 1",
      choices: [
        "Я не чувствую себя расстроенным, печальным",
        "Я расстроен",
        "Я все время расстроен и не могу от этого отключиться",
        "Я настолько расстроен и несчастлив, что не могу это выдержать",
      ],
      type: "MCQs",
      correctAnswer: "All of the above",
    },
    // {
    //   question: "Вопрос 2",
    //   choices: [
    //     "Я не тревожусь о своем будущем",
    //     "Я чувствую, что озадачен будущим",
    //     "Я чувствую, что меня ничего не ждет в будущем",
    //     "Мое будущее безнадежно, и ничто не может измениться к лучшему",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 3",
    //   choices: [
    //     "Я не чувствую себя неудачником",
    //     "Я чувствую, что терпел больше неудач, чем другие люди",
    //     "Когда я оглядываюсь на свою жизнь, я вижу в ней много неудач",
    //     "Я чувствую, что как личность я – полный неудачник",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 4",
    //   choices: [
    //     "Я получаю столько же удовлетворения от жизни, как раньше",
    //     "Я не получаю столько же удовлетворения от жизни, как раньше",
    //     "Я больше не получаю удовлетворения ни от чего",
    //     "Я полностью не удовлетворен жизнью. и мне все надоело",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 5",
    //   choices: [
    //     "Я не чувствую себя в чем-нибудь виноватым",
    //     "Достаточно часто я чувствую себя виноватым",
    //     "Большую часть времени я чувствую себя виноватым",
    //     "Я постоянно испытываю чувство вины",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 6",
    //   choices: [
    //     "Я не чувствую, что могу быть наказанным за что-либо",
    //     "Я чувствую, что могу быть наказан",
    //     "Я ожидаю, что могу быть наказан",
    //     "Я чувствую себя уже наказанным",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 7",
    //   choices: [
    //     "Я не разочаровался в себе",
    //     "Я разочаровался в себе",
    //     "Я себе противен",
    //     "Я себя ненавижу",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 8",
    //   choices: [
    //     "Я знаю, что я не хуже других",
    //     "Я критикую себя за ошибки и слабости",
    //     "Я все время обвиняю себя за свои поступки",
    //     "Я виню себя во всем плохом, что происходит",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 9",
    //   choices: [
    //     "Я никогда не думал покончить с собой",
    //     "Ко мне приходят мысли покончить с собой, но я не буду их осуществлять",
    //     "Я хотел бы покончить с собой",
    //     "Я бы убил себя, если бы представился случай",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 10",
    //   choices: [
    //     "Я плáчу не больше, чем обычно",
    //     "Сейчас я плачу чаще, чем раньше",
    //     "Теперь я все время плачу",
    //     "Раньше я мог плакать, а сейчас не могу, даже если мне хочется",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 11",
    //   choices: [
    //     "Сейчас я раздражителен не более, чем обычно",
    //     "Я легче раздражаюсь, чем раньше",
    //     "Теперь я постоянно чувствую, что раздражен",
    //     "Я стал равнодушен к вещам, которые меня раньше раздражали",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 12",
    //   choices: [
    //     "Я не утратил интереса к другим людям",
    //     "Я меньше интересуюсь другими людьми, чем раньше",
    //     "Я почти потерял интерес к другим людям",
    //     "Я полностью утратил интерес к другим людям",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 13",
    //   choices: [
    //     "Я откладываю принятие решения иногда, как и раньше",
    //     "Я чаще, чем раньше, откладываю принятие решения",
    //     "Мне труднее принимать решения, чем раньше",
    //     "Я больше не могу принимать решения",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 14",
    //   choices: [
    //     "Я не чувствую, что выгляжу хуже, чем обычно",
    //     "Меня тревожит, что я выгляжу старым и непривлекательным",
    //     "Я знаю, что в моей внешности произошли существенные изменения, делающие меня непривлекательным",
    //     "Я знаю, что выгляжу безобразно",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 15",
    //   choices: [
    //     "Я могу работать так же хорошо, как и раньше",
    //     "Мне необходимо сделать дополнительное усилие, чтобы начать делать что-нибудь",
    //     "Я с трудом заставляю себя делать что-либо",
    //     "Я совсем не могу выполнять никакую работу",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 16",
    //   choices: [
    //     "Я сплю так же хорошо, как и раньше",
    //     "Сейчас я сплю хуже, чем раньше",
    //     "Я просыпаюсь на 1-2 часа раньше, и мне трудно заснуть опять",
    //     "Я просыпаюсь на несколько часов раньше обычного и больше не могу заснуть",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 17",
    //   choices: [
    //     "Я устаю не больше, чем обычно",
    //     "Теперь я устаю быстрее, чем раньше",
    //     "Я устаю почти от всего, что я делаю",
    //     "Я не могу ничего делать из-за усталости",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 18",
    //   choices: [
    //     "Мой аппетит не хуже, чем обычно",
    //     "Мой аппетит стал хуже, чем раньше",
    //     "Мой аппетит теперь значительно хуже",
    //     "У меня вообще нет аппетита",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 19",
    //   choices: [
    //     "В последнее время я не похудел или потеря веса была незначительной",
    //     "За последнее время я потерял более 2 кг",
    //     "Я потерял более 5 кг",
    //     "Я потерял более 7 кr",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 20",
    //   choices: [
    //     "Я беспокоюсь о своем здоровье не больше, чем обычно",
    //     "Меня тревожат проблемы моего физического здоровья, такие, как боли, расстройство желудка, запоры и т.д.",
    //     "Я очень обеспокоен своим физическим состоянием, и мне трудно думать о чем-либо другом",
    //     "Я настолько обеспокоен своим физическим состоянием, что больше ни о чем не могу думать",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
    // {
    //   question: "Вопрос 21",
    //   choices: [
    //     "В последнее время я не замечал изменения своего интереса к сексу",
    //     "Меня меньше занимают проблемы секса, чем раньше",
    //     "Сейчас я значительно меньше интересуюсь сексуальными проблемами, чем раньше",
    //     "Я полностью утратил сексуальный интерес",
    //   ],
    //   type: "MCQs",
    //   correctAnswer: "All of the above",
    // },
  ],
};

export const dataQuizResultsTestsPage = {
  title: "Результаты",
  subtitle: "Результаты",
  description: "Результаты",
  content: {
    title: "Помочь интерпретировать результаты?",
    subtitle: "Помочь интерпретировать результаты?",
    description: "Напишите и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt: "" || "",
  },
  items: [
    {
      title: "0-9 баллов",
      description: (
        <Fragment>
          <h6>
            <strong>Отсутствие депрессии</strong>
          </h6>
          <p>
            У Вас отсутствуют симптомы депрессии. Ваше психоэмоциональное
            состояние в норме и не вызывает никаких опасений.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "10-15 баллов",
      description: (
        <Fragment>
          <h6>
            <strong>Легкая депрессия (субдепрессия)</strong>
          </h6>
          <p>
            У Вас наблюдаются признаки легкой депрессии (субдепрессии). Пора
            задуматься о своем психическом здоровье и обратиться за
            консультацией к психотерапевту.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "16-19 баллов",
      description: (
        <Fragment>
          <h6>
            <strong>Умеренная депрессия</strong>
          </h6>
          <p>
            У Вас наблюдаются симптомы умеренной депрессии. Депрессия на данном
            этапе создает определенные трудности для нормальной жизни и опасна
            быстрым переходом на следующую стадию. Необходимо обратиться за
            помощью к психотерапевту.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "20-29 баллов",
      description: (
        <Fragment>
          <h6>
            <strong>Выраженная депрессия</strong>
          </h6>
          <p>
            У Вас наблюдаются признаки выраженной депрессии (средней тяжести).
            Депрессия на этой стадии создает преграды для нормальной жизни и
            легко может перейти в тяжелую депрессию. Такое состояние нельзя
            оставлять без внимания и медицинского контроля, необходимо в
            обязательном порядке обратиться за консультацией к психотерапевту.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "30-63 балла",
      description: (
        <Fragment>
          <h6>
            <strong>Тяжелая депрессия</strong>
          </h6>
          <p>
            У Вас наблюдаются симптомы тяжелой депрессии. Это последняя, самая
            опасная стадия депрессии, с которой не получится справиться в
            одиночку. Необходимо в срочном порядке обратиться за помощью к
            психотерапевту.
          </p>
        </Fragment>
      ),
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};

// {
//   question: "How can a datatype be declared to be a constant type?",
//   choices: ["const", "var", "let", "constant"],
//   type: "MCQs",
//   correctAnswer: "const",
// },

// {
//   question:
//     "Which function is used to serialize an object into a JSON string in Javascript?",
//   choices: ["stringify()", "parse()", "convert()", "None of the above"],
//   type: "MCQs",
//   correctAnswer: "stringify()",
// },
// {
//   question:
//     "Which of the following keywords is used to define a variable in Javascript?",
//   choices: ["var", "let", "var and let", "None of the above"],
//   type: "MCQs",
//   correctAnswer: "var and let",
// },
