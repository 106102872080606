import { SEO } from "components/utils/Analytics";
import { dataCountDownPage } from "data";
import { Fragment } from "react";
import EventService from "services/events.service";
import SectionCountdown from "./components/Countdown.section";

import background from "assets/images/new/coming/lines.webp";
import { PageMain } from "core/components/Section";

/**
 * Component for showing Page "Countdown" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const CountdownPage = (props) => {
  const { id = "countdown" } = props;

  const handleSubmit = async (data) => {
    const response = await EventService.registerSubscription(data);
    console.log(response);
    return response;
  };

  return (
    <Fragment>
      <SEO data={dataCountDownPage.meta}>
        <link rel="canonical" href="https://psywealth.com/countdown" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <div
          className="bg-secondary bg-size-cover bg-position-center vh-100"
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "contain",
          }}
        >
          <SectionCountdown onSubmit={handleSubmit} />
        </div>
      </PageMain>
    </Fragment>
  );
};

export default CountdownPage;
