import { PropTypes } from "prop-types";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";

// Import Swiper styles
import { Fragment } from "react";
import "swiper/css";
import "swiper/css/pagination";

import "./Section.style.scss";

// NOTE :

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const ContentItem = (props) => {
    const { data } = props;

    // console.log("object");

    return (
      <Fragment>
        {/* <span className="ratings five mb-3"></span> */}
        <div className="text-center">
          <blockquote className="d-block mb-0 border-0 fs-lg">
            <p className="lead p-large mb-3">{data.description}</p>
            <div className="blockquote-details justify-content-center text-center">
              <div className="info ps-0">
                <h5 className="mb-2">{data.title}</h5>
                <p className="mb-0 fst-italic">{data.subtitle}</p>
              </div>
            </div>
          </blockquote>
        </div>
      </Fragment>
    );
  };

  const items =
    data.items.length > 0 &&
    data.items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <ContentItem data={item} key={index} />
        </SwiperSlide>
      );
    });

  return (
    <div className="row position-relative">
      <figure
        className="rounded position-absolute d-none d-lg-block rounded-5"
        style={{
          top: "50%",
          right: "0",
          width: "45%",
          height: "auto",
          transform: "translateY(-50%)",
          zIndex: "2",
        }}
      >
        <img
          src={require("assets/images/new/tei1.webp")}
          srcSet="assets/images/new/tei1@2x.webp 2x"
          alt=""
        />
      </figure>
      <div className="col-lg-9 rounded-5 text-center">
        <div className="card bg-secondary">
          <div className="card-body p-5 p-lg-8 py-xxl-12">
            <div className="row gx-0">
              {/* <div className="col-12 col-md-10 ps-xl-10">  */}
              <div className="col-12 col-md-10">
                <div className="slider">
                  <Swiper
                    className="swiper"
                    slidesPerView={1}
                    // spaceBetween={24}
                    // autoHeight={false}
                    navigation={false}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    modules={[Pagination]}
                  >
                    {items}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "Testimonial (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
