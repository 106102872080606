import React from "react";
import {
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKShareButton,
} from "react-share";

const ShareButtons = () => {
  const shareUrl = "https://psywealth.com/";
  const title = "Check out this awesome website!";

  return (
    <div>
      <VKShareButton url={shareUrl} title={title}>
        <LinkedinIcon size={32} round />
      </VKShareButton>
      <FacebookShareButton url={shareUrl} title={title}>
        <LinkedinIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} title={title}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </div>
  );
};

export default ShareButtons;
