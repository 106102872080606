import { SEO } from "components/utils/Analytics";
import { Spinner } from "components/utils/Spinner";
import { BLOG_API_URI, BLOG_ID } from "config/config";
import { dataBlogPage, dataMessages } from "data";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import SinglePost from "./components/BlogSinglePost";
import { useGetMetaBlogPost, useGetSingleBlogPostBySlug } from "./utils";

const BlogSinglePostPage = (props) => {
  const { id = "blog", cn = "pt-130" } = props;
  const { slug } = useParams();
  const URL = `${BLOG_API_URI}/posts/v2/${slug}?blog=${BLOG_ID}`;
  // const {
  //   data: postData,
  //   isLoading,
  //   updateUrl,
  //   hasError,
  //   errorMessage,
  // } = useFetch(URL);
  //let [htmlFileString, setHtmlFileString] = useState();
  //let [postData, setPostData] = useState();
  const [meta, setMeta] = useState({});
  const {
    data: postData,
    isLoading: isLoadingPostData,
    isError: isErrorPostData,
    error: errorPostData,
  } = useGetSingleBlogPostBySlug(slug);

  const {
    data: postMetadata,
    isLoading: isLoadingPostMetadata,
    isError: isErrorPostMetadata,
    error: errorPostMetadata,
  } = useGetMetaBlogPost(slug);

  const isLoading = isLoadingPostData || isLoadingPostMetadata;
  const isError = isErrorPostData || isErrorPostMetadata;
  const errors = [errorPostData, errorPostMetadata];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (postData) {
      _debug("POST:", postData);
    }
  }, [postData]);

  useEffect(() => {
    const createMeta = async () => {
      try {
        const metadataObj = postMetadata?.toHashMap("type");
        // 1. check data valid
        // const metaLD = JSON.stringify(metadataObj?.LD.content);
        const metaMain = JSON.parse(metadataObj?.Main.content);
        const metaOG = JSON.parse(metadataObj?.OG.content);
        const metaTW = JSON.parse(metadataObj?.TW.content);
        console.log(metaMain, metaOG);

        const metaNew = { ...dataBlogPage.meta, ...metaMain };

        // if (meta.OG && meta.OG.length > 0)
        // metaNew.OG = [...metaNew.OG, ...metaOG];
        const map1 = new Map(
          [...metaNew.OG, ...metaOG].map((obj) => [obj.property, obj])
        );
        metaNew.OG = Array.from(map1.values());

        // if (meta.TW && meta.TW.length > 0)
        // metaNew.TW = [...metaNew.TW, ...metaTW];
        const map2 = new Map(
          [...metaNew.TW, ...metaTW].map((obj) => [obj.property, obj])
        );
        metaNew.TW = Array.from(map2.values());

        _debug("METADATA FULL:", metaNew);
        setMeta(metaNew);
      } catch (err) {
        console.error(err);
        setMeta({});
      }
    };

    if (postMetadata) {
      _debug("METADATA:", postMetadata);

      createMeta();
    }
  }, [postMetadata]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorPostData && errorPostData?.status === 204) {
    return <div className="error">Нет данных о посте</div>;
  }

  return (
    <Fragment>
      <SEO data={meta}>
        <link rel="canonical" href={`https://psywealth.com/blog/${slug}`} />
        {/* {meta && meta.LD && (
          <script type="application/ld+json">{meta.LD}</script>
        )} */}
      </SEO>
      <div className="single-posts-wrapper">
        <SinglePost data={postData} />
      </div>
    </Fragment>
  );
};

export default BlogSinglePostPage;
