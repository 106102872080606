import { PropTypes } from "prop-types";
import { PageSection, SectionHeader } from "../../sections/CommonSection";

import "./AltFeatures.scss";

const SectionContent = (props) => {
  const { data, showSubtitle, showDescription, useAnimation } = props;

  const ContentItem = (props) => {
    const { data } = props;
    return (
      <div className="col-md-6 icon-box">
        {data.icon && <i>{data.icon}</i>}
        <div>
          <h4>{data.title}</h4>
          <p>{data.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="row gy-5">
      <div className="col-xl-7 d-flex order-2 order-xl-1">
        <div className="row align-self-center gy-5">
          {data.items &&
            data.items.map((item, index) => (
              <ContentItem data={item} key={index} />
            ))}
        </div>
      </div>

      <div
        className="col-xl-5 d-flex align-items-center order-1 order-xl-2 aos-init aos-animate"
        {...(useAnimation && {
          "data-aos": "fade-up",
          "data-aos-delay": 400,
        })}
      >
        <img
          src={require("assets/images/feature/alt-features.webp")}
          className="img-fluid"
          alt=""
        />
      </div>
    </div>
  );
};

/**
 * Component for showing section "Alt Features" of the site.
 *
 * @component
 */
const AltFeatures = (props) => {
  const {
    data,
    id = "alt-features",
    cn = "pt-130 pb-80",
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showTitle = true,
    showDescription = true,
    useAnimation = false,
  } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionHeader
          data={data}
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
        <SectionContent
          data={data}
          showTitle={showTitle}
          showDescription={showDescription}
          useAnimation={useAnimation}
        />
      </div>
    </PageSection>
  );
};

AltFeatures.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showHeaderSubtitle: PropTypes.bool ?? false,
  showHeaderDescription: PropTypes.bool ?? false,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default AltFeatures;
