import { SectionFAQLazy, SectionSubscribeLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataFAQ, dataFAQPage, dataSubscribe } from "data";
import { Fragment } from "react";

/**
 * Component for showing Page "FAQ" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageFAQ = (props) => {
  const { id = "faq" } = props;

  return (
    <Fragment>
      <SEO data={dataFAQPage.meta}>
        <link rel="canonical" href="https://psywealth.com/faq" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataFAQPage} />
        <SectionFAQLazy data={dataFAQ} type={"style3"} />
        {/* <SectionFAQ data={dataFAQ} type={"style2"} /> */}
        {/* <SectionFAQ data={dataFAQ} type={"style1"} /> */}
        {/* <SectionAskQuestionLazy data={dataAskQuestion} /> */}
        {/* <SectionSubscribeLazy data={dataSubscribe} type="style3" /> */}
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
      </PageMain>
    </Fragment>
  );
};

export default PageFAQ;
