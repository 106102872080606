import { BLOG_API_URI, BLOG_ID } from "config/config";
import { dataForm } from "data/dataSections";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const POST_URL = `${BLOG_API_URI}/posts`;

/**
 * Component for showing Component "Post button" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostButton = (props) => {
  const { data } = props;

  // console.log(data);

  return (
    <Link to={`/blog/${data.slug}?blog=${BLOG_ID}`} className="btn-link">
      <span className="btn_text"> {dataForm.msgReadMore}</span>
      <span className="btn_icon">
        <AiOutlineArrowRight />
      </span>
    </Link>
  );
};

export default BlogPostButton;
