import classNames from "classnames";
import ProgressiveImg2 from "components/utils/Image/ProgressiveImg2";
import { APP_API_URI } from "config/config";
import { PropTypes } from "prop-types";
import { BsPatchCheckFill, BsStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import placeholderSrc from "assets/images/hello-world.webp";

const CardImage = (props) => {
  const { data } = props;

  const getImageURL = (imageId) => {
    return `${APP_API_URI}/psychologists/psychologist/profile/${imageId}/image`;
  };

  return (
    <ProgressiveImg2
      hightSrc={
        data.id
          ? getImageURL(data.id)
          : "https://www.bootdey.com/image/380x380/00FFFF/000000"
      }
      lowSrc={placeholderSrc}
      alt={data?.imageAlt || data.title}
      width="160"
      height="160"
      loading="lazy"
      className="img-fluid w-100 rounded-5"
    />
  );
};

const CardLink = (props) => {
  const { slug, cn, children } = props;
  return (
    <Link className={classNames(cn, "w-100")} to={`/therapists/${slug}`}>
      {children}
    </Link>
  );
};

const CardTherapist2 = (props) => {
  const { data } = props;
  // console.log(props);

  return (
    <div className="rounded-5 card-bordered card-lift card">
      <div className="p-3 d-flex flex-column gap-3">
        <div className="position-relative">
          <CardLink slug={data.slug}>
            <CardImage data={data} />
            {/* <img
                          src="/static/media/mentor-img-7.272729140e1fb31da253.jpg"
                          alt="mentor 1"
                          className="img-fluid w-100 rounded-4"
                        />*/}
          </CardLink>
          <div className="position-absolute bottom-0 left-0 p-3">
            <span className="badge text-bg-success">Новый</span>
          </div>
        </div>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <div>
              <div className="d-flex align-items-center gap-2">
                <h3 className="mb-0">
                  <CardLink className="text-reset" slug={data.slug}>
                    {data.name}
                  </CardLink>
                </h3>
                <span>
                  <BsPatchCheckFill className="text-success" />
                </span>
              </div>
              <span className="text-gray-800">{data.qualification}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between fs-6">
              <div>
                {/* <span>@ Microsoft</span>
                <div className="vr mx-2 text-gray-200"></div> */}
                <span>{data.experience}</span>
              </div>
              <div className="d-flex gap-1 align-items-center lh-1">
                <BsStarFill className="text-warning" />
                <span className="fw-bold text-dark">5</span>
                <span>(Нет обращений)</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            {/* <div>
              <span>Starting from</span>
              <div className="d-flex flex-row gap-1 align-items-center">
                <h4 className="mb-0">$35.00</h4>
                <span className="fs-6">/ Month</span>
              </div>
            </div> */}
            <div>
              <CardLink slug={data.slug} className="btn btn-outline-primary">
                Обратиться
              </CardLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardTherapist2.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.oneOf(["style1", "style2", "style3"]),
};

PropTypes.defaultProp = {};

export default CardTherapist2;
