import { PropTypes } from "prop-types";

import { Image } from "components/utils/Image";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Section.style.scss";

// NOTE : https://html.theme-village.com/helpplus/index.html

const msgCTAAction = "Получить";

const SectionItem = (props) => {
  const { data } = props;
  return (
    <div className="col-xl-4 col-md-4">
      <div className="d-flex feature-item">
        <span className="icon shadow">
          <img src="assets/images/new/banner/mis1.webp" alt="" />
        </span>
        <div className="feat-txt ms-4">
          <h3>Become a volunteer</h3>
          <p>
            We appricitate every efforts. Reiciendis inventore, quasi rem eum
            velit exercitationem!
          </p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return (
    <div className="row g-lg-5 g-2">
      <div className="col-xl-4 col-md-4">
        <div className="d-flex feature-item">
          <span className="icon shadow">
            <img src="images/components/mis1.png" alt="" />
          </span>
          <div className="feat-txt ms-4">
            <h3>Become a volunteer</h3>
            <p>
              We appricitate every efforts. Reiciendis inventore, quasi rem eum
              velit exercitationem!
            </p>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-md-4">
        <div className="d-flex feature-item">
          <span className="icon shadow">
            <img src="images/components/mis2.png" alt="" />
          </span>
          <div className="feat-txt ms-4">
            <h3>Helping each Other</h3>
            <p>
              We appricitate every efforts. Reiciendis inventore, quasi rem eum
              velit exercitationem!
            </p>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-md-4">
        <div className="d-flex feature-item">
          <span className="icon shadow">
            <img src="images/components/donate.png" alt="" />
          </span>
          <div className="feat-txt ms-4">
            <h3>Start Donating</h3>
            <p>
              We appricitate every efforts. Reiciendis inventore, quasi rem eum
              velit exercitationem!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionContent2 = (props) => {
  const { data } = props;

  return (
    <div className="row position-relative z-2">
      <div className="col-md-8 col-lg-8 offset-lg-1">
        <div className="text-center text-md-start py-5 px-4 px-sm-5 pe-md-0 ps-lg-4 ps-xl-5">
          {/* Subtitle */}
          {data.subtitle && (
            <div className="d-flex align-items-center justify-content-center justify-content-md-start align-items-center mb-2">
              <span
                className="bg-white opacity-70 me-2 d-none d-md-block"
                style={{ width: "40px", height: "1px", marginTop: "-1px" }}
              ></span>
              <span className="fs-xs fw-semibold text-white opacity-70 text-uppercase">
                {data.subtitle}
              </span>
            </div>
          )}

          {/* Title */}
          <h2 className="h1 text-white pb-2 pb-sm-3">{data.title}</h2>

          {/* Description */}
          {data.description1 && data.description2 ? (
            <Fragment>
              <p className="text-white fs-4 mb-1">{data.description1}</p>
              <p className="text-white fs-4 ">{data.description2}</p>
            </Fragment>
          ) : (
            <p className="text-white fs-4">{data.description1}</p>
          )}

          {/* Link */}
          <Link
            className="btn btn-outline-light fs-base"
            to="/free/psychological-help"
          >
            {msgCTAAction}
          </Link>
        </div>
      </div>
      <div className="col-md-4">
        {/* <img
        className="d-block mx-auto mx-md-0 mt-n4 mt-md-0"
        src={require("assets/images/new/cta/image.webp")}
        width="534"
        alt="some text"
      /> */}
        <div className="position-absolute" style={{ right: "0", bottom: "0" }}>
          <Image image={data?.image} className="" width="100" />
        </div>
      </div>
    </div>
  );
};

/**
 * Component section content "About Us (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const { data, id = "", cn = "", type, showTitle, showDescription } = props;

  return (
    <div className="container">
      <SectionContent2
        data={data}
        showTitle={showTitle}
        showDescription={showDescription}
      />
    </div>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
