import { Fragment } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import InputGroupError from "./InputGroupError";

export const InputFloatingLabelPhone = (props) => {
  const {
    label,
    placeholder,
    required,
    register,
    errors,
    control,
    ...otherProps
  } = props;

  return (
    <FloatingLabel>
      <Form.Control
        type="tel"
        name="phone"
        placeholder={placeholder}
        isInvalid={!!errors.phone}
        {...register("phone", {
          required: required,
          validate: {
            maxLength: (v) =>
              v.length <= 11 ||
              "Телефонный номер должен содержать не более 10 символов",
          },
        })}
        {...otherProps}
        maxLength={11}
        aria-invalid={errors?.phone ? "true" : "false"}
        aria-describedby="phoneHelpBlock"
      />
      <Form.Label>{label || "Phone"}</Form.Label>
      {errors.phone && (
        <Form.Control.Feedback type="invalid">
          <InputGroupError message={errors.phone.message} />
        </Form.Control.Feedback>
      )}
    </FloatingLabel>
  );
};

const InputGroupPhone = (props) => {
  const {
    label,
    placeholder,
    help,
    required,
    register,
    errors,
    id,
    ...otherProps
  } = props;

  return (
    <Form.Group controlId={id || "formPhone"}>
      <InputFloatingLabelPhone
        label={label}
        placeholder={placeholder}
        required={required}
        register={register}
        errors={errors}
        {...otherProps}
      />
      {help && (
        <Form.Text id={id + " HelpBlock" || "formPhoneHelpBlock"} muted>
          {help}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export const InputLabeledPhone = (props) => {
  const {
    label,
    placeholder,
    required,
    register,
    errors,
    control,
    ...otherProps
  } = props;

  return (
    <Fragment>
      <Form.Label>{label || "Phone"}</Form.Label>
      <Form.Control
        type="tel"
        name="phone"
        placeholder={placeholder}
        isInvalid={!!errors.phone}
        {...register("phone", {
          required: required,
          validate: {
            maxLength: (v) =>
              v.length <= 11 ||
              "Телефонный номер должен содержать не более 10 символов",
          },
        })}
        {...otherProps}
        maxLength={11}
        aria-invalid={errors?.phone ? "true" : "false"}
        aria-describedby="phoneHelpBlock"
      />
      {errors.phone && (
        <Form.Control.Feedback type="invalid">
          <InputGroupError message={errors.phone.message} />
        </Form.Control.Feedback>
      )}
    </Fragment>
  );
};

export default InputGroupPhone;
