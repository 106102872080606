import classNames from "classnames";
import { PageSection, SectionHeader } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";

const msgGetStarted = "Заказать";

// const dataSources = [
//   "https://global-uploads.webflow.com/62efc7cb58ad153bfb146988/63455a67996ba248148c4e31_add-options%20(3)-poster-00001.jpg",
//   "https://global-uploads.webflow.com/62efc7cb58ad153bfb146988/63413ff244f1dc616b7148a0_Mco-poster-00001.jpg",
// ];

// const DEFAULT_DATA_SOURCE = dataSources[0];
const Y_OFFSET_DIFFERENCE = 1000;

const SectionContent = (props) => {
  const { data } = props;
  const { images: dataSources } = data;
  const [dataSource, setDataSource] = useState(dataSources[0]);

  const SectionItem = (props) => {
    const { data } = props;

    const stepNumber = data.index + 1;

    return (
      <div className="card border p-4 p-sm-5 mb-5">
        <div className="card-body p-0">
          <div className="item">
            <span className="step text-2">{`Шаг ${stepNumber}`} </span>
            <span className="number">{stepNumber}</span>
          </div>

          <div className="content">
            <h3 className="mb-3 item_title">{data?.title}</h3>
            <p className="mb-0">{data?.description}</p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY || window.pageYOffset;
      const index =
        Math.floor(position / Y_OFFSET_DIFFERENCE) % dataSources.length;

      const selectedSource = dataSources[index];
      if (selectedSource === dataSource) {
        return;
      }
      setDataSource(selectedSource);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dataSources]);

  return (
    <div className="row d-flex align-items-start mb-19">
      {/* Image */}
      <div className="col-lg-5 mb-5 position-lg-sticky" style={{ top: "8rem" }}>
        <figure>
          {/* Image  <img src={Image1} alt="" />*/}
          {dataSource.image && (
            <img src={dataSource.image.src} alt={dataSource.image.alt} />
          )}
        </figure>
      </div>

      {/* Description */}
      <div className="col-lg-6 ms-auto">
        {data.items &&
          data.items.length > 0 &&
          data.items.map((item, index) => (
            <SectionItem key={index} index={index} data={item} />
          ))}
      </div>
    </div>
  );
};

/**
 * Component for showing section "Used For" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionUsedFor = (props) => {
  const {
    data,
    id = "used-for",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentDescription = true,
    useAnimation = false,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      <SectionHeader
        data={data}
        className=""
        showSubtitle={showHeaderSubtitle}
        showDescription={showHeaderDescription}
      />
      <SectionContent data={data} isMobile={false} />
    </PageSection>
  );
};

SectionUsedFor.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1"]),
  showHeader: PropTypes.bool ?? false,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionUsedFor;
