const dataMetaBlogPage_Title =
  "Блог ментального здоровья и психологической помощи | Psywealth";
const dataMetaBlogPage_Description =
  "Блог ментального здоровья и психологической помощи | Psywealth";

const dataMetaBlogPage_Image =
  "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg";
const dataMetaBlogPage_CreateDate = "2023-10-01T07:00:00+00:00";
const dataMetaBlogPage_UpdateDate = "2024-12-10T07:59:00+00:00";

export const dataMetaBlogPage = {
  title: "Блог ментального здоровья и психологической помощи | Psywealth",
  description: `Блог ментального здоровья и психологической помощи | Psywealth`,
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: dataMetaBlogPage_Title,
    },
    {
      property: "og:description",
      content: dataMetaBlogPage_Description,
    },
    {
      property: "og:site_name",
      content: dataMetaBlogPage_Title,
    },
    {
      property: "og:url",
      content: "https://psywealth.com/blog/",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaBlogPage_Image,
    },
    {
      property: "og:image:alt",
      content:
        "Сервис онлайн-сессий с психологами - чат, видео, аудио - «PsyWealth.com»",
    },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "article:published_time",
      content: dataMetaBlogPage_CreateDate,
    },
    {
      property: "article:modified_time",
      content: dataMetaBlogPage_UpdateDate,
    },
  ],
  TW: [
    {
      property: "twitter:title",
      content: dataMetaBlogPage_Title,
    },
    {
      property: "twitter:description",
      content: dataMetaBlogPage_Description,
    },
    {
      property: "twitter:image",
      content: dataMetaBlogPage_Image,
    },
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:label1", content: "" },
    { property: "twitter:data1", content: "" },
  ],
};
