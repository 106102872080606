import { ContentSiteLink } from "components/utils/Utils";

export const PolicyTerms = () => {
  return (
    <div className="content">
      <p className="subtitle">Последнее обновление: 10.07.2023 г.</p>
      <p>
        В настоящей Политике конфиденциальности описаны правила обработки данных
        (в том числе персональных данных) пользователей сервиса «PsyWealth.com»,
        размещенного в сети по адресу <ContentSiteLink />
        (далее также – Сервис, Сервис «PsyWealth.com», «PsyWealth»).
      </p>
      <p>
        Оператор стремится обеспечивать максимальную конфиденциальность данных
        своих пользователей. Оператор будет использовать данные пользователей
        только в соответствии положениями Политики конфиденциальности и
        Пользовательского соглашения сервиса «PsyWealth.com».
      </p>
      <p>
        Оператором ваших данных является: Индивидуальный предприниматель Ловков
        Александр Вячеславович, ОГРНИП: 323695200000413, ИНН: 690405626350
        (далее – Оператор).
      </p>
      <h3 className="content-title">ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
      <p>
        <strong>Персональные данные</strong> - любая информация, относящаяся к
        прямо или косвенно определенному, или определяемому физическому лицу
        (субъекту персональных данных).
      </p>
      <p>
        <strong>Обработка персональных данных</strong> – обработка персональных
        данных - любое действие (операция) или совокупность действий (операций),
        совершаемых с использованием средств автоматизации или без использования
        таких средств с персональными данными, включая сбор, запись,
        систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, передачу (распространение, предоставление,
        доступ), обезличивание, блокирование, удаление, уничтожение персональных
        данных.
      </p>
      <p>
        <strong>Оператор персональных данных</strong> - государственный орган,
        муниципальный орган, юридическое лицо или физическое лицо,
        самостоятельно или совместно с другими лицами организующие и (или)
        осуществляющие обработку персональных данных, а также определяющие цели
        обработки персональных данных, состав персональных данных, подлежащих
        обработке, действия (операции), совершаемые с персональными данными.
      </p>
      <p>
        <strong>Обработчик персональных данных</strong> – лицо, осуществляющее
        обработку персональные данных в интересах и по поручению оператора
        персональных данных;
      </p>
      <p>
        <strong>Сервис, Сервис «PsyWealth.com»</strong> – программный комплекс,
        размещенный на сайте по адресу <ContentSiteLink />, позволяющий его
        пользователям создавать квизы для своих сайтов и сервисов.
      </p>
      <p>
        <strong>Квиз</strong> – маркетинговые опросы, создаваемые для уточнения
        потребностей клиентов сайта Заказчика и ознакомления их с ассортиментом
        предлагаемым Заказчиком товаров, работ, услуг, а также для получения
        контактной информации клиентов, и размещаемые на сайте Заказчика.
      </p>
      <p>
        <strong>Посетитель</strong> – лицо, посещающее сайт «PsyWealth.com» без
        регистрации (создания учетной записи) в Сервисе.
      </p>
      <p>
        <strong>Заказчик</strong> – зарегистрированный пользователь Сервиса
        «PsyWealth.com», использующий Сервис для создания Квизов.
      </p>
      <p>
        <strong>Сайт Заказчика</strong> – сайт в сети интернет, на котором
        Заказчик размещает квиз, созданный им посредством Сервиса
        «PsyWealth.com».
      </p>
      <p>
        <strong>Пользователь квиза</strong> – физическое лицо, использующее
        Квиз, созданный Заказчиком посредством Сервиса «PsyWealth.com», и
        предоставляющее через Квиз свои данные.
      </p>
      <h3 className="content-title">1. ОБРАБАТЫВАЕМЫЕ ДАННЫЕ</h3>
      <p>
        При использовании Сервиса «PsyWealth.com» Оператор может обрабатывать
        следующие данные:
      </p>
      <p>
        1.1. при регистрации Заказчика (создании учётной записи) в Сервисе:{" "}
      </p>
      <ul>
        <li>адрес электронной почты;</li>
        <li>Telegram ID.</li>
      </ul>
      <p>
        1.2. при заполнении Заказчиком личных данных в личном кабинете Сервиса:
      </p>
      <ul>
        <li>имя;</li>
        <li>адрес электронной почты;</li>
        <li>номер телефона;</li>
        <li>Telegram ID;</li>
        <li>должность.</li>
      </ul>
      <p>
        1.3. при заполнении Квизов Пользователями Квизов – любые данные
        Пользователей Квизов, собираемые Заказчиком посредством Квизов, в том
        числе могут быть: фамилия, имя, отчество; пол; возраст; дата рождения;
        место рождения; гражданство; адрес проживания; адрес электронной почты;
        номер телефона; ID в соцсетях; сведения о доходе; потребительские
        предпочтения и другие данные, за исключением: биометрических
        персональных данных; специальных персональных данных (данных, касающихся
        расовой, национальной принадлежности, политических взглядов, религиозных
        или философских убеждений, состояния здоровья, интимной жизни); сведений
        о судимости; данных о банковских картах и иных платежных средствах.
      </p>
      <p>
        1.4. при обработке файлов "cookies” и использовании аналогичных
        технологий:
      </p>
      <ul>
        <li>IP-адрес; </li>
        <li>файлы “cookies” и аналогичные технологии; </li>
        <li>данные об использовании Пользователем Сервиса; </li>
        <li>информация о местоположении подключения к Сервису.</li>
      </ul>
      <h3 className="content-title">2. ЦЕЛИ И УСЛОВИЯ ОБРАБОТКИ ДАННЫХ</h3>
      <p>2.1. Оператор обрабатывает данные в целях: </p>
      <ul>
        <li>
          предоставления доступа к Сервису «PsyWealth.com» и услугам Сервиса
          «PsyWealth.com»;
        </li>
        <li>оплаты Заказчиками услуг Сервиса «PsyWealth.com»;</li>
        <li>
          направления Заказчикам информационных сообщений о работе Сервиса
          «PsyWealth.com»;
        </li>
        <li>
          исполнения поручений Заказчиков на обработку персональных данных
          Пользователей Квизов (для случаев, когда Оператором персональных
          данных выступает Заказчик, а Оператор действует в качестве обработчика
          на основании поручения на обработку персональных данных);
        </li>
        <li>
          обеспечения коммуникации и взаимодействия между Заказчиками и
          Пользователями Квизов, направления Пользователем Квизов информационных
          и рекламных сообщений, продвижения товаров, работ, услуг партнеров
          Оператора (Заказчиков);
        </li>
        <li>
          проведение аналитических, маркетинговых, статистических и иных
          исследований использования Сервиса для оптимизации его работы,
          исправления ошибок и сбоев в работе Сервиса, развития продуктов и
          услуг Оператора и его партнеров.
        </li>
      </ul>
      <p>
        2.2. Оператор может обрабатывать следующие данные в целях предоставления
        доступа к Сервису «PsyWealth.com» и услугам Сервиса «PsyWealth.com»:
      </p>
      <ul>
        <li>субъекты (владелец) персональных данных: Заказчики;</li>
        <li>категории обрабатываемых данных: иные;</li>
        <li>
          перечень обрабатываемых данных: имя; адрес электронной почты; номер
          телефона; Telegram ID; должность;
        </li>
        <li>
          срок обработки данных: до момента достижения целей обработки или
          отзыва согласия на обработку, но не более 3 (трех) лет с момента
          удаления учетной записи Заказчика в Сервисе.
        </li>
      </ul>
      <p>
        2.3. Оператор может обрабатывать следующие данные в целях оплаты
        Заказчиками услуг Сервиса «PsyWealth.com»:
      </p>
      <ul>
        <li>субъекты (владелец) персональных данных: Заказчики;</li>
        <li>категории обрабатываемых данных: иные;</li>
        <li>перечень обрабатываемых данных: фамилия, имя; </li>
        <li>
          срок обработки данных: до момента достижения целей обработки или
          отзыва согласия на обработку, но не более 3 (трех) лет с момента
          удаления учетной записи Заказчика в Сервисе.
        </li>
      </ul>
      <p>
        2.4. Оператор может обрабатывать следующие данные в целях направления
        информационных сообщений о работе Сервиса «PsyWealth.com»:
      </p>
      <ul>
        <li>субъекты (владелец) персональных данных: Заказчики;</li>
        <li>категории обрабатываемых данных: иные;</li>
        <li>
          перечень обрабатываемых данных: фамилия, имя; адрес электронной почты;
        </li>
        <li>
          срок обработки данных: до момента достижения целей обработки или
          отзыва согласия на обработку, но не более 3 (трех) лет с момента
          удаления учетной записи Заказчика в Сервисе.
        </li>
      </ul>
      <p>
        2.5. Оператор может обрабатывать следующие данные в целях исполнения
        поручений Заказчика на обработку персональных данных Пользователей:
      </p>
      <ul>
        <li>субъекты (владелец) персональных данных: Пользователи;</li>
        <li>категории обрабатываемых данных: иные;</li>
        <li>
          перечень обрабатываемых данных: любые данные Пользователей квизов,
          собираемые Заказчиком посредством Квизов, в том числе могут быть:
          фамилия, имя, отчество; пол; возраст; дата рождения; место рождения;
          гражданство; адрес проживания; адрес электронной почты; номер
          телефона; ID в соцсетях; сведения о доходе; потребительские
          предпочтения и другие данные за исключением: биометрических
          персональных данных; специальных персональных данных (данных,
          касающихся расовой, национальной принадлежности, политических
          взглядов, религиозных или философских убеждений, состояния здоровья,
          интимной жизни); сведений о судимости;{" "}
        </li>
        <li>
          Заказчикам запрещается создавать Квизы для сбора указанных выше
          биометрических и специальных персональных данных без получения
          письменных согласий на обработку данных, а Пользователям Квизов
          рекомендуется не предоставлять такие данные через Квизы без письменных
          согласий на обработку данных. В случае предоставления таких данных без
          письменных согласий просим сообщить об этом Оператору;{" "}
        </li>
        <li>
          срок обработки данных: до момента достижения целей обработки или
          отзыва согласия на обработку, но не более 3 (трех) лет с момента
          предоставления согласия на обработку.
        </li>
      </ul>
      <p>
        2.6. Оператор может обрабатывать следующие данные в целях обеспечения
        коммуникации и взаимодействия между Заказчиками и Пользователями Квизов,
        направления Пользователем Квизов информационных и рекламных сообщений,
        продвижения товаров, работ, услуг партнеров Оператора (Заказчиков):
      </p>
      <ul>
        <li>субъекты (владелец) персональных данных: Пользователи Квизов;</li>
        <li>категории обрабатываемых данных: иные;</li>
        <li>
          перечень обрабатываемых данных: любые данные Пользователей Квизов,
          собираемые Заказчиком посредством Квизов, в том числе могут быть:
          фамилия, имя, отчество; пол; возраст; дата рождения; место рождения;
          гражданство; адрес проживания; адрес электронной почты; номер
          телефона; ID в соцсетях; сведения о доходе; потребительские
          предпочтения и другие данные за исключением: биометрических
          персональных данных; специальных персональных данных (данных,
          касающихся расовой, национальной принадлежности, политических
          взглядов, религиозных или философских убеждений, состояния здоровья,
          интимной жизни); сведений о судимости;
        </li>
        <li>
          Заказчикам запрещается создавать Квизы для сбора указанных выше
          биометрических и специальных персональных данных без получения
          письменных согласий на обработку данных, а Пользователям Квизов
          рекомендуется не предоставлять такие данные через Квизы без письменных
          согласий на обработку данных. В случае предоставления таких данных без
          письменных согласий просим сообщить об этом Оператору;{" "}
        </li>
        <li>
          срок обработки данных: до момента достижения целей обработки или
          отзыва согласия на обработку, но не более 3 (трех) лет с момента
          предоставления согласия на обработку.
        </li>
      </ul>
      <p>
        2.7. Оператор может обрабатывать следующие данные в целях проведения
        аналитических, маркетинговых, статистических и иных исследований
        использования Посетителями Сервиса для оптимизации его работы,
        исправления ошибок и сбоев в работе Сервиса, развития продуктов и услуг
        Оператора и его партнеров:
      </p>
      <ul>
        <li>
          субъекты (владелец) персональных данных: Посетители, Заказчики,
          Пользователи Квизов;
        </li>
        <li>категории обрабатываемых данных: иные;</li>
        <li>
          перечень обрабатываемых данных: IP-адрес; файлы “cookies” и
          аналогичные технологии; данные об использовании Посетителем Сервиса;
          информация о местоположении подключения к Сервису;
        </li>
        <li>
          срок обработки данных: до момента достижения целей обработки или
          отзыва согласия на обработку, но не более 3 (трех) лет с момента
          последнего посещения Сервиса.
        </li>
      </ul>
      <p>
        2.8. Обработка данных осуществляется Оператором в автоматизированной
        форме и включает в себя: сбор, запись, систематизация, накопление,
        хранение, уточнение (обновление, изменение), извлечение, использование,
        передача (предоставление, доступ), блокирование, удаление, уничтожение
        персональных данных.
      </p>
      <p>
        2.9. Порядок уничтожения данных: данные подлежат уничтожению в случаях:
      </p>
      <ul>
        <li>достижения целей обработки; </li>
        <li>истечения сроков обработки;</li>
        <li>отзыва согласия на обработку;</li>
        <li>реализации права на забвение;</li>
        <li>
          предъявления уполномоченным государственным органом требования об
          уничтожении данных;
        </li>
        <li>ликвидации или реорганизации Оператора.</li>
      </ul>
      <p>
        Способ уничтожения: электронные массивы данных уничтожаются с помощью
        программных средств.
      </p>
      <h3 className="content-title">
        3. ФАЙЛЫ “COOKIES” И АНАЛОГИЧНЫЕ ТЕХНОЛОГИИ
      </h3>
      <p>
        Файлы “cookies” и аналогичные технологии (например, пиксели, тэги, кэш,
        локальное хранилище и т.п.) – это небольшие фрагменты данных чаще всего
        реализованные в виде текстовых файлов-идентификатор, которые
        устанавливаются на устройство или платформу подключения при посещении им
        интернет-ресурсов, отдельных страниц / модулей Сервиса, при
        взаимодействии с ними для получения и создания информации. В работе
        Сервиса используются файлы “cookies” различных видов, которые могут
        создаваться как самим Сервисом, так и третьими лицами - партнерами
        Оператора, предоставляющими, функционально-технические решения,
        необходимые для работы Сервиса.
      </p>
      <p>Файлы “сookies” и аналогичные технологии позволяют: </p>
      <p>
        1. посетителям, пользователям - персонализировать настройки работы с
        Сервисом,
      </p>
      <p>
        2. Оператору - собирать данные, необходимые для оптимизации работы
        Сервиса, исправления ошибок и сбоев в его работе, развития продуктов и
        услуг Оператора и его партнеров.{" "}
      </p>
      <p>
        Для этого Оператор сохраняет информацию о совершенных пользователями
        действиях в Сервисе, например, информацию об авторизации в личном
        кабинете, эффективности интерфейса на той или иной странице Сервиса,
        использовании или неиспользовании отдельных возможностей Сервиса и тому
        подобное.
      </p>
      <p>
        Посетители / пользователи могут самостоятельно настроить параметры
        установки, блокировки, либо отказа от использования файлов “cookies”, в
        конфигурациях используемого им браузера либо устройства, через которое
        осуществляется доступ к Сервису. Информация о настройке установок,
        блокировке и удалении файлов “cookies” может быть найдена в справочных
        разделах браузера или устройства пользователя.
      </p>
      <p>
        Удаление или запрет установки определенных файлов “cookies” может стать
        причиной невозможности использования отдельных функций Сервиса.
      </p>
      <h3 className="content-title">4. ОСНОВАНИЯ ОБРАБОТКИ ДАННЫХ</h3>
      <p>Оператор обрабатывает ваши данные, основываясь на:</p>
      <ul>
        <li>
          необходимости исполнения Оператором обязанностей перед Пользователями
          Сайта, предусмотренных Пользовательским соглашением;
        </li>
        <li>
          необходимости исполнения Оператором обязанностей перед Заказчиком,
          предусмотренных, поручением на обработку персональных данных (при
          наличии поручения);{" "}
        </li>
        <li>предоставленном согласии на обработку данных; </li>
        <li>законных интересах Оператора.</li>
      </ul>
      <p>4.1. Исполнение обязанностей из Пользовательского соглашения</p>
      <p>
        Оператор обрабатывает данные для исполнения своих обязанностей перед
        Заказчиками согласно условиям Пользовательского соглашения, в том числе
        для создания и использования личного кабинета в Сервисе, предоставления
        Заказчику доступа к Сервису «PsyWealth.com» и получения услуг Сервиса
        «PsyWealth.com», обеспечения коммуникации и взаимодействия между
        Заказчиками и Пользователями Квизов, направления Пользователем Квизов
        информационных и рекламных сообщений, продвижения товаров, работ, услуг
        партнеров Оператора (Заказчиков).{" "}
      </p>
      <p>4.2. Согласие на обработку данных</p>
      <p>4.2.1. Согласие Заказчика:</p>
      <p>
        Перед регистрацией личного кабинета в Сервисе Заказчик предоставляет
        Оператору свое согласие на обработку ваших данных. Данное согласие
        является самостоятельным законным основанием для обработки данных.
        Заказчики предоставляют свое согласие на обработку ваших данных путем
        заполнения в Сервисе специальной формы о предоставлении согласия
        (чек-бокс).{" "}
      </p>
      <p>
        При этом базовым основанием для обработки ваших данных является
        необходимость исполнения обязательств из Пользовательского соглашения
        Сервиса «PsyWealth.com».{" "}
      </p>
      <p>4.2.2. Согласия Пользователей Квизов:</p>
      <p>
        Перед отправкой данных, внесенных в Квиз, Пользователи Квизов
        предоставляют Оператору или Заказчику свое согласие на обработку своих
        данных. Данное согласие является самостоятельным законным основанием для
        обработки данных Пользователей Квизов.{" "}
      </p>
      <p>
        Пользователи Квизов предоставляют свое согласие на обработку данных
        путем заполнения на Сайте Заказчика специальной формы о предоставлении
        согласия (чек-бокс).{" "}
      </p>
      <p>4.2.3. Отзыв согласия:</p>
      <p>
        Вы можете отозвать свое согласие в любое время, направив заявление на
        электронную почту Оператора: privacy@psywealth.com.{" "}
      </p>
      <p>
        Отзыв согласия не повлияет на законность обработки, основанной на ранее
        выданном согласии.{" "}
      </p>
      <p>
        В случае отзыва вашего согласия и при отсутствии иных законных оснований
        для обработки данных все ваши данные будут уничтожены, что сделает
        невозможным использование Сервиса «PsyWealth.com».
      </p>
      <p>4.3. Законные интересы</p>
      <p>Оператор обрабатывает данные для:</p>
      <ul>
        <li>
          проведения аналитических, маркетинговых, статистических и иных
          исследований использования Сервиса для оптимизации его работы,
          исправления ошибок и сбоев в работе Сервиса, развития продуктов и
          услуг Оператора и его партнеров;
        </li>
        <li>
          защиты собственных законных интересов, например, для обеспечения
          возможности судебной защиты.
        </li>
      </ul>
      <h3 className="content-title">5. ПЕРЕДАЧА ДАННЫХ ТРЕТЬИМ ЛИЦАМ</h3>
      <p>Оператор может предоставлять ваши данные третьим лицам в случаях: </p>
      <ul>
        <li>
          если Оператор обязан сделать это по закону, в связи с проведением
          судебных разбирательств, административных процедур либо для
          установления, осуществления или защиты законных прав Оператора;{" "}
        </li>
        <li>
          если Пользователи Квизов предоставляют свои данные через Квиз и дали
          согласие на обработку своих данных;
        </li>
        <li>
          если Оператор действует на основании поручения Заказчика на обработку
          персональных данных Пользователей Квиза;
        </li>
        <li>
          если Оператор пользуется услугами сторонних дата-центров для
          обеспечения хранения и защиты обрабатываемых данных;{" "}
        </li>
        <li>
          если обработка данных будет поручена третьим лицам (например,
          операторам сервисов e-mail / sms маркетинга, операторам аналитических
          сервисов).
        </li>
      </ul>
      <p>
        Оператор не будет передавать ваши данные третьим лицам, если это прямо
        не предусмотрено в настоящей Политике конфиденциальности, в
        Пользовательском соглашении или в согласии на обработку данных.
      </p>
      <p>
        5.1. Передача данных Пользователей Квизов на основании согласия на
        обработку персональных данных
      </p>
      <p>
        В случаях, когда Пользователи Квизов при заполнении Квизов предоставляют
        Оператору свои данные и согласие на обработку этих данных, такие данные
        будут предоставлены Заказчикам, создавшим Квизы, В том числе Оператор
        может передавать следующие данные:
      </p>
      <ul>
        <li>
          любые данные Пользователей Квизов, собираемые Заказчиком посредством
          Квизов, в том числе могут быть: фамилия, имя, отчество; пол; возраст;
          дата рождения; место рождения; гражданство; адрес проживания; адрес
          электронной почты; номер телефона; ID в соцсетях; сведения о доходе;
          потребительские предпочтения и другие данные, за исключением:
          биометрических персональных данных; специальных персональных данных
          (данных, касающихся расовой, национальной принадлежности, политических
          взглядов, религиозных или философских убеждений, состояния здоровья,
          интимной жизни); сведений о судимости; данных о банковских картах и
          иных платежных средствах.
        </li>
      </ul>
      <p>
        Заказчикам запрещается создавать Квизы для сбора биометрических и
        специальных персональных данных (данных, касающихся расовой,
        национальной принадлежности, политических взглядов, религиозных или
        философских убеждений, состояния здоровья, интимной жизни) без получения
        письменных согласий Пользователей на обработку персональных данных.{" "}
      </p>
      <p>
        Мы не рекомендуем Пользователям Квизов предоставлять такие данные через
        Квизы без выдачи письменных согласий на обработку данных.{" "}
      </p>
      <p>
        В случае же предоставления биометрических и специальных персональных
        данных без письменных согласий, Пользователи Квизов должны сообщить об
        этом Оператору для уничтожения таких данных либо оформления письменных
        согласий (по выбору Пользователя Квиза).
      </p>
      <p>
        5.2. Передача данных Пользователей Квизов на основании поручения на
        обработку персональных данных
      </p>
      <p>
        В случаях, когда Оператор обрабатывает данные Пользователей Квизов
        (предоставляемых при заполнении Квизов) на основании поручения на
        обработку персональных данных от Заказчика, такие данные будут
        предоставлены Заказчикам, которые создали Формы сбора данных или дали
        Оператору поручения на обработку. В том числе Оператор может передавать
        следующие данные:
      </p>
      <ul>
        <li>
          любые данные Пользователей квизов, собираемые Заказчиком посредством
          Квизов, в том числе могут быть: фамилия, имя, отчество; пол; возраст;
          дата рождения; место рождения; гражданство; адрес проживания; адрес
          электронной почты; номер телефона; ID в соцсетях; сведения о доходе;
          потребительские предпочтения и другие данные, за исключением:
          биометрических персональных данных; специальных персональных данных
          (данных, касающихся расовой, национальной принадлежности, политических
          взглядов, религиозных или философских убеждений, состояния здоровья,
          интимной жизни); сведений о судимости; данных о банковских картах и
          иных платежных средствах.
        </li>
      </ul>
      <p>
        Заказчикам запрещается создавать Квизы для сбора биометрических и
        специальных персональных данных (данных, касающихся расовой,
        национальной принадлежности, политических взглядов, религиозных или
        философских убеждений, состояния здоровья, интимной жизни) без получения
        письменных согласий Пользователей на обработку персональных данных.{" "}
      </p>
      <p>
        Мы не рекомендуем Пользователям Квизов предоставлять такие данные через
        Квизы без выдачи письменных согласий на обработку данных.{" "}
      </p>
      <p>
        В случае же предоставления биометрических и специальных персональных
        данных без письменных согласий, Пользователи Квизов должны сообщить об
        этом Оператору для уничтожения таких данных либо оформления письменных
        согласий (по выбору Пользователя Квиза).
      </p>
      <p>5.3. Передача данных операторам сервисов e-mail - маркетинга</p>
      <p>
        В целях направления Заказчикам информационных сообщений о работе Сервиса
        Оператор может направить часть данных Заказчиков оператору сервиса
        e-mail - маркетинга, оказывающего соответствующие услуги Оператору. В
        том числе Оператор может передавать следующие данные:
      </p>
      <ul>
        <li>фамилия, имя;</li>
        <li>адрес электронной почты.</li>
      </ul>
      <p>
        Без направления этих данных оператору сервиса e-mail - маркетинга
        получение вами информационных сообщений о работе Сервиса может быть
        невозможно.
      </p>
      <p>5.4. Передача данных операторам аналитических сервисов </p>
      <p>
        В случае если вы не заблокируете использование Сервисом файлов “cookie”
        и аналогичных технологий, эти данные (без предоставления иных
        персональных данных) будут в автоматическом режиме будут обрабатываться
        оператором аналитического сервиса, оказывающего нам соответствующие
        услуги (например, Яндекс.Метрика). В том числе могут обрабатываться
        следующие данные:
      </p>
      <ul>
        <li>IP-адрес; </li>
        <li>файлы “cookies” и аналогичные технологии; </li>
        <li>данные об использовании пользователем Сервиса; </li>
        <li>информация о местоположении подключения к Сервису.</li>
      </ul>
      <p>5.5. Передача данных дата-центрам </p>
      <p>
        Для обеспечения хранения и защиты обрабатываемых данных Оператор может
        использовать сторонние дата-центры. Сторонние дата-центры могут
        обрабатывать следующие данные:
      </p>
      <ul>
        <li>
          данные Заказчиков: имя; адрес электронной почты; номер телефона;
          Telegram ID; должность;
        </li>
        <li>
          данные Пользователей квизов: любые данные Пользователей квизов,
          собираемые Заказчиком посредством Квизов, в том числе могут быть:
          фамилия, имя, отчество; пол; возраст; дата рождения; место рождения;
          гражданство; адрес проживания; адрес электронной почты; номер
          телефона; ID в соцсетях; сведения о доходе; потребительские
          предпочтения и другие данные, за исключением: биометрических
          персональных данных; специальных персональных данных (данных,
          касающихся расовой, национальной принадлежности, политических
          взглядов, религиозных или философских убеждений, состояния здоровья,
          интимной жизни); сведений о судимости; данных о банковских картах и
          иных платежных средствах
        </li>
      </ul>
      <p>
        Без направления этих данных в дата-центр работа Сервиса может быть
        невозможна.
      </p>
      <h3 className="content-title">6. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
      <p>
        Для того, чтобы контролировать обработку своих данных, вы можете
        реализовывать определенные права. В частности, вы имеете право:
      </p>
      <ul>
        <li>
          Получить доступ к вашим данным. Вы имеете право на получение
          информации, в том числе: подтверждение обработки ваших данных;
          информацию о правовых основаниях, целях, способах и сроках обработки
          данных; об операторе, обрабатывающем данные; сведения о лицах, которые
          имеют доступ к персональным данным или которым могут быть раскрыты
          данные; обрабатываемые данные и источник их получения; порядок
          осуществления прав владельца (субъекта) данных; сведения о лице,
          осуществляющем обработку данных по поручению оператора (если обработка
          была поручена); иные сведения.{" "}
        </li>
        <li>
          Требовать исправления (уточнения) данных. Если вы обнаружите, что ce
          Оператор обрабатывает неточные или устаревшие данные, вы можете
          проверить их точность и / или попросить обновить или исправить их.
        </li>
        <li>
          Ограничить (блокировать) обработку ваших данных. Если вы оспариваете
          точность своих данных, полагаете, что Оператор обрабатывает их
          незаконно или хотите возразить против обработки, вы имеете право
          временно остановить обработку ваших данных, чтобы проверить, была ли
          обработка корректной. В этом случае Оператор не будет обрабатывать
          данные для каких-либо целей, кроме их хранения, до тех пор, пока не
          исчезнут причины введения ограничения.
        </li>
        <li>
          Требовать уничтожения ваших данных. Если Оператор не обязан хранить
          данные в соответствии с законодательством, данные будут уничтожены по
          вашему запросу.
        </li>
      </ul>
      <p>
        Чтобы воспользоваться своими правами, вы можете направить свой запрос на
        электронную почту Оператора: privacy@psywealth.com. &nbsp;
      </p>
      <h3 className="content-title">7. БЕЗОПАСНОСТЬ ДАННЫХ</h3>
      <p>
        Оператор принимает необходимые и достаточные меры для защиты вашей
        информации и данных от несанкционированного или случайного доступа,
        уничтожения, изменения, блокировки, копирования, распространения, а
        также от других незаконных действий.
      </p>
      <p>
        Оператор предоставляет доступ к вашим данным только уполномоченным
        лицам. Такие лица соблюдают строгую конфиденциальность и предотвращают
        несанкционированный доступ к вашим данным.{" "}
      </p>
      <p>
        Оператор несёт ответственность за конфиденциальность обрабатываемых
        данных в той части, в которой Оператор имеет непосредственную
        возможность для обеспечения безопасности обработки данных и
        осуществления контроля обработки данных.{" "}
      </p>
      <p>
        Ответственность за конфиденциальность обрабатываемых данных,
        передаваемых третьим лицам (например, данных Пользователей Квизов,
        передаваемых Заказчикам) несут непосредственно третьи лица, получившие
        такие данные.
      </p>
      <h3 className="content-title">8. ОБНОВЛЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ</h3>
      <p>
        Оператор может обновлять настоящую Политику конфиденциальности. Новые
        версии Политики конфиденциальности будут публиковаться в Сервисе на
        сайте <ContentSiteLink />.
      </p>
      <p>
        Изменения в Политику конфиденциальности вступают в силу с момента
        публикации обновленной версии Политики конфиденциальности.
      </p>
      <p>
        Рекомендуем вам при каждом посещении сайта «PsyWealth.com» проверять
        страницу Политики конфиденциальности, чтобы убедиться, что вас
        устраивает ее действующая
      </p>
    </div>
  );
};
