import { SectionSubscribeLazy } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { dataPolicyPage, dataPolicyTermsPolicyPage, dataSubscribe } from "data";
import { Fragment } from "react";
import SectionPolicy from "./components/SectionTerms";

/**
 * Component for showing Page "Terms and Conditional" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageTermsAndConditions = (props) => {
  const { id = "policy-terms" } = props;

  return (
    <Fragment>
      <SEO data={dataPolicyPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policy/terms" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>

      <SectionPolicy data={dataPolicyTermsPolicyPage} />
      <SectionSubscribeLazy data={dataSubscribe} type="style3" />
    </Fragment>
  );
};

export default PageTermsAndConditions;
