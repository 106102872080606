import { useMediaQuery } from "hooks";
import { PropTypes } from "prop-types";
import { Nav, Tab } from "react-bootstrap";
import FAQAccordion from "./FAQAccordion";

// NOTE : https://edublink.react.devsblink.com/faq

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;
  const isWideScreen = useMediaQuery(1400);

  // _debug({ isWideScreen });

  //   <div className="row justify-content-center">
  //   {isWideScreen ? (
  //     <div className="col-lg-8 col-md-offset-2 faq-right-collapse">
  //       <FAQAccordion data={data} showMain={false} />
  //     </div>
  //   ) : (
  //     <Fragment>
  //       <div className="col-md-6 faq-right-collapse">
  //         <FAQAccordion data={data} showMain={false} screenCol={1} />
  //       </div>
  //       <div className="col-md-6 faq-right-collapse">
  //         <FAQAccordion data={data} showMain={false} screenCol={2} />
  //       </div>
  //     </Fragment>
  //   )}
  // </div>

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <div className="row justify-content-center">
        <div className="col-lg-4">
          <div className="faq-page-nav">
            <h3 className="title">Вопросы по этой категории</h3>
            <p></p>

            <Nav variant="pills" className="flex-column nav-tabs">
              {/*{data.items.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={item.id}>{item.title}</Nav.Link>
                </Nav.Item>
              ))} */}

              <Nav.Item>
                <Nav.Link eventKey="first">Регулярные вопросы</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Условия и положения</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="col-lg-8">
          <Tab.Content>
            {/*  {data.items.map((item, index) => (
              <Tab.Pane eventKey={item.id} key={index}>
                <div className="faq-accordion">
                  <FAQAccordion data={item.items} showMain={false} />
                </div>
              </Tab.Pane>
            ))} */}

            <Tab.Pane eventKey="first">
              <div className="faq-accordion">
                <FAQAccordion data={data} showMain={false} screenCol={1} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <FAQAccordion data={data} showMain={false} screenCol={2} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

/**
 * Component section content "FAQ (style 3)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
