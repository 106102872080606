import useProgressiveImg from "hooks/useProgressiveImg";

const ProgressiveImg2 = ({ lowSrc, hightSrc, alt, ...otherProps }) => {
  const [src, { blur }] = useProgressiveImg(lowSrc, hightSrc);

  return (
    <img
      src={src}
      style={{
        filter: blur ? "blur(20px)" : "none",
        transition: blur ? "none" : "filter 0.3s ease-out",
      }}
      alt={alt || "PsyWealth"}
      {...otherProps}
    />
  );
};

export default ProgressiveImg2;
