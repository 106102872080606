import classNames from "classnames";
import {
  PageSection,
  SectionHeader,
  useSectionPrefix,
} from "core/components/Section";
import { PropTypes } from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { Button as BSButton, Tab } from "react-bootstrap";
import Quiz from "./Quiz";

const msgReset = "С начала";
const msgStart = "Начать тест";

const StartPanel = (props) => {
  const { onSubmit } = props;

  return (
    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center">
      <div className="col-md-8 col-lg-6 position-relative">
        <figure className="rounded-4 overflow-hidden">
          <img src={require("assets/images/tests/test1jpg.webp")} alt="" />
        </figure>
      </div>

      <div className="col-lg-6">
        {/* <h2 className="display-4 mb-3">Шкала депрессии А.Бека</h2> */}
        <p className="">В этом опроснике содержатся группы утверждений.</p>
        <p className="">
          Пожалуйста, внимательно прочитайте каждую группу утверждений. Затем
          определите в каждой группе одно утверждение, которое лучше всего
          соответствует тому, как Вы себя чувствовали{" "}
          <em>
            <strong data-redactor-tag="strong">на этой неделе и сегодня</strong>
          </em>
          .
        </p>
        <p className="">
          Выделите цифру, находящуюся напротив выбранного утверждения. Прежде,
          чем сделать свой выбор, убедитесь, что Вы прочли{" "}
          <em>
            <strong data-redactor-tag="strong">все</strong>
          </em>{" "}
          утверждения в каждой группе.
        </p>

        <BSButton
          type="button"
          variant="primary"
          onClick={onSubmit}
          className="mt-3"
        >
          {msgStart}
        </BSButton>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    onFinished,
    ...otherProps
  } = props;
  const [activeKey, setActiveKey] = useState("first");
  // console.log("activeKey: ", activeKey);

  const handleSelect = (key) => {
    alert(`selected ${key}`);
    setActiveKey({ key });
  };

  const handleReset = () => {
    // alert(`selected ${key}`);
    setActiveKey("first");
  };

  useEffect(() => {
    // setActiveKey("profile");
    // console.log(activeKey);
  }, []);

  return (
    <Fragment>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="first"
        activeKey={activeKey}
        onSelect={handleSelect}
      >
        <div className="row g-0">
          <div className="col-12">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <StartPanel onSubmit={() => setActiveKey("second")} />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <Quiz
                      onReset={() => handleReset()}
                      onFinished={onFinished}
                    />
                    <button
                      className={`nav-link`}
                      type="button"
                      onClick={() => handleReset()}
                    >
                      {msgReset}
                    </button>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </Fragment>
  );
};

/**
 * Component for showing section "Quiz" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionQuiz = (props) => {
  const {
    data,
    id = "quiz",
    cn = "",
    type,
    showHeader = true,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentSubtitle = false,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    onFinished = () => {},
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      {showHeader && (
        <SectionHeader
          data={data}
          className=""
          showSubtitle={showHeaderSubtitle}
          showDescription={showHeaderDescription}
        />
      )}
      <SectionContent
        data={data}
        id={id}
        cn={cn}
        type={type}
        showTitle={showContentTitle}
        showSubtitle={showContentSubtitle}
        showDescription={showContentDescription}
        onFinished={onFinished}
        {...otherProps}
      />
    </PageSection>
  );
};

SectionQuiz.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1"]),
  showHeader: PropTypes.bool ?? true,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentSubtitle: PropTypes.bool ?? false,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionQuiz;
