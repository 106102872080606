import { CardCourse } from "components/pages/Courses";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

// import "./TopCourses.scss";

// NOTE :

const msgTitle = "Категории";
const msgNoData = "Нет данных о категориях в блоге";

const SectionGoToCourses = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-6 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/courses"} className="btn btn-primary theme-btn">
              Смотреть все курсы
            </Link>
          </h2>
          <p className="display-30">Смотреть все курсы</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data } = props;

  return <Fragment></Fragment>;
};

/**
 * Component section content "Top Courses (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data: courses,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
  } = props;

  return (
    <Fragment>
      <div className="row">
        <Carousel variant="dark">
          {courses &&
            courses.length > 0 &&
            courses.map((item, index) => {
              return (
                <Carousel.Item index={index} key={index}>
                  <div className="row equal">
                    <div className="col-lg-6 col-md-12 col-12">
                      <CardCourse
                        data={item}
                        index={index}
                        key={index}
                        type="course"
                      />
                    </div>
                    {courses.length > index && courses[index + 1] ? (
                      <div className="col-lg-6 col-md-12 col-12">
                        <CardCourse
                          data={courses[index + 1]}
                          index={index + 1}
                          key={index + 1}
                          type="course"
                        />
                      </div>
                    ) : (
                      <SectionGoToCourses />
                    )}
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
    </Fragment>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
