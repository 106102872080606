export const dataMetaFAQPage = {
  title:
    "Сервис подбора психологов и психотерапевтов PsyWealth в вопросах и ответах",
  description:
    "Отвечаем на частые вопросы о психотерапии и о том, как проходят консультации с психологом на сервисе PsyWealth.",
  name: "",
  type: "",
  author: "psywealth, Inc.",
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content:
        "Сервис подбора психологов и психотерапевтов PsyWealth в вопросах и ответах",
    },
    {
      property: "og:description",
      content:
        "Отвечаем на частые вопросы о психотерапии и о том, как проходят консультации с психологом на сервисе PsyWealth.",
    },
    {
      property: "og:url",
      content: "https://psywealth.com/faq/",
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:image",
      content:
        "https://static.tildacdn.com/tild6464-3961-4635-b834-633364363338/Group_4.svg",
    },
    { property: "og:image:alt", content: "«psywealth-Архив»" },
    {
      property: "og:locale",
      content: "ru_RU",
    },
    {
      property: "og:site_name",
      content: "«psywealth-Архив»",
    },
  ],
  TW: [
    { property: "twitter:creator", content: "" },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:title", content: "" },
    { property: "twitter:description", content: "" },
    { property: "twitter:label1", content: "Примерное время для чтения" },
    { property: "twitter:data1", content: "10 минуты" },
  ],
};
