import { SectionTopPostsByAuthorLazy } from "components/sections";
import { Spinner } from "components/utils/Spinner";
import { PageSection } from "core/components";
import { dataMessages, dataTopPostsByAuthor } from "data";
import { Fragment, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { SectionTherapistPrices } from "./components";
import {
  TherapistBio,
  TherapistInfo,
  TherapistInfoCard,
  TherapistQualification,
  TherapistWorkingWith,
} from "./components/Details";
import { useGetTherapistBySlug } from "./utils/useGetTherapistBySlug.hook";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// NOTE : https://epicbootstrap.com/snippets/team-cards

const dataSections = [
  {
    title: "Профиль",
    subtitle: "Профиль",
    description: "Профиль",
  },
  {
    title: "С чем поможет",
    subtitle: "С чем поможет",
    description: "С чем поможет",
  },
  {
    title: "О себе",
    subtitle: "О себе",
    description: "",
  },
  {
    title: "Квалификация",
    subtitle: "Квалификация",
    description: "Квалификация",
  },
  {
    title: "Стоимость консультации психолога",
    subtitle: "Стоимость консультации психолога",
    description: "Стоимость консультации психолога",
  },
];

const SectionContent = (props) => {
  const { data } = props;
  const { therapist, subsections } = data;

  const TherapistDetails = () => {
    return (
      <div className="therapist-info-details">
        <TherapistInfo data={{ therapist, title: subsections[0].title }} />
        {/* <TherapistProfile data={{ therapist, title: subsections[0].title }} /> */}
        <TherapistWorkingWith
          data={{ therapist, title: subsections[1].title }}
        />
        <TherapistBio data={{ therapist, title: subsections[2].title }} />
        <TherapistQualification
          data={{ therapist, title: subsections[3].title }}
        />
        {/* <TherapistPrice data={{ therapist, title: subsections[4].title }} /> */}

        {/* <button onClick={() => navigate(-1)}>Go back</button>*/}

        {/* <DemoSection /> */}
      </div>
    );
  };

  return (
    <Row>
      <div className="col-lg-4 col-md-12 col-12">
        <TherapistInfoCard data={therapist} />
      </div>

      <div className="col-lg-8 col-md-12 col-12">
        <TherapistDetails />
      </div>
    </Row>
  );
};

const TherapistSection = (props) => {
  const { data, id = "therapist", cn = "pt-100 pb-100" } = props;
  const { slug } = useParams();

  const {
    data: therapist,
    isLoading: isLoadingTherapist,
    isError: isErrorTherapist,
    error: errorTherapist,
  } = useGetTherapistBySlug(slug);

  const isLoading = isLoadingTherapist;
  const isError = isErrorTherapist;
  const errors = [errorTherapist];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (therapist) {
      _debug("THERAPIST:", therapist);
    }
  }, [therapist]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorTherapist && errorTherapist?.status === 204) {
    return <div className="error">Нет данных о терапевте</div>;
  }

  /*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/

  return (
    <Fragment>
      {/*<SEO data={{ ...dataTherapistsPage.meta }}>
        <link rel="canonical" href="https://psywealth.com/therapists" />
      </SEO>*/}
      <PageSection id={id} cn={cn}>
        {/* <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />*/}
        <div className="container">
          <SectionContent data={{ therapist, subsections: dataSections }} />
        </div>
      </PageSection>
      <SectionTherapistPrices data={{ ...data, therapist }} />
      <SectionTopPostsByAuthorLazy
        data={dataTopPostsByAuthor}
        type={"style2"}
      />
    </Fragment>
  );
};

export default TherapistSection;
