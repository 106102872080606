import { PropTypes } from "prop-types";
import { Fragment } from "react";

import "./Section.style.scss";

// NOTE :

// NOTE : https://bootstrapmade.com/demo/Logis/

const msgSubmit = "Подробнее";

const SectionItem = (props) => {
  const { data, useAnimation } = props;
  // className="row gy-4 align-items-center features-item aos-animate"

  return (
    <Fragment>
      <div className="row align-items-center pt-xl-2 pb-5 mb-lg-2 mb-xl-3 mb-xxl-4">
        <div className="col-md-7 col-lg-6 mb-4 mb-md-0">
          <div className="d-block position-relative">
            <div
              className="bg-info rounded-5 position-absolute top-0 start-0 w-100 h-100 aos-init aos-animate"
              {...(useAnimation && {
                "data-aos": "zoom-in",
                "data-aos-duration": 600,
                "data-aos-offset": 250,
              })}
            ></div>
            <img
              className="d-block position-relative z-2 mx-auto aos-init aos-animate"
              src={data.image.src}
              width="500"
              height="500"
              alt={data.image.alt}
              data-aos="fade-in"
              data-aos-duration="400"
              data-aos-offset="250"
            />
          </div>
        </div>
        <div
          className="col-md-5 col-xl-4 offset-lg-1 aos-init aos-animate"
          {...(useAnimation && {
            "data-aos": "fade-up",
            "data-aos-duration": 400,
            "data-aos-offset": 170,
          })}
        >
          <div className="ps-md-3 ps-lg-0">
            {/* Title */}
            <h2 className="h4">{data.title}</h2>
            {/* Description */}
            {data.description1 && data.description2 ? (
              <Fragment>
                <p className="mb-1">{data.description1}</p>
                <p className="">{data.description2}</p>
              </Fragment>
            ) : (
              <p className="">{data.description1}</p>
            )}
            {/* Control */}
            {data.control && (
              <a
                className="btn btn-sm btn-outline-dark rounded-pill"
                href="portfolio-single-v1.html"
              >
                Read the full story
              </a>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const SectionContent = (props) => {
  const { data, useAnimation } = props;

  return (
    <Fragment>
      {data.items.map((item, index) => {
        return (
          <SectionItem
            data={item}
            index={index}
            key={index}
            useAnimation={useAnimation}
          />
        );
      })}
    </Fragment>
  );
};

/**
 * Component section content "Overview (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
  } = props;

  return (
    <SectionContent
      data={data}
      showTitle={showTitle}
      showDescription={showDescription}
      useAnimation={useAnimation}
    />
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
