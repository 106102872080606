import { default as classNames } from "classnames";
import { Spinner } from "components/utils/Spinner";
import { PageSection, SectionHeader } from "core/components";
import { PropTypes } from "prop-types";
import { Fragment, useState } from "react";
import { Card, Form } from "react-bootstrap";

const SectionContent = () => {
  const [isSiteContext, setSiteContext] = useState(false);
  const Paragraph = (props) => {
    const { cn } = props;
    return (
      <p className={classNames("m-b-30", cn)}>
        In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
        dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
        Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean
        leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
      </p>
    );
  };

  const handleChangeValue = (e) => {
    //e.preventDefault();
    setSiteContext(!isSiteContext);
  };

  return (
    <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Check this switch"
        onChange={handleChangeValue}
        value={isSiteContext}
        className="mb-3"
      />
      <div className={classNames({ "site-content": isSiteContext })}>
        <div className="row mb-3">
          <div className="">
            <Card>
              <Card.Header>
                <h2 className="text-muted">Headings</h2>
              </Card.Header>
              <Card.Body>
                <h1>h1. Bootstrap heading</h1>
                <Paragraph />
                <h2>h2. Bootstrap heading</h2>
                <Paragraph />
                <h3>h3. Bootstrap heading</h3>
                <Paragraph />
                <h4>h4. Bootstrap heading</h4>
                <Paragraph />
                <h5>h5. Bootstrap heading</h5>
                <Paragraph />
                <h6>h6. Bootstrap heading</h6>
                <Paragraph />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mb-3">
          <div className="">
            <Card>
              <Card.Header>
                <h2 className="text-muted">Site Title</h2>
              </Card.Header>
              <Card.Body>
                <SectionHeader
                  data={{
                    title: "Title",
                    subtitle: "Subtitle",
                    description:
                      "In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.",
                  }}
                  showDescription
                />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Card>
              <Card.Header>
                <h2>Contextual Colors</h2>
              </Card.Header>
              <Card.Body>
                <p>
                  Use the contextual classes to provide "meaning through
                  colors":
                </p>
                <p className="text-muted">This text is muted.</p>
                <p className="text-primary">This text is important.</p>
                <p className="text-success">This text indicates success.</p>
                <p className="text-info">
                  This text represents some information.
                </p>
                <p className="text-warning">This text represents a warning.</p>
                <p className="text-danger">This text represents danger.</p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mb-3">
          <div className="">
            <Card>
              <Card.Header>
                <h2 className="text-muted">Paragraphs</h2>
              </Card.Header>
              <Card.Body>
                <Paragraph cn="lead" />
                <Paragraph cn="" />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Card>
              <Card.Header>
                <h2>BLOCKQUOTES</h2>
              </Card.Header>
              <Card.Body>
                <blockquote className="m-b-25">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                </blockquote>
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                  <footer>
                    Someone famous in{" "}
                    <cite title="Source Title">Source Title</cite>
                  </footer>
                </blockquote>
                <blockquote className="blockquote-reverse">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                  <footer>
                    Someone famous in{" "}
                    <cite title="Source Title">Source Title</cite>
                  </footer>
                </blockquote>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <Card>
              <Card.Header>
                <h2>UNORDERED LIST</h2>
              </Card.Header>
              <Card.Body>
                <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Consectetur adipiscing elit</li>
                  <li>Integer molestie lorem at massa</li>
                  <li>Facilisis in pretium nisl aliquet</li>
                  <li>
                    Nulla volutpat aliquam velit
                    <ul>
                      <li>Phasellus iaculis neque</li>
                      <li>Purus sodales ultricies</li>
                      <li>Vestibulum laoreet porttitor sem</li>
                      <li>Ac tristique libero volutpat at</li>
                    </ul>
                  </li>
                  <li>Faucibus porta lacus fringilla vel</li>
                  <li>Aenean sit amet erat nunc</li>
                  <li>Eget porttitor lorem</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <Card>
              <Card.Header>
                <h2>ORDERED LIST</h2>
              </Card.Header>
              <Card.Body>
                <ol>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Consectetur adipiscing elit</li>
                  <li>Integer molestie lorem at massa</li>
                  <li>Facilisis in pretium nisl aliquet</li>
                  <li>
                    Nulla volutpat aliquam velit
                    <ol>
                      <li>Phasellus iaculis neque</li>
                      <li>Purus sodales ultricies</li>
                      <li>Vestibulum laoreet porttitor sem</li>
                      <li>Ac tristique libero volutpat at</li>
                    </ol>
                  </li>
                  <li>Faucibus porta lacus fringilla vel</li>
                  <li>Aenean sit amet erat nunc</li>
                  <li>Eget porttitor lorem</li>
                </ol>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <Card>
              <Card.Header>
                <h2>UNSTYLED LIST</h2>
              </Card.Header>
              <Card.Body>
                <ul className="list-unstyled">
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Consectetur adipiscing elit</li>
                  <li>Integer molestie lorem at massa</li>
                  <li>Facilisis in pretium nisl aliquet</li>
                  <li>
                    Nulla volutpat aliquam velit
                    <ul>
                      <li>Phasellus iaculis neque</li>
                      <li>Purus sodales ultricies</li>
                      <li>Vestibulum laoreet porttitor sem</li>
                      <li>Ac tristique libero volutpat at</li>
                    </ul>
                  </li>
                  <li>Faucibus porta lacus fringilla vel</li>
                  <li>Aenean sit amet erat nunc</li>
                  <li>Eget porttitor lorem</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </Form>
  );
};

const SectionContentExpra = () => {
  return (
    <Fragment>
      <div className="row mb-3">
        <Card>
          <Card.Header>
            <h2 className="text-muted">Headings</h2>
          </Card.Header>
          <Card.Body>
            <Spinner />
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
};

const SectionTypography = (props) => {
  const { data, id = "typography", cn = "pt-100 pb-100" } = props;

  return (
    <PageSection id={id} cn={cn}>
      <div className="container">
        <SectionContent />
        <SectionContentExpra />
      </div>
    </PageSection>
  );
};

SectionTypography.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default SectionTypography;
