import { PropTypes } from "prop-types";

// import "./Section.style.scss";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=50603

const SectionContent = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  const ContentItem = (props) => {
    const { data } = props;

    return (
      <div className="col">
        <h4 className="mb-lg-4">{data.title}</h4>
        <ul className="list-unstyled mb-0">
          {data.items.map((item1, index1) => {
            if (item1.type === "link") {
              return (
                <li className="pb-1 mb-2" key={index1}>
                  <a
                    key={index1}
                    className="nav-link fw-normal p-0 mt-n1"
                    href={item1.link}
                  >
                    {item1.title}
                  </a>
                </li>
              );
            } else if (item1.component) {
              return (
                <li className="pb-1 mb-2" key={index1}>
                  {item1.component}
                </li>
              );
            }
            return (
              <li className="pb-1 mb-2" key={index1}>
                <p
                  key={index1}
                  className="tfs-lg fw-medium pb-3 mb-3 ext-wrap"
                  style={{ width: "360px" }}
                >
                  {item1.title}
                </p>
              </li>
            );
          })}
          {/*<li className="nav flex-nowrap mb-3">
            <i className="ai-phone lead text-primary me-2"></i>
            <a
              className="nav-link fw-normal p-0 mt-n1"
              href="tel:+178632256040"
            >
              +1&nbsp;(786)&nbsp;322&nbsp;560&nbsp;40
            </a>
          </li>
          <li className="nav flex-nowrap mb-3">
            <i className="ai-mail lead text-primary me-2"></i>
            <a
              className="nav-link fw-normal p-0 mt-n1"
              href="mailto:chicago@example.com"
            >
              newyork@example.com
            </a>
          </li>
          <li className="nav flex-nowrap mb-3">
            <i className="ai-map-pin lead text-primary me-2"></i>
            <a className="nav-link fw-normal p-0 mt-n1" href="#">
              396 Lillian Blvd, Holbrook, NY 11741
            </a>
          </li> */}
        </ul>
      </div>
    );
  };

  return (
    <div className="py-lg-3 py-xl-4 py-xxl-5">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 py-sm-2 py-md-3 py-lg-4">
        {data.items.map((item, index) => (
          <ContentItem data={item} key={index} />
        ))}
      </div>
    </div>
  );
};

/**
 * Component section content "Contacts (style 3)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle3 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;
  console.log(props);

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle3.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle3;
