import classNames from "classnames";
import { useAuth } from "contexts/AuthProvider";
import { dataSite, dataTopMenu } from "data";
import { useMatchPath } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { RiLoginBoxLine, RiLogoutBoxLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { _debug } from "utils";

// NOTE : http://themazine.com/html/Lebari/lebari/index.html
// NOTE : https://themes.getbootstrap.com/preview/?theme_id=148345

/**
 * Component for showing "Top menu" of the site.
 *
 * @component
 */
const TopMenu = () => {
  const [isSticky, setSticky] = useState(false);
  const { auth, isAuthenticated, logout } = useAuth();
  const currentPermission = auth.permissions;
  const location = useLocation();
  const isPsychologistRoute = useMatchPath([
    "/psychologist",
    "/psychologist/:id",
    "/partnership",
  ]);

  _debug("TopMenu:", location);
  _debug("TopMenu:", isAuthenticated);
  _debug("TopMenu:", currentPermission);
  // _debug("TopMenu:", currentRoute);

  const renderMenuItems = () => {
    return dataTopMenu.map((menuItem, index) => {
      // _debug("TopMenu (Item):", {
      //   menuItem,
      //   currentPermission,
      //   isPsychologistRoute,
      // });

      if (!menuItem.path) {
        return null;
      }
      if (menuItem.showOnlyWhenLoggedIn && !isAuthenticated) {
        return null;
      }
      if (menuItem.hideWhenLoggedIn && isAuthenticated) {
        return null;
      }
      if (menuItem.role === "GUEST") {
        if (
          currentPermission &&
          currentPermission.length &&
          (currentPermission.includes("PSYCHOLOGIST") ||
            currentPermission.includes("CUSTOMER"))
        )
          return null;
      } else if (menuItem.role !== "ANY") {
        if (
          isPsychologistRoute === "/psychologist" &&
          menuItem.role !== "PSYCHOLOGIST"
        ) {
          return null;
        }
        if (
          isPsychologistRoute !== "/psychologist" &&
          menuItem.role === "PSYCHOLOGIST"
        ) {
          return null;
        }
      }

      return (
        <Nav.Item
          key={index}
          className={menuItem.showIcon ? "align-items-center" : ""}
        >
          {menuItem.showIcon && menuItem.icon}
          <Nav.Link
            key={menuItem.path || menuItem.title}
            href={menuItem.path}
            bsPrefix="top-menu-nav-link nav-link"
          >
            {menuItem.title}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  const renderMenuItems2 = () => {
    return dataTopMenu.map((menuItem, index) => {
      // _debug("TopMenu (Item):", {
      //   menuItem,
      //   currentPermission,
      //   isPsychologistRoute,
      // });

      if (!menuItem.path) {
        return null;
      }
      if (menuItem.showOnlyWhenLoggedIn && !isAuthenticated) {
        return null;
      }
      if (menuItem.hideWhenLoggedIn && isAuthenticated) {
        return null;
      }
      if (menuItem.role === "GUEST") {
        if (
          currentPermission &&
          currentPermission.length &&
          (currentPermission.includes("PSYCHOLOGIST") ||
            currentPermission.includes("CUSTOMER"))
        )
          return null;
      } else if (menuItem.role !== "ANY") {
        if (
          isPsychologistRoute === "/psychologist" &&
          menuItem.role !== "PSYCHOLOGIST"
        ) {
          return null;
        }
        if (
          isPsychologistRoute !== "/psychologist" &&
          menuItem.role === "PSYCHOLOGIST"
        ) {
          return null;
        }
      }

      return (
        <li className="menu-item" key={menuItem.key || index}>
          <Nav.Link href={menuItem.path} bsPrefix="top-menu-nav-link nav-link">
            {menuItem.title}
          </Nav.Link>
        </li>
      );
    });
  };

  const renderMenuItems3 = (permission, isAuth, isPsyRoute) => {
    console.log(permission, isAuth, isPsyRoute);
    const getPathByPermission = (permission) => {
      if (permission.includes("PSYCHOLOGIST")) {
        return "/psychologist/lk/auth";
      } else if (permission.includes("CUSTOMER")) {
        return "/lk/auth";
      }
      return "/lk/auth";
    };

    if (isAuth) {
      if (permission && permission.length) {
        return (
          <Link
            to={getPathByPermission(permission)}
            className="btn btn-outline-primary fs-base"
            onClick={logout()}
          >
            <i className="icon-link me-2">
              <RiLogoutBoxLine className="bi" aria-hidden="true" />
            </i>
            Выйти
          </Link>
        );
      }
    }

    return (
      <Link
        as="li"
        to={isPsyRoute ? "/psychologist/lk/auth" : "/lk/auth"}
        className="btn btn-outline-primary fs-base"
      >
        <i className="icon-link me-2">
          <RiLoginBoxLine className="bi" aria-hidden="true" />
        </i>
        {isPsyRoute ? "ЛК Психолога" : "Войти"}
      </Link>
    );
  };

  const button = useMemo(() => {
    // console.log(currentPermission, isAuthenticated, isPsychologistRoute);

    const getPathByPermission = (permission) => {
      if (permission.includes("PSYCHOLOGIST")) {
        return "/psychologist/lk/auth";
      } else if (permission.includes("CUSTOMER")) {
        return "/lk/auth";
      }
      return "/lk/auth";
    };

    if (isAuthenticated) {
      if (currentPermission && currentPermission.length) {
        return (
          <Link
            to={getPathByPermission(currentPermission)}
            className="btn btn-outline-primary fs-base"
            onClick={() => logout()}
          >
            <i className="icon-link me-2">
              <RiLogoutBoxLine className="bi" aria-hidden="true" />
            </i>
            Выйти
          </Link>
        );
      }
    }

    return (
      <Link
        to={isPsychologistRoute ? "/psychologist/lk/auth" : "/lk/auth"}
        className="btn btn-outline-primary fs-base"
      >
        <i className="icon-link me-2">
          <RiLoginBoxLine className="bi" aria-hidden="true" />
        </i>
        {isPsychologistRoute ? "ЛК Психолога" : "Войти"}
      </Link>
    );
  }, [currentPermission, isAuthenticated, isPsychologistRoute]);

  const handleScroll = () => {
    const show = window.scrollY >= 400;

    if (show) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="top-menu"
      className={classNames("top-menu navbar-transparent py-2 py-lg-3 w-100", {
        "navbar-sticky style2": isSticky,
      })}
    >
      <Navbar expand="lg" className="">
        <Container>
          {/* Brand */}
          <Navbar.Brand href="/" className="">
            <img
              alt="psywealth"
              src={require("assets/icons/logo-64x64.png")}
              width="32"
              height="32"
              className="d-inline-block me-2 top-menu__navbar-icon"
            />
            <span className="top-menu__navbar-title">
              {dataSite.main.title}
            </span>
          </Navbar.Brand>
          {/* Toggler */}
          <Navbar.Toggle aria-controls="top-menu-navbar-nav" />
          {/* Collapse */}
          <Navbar.Collapse id="top-menu-navbar-nav" className="">
            {/* Main Menu */}
            <Nav as="ul" className="mx-auto gap-lg-4">
              {renderMenuItems2(dataTopMenu)}
            </Nav>
            {/* Auth Menu */}
            <Nav as="ul" className="align-items-center dropdown-hover ms-sm-2">
              <Nav.Link as="li"> {button}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default TopMenu;
