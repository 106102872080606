/**
 * Data of page "Psychologist" of the site.
 *
 *
 */
import {
  BsActivity,
  BsAspectRatio,
  BsBrightnessHigh,
  BsCameraVideo,
  BsClipboard2,
  BsEmojiWink,
  BsLayoutTextWindowReverse,
  BsMortarboard,
  BsPencilSquare,
  BsSpeedometer2,
  BsStar,
  BsXDiamond,
} from "react-icons/bs";
import { FaGift } from "react-icons/fa6";
import { GoFlame, GoMortarBoard, GoRocket, GoSearch } from "react-icons/go";

const dataOverviewImageAlt = "";
const dataUsedForImageCommon = { alt: "", width: "400", height: 390 };
export const dataUsedForPartnershipPage = {
  title: "Как начать сотрудничество",
  subtitle: "",
  description: "",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },

  items: [
    // Заполните анкету психотерапевта и после ее проверки пройдите интервью c нашим экспертом
    // Расскажите нам подробно о себе, своём образовании, опыте консультирования и методах работы, чтобы мы подбирали вам подходящих и интересных клиентов
    // Заполнение анкеты займет 30–40 минут вашего времени, интервью — 60 минут
    {
      title:
        "Заполните анкету психотерапевта и после ее проверки пройдите интервью c нашим экспертом",
      description1:
        "Расскажите нам подробно о себе, своём образовании, опыте консультирования и методах работы, чтобы мы подбирали вам подходящих и интересных клиентов",
      description2:
        "Заполнение анкеты займет 30–40 минут вашего времени, интервью — 60 минут",
      index: 0,
      image: {
        src: require("assets/images/new/service-image-1.webp"),
        alt: "Заполните анкету психотерапевта" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },

    // Заполняете свой профиль специалиста
    // Перед тем как начать предлагать вам клиентов, вы заполняете свой профиль специалиста: добавляете фото, рассказываете о себе и своем опыте. Каждый элемент профиля помогает клиенту лучше узнать вас и сделать выбор. Это делает процесс подобра психотерапевта более точным
    // В среднем стоит выделить на это 60–80 минут

    {
      title: "Заполняете свой профиль специалиста",
      description1:
        "Перед тем как начать предлагать вам клиентов, вы заполняете свой профиль специалиста: добавляете фото, рассказываете о себе и своем опыте. Каждый элемент профиля помогает клиенту лучше узнать вас и сделать выбор. Это делает процесс подобра психотерапевта более точным.",
      description2: "В среднем стоит выделить на это 60–80 минут",
      index: 1,
      image: {
        src: require("assets/images/new/service-image-2.webp"),
        alt: "Депрессия и апатия" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },

    // Нам приходит заявка от клиента на работу с вами
    // Клиент может выбрать вас через каталог со всеми специалистами сервиса или при автоматическом подборе, когда он заполняет анкету о себе и выбирает темы, которые его беспокоят. Алгоритм предлагаем ему на выбор трех подходящих терапевтов. Если клиент обозначил в своей анкете темы, с которыми вы работаете, вы предлагаетесь в подборе. После того, как клиент выбрал вас и оставил нам свои данные, менеджер сервиса создает чат в мессенджере WhatsApp или Telegram с вами и клиентом.
    // В среднем первые клиенты приходят в течение 14 дней

    {
      title: "Нам приходит заявка от клиента на работу с вами",
      description1:
        "Клиент может выбрать вас через каталог со всеми специалистами сервиса или при автоматическом подборе, когда он заполняет анкету о себе и выбирает темы, которые его беспокоят. Алгоритм предлагаем ему на выбор трех подходящих терапевтов. Если клиент обозначил в своей анкете темы, с которыми вы работаете, вы предлагаетесь в подборе. После того, как клиент выбрал вас и оставил нам свои данные, менеджер сервиса создает чат в мессенджере WhatsApp или Telegram с вами и клиентом.",
      description2: "В среднем первые клиенты приходят в течение 14 дней",
      index: 2,
      image: {
        src: require("assets/images/new/service-image-3.webp"),
        alt: "Проблемы в отношениях" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },

    // Вы договариваетесь с клиентом о первой встрече
    // Мы рекомендуем психотерапевтам начинать общение первыми. Вы можете перейти в личную переписку с клиентом или остаться в чате с менеджером. Многие наши специалисты заранее уточняют организационные вопросы и уже имеют готовый документ с условиями своей работы, который присылают клиенту перед встречей. Присылать контракт не обязательно - все зависит от вашего подхода к коммуникации.
    // Менеджер создает чат в течение 24 часов после обращения клиента

    {
      title: "Вы договариваетесь с клиентом о первой встрече",
      description1:
        "Мы рекомендуем психотерапевтам начинать общение первыми. Вы можете перейти в личную переписку с клиентом или остаться в чате с менеджером. Многие наши специалисты заранее уточняют организационные вопросы и уже имеют готовый документ с условиями своей работы, который присылают клиенту перед встречей. Присылать контракт не обязательно - все зависит от вашего подхода к коммуникации.",
      description2:
        "Менеджер создает чат в течение 24 часов после обращения клиента",
      index: 3,
      image: {
        src: require("assets/images/new/service-image-4.webp"),
        alt: "Разобраться в семейных проблемах" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },

    // Клиент оплачивает вам сессии, вы переводите процент сервису
    // После проведенной сессии вы переводите оговоренный процент нашему сервису. Если клиент не пришел или передумал - вы ничего не оплачиваете.
    // Рекомендуем оплатить процент в течение 3-х дней после первой сессии

    {
      title: "Клиент оплачивает вам сессии, вы переводите процент сервису",
      description1:
        "После проведенной сессии вы переводите оговоренный процент нашему сервису. Если клиент не пришел или передумал - вы ничего не оплачиваете.",
      description2:
        "Рекомендуем оплатить процент в течение 3-х дней после первой сессии",
      index: 4,
      image: {
        src: require("assets/images/new/service-image-5.webp"),
        alt: "Личностный рост" || dataUsedForImageCommon.alt,
        title: null,
        props: {
          width: dataUsedForImageCommon.width,
          height: dataUsedForImageCommon.height,
        },
      },
    },
  ],

  images: [
    {
      image: {
        src: require("assets/images/feature/features_demo_1.webp"),
        alt:
          "Устроить личную жизнь и разобраться в семейных проблемах" ||
          dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_2.webp"),
        alt:
          "Поднять самооценку и понять, что вам нужно" || dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_3.webp"),
        alt:
          "Избавиться от страхов, панических атак и тревоги" ||
          dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_4.webp"),
        alt:
          "Преодолеть конфликты и наладить отношения с близкими" ||
          dataOverviewImageAlt,
        title: null,
      },
    },
    {
      image: {
        src: require("assets/images/feature/features_demo_5.webp"),
        alt: "Выйти из депрессии и обрести спокойствие" || dataOverviewImageAlt,
        title: null,
      },
    },
  ],
};

export const dataServicesPartnershipPage = {
  title: "Преимущества работы с нами",
  subtitle: "Преимущества работы с нами",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      id: 1,
      title: "Проходите супервизии и интервизии",
      subtitle: "",
      description:
        "Выявите причину, научитесь говорить «нет» без угрызения совести",
      image: {
        src: require("assets/images/services/service-1-1.webp"),
        alt: "",
        title: "",
      },
      icon: null,
    },
    {
      id: 2,
      title: "Не нужно арендовать кабинет",
      subtitle: "",
      description:
        "Научитесь ценить себя в отношениях с партнером, семьей и друзьями ",
      image: {
        src: require("assets/images/services/service-1-2.webp"),
        alt: "",
        title: "",
      },
      icon: null,
    },
    {
      id: 3,
      title: "Можно работать из любой точки мира",
      subtitle: "",
      description:
        "Найдем причины беспокойства и научимся справляться со стрессом",
      image: {
        src: require("assets/images/services/service-1-3.webp"),
        alt: "",
        title: "",
      },
      icon: null,
    },
    {
      id: 4,
      title: "Сами решаете, сколько клиентов готовы взять",
      subtitle: "",
      description:
        "Проведем через беременность и роды, чтобы вы стали счастливой мамой",
      image: {
        src: require("assets/images/services/service-1-4.webp"),
        alt: "",
        title: "",
      },
      icon: null,
    },
    {
      id: 5,
      title: "Бесплатно обучаетесь онлайн-консультированию",
      subtitle: "",
      description:
        "Полюбите себя полностью, обретете в материнстве покой и любовь",
      image: {
        src: require("assets/images/services/service-1-5.webp"),
        alt: "",
        title: "",
      },
      icon: null,
    },
    {
      id: 6,
      title: "Попадаете в комьюнити профессионалов",
      subtitle: "",
      description:
        "Обретёте покой в душе и сердце, научитесь беречь себя от раздражителей",
      image: {
        src: require("assets/images/services/service-1-6.webp"),
        alt: "",
        title: "",
      },
      icon: null,
    },
  ],
};

export const dataHeroPartnershipPage = {
  title: "4 100+ психологов уже развивают практику с PsyWealth",
  subtitle: "4 100+ психологов уже развивают практику с PsyWealth",
  description:
    "Мы всегда рады специалистам, которые относятся к профессии так же трепетно и бережно, как и мы.",
  //"Хранение документов еще никогда не было таким простым - услуги по организации и ведению электронных архивов",
  items: null,
  image: require("assets/images/hero/hero2-920x820.webp"),
  imageAlt: "Сервис психологической помощи - «PsyWealth»",
};

export const dataFeaturePartnershipPage = {
  title: "Ждём от вас",
  subtitle: "Ждём от вас",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  items: [
    {
      title: "От 3-х лет непрерывного опыта",
      subtitle: "",
      description:
        "Речь о платном консультировании. Студенческая практика не засчитывается.",
      icon: null,
      image: null,
      imageAlt: "",
    },
    {
      title: "От 500 часов обучения методу",
      subtitle: "",
      description:
        "Образование в одном из методов от 500 часов. Наличие регулярных супервизий обязательно.",
      icon: null,
      image: null,
      imageAlt: "",
    },
    {
      title: "Опыт личной терапии",
      subtitle: "",
      description:
        "Обязательно наличие регулярной индивидуальной личной психотерапии.",
      icon: null,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataOverviewPartnershipPage = {
  title: "Вы получите",
  subtitle: "Вы получите",
  description:
    // "Цифровизация бизнес-процессов и комплекс автоматизированных архивных процедур по использованию и доступу к документации",
    // Типовые решения подготовлены для быстрого внедрения в любую сферу бизнеса и госуправления: банки, госучреждения, коммерческие компании, промыш­ленные предприятия и крупные холдинги повышают операционную эффективность и автоматизируют документационные процессы на платформе ЭЛАР Контекст
    // На основе стандартных решений можно быстро внедрить их в любой сфере деятельности, которая касается бизнеса и госуправления: банки, государственные учреждения, компании с большим количеством сотрудников, крупные предприятия и холдинги могут повысить эффективность своих бизнес-процессов благодаря использованию ЭЛАР Контекст.
    "Это не наши слова — так считают наши пользователи",
  items: [
    {
      title: "Клиентов",
      subtitle: "",
      description1: "В среднем каждый наш специалист работает с 10 клиентами.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features12-1_600x600.webp"),
        alt: "Фото электронный архив финансовых и бухгалтерских документов",
        title: "",
      },
      list: {
        items: [],
      },
      control: null,
      // control: {
      //   show: false,
      //   msgSubmit: "Подробнее",
      //   title: "Архив кадровых документов и документов воинского учёта",
      //   description: "",
      //   descriptionHTML: null,
      // },
    },
    {
      title: "Супервизии (разумеется, бесплатные)",
      subtitle: "",
      description1:
        "В неделю у нас проходит 7 супервизионных групп – их можно посещать, как в качестве докладчика, так и в качестве слушателя.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features12-2_600x600.webp"),
        alt: "",
        title: "",
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Семинары (тоже бесплатные)",
      subtitle: "",
      description1:
        "Каждую неделю мы организовываем семинары от самых выдающихся членов профессионального сообщества.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features12-3_600x600.webp"),
        alt: "",
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
    {
      title: "Доступ к сообществу профессионалов",
      subtitle: "",
      description1:
        "Мы регулярно проводим общие онлайн-встречи, чтобы обменяться знаниями и опытом.",
      description2: "",
      icon: null,
      image: {
        src: require("assets/images/feature/features12-4_600x600.webp"),
        alt: "",
        title: null,
      },
      list: {
        items: [],
      },
      control: null,
    },
  ],
};

export const dataFAQComponent1 = {
  data:
    `<ul className="list-styled">` +
    `<li>Базовое высшее психологическое или смежное (педагогическое, медицинское, философское) образование</li>` +
    `<li>Подтвержденное сертификатами и дипломами образование в одном из психотерапевтических методов от 500 часов</li>` +
    `<li>Личная практика (за деньги, не учебная) от 3-х лет</li>` +
    `<li>Наличие регулярных суперивизий</li>` +
    `<li>Опыт личной психотерапии. Наличие регулярной личной психотерапии в текущий момент — весомый плюс.</li>` +
    `</ul>`,
};

export const dataFAQPartnershipPage = {
  title: "Вопросы и ответы",
  subtitle: "Вопросы и ответы",
  description: "Свяжитесь с нами, если у вас есть вопрос",
  content: {
    title: "Остались вопросы?",
    subtitle: "Остались вопросы?",
    description: "Напишите нам и специалист свяжется с вами",
    image: require("assets/images/question-image3_rb.webp"),
    imageAlt: "" || "",
  },
  items: [
    {
      title: "Как долго рассматривается заявка?",
      description: "В среднем мы рассматриваем анкету две-три недели.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Какие этапы после рассмотрения заявки?",
      description:
        "Если все условия соблюдены, то мы письмом приглашаем вас на собеседование с одним из наших кураторов сообщества. Кураторы — 18 опытных психотерапевтов, супервизоров, работающих на платформе PsyWealth более одного года.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Каковы условия принятия заявки на рассмотрение?",
      description: "",
      componentHTML: dataFAQComponent1,
      main: true,
      disable: false,
    },
    {
      title: "Сколько психотерапевт получает за сессию на платформе?",
      description:
        "Это зависит от процентной ставки, но в среднем за одну сессию с клиентом специалист получает около 2000 рублей при начале сотрудничества. У специалиста есть возможность увеличить стоимость своей работы при условии устойчивой практики на сервисе.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как проходят встречи с клиентами?",
      description:
        "Все сессии проходят только онлайн. У специалиста есть удобный личный кабинет с расписанием, системой оплаты и информацией о клиентах. Внутри кабинета и проводятся сессии — через видеосвязь. Использовать сторонние программы не нужно.",
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};

export const dataCTAPartnershipPage = {
  title: "Развивайте свою практику с нами",
  title1: "Заявка на консультацию",
  title2: "",
  subtitle: "Оставить заявку",
  description:
    "Оставить заявку на консультацию, наш менеджер свяжется с вами и поможет подобрать решение для вашей задачи",
  msg: {
    msgCTA: "Заполнить анкету",
  },
  backgroundImage: require("assets/images/bg/cta-bg2.webp"),
};

export const dataAboutPartnershipPage = {
  title: "Ждём от вас",
  subtitle: "Ждём от вас",
  description:
    "Оперативное и архивное хранение электронных документов, их систематизация и поиск, а также контроль доступа к корпоративному контенту",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
};

export const dataOurAdvantagesPartnershipPage = {
  title: "Что делает психотерапию с нами удобной?",
  subtitle: "Что делает психотерапию с нами удобной?",
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/about.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Ведение базы клиентов",
      subtitle: "",
      description:
        "С PsyWealth все данные о клиентах у вас под рукой в любое время. Теперь вы точно не забудете ни одного из ваших клиентов.",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Управление расписанием",
      subtitle: "",
      description:
        "Сервис позволит вам легко и быстро вести рабочее расписание. Зарегистрируйтесь и убедитесь в этом на практике.",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "История работы с клиентами",
      subtitle: "",
      description:
        "Быстрый доступ к сохраненным данным о работе с клиентом позволит вам сэкономить время и разгрузить голову.",
      suffix: "",
      prefix: "",
      icon: <BsLayoutTextWindowReverse />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Управление финансами",
      subtitle: "",
      description:
        "PsyWealth имеет гибкую систему управления финансовыми взаимоотношениями между специалистом и клиентом.",
      suffix: "",
      prefix: "",
      icon: <BsPencilSquare />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Уведомления",
      subtitle: "",
      description:
        "Мы уведомим вас о важных аспектах вашей работы с клиентами, будь то начало сеанса или необходимость напомнить клиенту об оплате.",
      suffix: "",
      prefix: "",
      icon: <BsSpeedometer2 />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsClipboard2 />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

export const dataMobileAppPartnershipPage = {
  title: "А вот что PsyWealth сделает за вас",
  subtitle: "А вот что PsyWealth сделает за вас",
  subtitle2: (
    <>
      This is a text in the first line;
      <br />
      this is a text in a second line
    </>
  ),
  description:
    "Мы взяли все организационные заботы на себя, чтобы вы могли сосредоточиться на самом важном — себе и своих эмоциях",
  image: {
    src: require("assets/images/feature/iphone14-2.webp"),
    alt: "",
    title: "",
  },
  items: [
    {
      title: "Запомнит все о клиентах",
      subtitle: "",
      description: "Запомнит все о клиентах",
      suffix: "",
      prefix: "",
      icon: <BsAspectRatio />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Запомнит расписание",
      subtitle: "",
      description: "Запомнит расписание",
      suffix: "",
      prefix: "",
      icon: <BsEmojiWink />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsMortarboard />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsStar />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: "Проконтролирует длительность сеансов",
      subtitle: "",
      description: "Проконтролирует длительность сеансов",
      suffix: "",
      prefix: "",
      icon: <BsXDiamond />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Сохранит информацию о сеансах",
      subtitle: "",
      description: "Сохранит информацию о сеансах",
      suffix: "",
      prefix: "",
      icon: <BsCameraVideo />,
      image: {
        src: null,
        imageAlt: "",
      },
    },

    {
      title: " Делаем оплату сессий удобной",
      subtitle: "",
      description:
        "Деньги за сессию списываются автоматически, но специалист получает их только после встречи. Если психолог отменит консультацию, деньги вернутся на вашу карту.",
      suffix: "",
      prefix: "",
      icon: <BsBrightnessHigh />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
    {
      title: "Бережём ваше время",
      subtitle: "",
      description:
        "Занимайтесь, где и когда вам удобно. Перенести или отменить сессию можно в личном кабинете в два клика.",
      suffix: "",
      prefix: "",
      icon: <BsActivity />,
      image: {
        src: null,
        imageAlt: "",
      },
    },
  ],
};

/**
 * Data of page "Partners" page of the site.
 *
 *
 */
export const dataHeroPartnershipPage2 = {
  title: "Присоединяйтесь к партнёрской программе «ELARCH-Архив»",
  subtitle:
    //  "Выберите удобную форму партнерства и зарабатывайте вместе с нами. Присоединяйтесь к партнерской программе прямо сейчас!",
    "Выберите удобную форму партнерства и зарабатывайте вместе с нами, присоединяйтесь прямо сейчас!",
  description:
    "Рекомендуйте электронный архив «ELARCH-Архив» для организаций и зарабатывайте до 40% от платежей клиентов и 50% от стоимости внедрения",
  items: [],
  image: require("assets/images/hero/hero-img (1).webp"),
  imageAlt: "Выберите удобную форму партнерства и зарабатывайте вместе с нами",
};

export const dataFeaturePartnershipPage2 = {
  title: "Если вы занимаетесь",
  subtitle: "Если вы занимаетесь",
  description: "Если вы занимаетесь",
  items: [
    {
      title: "Разработкой ПО и ИТ-консалтингом",
      subtitle: "",
      description:
        "увеличивайте чек за счет продажи дополнительного софта - сервиса электронного архива организации",
      icon: <FaGift />,
      //image: Image1,
      image: null,
      imageAlt: "",
    },
    {
      title: "Бухучетом и оказываете аудиторские услуги",
      subtitle: "",
      description: "Повышайте эффективность своих услуг за счет сервиса ЭДО",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Работаете с технической и конструкторской документацией",
      subtitle: "",
      description:
        "Зарабатывайте на внедрении сервиса электронного архива в свои проекты",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataFeatureTabsPartnershipPage = {
  title: "Требования к психологам",
  subtitle: "Требования к психологам",
  description: "",
  image: {
    src: null,
    alt: "",
    title: "",
  },
  items: [
    {
      tab: { id: 1, title: "Начинающие", key: "home1", available: true },
      title: "Начинающим психологам",
      description: "Для работы с нами необходимо",
      image: {
        src: require("assets/images/new/why-choose-img-1.webp"),
        alt: "Требования к начинающим психологам",
        title: "",
      },
      items: [
        {
          title: "Профильное образование",
          description:
            "Высшее психологическое, медицинское (психиатрия) или переподготовка (от 500 ч).",
        },
        {
          title: "Опыт работы",
          description: "От 3-х лет непрерывного опыта консультировании.",
        },
        {
          title: "Налоговый статус",
          description:
            "Наличие статуса самозанятого или индивидуального предпринимателя, открытые в России.",
        },
        {
          title: "Личная терапия",
          description:
            "Для нас важно, чтобы Вы имели личный опыт прохождения психотерапии в качестве клиентов.",
        },
        {
          title: "Супервизия и интервизия",
          description:
            "Мы верим, что осознанность и мастерство терапевта эффективно развивается только в диалоге с коллегами.",
        },
        {
          title: "Опыт работы в онлайн-формате",
          description:
            "Нам важно, чтобы вы умели и были готовы работать в формате видеосессий, синхронной и асинхронной переписки.",
        },
      ],
      control: {
        show: true,
        msgSubmit: "Подробнее",
        link: "/",
      },
    },
    {
      tab: { id: 2, title: "Опытные", key: "home2", available: true },
      title: "Опытным психологам",
      description: "Для работы с нами необходимо",
      image: {
        src: require("assets/images/new/why-choose-img-1.webp"),
        alt: "Требования к начинающим психологам",
        title: "",
      },
      items: [
        {
          title: "Профильное образование",
          description:
            "Высшее психологическое, медицинское (психиатрия) или переподготовка (от 500 ч).",
        },
        {
          title: "Опыт работы",
          description: "От 3-х лет непрерывного опыта консультировании.",
        },
        {
          title: "Налоговый статус",
          description:
            "Наличие статуса самозанятого или индивидуального предпринимателя, открытые в России.",
        },
        {
          title: "Личная терапия",
          description:
            "Для нас важно, чтобы Вы имели личный опыт прохождения психотерапии в качестве клиентов.",
        },
        {
          title: "Супервизия и интервизия",
          description:
            "Мы верим, что осознанность и мастерство терапевта эффективно развивается только в диалоге с коллегами.",
        },
        {
          title: "Опыт работы в онлайн-формате",
          description:
            "Нам важно, чтобы вы умели и были готовы работать в формате видеосессий, синхронной и асинхронной переписки.",
        },
      ],
      control: {
        show: true,
        msgSubmit: "Подробнее",
        link: "/",
      },
    },
  ],
};

export const dataFeature2PartnershipPage = {
  title: "Стартуйте уже сегодня",
  subtitle: "Стартуйте уже сегодня",
  description: "Стартуйте уже сегодня",
  items: [
    {
      title: "Быстро начать",
      subtitle: "",
      description:
        "Вы получите готовые скрипты, обучающие материалы о наших продуктах и нашу поддержку в ходе сделки",
      icon: <FaGift />,
      //image: Image1,
      image: null,
      imageAlt: "",
    },
    {
      title: "Легко продавать",
      subtitle: "",
      description:
        "Мы рассчитываем, что к 2025 году КЭДО станет обязательным и электронные архивы станут необходимым сервисом",
      // "Оцифровка документов перед сдачей документов в архив обеспечивает быстрый доступ к данным в любой момент, когда они могут понадобиться."
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
    {
      title: "Выгодно сотрудничать",
      subtitle: "",
      description:
        "Получайте процент за продажу, внедрение и продление лицензий клиентами",
      icon: <FaGift />,
      image: null,
      imageAlt: "",
    },
  ],
};

export const dataProcessPartnershipPage = {
  title: "Давайте зарабатывать вместе",
  subtitle: "Процесс получения партнерского статуса",
  description: "Процесс получения партнерского статуса",
  items: [
    {
      title: "Станьте участником партнерской программы «PsyWealth»",
      subtitle: "",
      description:
        "Регистрируетесь в партнёрской программе «PsyWealth» на сайте или через нашего менеджера.",
      image: require("assets/images/timeline/timeline2-1.webp"),
      imageAlt: "",
      icon: <GoFlame />,
    },
    {
      title: "Приводите клиента",
      subtitle: "",
      description: "Расскажите о нашем продукте своим клиентам и партнерам.",
      image: require("assets/images/timeline/timeline2-2.webp"),
      imageAlt: "",
      icon: <GoSearch />,
    },
    {
      title: "Презентуйте продукт и завершите сделку",
      subtitle: "",
      description:
        "Наш менеджер поможет вам презентовать продукт и завершить сделку.",
      image: require("assets/images/timeline/timeline2-3.webp"),
      imageAlt: "",
      icon: <GoMortarBoard />,
    },
    {
      title: "Вознаграждение",
      subtitle: "",
      description:
        "Вы получаете вознаграждение за все платежи по зарегистрированным от вас клиентам. Отслеживайте ваши сделки и прибыль в личном кабинете.",
      image: require("assets/images/timeline/timeline2-4.webp"),
      imageAlt: "",
      icon: <GoRocket />,
    },
  ],
};

export const dataPricingPartnershipPage = {
  title: "Варианты сотрудничества",
  subtitle: "Варианты сотрудничества",
  description: "Выберите интересующий вас вариант сотрудничества",
  description1:
    "* новый клиент - юридическое лицо, не использующее ранее сервис «ELARCH-Архив», оплатившее лицензию по договору с ELARCH.RU",

  items: [
    {
      title: "Реферальный партнер",
      subtitle: "Рекомендуйте «ELARCH-Архив» друзьям, клиентам и партнерам",
      showSubtitle: true,
      price: 0.0,
      showPrice: false,
      description: "",
      msg: {
        msgPurchase: "Стать партнером",
      },

      items: [
        { title: "15% от суммы сделки с клиентом" },
        { title: "5% от суммы по продлению" },
        { title: " - " },
        { title: " - " },
        { title: "Презентационные материалы" },
        { title: "Без обучения" },
      ],
    },
    {
      title: "Бизнес-партнер",
      subtitle: "Привлекайте от 3-х новых клиентов в квартал",
      showSubtitle: true,
      price: 0.0,
      showPrice: false,
      description: "",
      msg: {
        msgPurchase: "Стать партнером",
      },

      items: [
        { title: "30% от суммы сделки с клиентом" },
        { title: "10% от суммы по продлению" },
        { title: "50% от стоимости внедрения" },
        { title: " - " },
        { title: "Презентационные материалы" },
        { title: "Без обучения" },
      ],
    },
    {
      title: "Генеральный партнер",
      subtitle: "Привлекайте от 5-х новых клиентов в квартал",
      showSubtitle: true,
      price: 0.0,
      showPrice: false,
      description: "",
      msg: {
        msgPurchase: "Стать партнером",
      },

      items: [
        { title: "40% от суммы сделки с клиентом" },
        { title: "20% от суммы по продлению" },
        { title: "50% от стоимости внедрения" },
        { title: " - " },
        { title: "Презентационные материалы" },
        { title: "Обязательное обучение" },
      ],
    },
  ],
};

export const dataTestimonialPartnershipPage = {
  title: "Что говорят наши клиенты?",
  subtitle: "Отзывы",
  description: "",
  items: [
    {
      title: "Юлия Ш.",
      subtitle: "Саратов, опыт 5 лет",
      description:
        "Я занимаюсь любимым делом из дома и в удобное для себя время. Это стало возможным благодаря сотрудничеству с PsyWealth. Технически сервис очень прост и при этом продуман до мелочей. Например, я могу включать в рабочий календарь личные дела: супервизии, обучение и что угодно ещё.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
    {
      title: "Дарья М.",
      subtitle: "Киров, опыт 8 лет",
      description:
        "С сервисом сотрудничаю полгода — я довольна этой работой и дорожу ей. PsyWealth — моя уверенность в том, что у меня всегда будут клиенты. Плюс ко всему — удобный график работы, который я составляю сама. А значит, уровень дохода и количество свободного времени зависят только от меня.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
    {
      title: "Александра П.",
      subtitle: "Ярославль, опыт 12 лет",
      description:
        "PsyWealth даёт возможность закручивать классные коллаборации. Я работаю с сервисом несколько месяцев, и за это время PsyWealth пригласил меня написать статьи для интересных изданий и даже выступить очно в крупных компаниях.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
    {
      title: "Лукьянов Андрей",
      subtitle: "Коммерческий директор ООО «АСТРА»",
      description:
        "Для нашей задачи создания поддерживающей среды для сотрудников организации PsyWealth подошел почти идеально. Хорошо, что продукт постоянно прогрессирует и радует новыми возможностями.",
      image: require("assets/images/testimonial/testimonial-1-150x150.webp"),
    },
  ],
};
