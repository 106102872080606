export const getThemeStyles = (name) => {
  if (name === "contentTitle") {
    return "display-6 fw-bold";
  } else if (name === "contentDescription") {
    return "lead";
  } else if (name === "sectionHeader") {
    return "text-center";
  } else if (name === "sectionHeaderTitle") {
    // return "h1 pt-2 pt-sm-4 pt-lg-5 pb-2 mb-3 mb-lg-4 mt-md-3 mt-lg-0";
    return "h1";
  } else if (name === "sectionHeaderDescription") {
    return "";
  }

  return "";
};
