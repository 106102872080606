import classNames from "classnames";
import { getThemeStyles } from "components/utils/Theme";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { SectionTitles } from "./SectionTitles";

/**
 * Component "Header" of the site.
 *
 * @component
 * @version 1.0.0
 **/
export const SectionHeader = (props) => {
  const {
    data,
    type = "style1",
    showDescription,
    showSubtitle,
    className,
    render,
    useAnimation,
    ...otherProps
  } = props;
  const sectionHeaderStyle = getThemeStyles("sectionHeader");

  const contentHeader2 = (
    <div className="row">
      <div
        className={classNames("section-header", sectionHeaderStyle, className)}
      >
        <SectionTitles
          data={data}
          showSubtitle={showSubtitle}
          showDescription={showDescription}
          useAnimation={useAnimation}
          {...otherProps}
        />
      </div>
    </div>
  );

  const contentHeader1 = (
    <div
      className={classNames(
        "section-header inner-container-small text-center mb-4 mb-sm-7",
        sectionHeaderStyle,
        className
      )}
    >
      <SectionTitles
        data={data}
        showSubtitle={showSubtitle}
        showDescription={showDescription}
        useAnimation={useAnimation}
        {...otherProps}
      />
    </div>
  );

  return (
    <Fragment>
      {render ? (
        render()
      ) : (
        <Fragment>
          {(() => {
            if (type === "style2") {
              return contentHeader2;
            }
            return contentHeader1;
          })()}
        </Fragment>
      )}
    </Fragment>
  );
};

SectionHeader.propTypes = {
  data: PropTypes.object,
  type: PropTypes.oneOf(["style1", "style2"]),
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  render: PropTypes.func,
  useAnimation: PropTypes.bool ?? true,
};
