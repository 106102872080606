import React from "react";
import {
  BlogLayout,
  BlogSinglePostPage,
  PostsByCategoryPage,
  PostsByTagPage,
  PostsListPage,
} from "./Blog";
import { PageContacts } from "./Contacts";
import { PageCountDown } from "./CountDown";
import { PageCourses } from "./Courses";
import { PageDemo } from "./Demo";
import { PageFAQ } from "./FAQ";
import { PageFreePsychologicalHelp } from "./FreeHelp";
import { PageHome } from "./Home";
import { PageNotFound } from "./NotFound";
import { PageOurTeam } from "./OurTeam";
import { PagePartnersPsychologist } from "./Partnership";
import {
  PagePaymentTerms,
  PageTermsAndConditions,
  PolicyPageLayout,
} from "./Policy";
import { LayoutPsychologistProfile } from "./Psychologist";
import { PageSearch } from "./Search";
import { PageTests } from "./Tests";
import { PageTypography } from "./Typography";

const BlogLayoutLazy = React.lazy(() => import("./Blog/BlogLayout"));
const PostsListPageLazy = React.lazy(() => import("./Blog/PostsList.page"));
const BlogSinglePostPageLazy = React.lazy(() =>
  import("./Blog/BlogSinglePost.page")
);
const PostsByCategoryPageLazy = React.lazy(() =>
  import("./Blog/PostsByCategory.page")
);
const PostsByTagPageLazy = React.lazy(() => import("./Blog/PostsByTag.page"));
const PageContactsLazy = React.lazy(() => import("./Contacts/Contacts.page"));
const PageCountDownLazy = React.lazy(() =>
  import("./CountDown/CountDown.page")
);
const PageCoursesLazy = React.lazy(() => import("./Courses/Courses.page"));
const LayoutCustomerProfileLazy = React.lazy(() =>
  import("./Customer/CustomerProfile.layout")
);
const PageDemoLazy = React.lazy(() => import("./Demo/Demo.page"));
const PageFAQLazy = React.lazy(() => import("./FAQ/FAQ.page"));
const PageFreePsychologicalHelpLazy = React.lazy(() =>
  import("./FreeHelp/FreeHelp.page")
);
const PageNotFoundLazy = React.lazy(() => import("./NotFound/NotFound.page"));
const PageOurTeamLazy = React.lazy(() => import("./OurTeam/OurTeam.page"));
const PagePartnersPsychologistLazy = React.lazy(() =>
  import("./Partnership/PartnersPsychologist.page")
);
const PagePaymentTermsLazy = React.lazy(() =>
  import("./Policy/PaymentTerms.page")
);
const LayoutPsychologistProfileLazy = React.lazy(() =>
  import("./Psychologist/PsychologistProfile.layout")
);
const PageSearchLazy = React.lazy(() => import("./Search/Search.page"));
const PageTermsAndConditionsLazy = React.lazy(() =>
  import("./Policy/TermsAndConditions.page")
);
const PageTestsLazy = React.lazy(() => import("./Tests/Tests.page"));
const PageTypographyLazy = React.lazy(() =>
  import("./Typography/Typography.page")
);

export {
  BlogLayout,
  BlogLayoutLazy,
  BlogSinglePostPage,
  BlogSinglePostPageLazy,
  LayoutCustomerProfileLazy,
  LayoutPsychologistProfile,
  LayoutPsychologistProfileLazy,
  PageContacts,
  PageContactsLazy,
  PageCountDown,
  PageCountDownLazy,
  PageCourses,
  PageCoursesLazy,
  PageDemo,
  PageDemoLazy,
  PageFAQ,
  PageFAQLazy,
  PageFreePsychologicalHelp,
  PageFreePsychologicalHelpLazy,
  PageHome,
  PageNotFound,
  PageNotFoundLazy,
  PageOurTeam,
  PageOurTeamLazy,
  PagePartnersPsychologist,
  PagePartnersPsychologistLazy,
  PagePaymentTerms,
  PagePaymentTermsLazy,
  PageSearch,
  PageSearchLazy,
  PageTermsAndConditions,
  PageTermsAndConditionsLazy,
  PageTests,
  PageTestsLazy,
  PageTypography,
  PageTypographyLazy,
  PolicyPageLayout,
  PostsByCategoryPage,
  PostsByCategoryPageLazy,
  PostsByTagPage,
  PostsByTagPageLazy,
  PostsListPage,
  PostsListPageLazy,
};
