import { SectionSubscribeLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataPolicyPrivacyPage, dataSubscribe } from "data";
import { Fragment } from "react";
import { SectionPolicyPrivacyTermsLazy } from "./components";

/**
 * Component for showing Page "Privacy Policy Terms" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PagePrivacyTerms = (props) => {
  const { id = "privacy-terms" } = props;

  return (
    <Fragment>
      <SEO data={dataPolicyPrivacyPage.meta}>
        <link rel="canonical" href="https://psywealth.com/policies/privacy" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataPolicyPrivacyPage} />
        <SectionPolicyPrivacyTermsLazy data={dataPolicyPrivacyPage} />
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
      </PageMain>
    </Fragment>
  );
};

export default PagePrivacyTerms;
