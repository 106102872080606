import useCarouselControll from "hooks/useCarousel";
import { PropTypes } from "prop-types";
import { Fragment } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardPsychologist1 from "./CardPsychologist1";
import CardPsychologist2 from "./CardPsychologist2";
import CardPsychologist3 from "./CardPsychologist3";

import "./Section.style.scss";

// NOTE :

const SectionGoToPsychologists = (props) => {
  // const {} = props;
  // console.log("@@@@", data, index);

  return (
    <div className="col-lg-4 col-md-12 col-12">
      <div className="card blog-grid">
        <div className="card-content blog-grid-text p-4 align-middle">
          <h2 className="card-title h5 mb-3">
            <Link to={"/therapists"} className="btn btn-primary theme-btn">
              Наши психологи
            </Link>
          </h2>
          <p className="display-30">Наши психологи</p>
        </div>
      </div>
    </div>
  );
};

const SectionContent = (props) => {
  const { data: topPsychologists, type } = props;
  const {
    refCarousel,
    currentCaruselIndex,
    handleSelect,
    onPrevClick,
    onNextClick,
    ContentControl,
  } = useCarouselControll();

  return (
    <Fragment>
      <div className="row">
        <ContentControl />
        <div className="d-none" aria-live="polite" aria-atomic="true">
          slide <span className="current">{currentCaruselIndex + 1}</span> of{" "}
          {topPsychologists.length}
        </div>

        <Carousel
          ref={refCarousel}
          activeIndex={currentCaruselIndex}
          onSelect={handleSelect}
          variant="dark"
          slide
          indicators={false}
          controls={false}
        >
          {topPsychologists &&
            topPsychologists.length > 0 &&
            topPsychologists.map((item, index) => {
              const newItems = topPsychologists.slice(
                index,
                index + (type === "style2" ? 4 : 3)
              );

              if (!newItems || newItems.length < 2) return null;

              // console.log(newItems, item, index);

              return (
                <Carousel.Item index={index} key={index}>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                      {newItems.map((itemNew, indexNew) => {
                        const idx = index + indexNew;

                        if (type === "style2") {
                          return (
                            <CardPsychologist2
                              data={itemNew}
                              index={idx}
                              key={idx}
                              type="top"
                            />
                          );
                        } else if (type === "style3") {
                          return (
                            <CardPsychologist3
                              data={itemNew}
                              index={idx}
                              key={idx}
                              type="top"
                            />
                          );
                        }

                        return (
                          <CardPsychologist1
                            data={itemNew}
                            index={idx}
                            key={idx}
                            type="top"
                          />
                        );
                      })}
                      {newItems.length === 2 && <SectionGoToPsychologists />}
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
    </Fragment>
  );
};

/**
 * Component section content "Top Psychologist (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data,
    id = "",
    cn = "",
    type,
    showTitle,
    showSubtitle,
    showDescription,
    useAnimation,
    ...otherProps
  } = props;

  return (
    <SectionContent
      data={data}
      id={id}
      cn={cn}
      type={type}
      showTitle={showTitle}
      showSubtitle={showSubtitle}
      showDescription={showDescription}
      {...otherProps}
    />
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
